import { localeInterface } from "../../interfaces/actionInterfaces";
import { CHECKOUT_ACTIONS } from "../actions/actionTypes";
const initialState = {
  couponList: [],
  homeAddressList: [],
  homeDeliverCharges: {},
  branchSlots: {
    mornings: [],
    afternoon: [],
    evening: [],
  },
  vehicleData: {},
  bookingData: {
    payload: {},
    journeyData: {},
  },

  userEmergencyExists: true,
  rzpKey: "",
  isHomeDeliveryAvailable: true,
};
const {
  SET_COUPON_MASTERS,
  SET_HOME_ADDRESS_LIST,
  SET_HOME_DELIVERY_CHARGES,
  SET_BRANCH_SLOTS,
  SET_VEHICLE_DATA,
  SET_BOOKING_DATA,
  VALIDATE_USER_EMERGENCY_DETAILS_FAILED,
  VALIDATE_USER_EMERGENCY_DETAILS_REQUESTED,
  VALIDATE_USER_EMERGENCY_DETAILS_SUCCEED,
  HOME_DELIVERY_NOT_AVAILABLE,
  SET_CREATE_BOOKING_SUCCESS,
  GET_HOME_DELIVERY_CHARGES,
  TOGGLE_HOME_DELIVERY_AVAILABLE,
} = CHECKOUT_ACTIONS;
const checkoutReducer = (
  state: any = initialState,
  action: localeInterface
) => {
  const prevState = { ...state };
  const { type } = action;

  switch (type) {
    case SET_COUPON_MASTERS:
      return {
        ...state,
        couponList: action.payload,
      };
    case SET_HOME_ADDRESS_LIST:
      return {
        ...state,
        homeAddressList: action.payload,
      };
    case SET_HOME_DELIVERY_CHARGES:
      return {
        ...state,
        homeDeliverCharges: action.payload,
        addressNotDeliverable: null,
        isHomeDeliveryAvailable: true,
      };
    case HOME_DELIVERY_NOT_AVAILABLE:
      // dispatch when api gives 422 , delivery address is not deliverable
      return {
        ...state,
        addressNotDeliverable: action.payload,
        isHomeDeliveryAvailable: false,
      };
    case SET_BRANCH_SLOTS:
      return {
        ...state,
        branchSlots: action.payload,
      };
    case SET_VEHICLE_DATA:
      return {
        ...state,
        vehicleData: action.payload,
      };
    case SET_BOOKING_DATA:
      return {
        ...state,
        bookingData: action.payload,
      };
    case VALIDATE_USER_EMERGENCY_DETAILS_REQUESTED:
      return {
        ...state,
        userEmergencyExists: true,
      };
    case VALIDATE_USER_EMERGENCY_DETAILS_SUCCEED:
      return {
        ...state,
        userEmergencyExists: true,
      };
    case VALIDATE_USER_EMERGENCY_DETAILS_FAILED:
      return {
        ...state,
        userEmergencyExists: false,
      };
    case SET_CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        rzpKey: action.payload,
      };
    case TOGGLE_HOME_DELIVERY_AVAILABLE:
      return {
        ...state,
        isHomeDeliveryAvailable: true,
      };
    default:
      return { ...prevState };
  }
};
export default checkoutReducer;
