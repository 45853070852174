import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import homeSaga from "./homeSaga";
import rentSaga from "./rentSaga";
import checkoutSaga from "./checkoutSaga";
import profileSaga from "./profileSaga";
import globalSaga from "./globalSaga";
import bookingSaga from "./bookingSaga";
export function* rootSaga() {
  yield all([
    authSaga(),
    homeSaga(),
    rentSaga(),
    checkoutSaga(),
    profileSaga(),
    globalSaga(),
    bookingSaga()
  ]);
}
