import { New, upcomingcity } from "../assets";
import { LocalStorage } from "../constant/enum";
import { getLocalStorageData } from "../utills/helper";

const CityBadge = ({
  ind,
  item,
  cityData,
  getSelectedCity,
  setIsUpcomingModal,
  filterCommingSoonCity
}) => {
  const handleCityClick = () => {
    getSelectedCity(ind);
    /* if (item.status === 5) {
      setIsUpcomingModal(true);
    } */
  };

  // if (item.status === 5) cityExistZoho();

  if (item?.status === 2 || item?.status === 3 || item?.status === 4)
    return null;

  return (
    <>
      <div
        key={ind}
        className="col-md-2"
        /* data-bs-dismiss={item?.status === 5 ? "" : "modal"} */

        onClick={handleCityClick}
        data-bs-target={item?.status === 5 ? filterCommingSoonCity(item) : ""}
        data-bs-toggle="modal"
        data-bs-dismiss="modal"
      >
        <div className="city-model-wrap">
          {item.status === 5 || item.status === 6 ? (
            <div>
              {item.status === 5 ? (
                <img
                  src={upcomingcity}
                  className="city-new"
                  style={{ width: "80px" }}
                />
              ) : (
                <img src={New} className="city-new" />
              )}
            </div>
          ) : null}
          <img src={item.city_icon_image_path} alt="" />
          <p
            className={`${
              item?.city_name == cityData?.city_name ? "active-city" : ""
            }`}
            title={item.city_name}
          >
            {item.city_name}
          </p>
        </div>
      </div>
    </>
  );
};

export default CityBadge;
