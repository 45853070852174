import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { deleteIcon, editIcon } from "../assets";
import AddressSection from "./AddressSection";
import { actions } from "../redux";
const initiaState = {
  id: null,
  name: "",
  address: "",
  landmark: "",
  zipcode: "",
  city: "",
  latitude: 0,
  longitude: 0,
  saveAs: "",
  isDefaultAddress: false,
  formattedAddress: "",
  others: null,
};
interface Props {
  addressData?: any;
  handleChange?: Function;
  addAddess: boolean;
}
const AddressModel = ({ handleChange, addAddess }: Props) => {
  const dispatch = useDispatch();
  const { homeAddressList } = useSelector(
    (state: RootState) => state.checkoutReducer
  );
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const addressModel = locale["addressModel"];
  const [toggleAddress, setToggleAddress] = useState(addAddess);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [state, setState] = useState(initiaState);
  const handleDeleteAddress = (id) => {
    dispatch(actions.deleteAddress({ id }));
  };

  const handleEditAddress = (data) => {
    setIsEditAddress(true);
    setState({
      id: data.id,
      name: data?.name,
      address: data?.address1,
      landmark: data?.landmark,
      zipcode: (data?.zip && String(data?.zip)) || "",
      city: data?.city?.city_name,
      latitude: data?.latitude,
      longitude: data?.longitude,
      saveAs: data?.user_addresses?.save_as,
      isDefaultAddress: data?.user_addresses?.is_default,
      formattedAddress: data?.formatted_address,
      others: data?.user_addresses?.others,
    });
    setToggleAddress(true);
  };

  const resetState = () => {
    if (addAddess) {
      const ele = document.getElementById("settingCloseModel");
      if (ele) ele.click();
    } else {
      setToggleAddress(false);
    }
    setIsEditAddress(false);
    setState(initiaState);
  };
  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex={-1}
      id="offcanvaschangeaddress"
      aria-labelledby="offcanvaschangeaddressLabel"
    >
      <div></div>
      <div className="offcanvas-header top-header-canvas">
        <h5 className="offcanvas-title" id="offcanvaschangeaddressLabel">
          {toggleAddress ? "Add" : "Change"} {addressModel["deliveryAddress"]}
        </h5>
        {toggleAddress && (
          <button
            type="button"
            className="btn-back"
            aria-label="Close"
            onClick={() => resetState()}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </button>
        )}
        <button
          onClick={() => resetState()}
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          id="settingCloseModel"
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div className="offcanvas-body text-start">
        {!toggleAddress ? (
          <div className="mt-5 pt-3">
            {homeAddressList?.map((item, ind) => (
              <div className="overflow-hidden">
                <div className="row align-items-center change-address-wrapper-inner">
                  <div className="col-md-7 col-sm-7 col-8  address-details-wrap">
                    <div className="d-flex align-items-center">
                      <span className="use-address-icon">
                        <i className="fa fa-location-dot"></i>
                      </span>
                      <h6>{item?.name}</h6>
                    </div>
                    <p>{`${item.address1 ? item.address1 : ""} ${item.address2 ? item.address2 : ""
                      } ${item.landmark ? item.landmark : ""}`}</p>{" "}
                    {(addressModel["addressType"] as [])?.map(
                      (type, ind) =>
                        item?.user_addresses?.save_as === type["name"] && (
                          <button className="btn-home smallest-text">
                            <i
                              className={type["className"]}
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;{type["name"]}
                          </button>
                        )
                    )}
                  </div>
                  <div className="col-md-5 col-sm-5 col-4 use-button-wrap text-end">
                    <div className="edit-del-add">
                      <a title="Edit Address"
                        onClick={() => {
                          handleEditAddress(item);
                        }}
                      >
                        <img src={editIcon} alt="edit" />
                      </a>

                      <a title="Delete Address" onClick={() => handleDeleteAddress(item?.id)}>
                        <img src={deleteIcon} alt="delete" />
                      </a>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-sm rounded-pill"
                      data-bs-dismiss="offcanvas"
                      onClick={() => handleChange && handleChange(item)}
                    >
                      {addressModel["useAddress"]}
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <button
              onClick={() => {
                setToggleAddress(true);
              }}
              className="freedo-primary-button rounded-pill d-block text-dark text-center w-100"
            >
              {addressModel["addAddress"]}
            </button>
          </div>
        ) : (
          <AddressSection
            resetState={resetState}
            addressData={state}
            isEditAddress={isEditAddress}
          />
        )}
      </div>
    </div>
  );
};

export default AddressModel;
