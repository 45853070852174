import React, { useEffect, useState } from "react";
import { banner } from "../../assets";
import { Routes } from "../../constant/routeContants";
import { Helmet } from "react-helmet";

const Index = () => {
  const TERMS_CONDITIONS_APP =
    window.location.pathname === Routes.TERMS_CONDITIONS_APP;
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    const handleDarkModeChange = (event) => {
      setIsDarkMode(event.matches);
    };

    darkModeMediaQuery.addEventListener("change", handleDarkModeChange);
    setIsDarkMode(darkModeMediaQuery.matches);

    return () => {
      darkModeMediaQuery.removeEventListener("change", handleDarkModeChange);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta name="author" content="Freedo Rentals" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Bike Rentals in Gurgaon | Bike For Rent in Gurgaon"
        />
        <meta
          property="og:description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta
          property="og:url"
          content="https://freedo.rentals/terms-conditions"
        />
        <meta
          property="og:image"
          content="https://freedo.rentals/page image url"
        />
        <meta property="og:site_name" content="Freedo Rentals" />
        <link rel="canonical" href="https://freedo.rentals/terms-conditions/" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className={`${TERMS_CONDITIONS_APP && "mt-70"}`}>
        {!TERMS_CONDITIONS_APP && (
          <div
            className="privacy-banner"
            style={{ backgroundImage: `url(${banner})` }}
          >
            <h5 className="banner-title">FREEDO TERMS & CONDITIONS</h5>
          </div>
        )}
        <div className="freedo-policy container" style={{ background: isDarkMode ? "#121212" : "" }} >
          <div className={` ${!TERMS_CONDITIONS_APP && (isDarkMode ? "card" : "card mb-5")}`}>
            <div
              className="card-body px-4"
              style={{ background: isDarkMode ? "#121212" : "" }}
            >
              <div className="mt-2">
                <h6
                  className="mb-1"
                  style={{ color: isDarkMode ? "#FFF" : "" }}
                >
                  1. GENERAL
                </h6>
                <p>
                  These terms & conditions are applied to a user who has access
                  to avail the services provided by Freedo. These will regulate
                  & govern the relationship between Freedo and "User". Freedo
                  and "User" are hereinafter will be collectively referred as
                  "Parties" and individually as "Party". These terms &
                  conditions shall constitute a legally valid & binding contract
                  under the applicable laws. Use of any service through Freedo’s
                  Website/App by the user shall be deemed to be an acceptance of
                  all terms and conditions set forth herein. User is advised to
                  read these terms and conditions carefully before
                  registering/using on the Website/App or availing any services
                  through the same. User is advised not to use the Website/App
                  or avail any services being offered through them by Freedo, if
                  a user doesn’t agree with these terms and conditions. Freedo
                  reserves the right to change/alter/modify these terms and
                  conditions in its sole discretion, from time to time, with or
                  without prior notice to the user. Notice of any changes shall
                  be made by Freedo promptly & communicated to the user. In the
                  event of any conflict between these terms and conditions or
                  with any other document related to Freedo, these terms and
                  conditions shall govern. All the terms and expressions are
                  defined under ANNEXURE A. Freedo is a wholly owned and
                  controlled enterprise of Hero MotoCorp Limited. Freedo name
                  and its logo are registered trademark of Hero MotoCorp
                  Limited.
                </p>
                <h6
                  className="mb-1"
                  style={{ color: isDarkMode ? "#FFF" : "" }}
                >
                  2. USER REGISTRATION
                </h6>
                <p>
                  In order to avail the Services offered by Freedo, User shall
                  be required to complete the Registration process through its
                  official Website/App. User registration must satisfy the
                  eligibility criteria & be supported by all the necessary
                  documentation mentioned under ANNEXURE A. Just by satisfying
                  the eligibility criteria an applicant will not be
                  automatically given a right to become a user and avail the
                  services offered by Freedo. Registration acceptance is subject
                  to approval by Freedo at its sole discretion. Freedo reserves
                  all the rights to verify the authenticity of the documents
                  provided by the applicant/user at any point of time.
                </p>
                <div className="mt-1">
                  <p>TERMS OF USE</p>
                  <div>
                    <p>
                      <b>1. Booking</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a) The user is required to book the vehicle or service
                          strictly through the Freedo Rental Mobile App or
                          Freedo Rental.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) The booking made shall be made and honored by the
                          registered user only.
                        </p>
                      </li>
                      <li>
                        <p>
                          c) The booking shall be strictly non-transferable.
                        </p>
                      </li>
                      <li>
                        <p>
                          d) The booking shall be made and honored as per the
                          available inventory.
                        </p>
                      </li>
                      <li>
                        <p>
                          e) The booking shall be open to change as per the
                          availability of the inventory vehicles.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>2. Vehicle Pick-Up & Return</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a) Freedo Rentals, shall notify the pick-up and drop
                          location of the vehicle through Freedo Rentals Mobile
                          App/ Freedo Rentals Website.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) The User shall flourish a valid Government Id proof
                          before taking the delivery of the vehicle.
                        </p>
                      </li>
                      <li>
                        <p>
                          c) The User shall dutifully pick-up the vehicle from
                          the designated location.
                        </p>
                      </li>
                      <li>
                        <p>
                          d) The User, before taking the delivery of the
                          vehicle, shall inspect the vehicle to the best of
                          his/her capability for signs of damage or malfunction
                          and inform Freedo if any irregularities are observed.
                        </p>
                      </li>
                      <li>
                        <p>
                          e) Freedo shall initiate the ride/ booking start time
                          as soon as the physical possession of the vehicle has
                          been transferred to the User.
                        </p>
                      </li>
                      <li>
                        <p>
                          f) The User shall return the vehicle to same location
                          as designated by Freedo.
                        </p>
                      </li>
                      <li>
                        <p>
                          g) The User shall return the vehicle in the same
                          condition as it was received.
                        </p>
                      </li>
                      <li>
                        <p>
                          h) A thorough inspection of the vehicle shall be
                          marked before receiving the vehicle at the drop
                          location.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>3. User Liability:</b>{" "}
                    </p>
                    <p className="text-center text-decoration-underline">
                      <b>General Liability Clause</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a) The User is responsible to provide accurate and
                          verifiable information for verification and
                          registration on the Freedo Rentals Mobile App and
                          Freedo Rentals Website.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) The User shall liable for safe and legal usage of
                          the vehicle acquired from Freedo.
                        </p>
                      </li>
                      <li>
                        <p>
                          c) The User shall not tamper with the IOT devices
                          on-board. Any/ all tampering shall be penalized by
                          Freedo, by imposing costs upto 10,000/-INR
                        </p>
                      </li>
                      <li>
                        <p>
                          d) The User shall be liable for any and all traffic
                          violations, during usage of the subscribed vehicle.
                        </p>
                      </li>
                      <li>
                        <p>
                          e) The User shall be liable for the safe keeping of
                          the vehicle from any and all forms of damage caused by
                          Human intervention or Natural causes.
                        </p>
                      </li>
                      <li>
                        <p>
                          f) The User shall be liable for the damage/ wear and
                          tear caused during the subscription period of the
                          vehicle. (For further details please refer to the
                          Penalties clause)
                        </p>
                      </li>
                      <li>
                        <p>
                          g) The User shall be liable for the safety of the
                          vehicle documents (RC, Insurance Copy, Invoice Copy,
                          PUC Certificate etc.).
                        </p>
                      </li>
                      <li>
                        <p>
                          h) The User shall be liable to operate the vehicle as
                          per the intended use and location as agreed upon while
                          booking the vehicle. (Refer to Annexure F)
                        </p>
                      </li>
                      <li>
                        <p>
                          i) The User shall be liable for normal wear and tear
                          of the vehicle during usage. (Such as tyre puncture
                          etc.)
                        </p>
                      </li>
                      <li>
                        <p>
                          j) The User shall be liable to ensure that no
                          modification visual/ physical or electronic shall be
                          done.
                        </p>
                      </li>
                      <li>
                        <p>
                          k) The User shall be liable to ensure that no other
                          person/ persons use the vehicle other than the User
                          himself.
                        </p>
                      </li>
                      <li>
                        <p>
                          l) The User shall be liable for the safe-keeping of
                          the equipment/ accessories which were delivered
                          along-with the bike.(Such as helmets, navigators etc.)
                        </p>
                      </li>
                      <li>
                        <p>
                          m) The User shall be liable to inform Freedo at the
                          earliest and file a written complaint at the nearest
                          Police Station, in case of theft of vehicle or of
                          accessories
                        </p>
                      </li>
                      <li>
                        <p>
                          n) The User shall be responsible for the safe keeping
                          of the account information related to Freedo and shall
                          in no case or scenario share the same with any third
                          party. Non compliance of the same shall lead to
                          economic penalties as decided by Freedo from time to
                          time. A penalty of 10,000/-INR or any other as decided
                          by Freedo from time to time and location to location
                          shall be imposed against the breach of the present
                          term.
                        </p>
                      </li>
                      <li>
                        <p>
                          o) The User shall be liable for any and all loss
                          incurred by Freedo due to lapse/ Lapses on behalf of
                          the User in case of theft/ accident or any other form
                          of damage.
                        </p>
                      </li>
                    </ul>
                    <p className="text-center text-decoration-underline">
                      <b>Accidents and Damages</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a) The User shall be liable to inform Freedo, about
                          the any and all incidents of Accidents, Damages,
                          Notices and Challans (As mentioned in Annexure D),
                          within an hour. The same shall also be reported in a
                          writing.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) In case of accidents, resulting in damage to a
                          Third Person or a Third Person Property, the User
                          shall be liable to file a police complaint in
                          accordance to a jointly agreed statement with Freedo
                          and shall comply and co-operate to obtain the
                          following details:
                        </p>
                        <ul className="list-unstyled px-4 list-disc">
                          <li>
                            <p>
                              Complete Details of the incident namely: Date,
                              Time and Place of Incident.
                            </p>
                          </li>
                          <li>
                            <p>
                              Registration number, make, model, year and
                              insurance details (with name and contact number
                              for the insurance agent) for any other vehicle
                              involved.
                            </p>
                          </li>
                          <li>
                            <p>
                              Name, Address and Driver’s License number of the
                              person/ persons involved in the incident. (Also
                              the ownership details of the owner if the vehicle
                              is being driven by the driver)
                            </p>
                          </li>
                          <li>
                            <p>
                              Name, Address and Contact of witness/ witnesses
                              and any other person involved.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          c) The User shall be liable to compensate Freedo for
                          any and all loss / damages, incurred or arising out of
                          incident of accident/ claim.
                        </p>
                      </li>
                      <li>
                        <p>
                          d) The User is liable to co-operate, in the
                          investigation of the said incident, with Executive
                          Authorities, Police Authorities, Claim Settlement
                          Agency involved along with Freedo.
                        </p>
                      </li>
                      <li>
                        <p>
                          e) The User shall be liable to pay for any/ all
                          damages and cost to repair the vehicle, to the extent
                          that is not covered by the insurer.
                        </p>
                      </li>
                      <li>
                        <p>
                          f) If the accident/ incident is caused due to any
                          violation of the Terms and Conditions put forth by
                          Freedo, then, the user shall be liable for the entire
                          cost of repair and any/ all legal costs including but
                          not limited to damages/ claims / compensations/
                          challans etc.
                        </p>
                      </li>
                    </ul>
                    <p className="text-center text-decoration-underline">
                      <b>Insurance and Liabilities</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>a) All Freedo Vehicles are insured.</p>
                      </li>
                      <li>
                        <p>
                          b) Freedo shall not be claiming insurance on damages
                          below 5000/- INR (For Karnataka & Andhra Pradesh the
                          limit is 10000/- INR) and it shall be the sole
                          liability of the user to pay for the repair/damage
                          amounting upto 5000/- INR (For Karnataka & Andhra
                          Pradesh the limit is 10000/- INR) .
                        </p>
                      </li>
                      <li>
                        <p>
                          c) The User shall be liable to pay for any/ all costs
                          which is not covered the insurer, including but not
                          limited to vehicle damage/ third party damage/ claim.
                        </p>
                      </li>
                      <li>
                        <p>
                          d) Freedo shall provide primary third party liability
                          protection, only in the case where the User is in
                          compliance with the Terms & Conditions stated herein
                          and also any/ all applicable laws.
                        </p>
                      </li>
                      <li>
                        <p>
                          e) Freedo shall not be liable for any loss, theft,
                          damage etc. of any personal property of the User,
                          Co-passenger or any third party.
                        </p>
                      </li>
                      <li>
                        <p>
                          f) Freedo shall also not be liable to any damages,
                          claims etc. of any third party arising out of any
                          accident or mishaps etc. after the User has taken the
                          possession of the vehicle.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>4. Vehicle Failure/Breakdowns</b>{" "}
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a)
                          <span className="text-decoration-underline ms-2">
                            For the purpose of the present contract vehicle
                            break-down/ failure shall include but not limited
                            to:
                          </span>
                        </p>
                      </li>
                      <ul className="px-4 sub-list list-disc">
                        <li>
                          <p>Vehicle not starting.</p>
                        </li>
                        <li>
                          <p>Fuel leakage</p>
                        </li>
                        <li>
                          <p>Break Failure</p>
                        </li>
                        <li>
                          <p>Clutch/Acceleration Failure</p>
                        </li>
                        <li>
                          <p>Head light and Tail light not functioning</p>
                        </li>
                        <li>
                          <p>Drive Chain broken</p>
                        </li>
                        <li>
                          <p>Mechanical failures</p>
                        </li>
                        <li>
                          <p>Transmission failure</p>
                        </li>
                        <li>
                          <p>BMS Failure (Electric)</p>
                        </li>
                        <li>
                          <p>Fire (Electric)</p>
                        </li>
                        <li>
                          <p>Charging Malfunction(Electric)</p>
                        </li>
                      </ul>
                    </ul>
                    <p>
                      <b>5. Cancellation & Refund:</b>{" "}
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a) Any/ all booking made by the User shall be
                          cancelled through the Freedo Mobile App/ Freedo
                          Website or through the Freedo Call Center.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) Cancellations are subject to charges/ deductions
                          and differ from location to location.(Refer Annexure G
                          for charges)
                        </p>
                      </li>
                      <li>
                        <p>
                          c) Every cancellation shall be marked for User
                          validation, hygiene and evaluation.
                        </p>
                      </li>
                      <li>
                        <p>
                          d) In the case, where cancellation is done by Freedo
                          due to unavailability of inventory the entire amount
                          of booking shall be refunded to the user account.
                        </p>
                      </li>
                    </ul>

                    <p>
                      <b>6. Termination & Cancellation of User Registration:</b>{" "}
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a) Freedo reserves the right to cancel / suspend/
                          terminate, the User registration and also to withdraw
                          services rendered to User on account of any/ all
                          breach of the Terms and Conditions.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) Freedo reserves the right to cancel / suspend/
                          terminate, the User registration and also to withdraw
                          services rendered to User on account of false
                          information provided by the User, in violation of the
                          applicable laws.
                        </p>
                      </li>
                      <li>
                        <p>
                          c) Freedo reserves the right to cancel / suspend/
                          terminate, the User registration and also to withdraw
                          services rendered to User on account of breach of
                          Prohibited Use Clause.
                        </p>
                      </li>
                      <li>
                        <p>
                          d) Freedo reserves the right to cancel/ suspend/
                          terminate, the User registration and also to withdraw
                          services rendered to User, on account of any
                          misdemeanor or threat advanced towards any/ all
                          employees/ representative/ agents etc.
                        </p>
                      </li>
                      <li>
                        <p>
                          e) Freedo reserves the right to cancel/ suspend/
                          terminate, the User registration and also to withdraw
                          services rendered to User, on account of instances
                          including, but not limited to, late return of vehicle/
                          late payment/ multiple cancelations etc
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>7. Prohibited Use:</b>{" "}
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a) The subscribed vehicles has to be strictly used by
                          the User as per the present Terms and Conditions.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) The User shall be liable to disclose the purpose
                          for Hire of the vehicle (Personal / Commercial/
                          Tourism) before taking the delivery of the vehicle.
                        </p>
                      </li>
                      <li>
                        <p>
                          c) The subscription for the vehicle is
                          non-transferable and exclusively for personal use,
                          until and unless explicitly specified by the User that
                          the vehicle is for commercial use and subject to
                          compliance of the Commercial Usage Policy as given
                          under Annexure-E.
                        </p>
                      </li>
                      <li>
                        <p>
                          d) The subscribed vehicle shall not be used for any
                          kind of profit making (transporting goods/ people
                          etc.) until unless subscribed under the Commercial
                          Vehicle Policy as given under Annexure E.
                        </p>
                      </li>
                      <li>
                        <p>
                          e) The subscribed vehicle shall ply on public road,
                          with a maximum of 2 Pax (Rider & Pillion).
                        </p>
                      </li>
                      <li>
                        <p>
                          f) The subscribed vehicle shall not be used for any
                          acts prohibited or deemed illegal, as per the
                          applicable laws.
                        </p>
                      </li>
                      <li>
                        <p>
                          g) The subscribed vehicle shall not be used under the
                          influence of Alcohol, drugs or any other mind altering
                          substance.
                        </p>
                      </li>
                      <li>
                        <p>
                          h) The User shall not, in any circumstance, modify or
                          alter the subscribed vehicle.
                        </p>
                      </li>
                      <li>
                        <p>
                          i) The User shall not, use the subscribed vehicle, to
                          carry or transport any weapon, drug and any/ all items
                          declared restricted/ prohibited/ illegal by law.
                        </p>
                      </li>
                      <li>
                        <p>
                          j) The User shall not, in any circumstance, use the
                          subscribed vehicle for the purpose of Racing/
                          Performing Stunt or any other on-road and off-road
                          activity barred by law and the present terms and
                          conditions.
                        </p>
                      </li>
                      <li>
                        <p>
                          k) Freedo shall monitor the speed limits of its
                          vehicles given to users. In case the speed of the
                          vehicle goes beyond 80 kmph at any given point of
                          time, Freedo may penalize the user for violation of
                          speed norms of Freedo’s Terms & Conditions. The same
                          shall apply in case of exceeding the speed limits of
                          the respected areas.
                        </p>
                      </li>
                    </ul>
                    <p>
                      Freedo shall have the sole discretion to identify any /
                      all acts as prohibited and inappropriate and all such acts
                      which are identified shall be treated as a breach of the
                      present Terms and Conditions.<br></br>
                      Freedo shall reserve the right to inform appropriate and
                      proper authorities in the event of breach of the Terms and
                      Conditions, related to banned substances and alcohol abuse
                      during the usage of the subscribed vehicle. The User shall
                      be sole responsible for any / all costs/ fine/ challan/
                      accidents/ damages/ claims and any other costs, arising
                      out of the breach of the present terms and conditions.
                    </p>

                    <p>
                      Freedo shall not or under any circumstances be liable for
                      any damage or injuries to persons or property suffered or
                      sustained in the use, condition, or operation of the
                      subscribed vehicle and all such claims are specifically
                      waived by the User. The User agrees to and does hereby
                      hold the Company, its director/ officers/ agents/
                      representatives and employees, free and harmless from any
                      and all losses, costs, demands or liability of any kind
                      whatsoever, including legal costs and attorney’s fees.
                      Upon demand, User shall defend at User’s own expense, any
                      claims brought against the Company.
                    </p>
                    <p>
                      <b>9. Privacy and Data Protection:</b>{" "}
                    </p>
                    <p className="text-decoration-underline">
                      The User agrees that Freedo may use the Person Information
                      of the User and the same maybe used with third party, for
                      the purpose of:
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>a) Promotional purposes</p>
                      </li>
                      <li>
                        <p>b) Legal disclosure.</p>
                      </li>
                      <li>
                        <p>c) KYC verification.</p>
                      </li>
                      <li>
                        <p>d) Reporting of incidents</p>
                      </li>
                      <li>
                        <p>e) Providing support</p>
                      </li>
                      <li>
                        <p>f) Improving service</p>
                      </li>
                      <li>
                        <p>g) Creating User records for validation</p>
                      </li>
                      <li>
                        <p>h) Business analysis etc</p>
                      </li>
                    </ul>
                    <p>
                      Each subscribed vehicle shall be monitored and tracked by
                      Freedo for the prevention of Theft & Identify the Users
                      location, for safety.
                    </p>
                    <p>
                      <b>10. Recovery of Vehicles and Dues:</b>
                    </p>
                    <p>
                      It is herein after explicitly mentioned that if the User
                      fails to extend the subscription as per policy then Freedo
                      holds the absolute right to recover subscribed vehicles
                      from the User, after the completion of the booking period
                      and without placing any prior information to the User.
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a) The User shall be solely responsible to return the
                          vehicle at the designated location and time as
                          prescribed by Freedo.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) All subscribed vehicles shall be deemed to be from
                          the Freedo’s fleet of vehicles.
                        </p>
                      </li>
                      <li>
                        <p>
                          c) Freedo reserves the absolute right to take back the
                          physical possession of the vehicle/ vehicles if there
                          is a delay of more than 2 Hrs from the prescribed date
                          and time of return.
                        </p>
                      </li>
                      <li>
                        <p>
                          d) Freedo reserves the absolute right to take legal/
                          coercive actions to recover vehicles or any other
                          dues, including but not limited to filing FIRs/
                          Recovery Suits etc.
                        </p>
                      </li>
                      <li>
                        <p>
                          e) It is herein informed that Freedo reserves the
                          absolute right to immobilize the vehicle remotely for
                          the reasons related to recovery of the said vehicle.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>11. Jurisdiction & Dispute Resolution:</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a) The following terms and conditions shall be subject
                          to the applicable laws in India.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) The courts of New Delhi shall have the exclusive
                          jurisdiction over any and all disputes arising out of
                          the engagement between the User and Freedo.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>12. Aggregator/Operator Policy for GOA:</b>
                    </p>
                    <p>
                      For the State of GOA the terms and conditions of the Local
                      Operator shall supersede the Terms and Conditions of
                      Freedo. Note: This would include:
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>a) Terms of Use</p>
                      </li>
                      <li>
                        <p>b) Liability Clause</p>
                      </li>
                      <li>
                        <p>c) Vehicle Breakdown</p>
                      </li>
                      <li>
                        <p>d) Cancellation</p>
                      </li>
                      <li>
                        <p>e) Privacy</p>
                      </li>
                      <li>
                        <p>f) Payments/ Recovery of Vehicle</p>
                      </li>
                    </ul>
                    <p>
                      Please get in touch with the Local Operator to procure of
                      copy of the Terms and Conditions. Freedo shall not be
                      liable for support/ RSA/ Customer complaints related to
                      the State of GOA.
                    </p>
                    <p className="text-decoration-underline">
                      <b>ANNEXURE A- DEFINITIONS</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a)App – Freedo’s mobile application available on any
                          Mobile platform or web platform.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) To confirm authorization of users that access and
                          use the Services.Fess – the amount to be paid by the
                          User including security deposit to avail Freedo’s
                          services. Fees may be changed by Freedo in its sole
                          discretion, from time to time.
                        </p>
                      </li>
                      <li>
                        <p>
                          c) Personal Information – it means the Name, Contact
                          numbers, address, E-mail id, age, gender, driving
                          license details, Aadhaar card or any other identity
                          proof, device location, IP address and any other data
                          shared by User with Freedo.
                        </p>
                      </li>
                      <li>
                        <p>
                          d) Services – various type of Vehicle rental and
                          ancillary services provided by Freedo.
                        </p>
                      </li>
                      <li>
                        <p>
                          e) User – shall mean to include any individual or
                          person who registers with & avails the services of
                          Freedo, body corporate means the body corporate which
                          registers with & avails the services of Freedo.
                        </p>
                      </li>
                      <li>
                        <p>
                          f) Vehicle – all vehicles used for rendering the
                          services owned/under the control and possession of
                          Freedo.
                        </p>
                      </li>
                      <li>
                        <p>g) Website – means www.freedo.rentals</p>
                      </li>
                    </ul>
                    <p className="text-decoration-underline">
                      <b>
                        ANNEXURE B- ELIGILIBILTY CRITERIA AND SUPPORTING
                        DOCUMENTS
                      </b>
                    </p>
                    <p>
                      <b>Eligibility Criteria:</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          a)Users must be above the age of 18 (20 years of age
                          in respect of certain categories of Vehicles)
                        </p>
                      </li>
                      <li>
                        <p>
                          b) Users must possess a valid driver’s license (two
                          wheeler) issued by the Government of India to operate
                          the Vehicle.
                        </p>
                      </li>
                      <li>
                        <p>
                          c) Users must possess sufficient identity, age and
                          address proof.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>Supporting Documents:</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>a) Proof of Identity.</p>
                      </li>
                      <li>
                        <p>b) Proof of Age</p>
                      </li>
                      <li>
                        <p>c) Proof of Permanent Address.</p>
                      </li>
                      <li>
                        <p>
                          d) A valid Driver’s License to operate the Vehicle
                        </p>
                      </li>
                      <li>
                        <p>e) Recent colour photographs means Selfie</p>
                      </li>
                    </ul>
                    <p>
                      <b>
                        ANNEXURE C- PENALTIES, DAMAGE, CHALLANS & REPAIR POLICY
                      </b>
                    </p>
                    <p>
                      The following list details the penalty amount in the
                      respective incidents. Please note that this is just
                      indicative and not exhaustive and can be updated by Freedo
                      from time to time.
                    </p>
                    <div className="table-responsive">
                      <table className="table  table-striped table-sm">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "5%" }}>
                              <p><b>S. No.</b></p>
                            </th>
                            <th scope="col"><p><b>Incident</b></p></th>
                            <th scope="col"><p><b>Penalty Amount</b></p></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><p><b>1.</b></p></td>
                            <td>
                              <p>Any Traffic rules Violations / Challans</p>
                            </td>
                            <td>
                              <p>
                                Actual Fine incurred plus loss of revenue if
                                impounded.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td><p><b>2.</b></p></td>
                            <td>
                              <p>Returning of Vehicle to wrong location</p>
                            </td>
                            <td>
                              <p>
                                Rs 100, if parked within 3 km radius of Freedo
                                Station
                                {"/n"}
                                Rs 400, if Parked outside 3km radius of Freedo
                                Station
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td><p><b>3.</b></p></td>
                            <td>
                              <p>Towing or Impounding</p>
                            </td>
                            <td>
                              <p>
                                Charges shall be paid by the user if the towing
                                or impounding of the vehicle is attributed to
                                the fault of the user.(GST additional)
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td><p><b>4.</b></p></td>
                            <td>
                              <p>Other Major/Minor Damages</p>
                            </td>
                            <td>
                              <p>
                                Actual Cost incurred plus loss of revenue. (GST
                                additional)
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p>
                      In any case of vehicle being seized by traffic police or
                      any other authority, the entire expenses and legal
                      proceedings henceforth is to be borne by the customer and
                      Freedo shall charge the late return in case the vehicle is
                      submitted beyond the ride time.
                    </p>
                    <p>
                      <b>
                        ANNEXURE D- Guidelines for Commercial use of the Vehicle
                      </b>
                    </p>
                    <p>
                      Any user who wants to use the Vehicle for any kind of
                      commercial activities must inform Freedo in advance
                      regarding:
                    </p>
                    <ul className="px-4 sub-list list-disc">
                      <li>
                        <p>The nature of work.</p>
                      </li>
                      <li>
                        <p>Area of Service</p>
                      </li>
                      <li>
                        <p>Details of his/her employer</p>
                      </li>
                      <li>
                        <p>Proof of association with the employer</p>
                      </li>
                    </ul>
                    <p>
                      Freedo will confirm all the above mentioned details & it
                      is on sole discretion of Freedo to provide the Vehicle for
                      commercial activities to a user. The rental for commercial
                      use will be different from Freedo’s regular services & it
                      will be communicated before booking. A user will have the
                      following additional responsibilities in addition to all
                      the responsibilities mentioned in this document:
                    </p>
                    <ul className="px-4 sub-list list-unstyled">
                      <li>
                        <p>
                          a)The customer warrants that the information it
                          provides to the Company is accurate and complete. The
                          company is entitled at all times to verify the
                          information that has been provided through both online
                          and offline mediums.
                        </p>
                      </li>
                      <li>
                        <p>
                          b) The company shall have the sole discretion to
                          accept or reject each request for the Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          c) The vehicle must be used only for the purpose
                          mentioned during the booking.
                        </p>
                      </li>
                      <li>
                        <p>
                          d) The vehicle must be used only for the employer
                          mentioned during the booking. Any change intended must
                          be informed in advance.
                        </p>
                      </li>
                      <li>
                        <p>
                          e) The user must not carry out any service &
                          maintenance on vehicles other than the basic
                          activities like: tyre puncture, lubrication, Breaks
                          adjustment. All the regular & breakdown maintenance
                          will be done by Freedo at their authorised service
                          centre. User will inform Freedo immediately in case of
                          any breakdown incident.
                        </p>
                      </li>
                      <li>
                        <p>
                          f) The user must not do any modifications in the
                          Vehicle to facilitate the commercial activities.
                        </p>
                      </li>
                      <li>
                        <p>
                          g) The user must not carry any item with a weight of
                          more than of 30kg on a vehicle and should be within
                          the allowed dimensions of 550 mm long and 510 mm in
                          width.
                        </p>
                      </li>
                    </ul>
                    <p>
                      All the terms & conditions of Freedo shall apply as a
                      whole for the commercial usage of the vehicle.
                    </p>

                    <p>
                      <b>ANNEXURE E- Guidelines for Vehicle Usage</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          1. The user must not do any modifications in the
                          Vehicle to facilitate the commercial activities.
                        </p>
                        <p className="ps-1">
                          a. The Usage areas/locations are categorised as below:
                        </p>
                      </li>
                      <li>
                        <div className="table-responsive">
                          <table className="table table-striped table-sm">
                            <thead>
                              <tr>
                                <th scope="col" style={{ width: "10%" }}>
                                  <p><b>S. No.</b></p>
                                </th>
                                <th scope="col"><p><b>Booking Location</b></p></th>
                                <th scope="col">
                                  <p><b>Authorized Locations to Use Freedo Vehicles</b></p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><p><b>1.</b></p></td>
                                <td>
                                  <p>Noida, Ghaziabad, Bulandshahr, Hapur </p>
                                </td>
                                <td>
                                  <p>Noida, Ghaziabad, Bulandshahr, Hapur</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>2.</b></p></td>
                                <td>
                                  <p>Bangalore</p>
                                </td>
                                <td>
                                  <p>State boundary of Karnataka</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>3.</b></p></td>
                                <td>
                                  <p>Gurugram</p>
                                </td>
                                <td>
                                  <p>State boundaries of Haryana</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>4.</b></p></td>
                                <td>
                                  <p>Other Major/Minor Damages</p>
                                </td>
                                <td>
                                  <p>
                                    Actual Cost incurred plus loss of revenue.
                                    (GST additional)
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </li>
                      <li>
                        <p>
                          2. In any case of using the vehicle outside the
                          specified limit, user must inform Freedo in advance
                          (with written being must), prior to the booking with
                          details on the location and duration.
                        </p>
                      </li>
                      <li>
                        <p>
                          3. For usage outside the said area limits, user must
                          pay the relevant state border taxes, municipal taxes
                          and toll taxes to the respective authorities wherever
                          applicable in advance.
                        </p>
                      </li>
                      <li>
                        <p>
                          4. Since, every state has different procedures thus,
                          the user must check for any such charges is advance
                          via the transport websites or local authorities
                          concerning the area. Freedo will not reimburse any
                          such charges.
                        </p>
                      </li>
                      <li>
                        <p>
                          5. Freedo in no condition will not be held responsible
                          in case the user has been penalized due to lack of
                          requisite state-entry documents/invoices.
                        </p>
                      </li>
                      <li>
                        <p>
                          6. A customer on its own risk and by paying proper
                          taxes can take the vehicle outside the prescribed
                          areas, but in such cases no mechanical support &
                          insurance coverage will be provided. It is the sole
                          liability of the user to secure and bring back the
                          vehicle within the area limits without any harm or
                          damage to the vehicle or any property/individual from
                          the vehicle
                        </p>
                      </li>
                      <li>
                        <p>
                          7. Freedo in no case permits to move the vehicle to
                          Leh, Ladakh, Kaza Nako, Spiti Valley and Government
                          banned areas. No mechanical support & insurance
                          coverage will be provided if the vehicle is taken to
                          the mentioned locations.
                        </p>
                      </li>
                      <li>
                        <p>
                          8. In any case of vehicle being seized by traffic
                          police or any other authority, the entire expenses and
                          legal proceedings henceforth is to be borne by the
                          customer and Freedo shall charge the late return in
                          case the vehicle is submitted beyond the ride time.
                        </p>
                      </li>
                      <li>
                        <p>
                          9.The user in any condition must not drive the vehicle
                          to any area that is unauthorized or restricted to
                          drive by the authorities at the time.
                        </p>
                      </li>
                      <li>
                        <p>
                          10. The Pollution Certificate (PUC) of the Vehicle at
                          the start of the ride will be provided by Freedo,
                          however in case of any expiry of the PUC during the
                          booking tenure, it is the responsibility of the user
                          to renew the PUC on time.
                        </p>
                      </li>
                      <li>
                        <p>
                          11. It is the liability of the customer to clear the
                          vehicle of any kind of Challans that are dated within
                          the booking tenure before the handover of the vehicle
                          to Freedo. In cases of late notifications of challans,
                          the user shall hold the responsibility of clearing
                          them if dated within the booking period.
                        </p>
                      </li>
                      <li>
                        <p>
                          12. The helmet(s) if provided at the start of the
                          booking must be returned while ending the ride. Any
                          damage to the helmet is subjected the penalty with
                          respect to the damage done.
                        </p>
                      </li>
                      <li>
                        <p>
                          13. Door Step delivery and pickup is subjected to the
                          availability of Freedo, its executives, environment,
                          the nature/reason of the said service and other
                          conditions. The Door step pickup and delivery service
                          is subjected to certain charges based on the distance
                          of the respective.
                        </p>
                      </li>
                      <li>
                        <p>
                          14. A Trip extension request is to be made at least 2
                          days before the ride end time. Such requests are to be
                          made to the Customer care, mobile app or website.
                        </p>
                      </li>
                      <li>
                        <p>
                          15. Any extension of the on-going booking is subjected
                          to availability of the vehicle and any future
                          bookings. Freedo does not guarantee successful
                          extension of every booking even if made in advance. In
                          case the User is unwilling to return the vehicle in
                          case of a denied extension request, penalty charges
                          will be applicable.
                        </p>
                      </li>
                      <li>
                        <p>
                          16. Charges in case of extension of ride shall remain
                          the same as per the extension package shown over the
                          platform. However, in case of extension of a service
                          within 24hrs of the end ride time or post, an extra
                          charge shall be applied as the extension convenience
                          fee.
                        </p>
                      </li>
                      <li>
                        <p>
                          17. Extension charges should be paid fully in advance
                          through online payment methods in order to extend the
                          booking successfully. In case of no prior information,
                          the booking would have said to be terminated and the
                          User shall handover the vehicle successfully to
                          Freedo.
                        </p>
                      </li>
                      <li>
                        <p>
                          18. The user must ensure the availability of the
                          necessary documents at the time of start of his
                          booking. The documents include the Registration
                          Certificate, Insurance Copy, Permit, Fitness
                          certificate, PUC, Rental License copy.
                        </p>
                      </li>
                    </ul>

                    <p>
                      <b>ANNEXURE-F- GUIDELINES FOR VEHICLE USAGE</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          1. Bookings made through the Website/App shall be
                          cancelled through the same. The User may also cancel
                          bookings by calling Freedo’s helpline by providing
                          adequate details regarding the User and bookings. The
                          User shall be intimated of the cancellation via the
                          same mode.
                        </p>
                      </li>
                      <li>
                        <p>
                          2. Refund on any cancellation shall be made subject to
                          applicable deductions.
                        </p>
                      </li>
                      <li>
                        <p>
                          3. Cancellations are subjected the charges and the
                          nature of cancellations, with categorization as below:
                        </p>
                        <div className="table-responsive">
                          <table className="table table-striped table-sm">
                            <thead>
                              <tr>
                                <th scope="col" style={{ width: "10%" }}>
                                  <p><b>S. No.</b></p>
                                </th>
                                <th scope="col"><p><b>Time of Cancellation</b></p></th>
                                <th scope="col"><p><b>Cancellation Charges</b></p></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><p><b>1.</b></p></td>
                                <td>
                                  <p>
                                    More than 72 hrs prior to the ride start
                                    time
                                  </p>
                                </td>
                                <td>
                                  <p>10%(upto Rs 1000)</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>2.</b></p></td>
                                <td>
                                  <p>24-72 hrs prior to the ride start time</p>
                                </td>
                                <td>
                                  <p>25%(upto Rs 2000)</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>3.</b></p></td>
                                <td>
                                  <p>0-24 hrs prior to the ride start time</p>
                                </td>
                                <td>
                                  <p>50%(upto Rs 4000)</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>4.</b></p></td>
                                <td>
                                  <p>No Show</p>
                                </td>
                                <td>
                                  <p>100%</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </li>
                      <li>
                        <p>
                          4. In case the User fails to show up for his booking
                          at the pickup time, the booking shall be treated as a
                          No Show and no charges shall be reimbursed.
                        </p>
                      </li>
                      <li>
                        <p>
                          5. In case the User is unable to produce a valid
                          license or valid government id during the vehicle
                          pickup/delivery time, the booking shall be cancelled
                          with 20% deductions.
                        </p>
                      </li>
                      <li>
                        <p>
                          6. Freedo is not liable to reimburse any expenses due
                          to any type of cancellation associated to any third
                          party not connected with Freedo or the services
                          provided by Freedo.
                        </p>
                      </li>
                      <li>
                        <p>
                          7. In case of cancellations due to vehicle
                          unavailability by Freedo, Freedo shall reimburse full
                          amount to the original method of payment.
                        </p>
                      </li>
                      <li>
                        <p>
                          8. Any return after the booking is started and the
                          vehicle is accepted by the User, shall be classified
                          as premature surrender.
                        </p>
                      </li>
                      <li>
                        <p>
                          9. Refund for any case will be processed within a
                          maximum timeframe of 28 working days from Freedo.
                          However, the same can take even more to reflect back
                          to the User’s account depending upon the bank, payment
                          mode and other factors.
                        </p>
                      </li>
                    </ul>

                    <p>
                      <b>ANNEXURE-G- FARE AND EXTRA CHARGES</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          1. The fares shown in the website/app with respect to
                          the packages or other services are inclusive of GST.
                          The User can see the breakdown of all packages in the
                          payment summary page.
                        </p>
                      </li>
                      <li>
                        <p>
                          2. The prices for same vehicle can vary depending upon
                          the location, availability, any customizations, time,
                          duration or other factors.
                        </p>
                      </li>
                      <li>
                        <p>
                          3. The vehicle would be provided with sufficient fuel
                          at no cost to drive to any nearest fuel station, where
                          we recommend to get the fuel refilled as per the User
                          needs. Freedo will not entertain any requests
                          pertaining to an empty fuel tank after the booking is
                          started.
                        </p>
                      </li>
                      <li>
                        <p>3. The Extra Charges are categorized as:</p>
                        <div className="table-responsive">
                          <table className="table table-striped table-sm">
                            <thead>
                              <tr>
                                <th scope="col" style={{ width: "10%" }}>
                                  <p>
                                    <b>S. No.</b>
                                  </p>
                                </th>
                                <th scope="col">
                                  <p>
                                    <b>Category</b>
                                  </p>
                                </th>
                                <th scope="col">
                                  <p>
                                    <b>Charges</b>
                                  </p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1.</td>
                                <td>
                                  <p>Extra Helmet and accessories</p>
                                </td>
                                <td>
                                  <p>
                                    As per the respective charges shown on
                                    platform
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>2.</b></p></td>
                                <td>
                                  <p>Any damage or violation</p>
                                </td>
                                <td>
                                  <p>As per the damages</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>3.</b></p></td>
                                <td>
                                  <p>Any non-compliance to T&Cs</p>
                                </td>
                                <td>
                                  <p>As per the conditions</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>4.</b></p></td>
                                <td>
                                  <p>Home Delivery and Home Pickup</p>
                                </td>
                                <td>
                                  <p>As per the charges shown on platform</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </li>
                    </ul>
                    <p className="mt-2">
                      <b>ANNEXURE-H- ELECTRIC AND VEHICLE</b>
                    </p>
                    <ul className="list-unstyled">
                      <li>
                        <p>
                          1. In case of any booking related to the electric
                          vehicle, User acknowledges and warrants that he/she is
                          aware of the operation of the mentioned electric
                          vehicle, and is competent and physically fit to ride
                          the vehicle.
                        </p>
                      </li>
                      <li>
                        <p>
                          2. User understands that the electric vehicle requires
                          periodic charging of its battery and thus agrees to
                          use and operate with safely and judiciously being
                          aware all of the limitations and requirements
                          associated with the vehicle.
                        </p>
                      </li>
                      <li>
                        <p>
                          3. User shall not hold Freedo responsible in case of
                          any misinformation arising due to any issue/defect of
                          the display panel readings on the vehicle including
                          speed, battery range, alerts and distance travelled.
                        </p>
                      </li>
                      <li>
                        <p>
                          4. User understands and is fully aware that Electric
                          Vehicle batteries are combustible in nature and may
                          cause explosion and will take care and ensure
                          precaution to not tamper any electrical or mechanical
                          components in the vehicle. User will undertake full
                          responsibility of the vehicle in case of any such
                          incidents.
                        </p>
                      </li>
                      <li>
                        <p>
                          5. It is User’s responsibility to check the level of
                          charge power in the vehicle and mobile app and to
                          ensure that it is adequate before initiating operation
                          of the vehicle.
                        </p>
                      </li>
                      <li>
                        <p>
                          6. The distance and/or time that User may operate the
                          vehicle before it loses charging power is never
                          guaranteed. The vehicle may run out of charging power
                          and cease to operate at any time during User’s rental
                          of the vehicle, including before reaching User’s
                          desired destination.
                        </p>
                      </li>
                      <li>
                        <p>
                          7. In case of an electric vehicle runs out of charging
                          power during a rental, Freedo is not liable to provide
                          a replacement battery to continue the ride. However, a
                          battery charger would be provided to ensure the
                          vehicle is charged by the user.
                        </p>
                      </li>
                      <li>
                        <p>
                          8. No charger other than the provided with the vehicle
                          shall be used for charging the vehicle.
                        </p>
                      </li>
                      <li>
                        <p>
                          9. The User is to ensure the safe return of the
                          charger while returning the vehicle back to Freedo to
                          conclude the ride in compliance with the terms of this
                          Agreement.
                        </p>
                      </li>
                      <li>
                        <p>
                          10. User undertakes and warrants that he/she shall
                          never attempt to charge the battery, swap the battery
                          (unless permitted by Freedo in specific circumstances)
                          or in any manner attempt to interfere with the
                          power-electronics or other electrical parts of the
                          vehicle.
                        </p>
                      </li>
                      <li>
                        <p>
                          11. It is strongly advised to not frequently charge
                          the battery if the riding period is short.
                        </p>
                      </li>
                      <li>
                        <p>
                          12. Frequent discharging of the battery below 30% is
                          not advised for battery life. In addition,
                          overcharging the battery for more than 6 hours is
                          strongly prohibited.
                        </p>
                      </li>
                      <li>
                        <p>
                          13. Immediate use of the vehicle after charging is not
                          advised. The user must allow a gap of at least 3o
                          minutes before and after charging.
                        </p>
                      </li>
                      <li>
                        <p>
                          14. The user is not advised to switch on the vehicle
                          while the battery is being charged.
                        </p>
                      </li>
                      <li>
                        <p>
                          15. In case of any damages to charger/battery/motor
                          the customer is liable to pay the charges for the
                          entire damage in addition to the penalty and loss of
                          rentals.
                        </p>
                      </li>
                      <li>
                        <p>
                          16. Electric vehicles are classified under 150cc
                          vehicle for any extra charges associated.
                        </p>
                      </li>
                      <li>
                        <p>
                          17. The User shall be provided with a set of Battery
                          and Charger along-with the vehicle. In case of damage
                          to/ loss of, Battery the User shall be liable to pay a
                          sum of 52,000/-INR. Also, if there is damage to/ loss
                          of, Charger associated to the vehicle the User shall
                          be liable to pay a sum of 4500/-INR.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>ANNEXURE I- Modify Booking Policy</b>
                    </p>
                    <ul className="list-unstyled px-3">
                      <li>
                        <p>
                          The proposed solution list down the modify booking
                          functionality, charges, limitation and other details
                        </p>
                        <p className="ps-1">
                          The modification policy will be applicable basis the
                          following structure as proposed
                        </p>
                      </li>
                      <li>
                        <div className="table-responsive">
                          <table className="table table-striped table-sm">
                            <thead>
                              <tr>
                                <th scope="col"><p><b>Time Period</b></p></th>
                                <th scope="col"><p><b>Charges</b></p></th>
                                <th scope="col"><p><b>Limit and Remarks</b></p></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><p><b>{" >"}72 Hrs</b></p></td>
                                <td>
                                  <p>5% </p>
                                </td>
                                <td>
                                  <p>upto Rs. 500</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>{">"}24 to =72 Hrs</b></p></td>
                                <td>
                                  <p>10%</p>
                                </td>
                                <td>
                                  <p>upto 1000</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>{">"}0 to =24 Hrs</b></p></td>
                                <td>
                                  <p>25%</p>
                                </td>
                                <td>
                                  <p>upto 2000</p>
                                </td>
                              </tr>
                              <tr>
                                <td><p><b>No Show ({"<"}=0 Hours)</b></p></td>
                                <td>
                                  <p>100%</p>
                                </td>
                                <td>
                                  <p>NA</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
