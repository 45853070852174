import { useDispatch, useSelector } from "react-redux";
import { getFilePath } from "../../../../utills/helper"
import { useEffect, useState } from "react";
import { getDocumentDetailsById } from "../../../../redux/actions/rentActions/bookingAction";
import DocumentSkelaton from "../../../../component/SkelatonLoader/DocumentSkelaton";

const MyDocumentTab = ({myDocumentTab}) => {
  const dispatch = useDispatch();
  const [sklflag, setSkl]= useState(true)
    const bookingDetailsById = useSelector(
        (state: any) => state?.profileReducer?.bookingDetails
      );
    const { documentDetails } = useSelector((state:any) => state?.bookingReducer  )
    useEffect(()=>{
     if(myDocumentTab) dispatch(getDocumentDetailsById({id : bookingDetailsById?.id},handleSuccess))
    },[myDocumentTab])

    const handleSuccess = ()=>{
      setSkl(false)
    }
   
    return <div
      id="collapseFour"
      className="accordion-collapse collapse" 
      aria-labelledby="headingFour"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <div className="Vehicle-Price-Details">
          <div className="table-responsive">
            <table className="table table-borderless table-sm">
              {sklflag ? <DocumentSkelaton /> : <tbody>
                {/* RC (Registration Certificate) , Insurance, PUC    */}
                {
                 Object.keys(documentDetails).length ? documentDetails?.map((_document : any) => {
                    return <tr key={_document?.url}>
                    <td>{_document?.label} </td>
                    <td>
                      <a
                        target="_blank"
                        download
                        href={_document?.documentPath}
                      >
                        <i className="fas fa-file-download"></i>
                      </a>
                    </td>
                  </tr>
                  }) : <>No document available</>
                }
              </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </div>
}
export default MyDocumentTab;