import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { hindiTextValidation } from "../utills/helper";
import { langErrorMsg } from "../screen/city-freedo/constants/constants";

interface InputFieldProps {
  className?: string;
  type: string;
  required?: boolean;
  placeholder: string;
  img?: string;
  label?: string;
  id?: string;
  value: string | number;
  onChange?: any;
  name: string;
  maxLength?: number;
  errorMsg?: string;
  restrictFuntion?: any;
  inputMode?: "numeric" | "text";
  pattern?: string;
  style?: object;
  isDisabled?: boolean;
}

const NewInputField = forwardRef(
  (
    {
      className,
      type,
      required,
      img,
      label,
      id,
      value,
      onChange,
      errorMsg,
      restrictFuntion,
      isDisabled,
      ...rest
    }: InputFieldProps,
    ref
  ): JSX.Element => {
    const inputRef = useRef<any>(null);

    const inputFocus = () => {
      inputRef.current?.focus();
    };

    useImperativeHandle(ref, () => {
      return { focusInput: inputFocus };
    });
    const [langError, setLangError] = useState<boolean>(false);
    return (
      <div>
        <div className="">
          {label && (
            <label className="form-label pt-1">
              {label}
              {required && <span className="required-mark">*</span>}
            </label>
          )}
          <div className="input-group" style={{ marginTop: -8 }}>
            {img ? (
              <span className="input-group-text" id={id}>
                <img src={img} alt={img} />
              </span>
            ) : null}

            <input
              ref={inputRef}
              id={id}
              value={value ?? ""}
              type={type}
              className="form-control"
              /* checked={isChecked} */
              disabled={isDisabled}
              onChange={(e) => {
                if (type === "checkbox") {
                  onChange(e.target.name, value, e.target.checked);
                } else {
                  hindiTextValidation(e.target.value)
                    ? onChange(
                        e.target.name,
                        restrictFuntion
                          ? restrictFuntion(e.target.value)
                          : e.target.value,
                        setLangError(false)
                      )
                    : setLangError(true);
                }
              }}
              {...rest}
            />
          </div>
          {!!errorMsg && <span className="error-field">{errorMsg}</span>}
          {langError && (
            <span className="error-field">{langErrorMsg}</span>
          )}
        </div>
      </div>
    );
  }
);

export default NewInputField;
