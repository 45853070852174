import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import { handleMessage } from "../../utills/helper";
import { toastr } from "react-redux-toastr";
import { checkoutServices } from "../services";
import { Routes } from "../../constant/routeContants";
import * as selectors from "../selectors";

const {
  GET_COUPON_MASTERS,
  SET_COUPON_MASTERS,
  GET_BRANCH_SLOTS,
  SET_BRANCH_SLOTS,
  GET_HOME_DELIVERY_CHARGES,
  SET_HOME_DELIVERY_CHARGES,
  GET_HOME_ADDRESS_LIST,
  SET_HOME_ADDRESS_LIST,
  GET_VEHICLE_DATA,
  SET_VEHICLE_DATA,
  SET_CREATE_BOOKING_FAILED,
  SET_CREATE_BOOKING_SUCCESS,
  POST_CREATE_BOOKING,
  POST_PROCESS_ORDER,
  POST_PAYMENT_VERIFY,
  VALIDATE_USER_EMERGENCY_DETAILS_REQUESTED,
  VALIDATE_USER_EMERGENCY_DETAILS_FAILED,
  VALIDATE_USER_EMERGENCY_DETAILS_SUCCEED,
  HOME_DELIVERY_NOT_AVAILABLE,
} = actionTypes.CHECKOUT_ACTIONS;

function* getCouponList(action: any): any {
  const data = yield call(checkoutServices.couponList, action.payload);
  console.log(data);
  if (data?.status === 200) {
    yield put({
      type: SET_COUPON_MASTERS,
      payload: data?.data?.Result || [],
    });
  } else {
    toastr.error("", handleMessage(data));
  }
}
function* getBranchSlots(action: any): any {
  const data = yield call(checkoutServices.slotsList, action.payload);
  if (data?.status === 200) {
    yield put({
      type: SET_BRANCH_SLOTS,
      payload: data?.data?.Result || {},
    });
    if (data?.data?.Result === null) {
      toastr.error("", handleMessage(data));
      yield put({
        type: SET_BRANCH_SLOTS,
        payload: {},
      });
    }
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: SET_BRANCH_SLOTS,
      payload: {},
    });
  }
}
function* getHomeDeliveryChanrges(action: any): any {
  const { payload, setDeliveryChargeLoader, updateHomeDeliveryCharge } = action;
  const data = yield call(checkoutServices.homeDeliverCharges, payload);
  if (data?.status === 200) {
    if (data?.data?.Result) {
      setDeliveryChargeLoader(false);
      yield put({
        type: SET_HOME_DELIVERY_CHARGES,
        payload: data?.data?.Result || {},
      });
      toastr.info(
        "Delivery Charges for this branch is ₹" +
          data?.data?.Result?.home_delivery_charge,
        ""
      );
    } else {
      setDeliveryChargeLoader(false);
      toastr.error("", handleMessage(data?.data?.Message[0]));
    }
  } else if (data?.response?.status === 422) {
    yield put({
      type: HOME_DELIVERY_NOT_AVAILABLE,
      payload: data?.response.data || {},
    });
    setDeliveryChargeLoader(false);
    toastr.error("", handleMessage(data));
    updateHomeDeliveryCharge && updateHomeDeliveryCharge(null);
  } else {
    setDeliveryChargeLoader(false);
    toastr.error("", handleMessage(data));
    updateHomeDeliveryCharge && updateHomeDeliveryCharge(null);
  }
}
function* getHomeAddressList(action: any) {
  console.log("beforeaddress");
  const data = yield call(checkoutServices.homeAddressList, action.payload);
  if (data?.status === 200) {
    console.log("addres ", data);
    yield put({
      type: SET_HOME_ADDRESS_LIST,
      payload: data?.data?.Result?.data?.rows || [],
    });
  } else {
    toastr.error("", handleMessage(data));
  }
}
function* getVehicleModelDetails(action: any): any {
  const { payload, navigate, journyData, handleRentingFleetLoader } =
    action.payload;
  const data = yield call(checkoutServices.vehicleModelDetails, payload);
  if (data?.status === 200) {
    if (handleRentingFleetLoader) handleRentingFleetLoader();
    if (data?.data?.modelAvailability) {
      navigate(Routes.CHECKOUT, {
        state: { basicFilters: journyData, vehicleData: data?.data },
      });
      yield put({
        type: actionTypes.HOME_ACTIONS.SET_BASIC_FILTER,
        payload: journyData,
      });
      yield put({
        type: SET_VEHICLE_DATA,
        payload: data?.data || {},
      });
    } else toastr.error("", "Vehicle is not available on selected date!");
  } else {
    if (handleRentingFleetLoader) handleRentingFleetLoader();
    toastr.error("", handleMessage(data));
  }
}
function* createBookingCall(action: any): any {
  const { callBack, payload, handleLoader } = action.payload;
  const data = yield call(checkoutServices.createStoreBooking, payload);
  console.log(data);
  if (data?.status === 200) {
    yield put({
      type: SET_CREATE_BOOKING_SUCCESS,
      payload: data?.data?.result?.rzpkey,
    });
    callBack(data?.data);
  } else {
    toastr.error("", handleMessage(data));
    handleLoader();
    yield put({
      type: SET_CREATE_BOOKING_FAILED,
    });
  }
}
function* orderProcess(action: any): any {
  const { callBack, payload, handleLoader } = action.payload;
  const data = yield call(checkoutServices.processOrderBooking, payload);
  console.log(data);
  try {
    if (data?.status === 200) {
      callBack(data?.data, handleLoader);
    } else {
      handleLoader();
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    handleLoader();
  }
}
function* OrderPaymentVerify(action: any): any {
  const { callBack, payload } = action.payload;
  const data = yield call(checkoutServices.verifyOrderPayment, payload);
  try {
    if (data?.status === 200) {
      callBack(data);
    } else {
      callBack(null);
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    callBack(null);
    toastr.error("", handleMessage(data));
  }
}

function* validateUserEmergencyDetails(): any {
  try {
    const data = yield call(checkoutServices.validateUserEmergency);
    if (data?.status === 200) {
      if (data?.data?.Result?.emergencyExists)
        yield put({ type: VALIDATE_USER_EMERGENCY_DETAILS_SUCCEED });
      else yield put({ type: VALIDATE_USER_EMERGENCY_DETAILS_FAILED });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({ type: VALIDATE_USER_EMERGENCY_DETAILS_FAILED });
  }
}

function* checkoutSaga() {
  yield takeLatest(GET_COUPON_MASTERS, getCouponList);
  yield takeLatest(GET_BRANCH_SLOTS, getBranchSlots);
  yield takeLatest(GET_HOME_ADDRESS_LIST, getHomeAddressList);
  yield takeLatest(GET_HOME_DELIVERY_CHARGES, getHomeDeliveryChanrges);
  yield takeLatest(GET_VEHICLE_DATA, getVehicleModelDetails);
  yield takeLatest(POST_CREATE_BOOKING, createBookingCall);
  yield takeLatest(POST_PROCESS_ORDER, orderProcess);
  yield takeLatest(POST_PAYMENT_VERIFY, OrderPaymentVerify);
  yield takeLatest(
    VALIDATE_USER_EMERGENCY_DETAILS_REQUESTED,
    validateUserEmergencyDetails
  );
}

export default checkoutSaga;
