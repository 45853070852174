import { call, put, takeLatest } from "redux-saga/effects";
import { RENT_ACTIONS } from "../actions/actionTypes";
import { rentServices } from "../services";
const {
  GET_VEHICLE_TYPE,
  SET_VEHICLE_TYPE,
} = RENT_ACTIONS;
const { vehicleType } = rentServices;


function* getVehicleTypes(action: any): any {
  const data = yield call(vehicleType, action.payload);
  if (data.status === 200) {
    yield put({
      type: SET_VEHICLE_TYPE,
      payload: data.data.Result,
    });
  }
}

function* rentSaga() {
  yield takeLatest(GET_VEHICLE_TYPE, getVehicleTypes);
}


export default rentSaga