import { getParamsString } from "../utills/helper";
import { CHECKOUT_API_URLS, versioning, BASE_URL } from "./apiUrls";

const getCouponListUrl = (params: any) => {
  let baseUrl = `${CHECKOUT_API_URLS.getCouponList}`+getParamsString(params);
  return baseUrl;
}
const getBranchSlotsList = (params: any) => {
    let baseUrl = `${versioning}${CHECKOUT_API_URLS.getBranchSlotsForBooking}`
    baseUrl += params?.branch_id && params?.branch_id != 0 ? `?branch_id=${params?.branch_id}` : "";
    baseUrl += params?.date ? `&date=${params?.date}` : "";
    baseUrl += params?.option_id && params?.option_id != 0 ? `&option_id=${params?.option_id}` : "";
    return baseUrl
  };
  const getHomeAddressList = (params: any) => {
    return CHECKOUT_API_URLS.getHomeAddress
  };
  const getHomeDeliveryCharge = (params: any) => {
    let baseUrl = `${CHECKOUT_API_URLS.getHomeDeliveryCharges}`
    baseUrl += params?.branch_id && params?.branch_id != 0 ? `?branchId=${params?.branch_id}` : "";
    baseUrl += params?.city_id && params?.city_id != 0 ? `&cityId=${params?.city_id}` : "";
    baseUrl += params?.addressId && params?.addressId != 0 ? `&addressId=${params?.addressId}` : "";
    return baseUrl
  };
  const createBookingUrl = () => {
    return`${CHECKOUT_API_URLS.storeBookingV1}`
  };
  const processOrderUrl= () => {
    return`${BASE_URL}/${CHECKOUT_API_URLS.processOrder}`
  };
  const orderPaymentVerifyUrl= () => {
    return`${BASE_URL}/${CHECKOUT_API_URLS.verifyBookingPayment}`
  };
export { getCouponListUrl, getBranchSlotsList, getHomeAddressList, getHomeDeliveryCharge, createBookingUrl, processOrderUrl, orderPaymentVerifyUrl };
