import React, { useEffect, useState } from "react";
import { getInTouch } from "../../assets";
import InputField from "../../component/InputField";
import en from "../../locales/en.json";
import {
  emailValidation,
  mobileValidation,
  restrictNumber,
  restrictNameWithSpaces,
  hindiTextValidation,
} from "../../utills/helper";
import { useDispatch } from "react-redux";
import { postContactUs } from "../../redux/actions/masterActions";
interface FormProps {
  type: string;
  placeholder: string;
  label: string;
  id: string;
  value: any;
  onChange: Function;
  name: string;
  maxLength: number;
  errorMsg: string;
  showError: boolean;
  restrictFuntion: any;
}
const Form = ({
  type,
  placeholder,
  label,
  id,
  value,
  onChange,
  name,
  maxLength,
  errorMsg,
  showError,
  restrictFuntion,
}: FormProps) => (
  <div className="mb-2">
    <label className="form-label"> {label} </label>
    <InputField
      type={type}
      className='form-control'
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      name={name}
      maxLength={maxLength}
      restrictFuntion={restrictFuntion}
    />
    {showError && <span className="error-message">{errorMsg}</span>}
  </div>
);
const initialState = {
  name: "",
  mobile_number: "",
  email_id: "",
  queries: "",
}
const initError = {
  name: false,
  mobile_number: false,
  email_id: false,
  queries: "",
}
const GetInTouch = () => {
  const dispatch = useDispatch()
  const {
    ContactUs,
    Name,
    EmailID,
    MobileNumber,
    WriteMessage,
    SendMessage,
    GetInTouch,
  } = en.contact;
  const [state, setState] = useState(initialState);

  const [formChanged, setFormChanged] = useState(false);
  const [errorState, setErrorState] = useState(initError);

  useEffect(() => {
    let { name, mobile_number, email_id } = state;
    if (formChanged) {
      setErrorState((preState) => ({
        ...preState,
        name: name ? false : true,
        mobile_number:
        mobile_number && mobileValidation(mobile_number) && mobile_number.length === 10
            ? false
            : mobile_number == "" ? false :true,
        email_id: email_id && emailValidation(email_id) ? false : email_id=="" ? false : true,
      }));      
    } else setFormChanged(true)
  }, [state]);

  const onChange = (name: string, value: string) => {
    hindiTextValidation(value) && setState((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(postContactUs(state))
    setState(initialState)
    setFormChanged(false)
  };

  const isDisabled = () => {
    const { name, mobile_number, email_id, queries } = errorState;
    return name || mobile_number || email_id || Object.values(state).some(e => e == "") ;
  };
  console.log({errorState})
  return (
    <div className="row">
      <div className="col-md-6">
        <img src={getInTouch} alt="" />
      </div>
      <div className="col-md-6">
        <div className="left w-100">
          <h2>{GetInTouch}</h2>
          <form className="mt-3" onSubmit={handleSubmit}>
            <Form
              type="text"
              placeholder="Enter your name"
              label={Name}
              id="exampleInputEmail1"
              value={state.name}
              onChange={onChange}
              name="name"
              maxLength={50}
              errorMsg={"Please enter your name"}
              showError={errorState.name}
              restrictFuntion={restrictNameWithSpaces}
            />
            <Form
              type="text"
              placeholder="Enter your email id"
              label={EmailID}
              id="exampleInputEmail1"
              value={state.email_id}
              onChange={onChange}
              name="email_id"
              maxLength={50}
              errorMsg={"Please enter valid email address"}
              showError={errorState.email_id}
              restrictFuntion={null}
            />
            <Form
              type="text"
              placeholder="Enter your mobile number"
              label={MobileNumber}
              id="exampleInputEmail1"
              value={state.mobile_number}
              onChange={onChange}
              name="mobile_number"
              maxLength={10}
              errorMsg={"Please enter valid mobile number"}
              showError={errorState.mobile_number}
              restrictFuntion={restrictNumber}
            />
            <div className="mb-2">
              <label className="form-label"> {WriteMessage} </label>
              <textarea
                value={state.queries}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={2}
                placeholder="Type your message/queries here..."                
                onChange={(e:any)=>{onChange('queries',e.target.value)}}
              ></textarea>
            </div>
            <button
              disabled={isDisabled()}
              type="submit"
              className="freedo-primary-button rounded-pill"
              title="Please enter all fields to enable this!"
            >
              {SendMessage}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
