import React, { FC, useCallback, useEffect, useState } from "react";
import { searchFaq } from "../../assets";
import en from "../../locales/en.json";
import { useDispatch, useSelector } from "react-redux";
import { getFAQList } from "../../redux/actions/masterActions";
import { debounce, hindiTextValidation } from "../../utills/helper";
import { Helmet } from "react-helmet";
import { langErrorMsg } from "../city-freedo/constants/constants";

const Index: FC = () => {
  const { FAQs } = en.home;
  const dispatch = useDispatch();
  const faqList = useSelector((state: any) => state?.homeReducer.faqList);
  const [FAQData, setFAQ] = useState<any>([]);
  const [searchFaqData, setSearchFaqData] = useState<any>("");
  const [errMsg, setErrMsg] = useState<boolean>(false);

  useEffect(() => {
    if (FAQs?.FaqsData?.length) setFAQ([...FAQs?.FaqsData]);
  }, []);

  const [FAQSet, setFAQSet] = useState<boolean>(false);
  const handleFilter = (e: any) => {
    setFAQ(
      FAQs.FaqsData.filter((_faq) =>
        _faq.question.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setFAQSet(true);
  };
  const debouncedChangeHandler = useCallback(debounce(handleFilter, 1000), []);

  /*  useEffect(() => {
    dispatch(getFAQList({}));
  }, []); */
  const handleSearchFaq = (e: any) => {
    if(errMsg)setErrMsg(false);
    const hindiValidation = hindiTextValidation(e.target.value);
    hindiValidation ? setSearchFaqData(e.target.value) : setErrMsg(true);
  };
  return (
    <>
      <Helmet>
        <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta name="author" content="Freedo Rentals" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Bike Rentals in Gurgaon | Bike For Rent in Gurgaon"
        />
        <meta
          property="og:description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta property="og:url" content="https://freedo.rentals/faq/" />
        <meta
          property="og:image"
          content="https://freedo.rentals/page image url"
        />
        <meta property="og:site_name" content="Freedo Rentals" />
        <link rel="canonical" href="https://freedo.rentals/faq/" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <section className="main-faq-wrapper ">
        <div className="container-fluid">
          <div className="faq-banner-wrap">
            <h2 className="text-center text-white">
              {" "}
              Frequently Asked Questions{" "}
            </h2>
          </div>
          <form
            className="col-md-6 col-sm-7 col-10"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="mb-3">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <img src={searchFaq} alt="" />
                </span>
                <input
                  type="text"
                  value={searchFaqData}
                  onChange={(e) => {
                    handleSearchFaq(e);
                    debouncedChangeHandler(e);
                  }}
                  className="form-control"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errMsg ? (
                <span className="error-field">{langErrorMsg}</span>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
        <div className="faq-inner-wrapper">
          <div className="container-fluid">
            <div className="faq-wrap">
              {/* <h3>Category Heading 1</h3> */}
              {FAQData.length ? (
                <>
                  <div className="accordion" id="accordionExample">
                    {FAQData?.map((_faq) => {
                      return (
                        <div key={_faq.id} className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={"headingThree" + _faq.id}
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapseThree" + _faq.id}
                              aria-expanded={false}
                              aria-controls={"collapseThree" + _faq.id}
                            >
                              {_faq.question}
                            </button>
                          </h2>
                          <div
                            id={"collapseThree" + _faq.id}
                            className="accordion-collapse collapse"
                            aria-labelledby={"headingThree" + _faq.id}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">{_faq.answer}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>{FAQSet && !FAQData.length && "No Search Data Found"}</>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
