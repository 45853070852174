import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import profileServices from "../../redux/services/profileServices";
import { toastr } from "react-redux-toastr";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { handleMessage } from "../../utills/helper";
import { userPic } from "../../assets";
import { actions } from "../../redux";
const UploadUserImageModal = (props: any) => {
  const [sThreeApiUrl, setSThreeApiUrl] = useState<any>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [imageSelected, setImageSelected] = useState<any>();
  const [progressInterval, setProgressInterval] = useState<any>();
  const { userProfileDetail } = useSelector(
    (state: RootState) => state.profileReducer
  );
  const { option } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.img) {
      const reader: any = new FileReader();
      reader.readAsDataURL(props.img);
      reader.addEventListener("load", () => {
        setImageSelected(reader.result);
      });
    } else {
      setImageSelected(userProfileDetail.profile_image_url);
    }
    requestSignedUrl();

  }, []);
  useEffect(() => {
    isUploading && closeModal();
  }, [userProfileDetail]);

  useEffect(() => {
    if (progressValue >= 80) {
      clearInterval(progressInterval);
    }
  }, [progressValue]);
  const requestSignedUrl = async () => {
    profileServices
      .getUserProfileSignedUrl({
        mobile_number: userProfileDetail?.mobile_number ?? "",
        extension: "jpg",
      })
      .then((response: any) => {
        if (response && response.status === 200) {
          const s3url = response.data?.Result;
          setSThreeApiUrl(s3url);
        }
      });
  };
  const startTimer = () => {
    setIsUploading(true);
    const pg = setInterval(
      () => setProgressValue((preState) => preState + 10),
      1000
    );
    setProgressInterval(pg);
  };
  const closeModal = () => {
    props.handleClose();
  };
  const uploadImageS3 = async () => {
    const imgUri = props.img;
    if (imgUri && sThreeApiUrl?.signedUrl) {
      startTimer();
      setIsUploading(true);
      try {
        fetch(sThreeApiUrl?.signedUrl, {
          method: "put",
          headers: new Headers({
            "Content-Type": "image/jpeg",
          }),
          body: imgUri,
        })
          .then((res) => {
            clearInterval(progressInterval);
            setProgressValue(95);
            if (res.status == 200) {
              saveImageData();
            }
          })
          .catch(function (error) {
            clearInterval(progressInterval);
            setIsUploading(false);
            toastr.error("Oops", handleMessage(error));
          });
      } catch (error) {
        setIsUploading(false);
        clearInterval(progressInterval);
        toastr.error(
          "",
          "Something went wrong, please upload image lesser than 5mb !"
        );
      }
    } else {
      toastr.warning("", "Please pick the image!");
    }
  };
  const saveImageData = async () => {
    const payload = {
      firstName: userProfileDetail.first_name,
      lastName: userProfileDetail.last_name,
      emailId: userProfileDetail.email_id,
      gender: userProfileDetail.gender,
      profileImage: sThreeApiUrl?.key,
    };
    //
    dispatch(actions.postUpdateUserProfile(payload));
  };
  const onErrorProfileImage = (e: any) => {
    e.target.src = userPic;
  };
  return (
    <Modal
      scrollable={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      className="modal fade vehicle-model"
      id="cancel-modal"
      tabIndex={-1}
      aria-hidden="true"
      onHide={closeModal}
    >
      <Modal.Header closeButton className="shadow p-3 bg-body">
        <Modal.Title className="text-center ">
          {
            <h6 className="m-auto modal-title" id="bookvehicleLabel">
              {option === "view" ? "Profile Picture" : "Preview"}
            </h6>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {option === "view" ? (
            <>
            <div className="row justify-content-center">
              <img
                src={imageSelected}
                alt=""
                className="selected-image"
                onError={onErrorProfileImage}
              />
            </div>
            {/* <button
                      type="button"
                      className="btn freedo-primary-button-small"
                      onClick={() =>  {props.changePictureHandle(); closeModal() }}
                    >
                      Change Picture
                    </button> */}
            <div className="modal-footer">
               
                    <button
                      type="button"
                      className="btn freedo-primary-button-small"
                      onClick={() =>  {props.changePictureHandle(); closeModal() }}
                    >
                      Change Picture
                    </button>
                 
              </div>
            </>
          ) : (
            <>
              <div className="row justify-content-center">
                <div className=" pb-3 d-flex justify-content-center">
                  {isUploading ? (
                    <CircularProgressbarWithChildren
                      className="profileImgPreviewProgress"
                      value={progressValue}
                      strokeWidth={4}
                      maxValue={90}
                    >
                      <img
                        className="profileImgPreview"
                        src={imageSelected}
                      ></img>
                    </CircularProgressbarWithChildren>
                  ) : (
                    <img
                      className="profileImgPreview"
                      src={imageSelected}
                    ></img>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                {!isUploading ? (
                  <>
                    <button
                      type="button"
                      className="btn cancel-booking-btn"
                      onClick={() => {
                        closeModal();
                      }}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn freedo-primary-button-small"
                      onClick={() => uploadImageS3()}
                      disabled={!sThreeApiUrl?.signedUrl}
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <p>Setting your profile picture...</p>
                )}
              </div>
            </>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
};

export default UploadUserImageModal;
