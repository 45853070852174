import React, { useRef, useEffect, ReactElement, ReactPortal } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { showUnderMaintenance } from "../redux/actions";

type Props = {
  children: ReactElement,
  handleOutsideClick: Function
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref,handleOutsideClick) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleOutsideClick && handleOutsideClick(event);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */

function OutsideAlerter(props :Props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef,props.handleOutsideClick);

  return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired
};

export default React.memo(OutsideAlerter);
