import {
  completeRide,
  completed,
  pro,
  proceed,
  rejected,
  req,
  request,
  statusSuccess,
  refundRequested,
  refundProcessed,
  refundCompleted,
} from "../../../assets";
import { useEffect, useState } from "react";
import { STATUS, WAREHOUSE_STATUS } from "../../../constant/enum";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  checkRefundStatus,
  getBookingStatus,
} from "../../../redux/actions/rentActions/bookingAction";
import { Routes } from "../../../constant/routeContants";

const BookingStatusModal = ({
  onHide,
  status,
  warehouse_status,
  refundScreen,
  bookingId,
}: any) => {
  // const [toggleClass, setToggleClass] = useState(false)
  const { refundData, bookingStatus } = useSelector(
    (state: any) => state.bookingReducer
  );
  const { Cancelled, Ongoing, Pending, Upcoming, Completed, Ended } = STATUS;
  const { Rejected } = WAREHOUSE_STATUS;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBookingStatus(bookingId));
  }, []);

  useEffect(() => {
    if (bookingStatus?.refund_payments?.length)
      dispatch(checkRefundStatus(bookingId));
  }, [bookingStatus]);
  return (
    <div
      className="modal end-ride-wrapper backdrop fade show"
      id="Booking-Status"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content booking-status-wrapper">
          <div className="modal-header">
            <h5>{refundScreen ? "Refund" : "Booking"} Status</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() =>
                onHide((prev) => ({
                  ...prev,
                  bookingStatus: false,
                  refundScreen: false,
                }))
              }
            ></button>
          </div>
          <div className="modal-body">
            {!refundScreen ? (
              status != Cancelled ? (
                <>
                  <div className="booking-status-steps row">
                    <div className="booking-status-box">
                      <div
                        className={`booking-status-image-wrap ${
                          status === Pending ? "" : "active"
                        }`}
                      >
                        <img src={request} alt="" />
                      </div>
                      <h6>{`Payment ${
                        status == Pending ? "Pending" : "Successfully Done"
                      }`}</h6>
                      <p>
                        {status == Pending
                          ? " Your payment is pending with us, will notify you once your booking is confirmed. If there is any issue feel free to reach our customer care service"
                          : "We have received your payment will confirm your booking shortly"}
                      </p>
                    </div>

                    <div className="booking-status-box">
                      <div
                        className={`booking-status-image-wrap ${
                          status === Upcoming || status === Pending
                            ? ""
                            : "active"
                        }`}
                      >
                        <img
                          src={proceed}
                          alt=""
                          style={{ opacity: `${status == Pending && 0.2}` }}
                        />
                      </div>
                      {status != Pending && (
                        <>
                          <h6>{`Booking request is ${
                            warehouse_status == "in-process"
                              ? "Under Process"
                              : warehouse_status == "accepted"
                              ? "Accepted"
                              : "Rejected"
                          }`}</h6>
                          <p>
                            {warehouse_status == "in-process"
                              ? " Your booking request is under process in our warehouse, will let you know once your booking gets accepted"
                              : warehouse_status == "accepted"
                              ? "Your booking request is confirmed. Our delivery executive will start your ride in a short while."
                              : "Your booking request is rejected. We are sorry for the inconvenience.  Refund will be processed in the next 3-4 working days."}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="booking-status-box noBdr">
                      <>
                        <div
                          className={`booking-status-image-wrap no-bdr active`}
                        >
                          <img
                            src={statusSuccess}
                            alt=""
                            style={{
                              opacity: `${
                                (status == Pending || status == Upcoming) && 0.2
                              }`,
                            }}
                          />
                          <img
                            className="tick-yes"
                            src={completeRide}
                            alt=""
                            style={{
                              opacity: `${
                                (status == Pending || status == Upcoming) && 0.2
                              }`,
                            }}
                          />
                        </div>
                        {status == Ongoing ? (
                          <>
                            <h6>Booking is approved</h6>
                            <p>Your booking request is confirmed </p>
                            <p>Enjoy your ride.</p>
                          </>
                        ) : status == Completed ? (
                          <>
                            <h6>Ride Completed</h6>
                            <p>Thank you for choosing Freedo Rentals.</p>
                          </>
                        ) : status == Ended ? (
                          <>
                            <h6>The ride has ended</h6>
                            <p>
                              Your ride has ended with us. Thank you for
                              choosing Freedo Rentals.{" "}
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                        {/* {(status == Ongoing) && (
                       
                     )} */}
                      </>

                      {/* <div className={toggleClass?"toggled-on":"toggled-off"}>
                     <div className="toggle-title" onClick={()=>setToggleClass(!toggleClass)}>
                       <i className="fa fa-angle-down fa-fw"></i>
                       <i className="fa fa-angle-up fa-fw"></i>
                       <a href="#" >Track your refund status</a>
                     </div>
                     <div className="toggle-content">
                       <div className="refund-staus-details">
                         <div className="refund-staus-icon active">
                           <img src={req} alt="" />
                         </div>
                         <div className="deatils">
                           <h6>Request</h6>
                           <p>14-10-2022 13:02</p>
                         </div>
                       </div>
 
                       <div className="refund-staus-details">
                         <div className="refund-staus-icon">
                           <img src={pro} alt="" />
                         </div>
                         <div className="deatils">
                           <h6>Process</h6>
                           <p>14-10-2022 13:02</p>
                         </div>
                       </div>
 
                       <div className="refund-staus-details ">
                         <div className="refund-staus-icon nobdr">
                           <img src={completed} alt="" />
                         </div>
                         <div className="deatils">
                           <h6>Completed</h6>
                           <p> 14-10-2022 13:02</p>
                         </div>
                       </div>
                     </div>
                   </div> */}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="mx-auto text-center">
                    {" "}
                    <div
                      className={`booking-status-image-wrap no-bdr ${
                        status === Cancelled || status === Ongoing
                          ? "active"
                          : ""
                      }`}
                    >
                      <img src={rejected} alt="" />
                    </div>
                    <h6>Booking is Cancelled !</h6>{" "}
                    <p>Your booking request is Cancelled.</p>
                    {/* <p>
                       Refund will be processed in the next 3-4 working days.
                     </p> */}
                  </div>
                </>
              )
            ) : (
              <div className="booking-status-steps row">
                <div className="booking-status-box">
                  <div className={`booking-status-image-wrap ${"active"}`}>
                    <img src={refundRequested} alt="" />
                  </div>
                  <h6>Requested</h6>
                  <p>
                    {refundData?.refund_issue_date
                      ? moment(refundData?.refund_issue_date).format(
                          "DD-MM-YYYY HH:mm A"
                        )
                      : ""}
                  </p>
                </div>

                <div className="booking-status-box">
                  <div
                    className={`booking-status-image-wrap ${
                      refundData?.RefundStatusCode === 1 ? "active" : ""
                    }`}
                  >
                    <img src={refundProcessed} alt="" />
                  </div>
                  <>
                    <h6>Processed</h6>
                    <p>
                      {refundData?.refund_processed_on
                        ? moment(refundData?.refund_processed_on).format(
                            "DD-MM-YYYY HH:mm A"
                          )
                        : ""}
                    </p>
                  </>
                </div>
                <div className="booking-status-box noBdr">
                  <>
                    <div className={`booking-status-image-wrap no-bdr active`}>
                      <img
                        src={refundCompleted}
                        alt=""
                        style={{
                          opacity: `${
                            refundData?.RefundStatusCode != 1 && 0.2
                          }`,
                        }}
                      />
                    </div>
                    <h6>Completed</h6>
                    <p>
                      {refundData?.refund_completion_date &&
                      refundData?.RefundStatusCode == 1
                        ? moment(refundData?.refund_completion_date).format(
                            "DD-MM-YYYY HH:mm A"
                          )
                        : ""}
                    </p>
                  </>
                </div>
              </div>
            )}
            <div className="end-bookingstatus-bottom text-center">
              {refundScreen ? (
                <p>
                  {refundData?.RefundStatusCode == 1
                    ? refundData?.RefundRefNum
                      ? `We have initiated your refund. Your refund reference number is ${refundData?.RefundRefNum}. This will be reflected in your bank account soon. Please reach out to your bank in case of any queries`
                      : `We have initiated your refund. Your refund will be processed within 7 working days.`
                    : `Your Refund will be processed in the next 3-4 working days.
                We are sorry for the inconvenience. For any queries contact
                us.`}
                </p>
              ) : (
                <>
                  {bookingStatus?.refund_payments?.length > 0 &&
                    status === Cancelled && (
                      <Link
                        to={""}
                        onClick={() =>
                          onHide((prev) => ({ ...prev, refundScreen: true }))
                        }
                      >
                        Track Refund Status
                      </Link>
                    )}
                  <p>For any queries, please contact us.</p>
                </>
              )}
              <a href={Routes.CONTACT}>
                {" "}
                <i className="fas fa-phone"></i> Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingStatusModal;
