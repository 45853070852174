import { useEffect } from "react";
// import "./css/city-responsive.css";
// import "./css/city-animation.css";
import "./css/city-main.css";
// import './css/all.min.css'
import { useDispatch, useSelector } from "react-redux";
import { getPackageMasters, getVehicleList, saveSelectedCity, setVehicleList } from "../../redux/actions";
import Blogs from "./components/Blogs";
import FAQ from "./components/FAQ";
import Missions from "./components/Missions";
import RentingFleet from "./components/RentingFleet";
import Services from "./components/Services";
import TopBanner from "./components/TopBanner";
import TopFooter from "./components/TopFooter";
import { locationPageData } from "./constants/constants";
import { Helmet } from "react-helmet";
import { setLocalStorageData } from "../../utills/helper";
import { LocalStorage } from "../../constant/enum";

const CityFreedo = () => {
  const dispatch = useDispatch();
  const { packages,cityList } = useSelector((state) => state.homeReducer);
  const { locationVehicleList } = useSelector((state) => state.localeReducer);
  const pathname = window.location.pathname;

  const locationPage = locationPageData?.filter((city) =>
    pathname.toLowerCase().includes(city.pathname.toLowerCase())
  )[0];

  const {
    top_banner,
    mission,
    faqs = {},
    blogs,
    cityId,
    cityName,
    top_footer,
    metaData = {},
    service = {},
  } = locationPage || {};
  const { title = "", description = "", keywords = "" } = metaData;

  useEffect(() => {
    dispatch(
      getPackageMasters({
        id: cityId,
        type: "GetPackages",
      })
    );
    dispatch(setVehicleList({}));
    return()=>{
     
    }
  }, []);

  useEffect(() => {
    if (packages.length) {
      let payload = {
        cityId: cityId,
        packageId: packages[0].id,
      };
      callListApi(payload);
    }
  }, [packages]);

  useEffect(() => {
    if (cityId && cityList?.length) {
      let selectedCity= cityList?.find((item) => {
        return (item?.id == cityId) && (item?.city_name == cityName);
      });
      setLocalStorageData(LocalStorage.userCity, selectedCity);
      dispatch(saveSelectedCity(selectedCity));
    }
  }, [cityId, cityList]);

  const callListApi = (payload) => {
    dispatch(
      getVehicleList({
        payload,
        primaryFilter: false,
        isLocationList:true
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <TopBanner cityId={cityId} cityName={cityName} data={top_banner} />
      <Services data={service} />
      <Missions data={mission} />
      <RentingFleet
        vehicleList={locationVehicleList}
        callListApi={callListApi}
        cityId={cityId}
        cityName={cityName}
        packages={packages}
      />
      <FAQ data={faqs} />
      <Blogs data={blogs} />
      <TopFooter data={top_footer} />
    </>
  );
};

export default CityFreedo;
