import axios from "axios";
import {
  API_URLS,
  BASE_URL,
  BASE_URL_CUSTOMER,
  KYC_API_URLS,
  LatestVersion,
  oldVersion,
  versioning,
  FeedbackAPIExtenstion
} from "../../config/apiUrls";
import { customAxios } from "../../config/axiosConfig";
import { getUrlWithParams } from "../../config/getApiUrls";
import { getParamsString } from "../../utills/helper";

const getBookingList = (params: any) => {
  return customAxios.get(
    BASE_URL_CUSTOMER +
    LatestVersion +
    API_URLS.getBookingList +
    getParamsString(params)
  );
};
const getBookingDetailsById = (params: any) => {
  return customAxios.get(
    BASE_URL_CUSTOMER +
    LatestVersion +
    API_URLS.getBookingDetailsById +
    params.booking_id
  );
};
const getExtendDetails = (params) => {
  return customAxios.get(
    BASE_URL_CUSTOMER + LatestVersion + API_URLS.extendDetails + params.id
  );
};
const getCurrentBooking = () => {
  return customAxios.get(
    BASE_URL_CUSTOMER + LatestVersion + API_URLS.getCurrentBooking
  );
};

const getPackageForExtendRide = (params) => {
  return customAxios.get(
    BASE_URL_CUSTOMER +
    LatestVersion +
    API_URLS.getExtendPackage +
    params.booking_id
  );
};
const getPriceDetailById = (params) => {
  return customAxios
    .get(
      BASE_URL_CUSTOMER +
      LatestVersion +
      API_URLS.getBookingPriceById +
      params.id
    )
    .catch((ex: any) => ex);
};
const getDocumentsById = (params) => {
  return customAxios
    .get(
      BASE_URL_CUSTOMER +
      LatestVersion +
      API_URLS.getDocumentDetails +
      params.id
    )
    .catch((ex: any) => ex);
};
const endRide = (booking_id, payload) => {
  return customAxios
    .put(
      BASE_URL_CUSTOMER +
      LatestVersion +
      API_URLS.getBookingDetailsById +
      API_URLS.endRide +
      booking_id,
      payload
    )
    .catch((ex: any) => ex);
};
const getBranches = (params: any) =>
  customAxios
    .get(BASE_URL_CUSTOMER + LatestVersion + API_URLS.getBranch + params.id)
    .catch((ex) => ex);

const extendRide = (params: any) =>
  customAxios
    .post(BASE_URL_CUSTOMER + oldVersion + API_URLS.extendRide, params)
    .catch((ex) => ex);

const uploadOdometerReading = (params: any) =>
  customAxios
    .post(
      BASE_URL_CUSTOMER + versioning + API_URLS.uploadOdometerReadings,
      params
    )
    .catch((ex) => ex);

const payExtraCharges = (params: any) =>
  customAxios
    .post(BASE_URL_CUSTOMER + oldVersion + API_URLS.payExtraCharges, params)
    .catch((ex) => ex);

const getAllMitcList = (params: any) =>
  customAxios
    .get(BASE_URL_CUSTOMER + API_URLS.getAllMitcIds + "/" + params)
    .catch((ex) => ex);

const getCancellationCharge = (params) => {
  return customAxios.get(
    BASE_URL_CUSTOMER + oldVersion + API_URLS.cancellationCharge + params
  );
};

const cancleBooking = (params) => {
  return customAxios.put(
    BASE_URL_CUSTOMER + oldVersion + API_URLS.cancelBooking + params
  );
};

const checkRefundStatus = (params) => {
  return customAxios.get(
    BASE_URL_CUSTOMER + API_URLS.checkRefundStatus + params
  );
};
const getBookingStatus = (params) => {
  return customAxios.get(
    BASE_URL_CUSTOMER + API_URLS.getBookingStatus + params
  );
};
const getModificationCharges = (params) => {
  return customAxios.get(
    BASE_URL_CUSTOMER +
    oldVersion +
    API_URLS.modificationCharges +
    params.id +
    "/" +
    params.date
  );
};
const getBookingChangeCharges = () => {
  return customAxios.get(
    BASE_URL_CUSTOMER + oldVersion + API_URLS.bookingChangeCharges
  );
};
const getModelAvailableDates = (params) => {
  return customAxios.get(
    getUrlWithParams(
      BASE_URL_CUSTOMER + oldVersion + API_URLS.getModelAvailableDates + "?",
      params
    )
  );
};

const modifyBooking = (params) => {
  return customAxios.put(
    BASE_URL_CUSTOMER + oldVersion + API_URLS.modifyBooking,
    params
  );
};
const getFeedbackFormByLink = (params) => {
  // return customAxios.get(
  //   getUrlWithParams(
  //     BASE_URL + FeedbackAPIExtenstion + API_URLS.getFeedbackForm + "?",
  //     params
  //   )
  // );
  return customAxios.get(
    BASE_URL + FeedbackAPIExtenstion + API_URLS.getFeedbackFormByBookingId + encodeURIComponent(params?.data)
  );
};
const getFeedbackFormByBookingId = (params) => {
  return customAxios.get(BASE_URL + FeedbackAPIExtenstion + API_URLS.getFeedbackFormByBookingId + params.id);
};
const submitFeedbackForm = (params) => {
  return customAxios
    .post(BASE_URL + FeedbackAPIExtenstion + API_URLS.SubmitFeedbackForm, params)
};
export default {
  getBookingList,
  getBookingDetailsById,
  getExtendDetails,
  getCurrentBooking,
  getPackageForExtendRide,
  getPriceDetailById,
  getDocumentsById,
  endRide,
  getBranches,
  extendRide,
  uploadOdometerReading,
  payExtraCharges,
  getAllMitcList,
  getCancellationCharge,
  cancleBooking,
  checkRefundStatus,
  getBookingStatus,
  getModificationCharges,
  getModelAvailableDates,
  modifyBooking,
  getBookingChangeCharges,
  getFeedbackFormByLink,
  getFeedbackFormByBookingId,
  submitFeedbackForm
};
