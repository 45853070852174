import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { getMitcList } from "../../../../redux/actions/rentActions/bookingAction";
import { MitcFormsSkelaton } from "../../../../component/SkelatonLoader/MitcFormsSkelaton";

const MitcTab = ({ bookingId,mitcTab }) => {
  const dispatch = useDispatch();
  const [mitcList, setMitcList] = useState<any>([]);
  const [mitcLodader, setMitcLodader] = useState<boolean>(true);
  useEffect(() => {
    if (bookingId && mitcTab) {
      dispatch(getMitcList(bookingId, handleuSuccess, handleError));
    }
  }, [bookingId,mitcTab]);

  const handleuSuccess = (data) => {
    if (data?.length) {
      setMitcList([...data]);
      setMitcLodader(false);
    } else setMitcLodader(false);
  };

  const handleError = () => setMitcLodader(false);

  const handleIconClick = (status) => {
    status === "Created"
      ? toastr?.error(`! Warning`, `MITC is unverified`)
      : toastr?.success(`Success`, `MITC is verified`);
  };
  return (
    <div
      id="collapseSeven"
      className="accordion-collapse collapse"
      aria-labelledby="headingSeven"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <div className="Vehicle-Price-Details">
          <div className="table-responsive">
            <table className="table table-borderless table-sm">
              {mitcLodader ? (
                <MitcFormsSkelaton />
              ) : (
                <tbody>
                  {mitcList.length > 0 ? (
                    mitcList.map((item, ind) => (
                      <tr>
                        <td className="d-flex justify-content-between">
                          <a href={item.mitcUrl} target="_blank">
                            Form {mitcList.length - ind}
                          </a>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleIconClick(item?.status)}
                          >
                            {item?.status === "Created" ? (
                              <i className="fa-solid fa-circle-exclamation p-2" />
                            ) : (
                              <i className="fa-solid fa-circle-check p-2" />
                            )}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p>No MITC forms are available</p>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MitcTab;
