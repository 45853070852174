import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { apiFailed, apiSuccess } from "../assets";
import { resetResponseModalState } from "../redux/actions";

const SuccessModal = ({ isSuccess, modalMessage }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(resetResponseModalState());
  };
  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 3000);
  }, []);

  return (
    <Modal
      className="successModalContainer"
      size="sm"
      centered
      show={true}
      onHide={handleClose}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center mb-3">
        <img src={isSuccess ? apiSuccess : apiFailed} className="mb-3" />
        <p>{modalMessage}</p>
        {!isSuccess && (
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            Try again
          </a>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
