import React, { useEffect, useState } from "react";
// import Collapse from "react-bootstrap/Collapse";
const FAQ = ({ data }) => {
  const [isOpen, setIsOpen] = useState([]);

  useEffect(() => {
    const arr = data?.faq.map((item) => ({ value: false }));
    setIsOpen([...arr]);
  }, []);

  const handleToggle = (value, i) => {
    const arr = isOpen?.map((item, ind) => {
      let open = { value: true };
      let close = { value: false };
      if (value) {
        return i === ind ? open : close;
      } else return close;
    });
    setIsOpen([...arr]);
  };

  if (!data) return null;

  return (
    <section className="faq-wrapper">
      <h2 className="rental-head text-center">FAQ's</h2>
      <div className="container-fluid">
        <div className="faqHeader">
          <b>{data?.heading}</b>
          <br />
          <span>{data?.text}</span>
        </div>
        <div className="faq-wrap">
          <div className="accordion" id="accordionExample">
            {data?.faq?.length &&
              data?.faq?.map((item, i) => (
                <>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading${i}`}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapseOne_${i}`}
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        {item.question}
                      </button>
                    </h2>
                    <div
                      id={`collapseOne_${i}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${i}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">{item.anaswer}</div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
