import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  postContact,
  addAddress,
  deleteAddress,
  updateAddress,
  getAdvertisements,
  submitEnquiry,
  getVehicleDetails,
  getMitcHtmlContent,
  sendOtpForMitc,
  verifyMitcForm,
} from "../services/globalServices";
import { GLOBAL_ACTION, CHECKOUT_ACTIONS } from "../actions/actionTypes";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../utills/helper";
import { getHomeAddressList } from "../actions";

function* postContactUsForm(action: any): any {
  const data = yield call(postContact, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
  } else {
    toastr.error("", handleMessage(data));
  }
}
function* setAddress(action: any): any {
  const { params, isEditAddress, handleSuccess } = action.payload;
  const callService = isEditAddress ? updateAddress : addAddress;
  try {
    const data = yield call(callService, params);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      handleSuccess(); // going back to address list screen
      yield put({
        // calling address list api
        type: CHECKOUT_ACTIONS.GET_HOME_ADDRESS_LIST,
        payload: {},
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
  }
}
function* deleteHomeAddress(action: any): any {
  console.log(action, 8889);
  try {
    const data = yield call(deleteAddress, action.payload);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      yield put({
        // calling address list api
        type: CHECKOUT_ACTIONS.GET_HOME_ADDRESS_LIST,
        payload: {},
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
  }
}
function* getAdvertisement(action: any): any {
  try {
    const data = yield call(getAdvertisements, action.payload);
    if (data?.status === 200) {
      yield put({
        type: GLOBAL_ACTION.SET_ADVERTISMENT,
        payload: data?.data?.Result || [],
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
  }
}
function* setEnquiry(action: any): any {
  try {
    const data = yield call(submitEnquiry, action.payload);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      action.handleSuccess();
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
  }
}

function* getVehicleSpecfications(action: any) {
  try {
    const data = yield call(getVehicleDetails, action.payload);
    if (data?.status === 200) {
      action.handleSuccess(data?.data);
    } else {
      console.log(data, 8889);
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    console.log(error, 8889);
    toastr.error("", handleMessage(error));
  }
}

function* getMitcHtml(action: any): any {
  try {
    const data = yield call(getMitcHtmlContent, action.payload);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      action.handleSuccess('GetMitcData',data?.data?.Result||{});
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
  }
}

function* sendOtpMitc(action: any): any {
  try {
    const data = yield call(sendOtpForMitc, action.payload);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      action.handleSuccess('SendOtp');
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
  }
}

function* verifyMitc(action: any): any {
  try {
    const data = yield call(verifyMitcForm, action.payload);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      action.handleSuccess('VerifyOtp');
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
  }
}

function* resendOtpMitc(action : any) : any {
  try {
    const data = yield call(sendOtpForMitc, action.payload);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      // action.handleSuccess('SendOtp');
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
  }
}
function* globalSaga() {
  yield takeEvery(GLOBAL_ACTION.SET_ADDRESS, setAddress);
  yield takeEvery(GLOBAL_ACTION.DELETE_ADDRESS, deleteHomeAddress);
  yield takeLatest(GLOBAL_ACTION.POST_CONTACT_US, postContactUsForm);
  yield takeLatest(GLOBAL_ACTION.GET_ADVERTISMENT, getAdvertisement);
  yield takeLatest(GLOBAL_ACTION.SET_ENQUIRY, setEnquiry);
  yield takeLatest(GLOBAL_ACTION.GET_VEHICLE_DETAILS, getVehicleSpecfications);
  yield takeLatest(GLOBAL_ACTION.GET_MITC_CONTENT, getMitcHtml);
  yield takeLatest(GLOBAL_ACTION.SEND_OTP_MITC, sendOtpMitc);
  yield takeLatest(GLOBAL_ACTION.VERIFY_MITC, verifyMitc);
  yield takeLatest(GLOBAL_ACTION.RESEND_OTP_MITC, resendOtpMitc);
}
export default globalSaga;
