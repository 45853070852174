import moment from "moment";
import { targetGrey } from "../../assets";
import SelectCityModal from "../homeScreen/SelectCityModal";
import "./TopFilter.css";
import { useRef } from "react";
import { format } from "date-fns";
import DatePickerInput from "../../component/DatePicker/DatePickerInput";
import { toggleCityModal } from "../../redux/actions";
import { useDispatch } from "react-redux";

const FilterForm = ({
  packages,
  handlePackageChange,
  findEndDate,
  cityData,
  setPrimaryFilter,
  primaryFilter,
  resetPackages,
}) => {
  const dispatch = useDispatch();
  const { selectedPackage, startDate, endDate } = primaryFilter;
  const datePickerRef = useRef<any>();
  const DateFormatForPicker = (date = new Date(), isTime = false) => {
    let dateFormat = format(new Date(date), "yyyy-MM-dd");
    if (isTime) {
      dateFormat = format(new Date(date), "yyyy-MM-dd hh:mm a");
    }
    return dateFormat;
  };
  const handleDatePickerOnchange = (e) => {
    let selectedDate = e;
    if (selectedDate) {
      DateFormatForPicker(e);
    }
    let endDate = findEndDate(
      selectedDate,
      primaryFilter.selectedPackage.package_days
    );
    setPrimaryFilter({
      ...primaryFilter,
      startDate: selectedDate,
      endDate,
    });
  };

  const formattedLaunchDate = cityData?.launch_date
    ?.split("-")
    .reverse()
    .join("-");
  let launchDateForPicker = formattedLaunchDate
    ? new Date(formattedLaunchDate)
    : new Date();
  launchDateForPicker =
    launchDateForPicker <= new Date() ? new Date() : launchDateForPicker;

  return (
    <>
      <div className="filter-row form">
        <div className="detect-filter">
          <div
            className="input-group"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalToggle"
            onClick={() => dispatch(toggleCityModal(true))}
          >
            <input
              type="button"
              className="form-control form-control-sm text-start"
              value={cityData?.city_name}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
            <div className="loaction-detect">
              <i className="fa-solid fa-location-dot"></i>
            </div>
            <a href="#" className="input-group-text" id="basic-addon1">
              <img src={targetGrey} alt="" />
            </a>
          </div>
        </div>

        <div className="date-filter">
          <div className="input-group">
            <DatePickerInput
              startDate={startDate}
              handleDatePickerOnchange={handleDatePickerOnchange}
              datePickerRef={datePickerRef}
              isClearableEnabled={false}
              minDate={launchDateForPicker}
            />
            <div className="start-end-date-text">Start Date</div>
          </div>

          <div>
            <div className="progressbar">
              <span className="active"></span>
              <span className="next"></span>
            </div>
          </div>

          <div className="input-group end-date-mobile">
            <input
              className="form-control form-control-sm rounded-pill"
              value={endDate ? moment(endDate).format("DD-MM-YYYY") : ""}
              style={{ color: "#777" }}
            />
            <div className="start-end-date-text">End Date</div>
          </div>

          <div className="ml-0 end-date">
            {endDate ? moment(endDate).format("DD-MM-YYYY") : ""}
          </div>
        </div>

        <div className="day-select-filter">
          <select
            className="form-select form-control-sm w-100"
            aria-label="packageSelect"
            onChange={(e) => {
              handlePackageChange(e.target.value);
            }}
            value={selectedPackage?.package_name}
            disabled={packages?.length == 0}
          >
            {packages?.length == 0 ? (
              <option>{`No package found`}</option>
            ) : (
              packages.map((item, ind: number) => (
                <option key={ind}>{item.package_name}</option>
              ))
            )}
          </select>

          <div className="clock-Day">
            <i className="fa-regular fa-clock"></i>
          </div>
        </div>
      </div>
      <div className="locate-me-model-wrap">
        <div className="location-wrap">
          <SelectCityModal handleChange={resetPackages} />
        </div>
      </div>
    </>
  );
};

export default FilterForm;
