import * as actionTypes from "../actionTypes";

export const userLoginRequested = (payload: any) => {
  return { type: actionTypes.AUTH_ACTIONS.USER_LOGIN_REQUESTED, payload };
};

export const setUserToken = (payload: any) => {
  return { type: actionTypes.AUTH_ACTIONS.SET_USER_TOKEN, payload };
};

export const logout = () => {
  return { type: actionTypes.AUTH_ACTIONS.LOGOUT };
};

export const setUserData = (payload: any) => {
  return { type: actionTypes.AUTH_ACTIONS.SET_USER_DATA, payload };
};

export const otpVerification = (payload: any) => {
  return { type: actionTypes.AUTH_ACTIONS.OTP_VERIFICATION_REQUESTED, payload };
};

export const userSignUpRequested = (payload: any) => {
  return { type: actionTypes.AUTH_ACTIONS.USER_SIGNUP_REQUESTED, payload };
};

export const sendOtpForSignUp = (payload: any) => {
  return {
    type: actionTypes.AUTH_ACTIONS.SENT_OTP_FOR_SIGNUP_REQUESTED,
    payload,
  };
};

export const resendOtp = (payload: any) => {
  return { type: actionTypes.AUTH_ACTIONS.RESEND_OTP_REQUESTED, payload };
};

export const cloeseOtpScreen = () => {
  return {
    type: actionTypes.AUTH_ACTIONS.CLOSE_OTP_SCREEN,
  };
};
export const userLogout = () => {
  return {
    type: actionTypes.AUTH_ACTIONS.LOGOUT_SUCCESS,
  };
};

export const getDelAccOptions = (payload:any) => {
  return {
    type: actionTypes.DELETE_ACCOUNT_ACTION.GET_DEL_ACC_OPTIONS_REQUESTED,
    payload
  };
};

export const otpForDelAcc = (payload:any) => {
  return {
    type: actionTypes.DELETE_ACCOUNT_ACTION.SEND_OTP_FOR_DEL_ACC_REQUESTED,
    payload,
  };
};

export const changeAccStatus = (payload:any) => {
  return {
    type: actionTypes.DELETE_ACCOUNT_ACTION.CHANGE_ACC_STATUS_REQUESTED,
    payload,
  };
};

export const showUnderMaintenance = (payload: any)=>{
  return {
    type : actionTypes.AUTH_ACTIONS.UNDER_MAINTENANCE, payload
  }
}
