import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { underMaintenanceImg } from "../assets";
import { useDispatch, useSelector } from "react-redux";
import { showUnderMaintenance } from "../redux/actions";
import { RootState } from "../redux/store";
const details = {
  heading: "Temporarily down for maintenance",
  subHeading: "We’ll be back soon!",
  text: "Sorry for the inconvenience but we’re performing some maintenance at the moment. we’ll be back online shortly!",
  redirectTo: "/",
};

const UnderMaintenance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  useEffect(() => {
    return () => {
      dispatch(showUnderMaintenance(false));
    };
  }, []);

  const handleRedirection = () => {
    dispatch(showUnderMaintenance(false));
    navigate(details.redirectTo);
  };

  return (
    <>
      <div>
              <center>
                <img src={underMaintenanceImg} alt="Under-Maintenance" />
              </center>
              <div className="text-center payment-text">
                <h1>{details.subHeading}</h1>
                <h2>{details.heading}</h2>
                <p className="mt-1 mb-1">
                  <small>{details.text}</small>
                </p>
              </div>
            

            <div className="d-flex justify-content-center m-3">
              <button
                onClick={handleRedirection}
                className="freedo-primary-button btn-sm rounded-pill"
              >
                Back to Home
              </button>
            </div>
      </div>
    </>
  );
};

export default React.memo(UnderMaintenance);
