import axios from "axios";
import {
  API_URLS,
  BASE_URL_CUSTOMER,
  LatestVersion,
} from "../../config/apiUrls";
import { Axios, customAxios } from "../../config/axiosConfig";
import { getMasterData } from "../../config/getApiUrls";

export const postContact = (params: any) => {
  return Axios.post(BASE_URL_CUSTOMER + API_URLS.postContact, params);
};

export const addAddress = (params: any) => {
  return customAxios.post(BASE_URL_CUSTOMER + API_URLS.addAddress, params);
};
export const deleteAddress = (params: any) => {
  return customAxios.put(
    BASE_URL_CUSTOMER + API_URLS.deleteAddress + `/${params.id}`
  );
};

export const updateAddress = (params: any) => {
  return customAxios.put(
    BASE_URL_CUSTOMER + API_URLS.updateAddress + `/${params.id}`,
    params
  );
};
export const getAdvertisements = (params: any) =>
  Axios
    .get(BASE_URL_CUSTOMER + getMasterData({ type: params.type }))
    .catch((ex: any) => ex);

export const submitEnquiry = (params: any) => {
  return Axios.post(BASE_URL_CUSTOMER + `${API_URLS.notifyMe}`, params);
};

export const getVehicleDetails = (params: any) =>
  Axios
    .get(
      BASE_URL_CUSTOMER +
        LatestVersion +
        API_URLS.getVehicleDetails +
        `/${params.modelId}`
    )
    .catch((ex) => ex);

export const getMitcHtmlContent = (params: any) =>
  Axios
    .post(BASE_URL_CUSTOMER + API_URLS.getMitcHtmlContent, params)
    .catch((ex: any) => ex);

export const sendOtpForMitc = (params: any) =>
  Axios
    .post(BASE_URL_CUSTOMER + API_URLS.sendOtpForMitc, params)
    .catch((ex: any) => ex);

export const verifyMitcForm = (params: any) =>
  Axios
    .post(BASE_URL_CUSTOMER + API_URLS.verifyMitcForm, params)
    .catch((ex: any) => ex);
