const storeData = async (key: string, value: any) => {
  try {
    if(value !== undefined){
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (e) {
    console.log(`Error in storing ${key} to localstotage.`);
  }
};

const getData = async (key: string) => {
  try {
    const value = localStorage.getItem(`${key}`);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.log(`Error in getting ${key} from localstotage.`);
  }
};

export { storeData, getData };
