import React from "react";
import { ourMission, ourStory, ourVission } from "../../assets";
import {aboutUsVideo} from "../../assets"

const TopBanner = () => {
  return (
    <section className="innerpage--wrapper">
      <div className="container-fluid">
        <div className="main-content-wrapper about-wrapper">
          <div className="row innerpage-main-head">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <h1>MEET FREEDO</h1>
              <p>
                Freedo aims to reduce dependence on personal vehicle and
                owner-ship by introducing User-ship through shared vehicles, and
                in the process leaving the planet a bit healthier.
              </p>

              <h6 className="inner-bottom-text">
                Choose Freedom to Move, Choose Freedo !
              </h6>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="row justify-content-end slider-right-wrap">
                <div className="slider-img-wrap">
                  <span className="innerpage-img-head">
                    <img src={ourStory} alt="Freedo's Story" />
                    <h5>
                      <a href="#our-story">OUR STORY</a>
                    </h5>
                  </span>
                  <video width="100%" className="slide-2" autoPlay muted loop>
                    {/* onMouseEnter={(e) => e.currentTarget.play()} onMouseLeave={(e) => e.currentTarget.pause()}  */}
                    <source src={aboutUsVideo} type="video/mp4" />
                    <source src="movie.ogg" type="video/ogg" />
                  </video>
                </div>
                <div className="slider-img-wrap">
                  <span className="innerpage-img-head">
                    <img
                      src={ourMission}
                      className="slide-3"
                      alt="Freedo's Story"
                    />
                    <h5>
                      <a href="#our-mission">OUR MISSION</a>
                    </h5>
                  </span>
                  <span className="innerpage-img-head">
                    <img src={ourVission} alt="Freedo's Vision" />
                    <h5>
                      <a href="#our-vision">OUR VISION</a>
                    </h5>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
