import { PROFILE_ACTIONS } from "../actionTypes";

const {
  GET_BOOKING_LIST,
  GET_BOOKING_DETAILS_BY_ID,
  GET_EXTEND_DETAILS,
  GET_CURRENT_BOOKING,
  GET_EXTEND_PACKAGE,
  GET_PRICE_DETAILS_BY_ID,
  END_RIDE,
  GET_DOCUMENT_DETAILS_BY_ID,
  SET_BOOKING_ID,
  GET_BRANCH,
  EXTEND_RIDE,
  ODOMETER_READING,
  PAY_EXTRA_CHARGES,
  GET_MITC_LIST,
  SET_BOOKING_DETAILS_BY_ID,
  SET_EXTEND_RIDE_DATA,
  RESET_EXTEND_RIDE_DATA,
  GET_CANCELLATION_CHARGES,
  CANCLE_BOOKING,
  CHECK_REFUND_STATUS,
  SET_CANCLE_LOADER,
  CANCEL_SUCCESS,
  GET_BOOKING_STATUS,
  GET_MODIFICATION_CHARGES,
  GET_MODEL_AVAILABLE_DATES,
  MODIFY_BOOKING,
  SET_MODIFY_BOOKING_STATE,
  CLEAR_MODIFY_BOOKING_STATE,
  GET_BOOKING_CHANGE_CHARGES
} = PROFILE_ACTIONS;
export const getBookingDetails = (payload: any) => {
  return { type: GET_BOOKING_LIST, payload };
};
export const getBookingDetailsById = (payload: any) => {
  return { type: GET_BOOKING_DETAILS_BY_ID, payload };
};
export const getExtendDetails = (payload: any) => {
  return { type: GET_EXTEND_DETAILS, payload };
};
export const getCurrentBooking = (payload: any) => {
  return { type: GET_CURRENT_BOOKING, payload };
};
export const getExtendPackageDetails = (payload: any) => {
  return { type: GET_EXTEND_PACKAGE, payload };
};
export const getPriceDetailsById = (payload: any, handleSuccess) => {
  return { type: GET_PRICE_DETAILS_BY_ID, payload, handleSuccess };
};
export const endRideRequest = (id, payload: any, handleSuccess, setLoader) => {
  return { type: END_RIDE, payload, id, handleSuccess, setLoader };
};

export const getDocumentDetailsById = (payload: any, handleSuccess) => {
  return { type: GET_DOCUMENT_DETAILS_BY_ID, payload, handleSuccess };
};
export const setBookingId = (payload: any) => {
  return { type: SET_BOOKING_ID, payload };
};
export const getBranchList = (payload: any, setBranchList) => {
  return { type: GET_BRANCH, payload, setBranchList };
};
export const extendRideAction = (
  payload: any,
  handleSuccess,
  setExtendRideLoader
) => {
  return { type: EXTEND_RIDE, payload, handleSuccess, setExtendRideLoader };
};
export const odometerReadingAction = (payload: any, handleSuccess) => {
  return { type: ODOMETER_READING, payload, handleSuccess };
};

export const payExtraChargesAction = (
  payload: any,
  handleSuccess,
  setExtraChargeLoader
) => {
  return {
    type: PAY_EXTRA_CHARGES,
    payload,
    handleSuccess,
    setExtraChargeLoader,
  };
};

export const getMitcList = (payload: any, handleSuccess, handleError) => {
  return { type: GET_MITC_LIST, payload, handleSuccess };
};

export const resetBookingDetails = () => {
  return { type: SET_BOOKING_DETAILS_BY_ID, payload: {} };
};

export const setExtendRideState = (payload: any) => {
  return { type: SET_EXTEND_RIDE_DATA, payload };
};

export const reSetExtendRideState = () => {
  return { type: RESET_EXTEND_RIDE_DATA };
};

export const getCancellationCharge = (payload: any) => {
  return { type: GET_CANCELLATION_CHARGES, payload };
};

export const cancleBooking = (payload: any) => {
  return { type: CANCLE_BOOKING, payload };
};

export const checkRefundStatus = (payload: any) => {
  return { type: CHECK_REFUND_STATUS, payload };
};

export const setCancleLoader = (payload: any) => {
  return { type: SET_CANCLE_LOADER, payload };
};

export const setCancleSuccess = (payload: any) => {
  return { type: CANCEL_SUCCESS, payload };
};

export const getBookingStatus = (payload: any) => {
  return { type: GET_BOOKING_STATUS, payload };
};

export const getModificationCharges = (payload: any,callback) => {
  return { type: GET_MODIFICATION_CHARGES, payload,callback };
};

export const getModelAvailableDates = (payload: any) => {
  return { type: GET_MODEL_AVAILABLE_DATES, payload };
};

export const modifyBooking = (payload,callback) => {
  return { type: MODIFY_BOOKING,payload,callback };
};

export const setModifyBookingState = (payload) => {
  return { type: SET_MODIFY_BOOKING_STATE,payload };
};

export const clearModifyBookingState = () => {
  return { type: CLEAR_MODIFY_BOOKING_STATE };
};
export const getBookingChangeCharges = () => {
  return { type: GET_BOOKING_CHANGE_CHARGES };
};
