import { localeInterface } from "../../interfaces/actionInterfaces";
import { HOME_ACTIONS } from "../actions/actionTypes";
const initialState = {
  packages: [],
  vehicleList: {},
  vehicleListLoader: false,
  cityList: [],
  carouselData: [],
  coupanDetails: {},
  faqList: [],
  isPrimaryFilter: true,
  defaultCity: {},
  zohoLeadSucess: false,
  isCityModalOpen: false,
  isJourneyModalOpen: false,
  getnearestcity: false,
  isdetectionmodalopen:false,
  detectlocationloader:false,
  isCitySelected:false,
};
const {
  SET_CITY_LIST_MASTERS,
  SET_PACKAGE_MASTERS,
  SET_VEHICLE_LIST,
  SET_CAROUSEL_DATA,
  SET_COUPAN_DATA,
  SET_FAQ_LIST,
  SET_FILTER_TYPE,
  SET_DEFAULT_CITY,
  GET_NEAREST_CITY_FAILED,
  GET_NEAREST_CITY_REQUESTED,
  GET_NEAREST_CITY_SUCCESS,
  TOGGLE_DETECTION_LOADER,
  ZOHO_UPCOMING_CITY_LEAD_REQUESTED,
  ZOHO_UPCOMING_CITY_LEAD_FAILED,
  ZOHO_UPCOMING_CITY_LEAD_SUCCESS,
  TOGGLE_CITY_MODAL,
  TOGGLE_JOURNEY_MODAL,
  SET_VEHICLE_LIST_LOADER,
} = HOME_ACTIONS;
const homeReducer = (state: any = initialState, action: localeInterface) => {
  const prevState = { ...state };
  const { type } = action;

  
  switch (type) {
    case SET_PACKAGE_MASTERS:
      return {
        ...state,
        packages: action.payload,
      };

    case SET_VEHICLE_LIST:
      return {
        ...state,
        vehicleList: action.payload,
      };

      case SET_VEHICLE_LIST_LOADER:
      return {
        ...state,
        vehicleListLoader: action.payload,
      };

    case SET_FILTER_TYPE:
      return {
        ...state,
        isPrimaryFilter: action.payload,
      };

    case SET_CITY_LIST_MASTERS:
      return {
        ...state,
        cityList: action.payload,
      };
    case SET_CAROUSEL_DATA:
      return {
        ...state,
        carouselData: action.payload,
      };
    case SET_COUPAN_DATA:
      return {
        ...state,
        coupanDetails: action.payload,
      };
    case SET_FAQ_LIST:
      return {
        ...state,
        faqList: action.payload,
      };
    case SET_DEFAULT_CITY:
      return {
        ...state,
        defaultCity: action.payload,
      };
    case ZOHO_UPCOMING_CITY_LEAD_REQUESTED:
      return {
        ...state,
        zohoLeadSucess: false,
      };
    case ZOHO_UPCOMING_CITY_LEAD_SUCCESS:
      return {
        ...state,
        zohoLeadSucess: true,
      };
    case ZOHO_UPCOMING_CITY_LEAD_FAILED:
      return {
        ...state,
        zohoLeadSucess: false,
      };
    case TOGGLE_CITY_MODAL:
      return {
        ...state,
        isCityModalOpen: action.payload,
      };
    case TOGGLE_JOURNEY_MODAL:
      return {
        ...state,
        isJourneyModalOpen: action.payload,
      };

    case GET_NEAREST_CITY_FAILED:
      return {
        ...state,
        getnearestcity: false,
        detectlocationloader:false,
        isCitySelected:false

      };

    case GET_NEAREST_CITY_SUCCESS:
      return {
        ...state,
        getnearestcity: true,
        detectlocationloader:false,
        isCitySelected:true
      };

    case GET_NEAREST_CITY_REQUESTED:
      return {
        ...state,
        getnearestcity: false,
        detectlocationloader:true,
      };

    default:
      return { ...prevState };
  }
};
export default homeReducer;
