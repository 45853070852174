export enum Routes {
  HOME = "/",
  ABOUT_US = "/about",
  CONTACT = "/contactus",
  FAQ = "/faq",
  CHECKOUT = "/checkout",
  RENT = "/rent",
  BLOG = "/blog",
  BLOG_DETAILS = "/blog/:title",
  PROFILE = "/profile",
  BOOKING_DETAILS = "/booking-details/:encrypted",
  BANGALORE = '/bike-rentals-in-bangalore',
  GURGAON = '/bike-rentals-in-gurgaon',
  JAIPUR = '/bike-rentals-in-jaipur',
  VADODARA ='/bike-rentals-in-vadodara',
  DELHI = '/bike-rentals-in-delhi',
  NOIDA = '/bike-rentals-in-noida',
  GHAZIABAD = '/bike-rentals-in-ghaziabad',
  FARIDABAD = '/bike-rentals-in-faridabad',
  VIJAYAWADA = '/bike-rentals-in-vijayawada',
  HYDERABAD = '/bike-rentals-in-hyderabad',
  GOA = "/bike-rentals-in-goa",
  LUCKNOW = "/bike-rentals-in-lucknow",
  TERMS_CONDITIONS = '/terms-conditions',
  TERMS_CONDITIONS_APP = '/terms-conditions-app',
  PRIVACY_POLICIE = '/privacy-policy',
  PAYMENT_SUCCESS = '/payment-success-response-handler/:orderId',
  PAYMENT_PENDING = '/payment-pending-web/:orderId',
  FREEDO_BUSINESS = '/join-us',
  MITC_FORM = '/verify-mitc',
  THANKYOU_PAGE = '/thankyou-screen/:purpose',
  DELETE_ACCOUNT = '/privacy-policy/delete-account',
  FEEDBACK = "/feedback/:id",
}

export const showSEOFooterRoutes = { [Routes.HOME.toLowerCase()]: true };

export const AuthRoutes = ['checkout','profile','booking-details','payment-success-response-handler']
