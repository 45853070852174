import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BillingPrice from "../../../checkoutScreen/BillingPrice";
import { BOOKING_DATA } from "../../../../constant/enum";
import { getPriceDetailsById } from "../../../../redux/actions/rentActions/bookingAction";
import PriceDetailsSkelaton from "../../../../component/SkelatonLoader/PriceDetailsSkelaton";

const PriceDetailsTab = ({ id, priceDetailsTab }) => {
  const dispatch = useDispatch();
  const [sklflag, setSkl] = useState(true);
  const { priceDetails } = useSelector((state: any) => state?.profileReducer);
  const bookingDetailsById = useSelector(
    (state: any) => state?.bookingReducer?.bookingDetails
  );
  
  useEffect(() => {
    if (priceDetailsTab) dispatch(getPriceDetailsById({ id }, handleSuccess));
  }, [priceDetailsTab]);

  const handleSuccess = () => {
    setSkl(false);
  };

  const details = {
    packageCharge: priceDetails?.package_charge || 0,
    discount: priceDetails?.discount || 0,
    additionalHelmet: priceDetails.additonal_helmet_charge || 0,
    deliverCharge: priceDetails?.deliver_charge || 0,
    sgst: priceDetails?.sgst || 0,
    cgst: priceDetails?.igst || 0,
    total: priceDetails?.amount || 0,
    pickUpDeliveryCharges: priceDetails?.pick_up_delivery_charges || 0,
    dropOffDeliveryCharges: priceDetails?.drop_off_deliver_charge || 0,
    totalAmount: priceDetails?.amount || 0,
    securityAmt:
      priceDetails?.security_amt &&
      bookingDetailsById?.moduleBranches?.SecurityAmount
        ? priceDetails?.security_amt
        : 0,
  };
  return (
    <div>
      {sklflag ? (
        <PriceDetailsSkelaton />
      ) : (
        <BillingPrice priceDetail={details} heading={false} />
      )}
    </div>
  );
};

export default PriceDetailsTab;
