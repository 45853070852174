import React from 'react';
import OurMission from './OurMission';
import OurStory from './OurStory';
import OurVision from './OurVision';
import TopBanner from './TopBanner';
import { Helmet } from "react-helmet";

const index = () => {
    return (
        <>
        <Helmet>
          <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
          <meta
            name="description"
            content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
          />
          <meta name="author" content="Freedo Rentals" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Bike Rentals in Gurgaon | Bike For Rent in Gurgaon"
          />
          <meta
            property="og:description"
            content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
          />
          <meta property="og:url" content="https://freedo.rentals/gurgaon" />
          <meta
            property="og:image"
            content="https://freedo.rentals/page image url"
          />
          <meta property="og:site_name" content="Freedo Rentals" />
          <link rel="canonical" href="https://freedo.rentals/aboutus/" />
          <meta name="robots" content="index, follow" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
            <TopBanner />
            <section className="mission-wrapper">
                <div className="container-fluid">
                    <OurStory />
                    <OurMission />
                    <OurVision />
                </div>
            </section>
        </>
    );
};

export default index;
