import React, { useState, useEffect } from "react";
// @ts-ignore
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import BlogCard from "./BlogCard";
import { BlogDetailData, blogsData } from "../../constant/BlogData";

const BlogDetails = () => {
  const { searchOptions, cardData } = blogsData;
  const [searchPopup, setSearchPopup] = useState<boolean>(false);
  const [blogDetails, setBlogDetails] = useState<any>();
  const [relatedPosts, setRelatedPosts] = useState<any>();
  const location = useLocation();
  const blogTitle: string = location?.pathname?.split("/")[2];
  const blogDetail: any = BlogDetailData[blogTitle];
  const {
    title = "",
    topic = "",
    paraone = "",
    date = "",
    location: blogLocation = "",
    imgSrc = "",
    content = "",
    meta = {},
  } = blogDetail || {};

  const related = cardData?.filter(
    (item) =>
      item?.topic === blogDetail?.topic && item?.title !== blogDetail?.title
  );
  useEffect(() => {
    setBlogDetails(blogDetail);
    setRelatedPosts(related);
  }, [blogTitle]);

  return (
    <>
      <Helmet>
        <title>{meta?.title}</title>
        <meta name="description" content={meta?.description} />
        <meta name="author" content={meta?.author} />
        <meta property="og:locale" content={meta?.ogLocale} />
        <meta property="og:type" content={meta?.ogType} />
        <meta property="og:title" content={meta?.ogTitle} />
        <meta property="og:description" content={meta?.ogDescription} />
        <meta property="og:url" content={meta?.imgSrc} />
        <meta property="og:image" content={meta?.ogImage} />
        <meta property="og:site_name" content={meta?.ogSiteName} />
        <link rel="canonical" href={meta?.canonical} />
        <meta name="robots" content={meta?.robot} />
        <meta name="viewport" content={meta?.viewport} />
      </Helmet>
      <section className="blog-main mt-4">
        <div className="container-fluid">
          <div className="search-blog mx-auto col-md-8 col-sm-10">
            {/* <div className="input-group mb-3">
              <a href="#" className="input-group-text" id="basic-addon1">
                <i className="fas fa-map-marker-alt"></i>
              </a>
              <input
                type="text"
                className="form-control"
                placeholder="Search topic here"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onClick={() => setSearchPopup((value) => true)}
                readOnly
              />
              <a
                href="#"
                className="input-group-text bg-transparent"
                id="basic-addon2"
              >
                <i className="fas fa-search"></i>
              </a>
            </div> */}

            <div className="locate-me-model-wrap">
              <div className="location-wrap">
                {/* <SelectCityModal handleChange={handleCityChange} /> */}
              </div>
            </div>
            {searchPopup ? (
              <div className="search-options">
                <ul>
                  {searchOptions?.map((option) => (
                    <li>
                      <Link to={"/blog"}>{option}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <div className="location-options d-none">
              <ul>
                <li>
                  {" "}
                  <a href="#">Noida</a>
                </li>
                <li>
                  {" "}
                  <a href="#" className="active">
                    Delhi
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="#">Greater Noida</a>
                </li>
                <li>
                  {" "}
                  <a href="#">Bengaluru</a>
                </li>
                <li>
                  {" "}
                  <a href="#">Ghaziabad</a>
                </li>
                <li>
                  {" "}
                  <a href="#">Faridabad</a>
                </li>
                <li>
                  {" "}
                  <a href="#">Gurugram</a>
                </li>
              </ul>
            </div>
          </div>
          {/* <---- search bar ends -----> */}
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/blog">Blogs</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Blogs Details
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="d-flex align-items-center flex-wrap">
          <div className="container-fluid  mb-5">
            <div
              className="single-blog-main"
              style={{ backgroundImage: `url(${imgSrc})` }}
            ></div>
            <div className="single-blog-content-main">
              <div className="d-flex align-items-center justify-content-between">
                <span className="badge bg-freedo-theme fw-normal mb-3">
                  {" "}
                  {topic}{" "}
                </span>
                <div className="blog-time text-dark mb-2">
                  <i className="far fa-calendar"></i> {date} / {blogLocation}
                </div>
              </div>
              <h5 className="mb-2">
                {" "}
                <a
                  dangerouslySetInnerHTML={{ __html: `${title}` }}
                  href="#"
                  className="text-dark d-block"
                ></a>{" "}
              </h5>

              <p
                className="text-secondary fw-light single-blog-detail"
                dangerouslySetInnerHTML={{ __html: paraone }}
              />

              {content && Array.isArray(content) ? (
                content.map((item, index) => {
                  return (
                    <div key={index} className="mt-4">
                      {item.cityName !== "" ? <h3>{item.cityName}</h3> : null}

                      <div className="blog-sub-content">
                        {item.imgName !== "" ? (
                          <img src={item.imgName} />
                        ) : null}
                      </div>
                      <p
                        className="text-secondary fw-light single-blog-detail"
                        dangerouslySetInnerHTML={{ __html: `${item.content}` }}
                      />
                    </div>
                  );
                })
              ) : (
                <p
                  className="text-secondary fw-light single-blog-detail"
                  dangerouslySetInnerHTML={{ __html: `${content}` }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <h4 className="mb-3">Related Posts</h4>
          <div className="row">
            {relatedPosts?.map((posts) => (
              <BlogCard data={posts} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
