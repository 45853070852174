import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button1 } from "../../component/Button";
import OtpInput from "../../component/OTPField";
import { RootState } from "../../redux/store";
import { Link } from "react-router-dom";
import { resendOtpMitc } from "../../redux/actions";

const VerifyOtp = ({
  submitOtp,
  encrypted_booking_id,
  seconds,
  setSeconds,
  setDisableCB,
  disableCB,
  otpFocusRef,
}) => {
  const [otp, setOtp] = useState("");
  const { closeDrawer } = useSelector((state: RootState) => state.authReducer);
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { AuthScreens } = locale;
  const dispatch = useDispatch();

  const onChange = (value: string) => {
    setOtp(value);
  };

  useEffect(() => {
    return () => {
      setOtp("");
    };
  }, []);

  return (
    <div className="signin-wrapper">
      <div
        className="offcanvas offcanvas-end"
        data-bs-scroll="true"
        data-bs-backdrop="false"
        id="mitcOtp"
        aria-labelledby="mitcOtpLabel"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            // ref={buttonRef}
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOtp("4");
              setDisableCB(false);
            }}
          ></button>
        </div>
        <div className="offcanvas-body">
          <h3>{AuthScreens["OTPVerification"]}</h3>
          <p className="mt-3 text-muted text-center">
            {AuthScreens["OTPWarning"]}
          </p>
          <form onSubmit={(e) => submitOtp(otp, e)}>
            {disableCB ? (
              <OtpInput
                ref={otpFocusRef}
                handleChange={(otp: string) => onChange(otp)}
              />
            ) : null}
            <Button1
              type="submit"
              disabled={otp.length < 6}
              className="freedo-primary-button rounded-pill w-100 mt-3"
              title={AuthScreens["VerifyCode"]}
              //   loading={authLoader}
              onSubmit={(e) => submitOtp(otp, e)}
            />
          </form>
          <div className="text-center resend-otp">
            {seconds ? (
              <>
                <span>{`Resend OTP in : ` + seconds}</span>
              </>
            ) : (
              <Link
                to=""
                onClick={(e: any) => {
                  e.preventDefault();
                  setSeconds(30);
                  dispatch(
                    resendOtpMitc({
                      encrypted_booking_id,
                    })
                  );
                }}
              >
                {AuthScreens["resendOtp"]}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VerifyOtp);
