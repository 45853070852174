import axios from "axios";
import {
  API_URLS,
  BASE_URL,
  BASE_URL_CUSTOMER,
  CHECKOUT_API_URLS,
} from "../../config/apiUrls";
import {
  getMasterData,
  getNearestCityParams,
  getVehicleList,
} from "../../config/getApiUrls";
import { getParamsString } from "../../utills/helper";
import { getCouponListUrl } from "../../config/getCheckoutAPIUrls";
import { Axios, customAxios } from "../../config/axiosConfig";
const packageMaster = (params: any) =>
  Axios
    .get(
      BASE_URL_CUSTOMER + getMasterData({ type: params.type, id: params.id })
    )
    .catch((ex: any) => ex);

const vehicleList = (params: any) =>
  Axios.get(BASE_URL_CUSTOMER + getVehicleList(params)).catch((ex: any) => ex);

const getCarouselData = (params: any) =>
  Axios
    .get(BASE_URL + getMasterData({ type: params.type, id: params.id }))
    .catch((ex: any) => ex);

const coupanList = (params: any) =>
  customAxios
    .get(BASE_URL_CUSTOMER + getCouponListUrl(params))
    .catch((ex: any) => ex);

const getFaqList = (params: any) =>
  Axios.get(BASE_URL_CUSTOMER + API_URLS.getFaqList).catch((ex: any) => ex);

const findNearestCity = (params: any) =>
  Axios
    .get(BASE_URL_CUSTOMER + getNearestCityParams(params))
    .catch((ex: any) => ex);

const setZohoLead = (payload: any) =>
  Axios
    .post(BASE_URL_CUSTOMER + API_URLS?.notifyMe, payload)
    .catch((ex: any) => ex);

export default {
  packageMaster,
  vehicleList,
  getCarouselData,
  coupanList,
  getFaqList,
  findNearestCity,
  setZohoLead,
};
