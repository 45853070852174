import { useEffect, useRef, useState } from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { cloeseOtpScreen } from "../../redux/actions";

const SignInPopUp = () => {
  const [isSignIn, setIsSignIn] = useState<boolean>(false);
  const buttonRef = useRef<any>(null);
  const { closeDrawer, isAuthenticated } = useSelector(
    (state: RootState) => state.authReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    closeDrawer && buttonRef?.current?.click();
    return () => {
      closeOtpSection();
    };
  }, [closeDrawer]);

  useEffect(() => {
    if (isAuthenticated) {
      handleClose();
    }
  }, [isAuthenticated]);

  const closeOtpSection = () => {
    setIsSignIn(false);
    dispatch(cloeseOtpScreen());
  };

  let myOffcanvas: any = document.getElementById("offcanvasRight");
  myOffcanvas &&
    myOffcanvas.addEventListener("hidden.bs.offcanvas", function () {
      if (isSignIn) setIsSignIn(false);
    });

  const handleClose = () => {
    closeOtpSection();
  };

  return (
    <div className="signin-wrapper">
      <div
        className={`offcanvassigninWrapper offcanvas offcanvas-end `}
        id="offcanvasSignIn"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            id="closeX_signInPopup"
            className="btn-close text-reset"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasSignIn"
          ></button>
        </div>
        {isSignIn ? (
          <SignUp setIsSignIn={setIsSignIn} />
        ) : (
          <SignIn setIsSignIn={setIsSignIn} />
        )}
      </div>
    </div>
  );
};

export default SignInPopUp;
