import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import Resizer from "react-image-file-resizer";
import {
  BankIcon,
  CameraIcon,
  DownloadIcon,
  ElectricityIcon,
  Other,
  RentalIcon,
  uploadSelfie,
  VoterIdIcon,
} from "../../../assets";
import TakeSelfieModel from "../../../component/TakeSelfieModel";
import {
  createAdditionaDocs,
  getAdditionaDocsStatus,
  getUserKycStatus,
  uploadImagesToS3,
} from "../../../redux/actions";
import { RootState } from "../../../redux/reducers";
import {
  compressSizer,
  getLocalStorageData,
  handleMessage,
  imgFileReader,
} from "../../../utills/helper";
import kycServices from "../../../redux/services/kycServices";
import SuccessScreen from "./SuccessScreen";

const Documents = [
  {
    name: "VoterId",
    label: "Voter Id",
    image: VoterIdIcon,
    id: 1,
    apiKey: "isVoterIdUploaded",
  },
  {
    name: "BankId",
    label: "Bank Detail",
    image: BankIcon,
    id: 2,
    apiKey: "isBankStatementUploaded",
  },
  {
    name: "ElectricityId",
    label: "Electricity Bill",
    image: ElectricityIcon,
    id: 3,
    apiKey: "isElectricityBillUploaded",
  },
  {
    name: "RentalId",
    label: "Rent Bill",
    image: RentalIcon,
    id: 4,
    apiKey: "isRentalAgreementUploaded",
  },
  {
    name: "OtherId",
    label: "Other",
    image: Other,
    id: 5,
    apiKey: "isOthersUploaded",
  },
];
const OtherDocuments = ({ userKycStatusCheck }) => {
  const dispatch = useDispatch();
  const webcamRef: any = React.useRef(null);
  const additionalVerified = userKycStatusCheck?.additionalVerified;
  const Token = getLocalStorageData("token");
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { otherDocsStatus } = useSelector(
    (state: RootState) => state.profileReducer
  );
  const { kyc } = locale["profle"];
  const [fileUri, setFileUri] = useState(null);
  const [srcUri, setSrcUri] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState("");
  const [sThreeApiUrl, setSThreeApiUrl] = useState<any>(null);
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingDocs, setIsLoadingDocs] = useState(false);
  const [selfieModal, setselfieModal] = useState(false);

  useEffect(() => {
    dispatch(getAdditionaDocsStatus());
  }, []);

  useEffect(() => {
    if (fileUri && currentDocument) {
      let data = {
        docType: currentDocument,
        extension: "jpg",
      };
      requestSignedUrl(data);
    }
  }, [fileUri]);

  useEffect(() => {
    if (sThreeApiUrl) {
      uploadImageSthree();
    }
  }, [sThreeApiUrl]);

  const requestSignedUrl = async (data) => {
    setIsLoadingImage(true);
    kycServices
      .getDLDocsignedurl({ docType: data.docType, extension: data.extension })
      .then((response: any) => {
        if (response && response.status === 200) {
          const s3url = response.data?.result?.data;
          setSThreeApiUrl(s3url);
        } else setIsLoadingImage(false);
      })
      .catch(() => setIsLoadingImage(false));
  };

  const handleS3ImageResponse = () => {
    let additional_docs: any = [...additionalDocs];
    let data = {
      docType: currentDocument,
      key: sThreeApiUrl.key,
    };
    const newArray = additional_docs.filter(
      (obj: any) => obj.docType != data.docType
    );
    additional_docs = newArray;
    additional_docs.push(data);
    setAdditionalDocs(additional_docs);
  };

  const uploadImageSthree = async () => {
    dispatch(
      uploadImagesToS3({
        url: sThreeApiUrl.signedUrl,
        params: fileUri,
        callBack: handleS3ImageResponse,
        setIsLoadingImage,
      })
    );
  };

  const handleUploadClick = (event, docType) => {
    var file = event?.target?.files[0];
    var fileType = file["type"];
    const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      toastr.error("", "Invalid file type");
      return;
    }
    setCurrentDocument(docType);
    imgFileReader(file, setSrcUri);
    setFileUri(file);
  };
  const captureSelfie = React.useCallback(async () => {
    const imageSrc: any =
      webcamRef?.current && webcamRef?.current?.getScreenshot();
    await fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file: any = new File([blob], "File name", { type: "image/png" });
        Resizer.imageFileResizer(
          file,
          1920,
          1080,
          "JPEG",
          compressSizer(file.size),
          0,
          (uri: any) => {
            imgFileReader(uri, setSrcUri);
            setFileUri(uri);
            setselfieModal(false);
          },
          "file",
          200,
          200
        );
      });
  }, [webcamRef]);

  /* const toggleSelfieModel = () => {
    const ele = document.getElementById("selfieButton");
    ele && ele.click();
  } */ const handleSelfieModal = () => {
    setselfieModal(!selfieModal);
  };

  const handleCameraClick = (docType) => {
    setCurrentDocument(docType);
    handleSelfieModal();
  };

  const handleSubmit = () => {
    dispatch(
      createAdditionaDocs({
        payload: { additional_docs: additionalDocs },
        setIsLoadingDocs,
        callBack: handleSuccuess,
      })
    );
  };

  const handleSuccuess = () => {
    dispatch(getUserKycStatus());
    dispatch(getAdditionaDocsStatus());
    window.scrollTo(0, 0);
  };

  const CardSection = ({ item, ind, imageUploaded, imageSuccess }) => (
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-8">
        <div
          className="d-flex uselfie_card mb-1 justify-content-center m-0"
          key={ind}
        >
          {isLoadingImage && currentDocument === item.name ? (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              <div className="card border-0 bg-none">
                <img
                  src={item?.image}
                  className="card-img-top img-fluid other-img"
                  alt="File Type"
                />
                <div className="card-body text-center p-0">
                  <h5 className="card-title">{item.label}</h5>
                  <div className="d-flex justify-content-center">
                    {imageSuccess
                      ? kyc["successMsg"]
                      : imageUploaded
                      ? kyc["uploadMsg"]
                      : null}
                  </div>

                  <div className="d-flex justify-content-center mb-2 other-doc">
                    <div
                      onClick={() => handleCameraClick(item.name)}
                      data-bs-toggle="modal"
                      data-bs-target="#selfieModal"
                    >
                      <i
                        className="fa-solid fa-camera fa-fade"
                        role="button"
                      ></i>
                    </div>
                    <p className="mt-2">OR</p>
                    <div className="fileIcon">
                      <label htmlFor={`image_upload${item.id}`}>
                        <i
                          className="fa-solid fa-arrow-up-from-bracket fa-fade fa-lg"
                          role="button"
                        ></i>
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleUploadClick(e, item.name);
                        }}
                        name={`image_upload${item.id}`}
                        id={`image_upload${item.id}`}
                      />
                    </div>
                    <div>
                      <button
                        id="selfieButton"
                        type="button"
                        className="btn btn-freedo-primary"
                        //data-bs-toggle="modal"
                        //data-bs-target="#selfieModal"
                        style={{ display: "none" }}
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <div>
      {additionalVerified ? (
        <SuccessScreen doc="Other Docs" />
      ) : (
        <div>
          {Documents.map((item, ind) => (
            <CardSection
              key={item.id}
              item={item}
              ind={ind}
              imageUploaded={additionalDocs.some(
                (obj: any) => obj.docType == item.name
              )}
              imageSuccess={otherDocsStatus[item.apiKey]}
            />
          ))}
          <div className="KYC-button-wrap">
            <button
              type="submit"
              className="freedo-primary-button-small rounded-pill"
              onClick={() => handleSubmit()}
              disabled={additionalDocs.length === 0}
            >
              {isLoadingDocs ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
          <TakeSelfieModel
            heading={"Please place the document before camera"}
            webcamRef={webcamRef}
            captureSelfie={captureSelfie}
            selfieModal={selfieModal}
            handleModal={handleSelfieModal}
          />
        </div>
      )}
    </div>
  );
};

export default OtherDocuments;
