import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RentingFleetCard from "../../component/RentingFleetCard";
import en from "../../locales/en.json";
import { RootState } from "../../redux/reducers";
import { noVehicleFound } from "../../assets";
const rent = en.rent;

const VehicleWrapper = ({
  state,
  setSecondaryFilter,
  secondaryFilterChanged,
}) => {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { vehicleList } = useSelector((state: RootState) => state.homeReducer);
  const { rentingFleet } = locale["home"];
  const [vehicles, setVehicles] = useState<any>([]);
  const vehicleListLength = vehicleList?.vehicleModelList?.length;
  const showRecords =
    vehicleListLength > state.records ? state.records : vehicleListLength;

  useEffect(() => {
    setVehicles(vehicleList?.vehicleModelList || []);
  }, [vehicleList]);
  return (
    <>
      <div className="display-result-filter">
        <p>
          Displaying {showRecords || 0} {showRecords > 1 ? "Results" : "Result"}
        </p>
        <select
          value={state.sortBy}
          onChange={(e) => {
            setSecondaryFilter((pre) => {
              return {
                ...pre,
                sortBy: e.target.value,
              };
            });
            secondaryFilterChanged("sortBy", e.target.value);
          }}
          className="form-select form-select-sm"
          aria-label="vehicleSort"
        >
          <option value={""}>Sort By </option>
          <option value="ASC">Price - Low to High</option>
          <option value="DESC">Price - High to Low</option>
        </select>
      </div>
      <div className="row rent-fleet">
        {vehicles?.length > 0 ? (
          <div className="vehicle-wrapper">
            {vehicles?.length > 0 &&
              vehicles?.slice(0, state.records).map((item, ind) => (
                <RentingFleetCard
                  key={item.modelId}
                  ind={ind}
                  data={item} // Data which shown on vehicl Card
                  rentingFleet={rentingFleet}
                  filters={state} //  filters selected, package selected etc..
                  imagePath={
                    vehicleList?.vehicleModelImagePath?.vehicleImagePath
                  }
                  isHomePage={false}
                />
              ))}
          </div>
        ) : (
          <div className="mt-5">
            <img src={noVehicleFound} className="no-vehicle-found"  alt="no-vehicle found" />
            {/* <video width="100%" className="" autoPlay muted loop>
              <source src={noVehicleFound} type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
            </video> */}
            <div className="text-center">
              <h5 className="mb-1">Oops! No vehicles found</h5>
              <p>Please modify your search and try again</p>
            </div>
          </div>
        )}
        {vehicleListLength > 0 && vehicleListLength > state.records && (
          <button
            className="freedo-outline-btn rounded-pill rent-card-btn"
            onClick={() => {
              setSecondaryFilter((pre) => {
                return { ...pre, records: pre.records + 6 };
              });
            }}
            disabled={vehicleList?.totalItems <= state.records}
          >
            {rent.ShowMore}
          </button>
        )}
      </div>
    </>
  );
};

export default VehicleWrapper;
