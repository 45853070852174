import React, { useState, useEffect } from "react";
import UploadDL from "./uploadDL";
import { useDispatch, useSelector } from "react-redux";
import AdharVerification from "./adharVerification";
import { getUserKycStatus } from "../../../redux/actions/profileActions";
import Selfie from "./Selfie";
import OtherDocuments from "./OtherDocuments";
import { HelpModal } from "../../../component/HelpModal";
const Index = (props: any) => {
 const [docType, setDocType] = useState<any>("0");
 const dispatch = useDispatch();
 const userKycStatusCheck = useSelector(
  (state: any) => state?.profileReducer?.userKycStatus
 );
 useEffect(() => {
  dispatch(getUserKycStatus());
 }, []);

 const handleComponent = () => {
  switch (docType) {
   case "0":
    return <UploadDL userKycStatusCheck={userKycStatusCheck} />;
   case "1":
    return <AdharVerification userKycStatusCheck={userKycStatusCheck} />;
   case "2":
    return <Selfie userKycStatusCheck={userKycStatusCheck} />;
   case "3":
    return <OtherDocuments userKycStatusCheck={userKycStatusCheck} />;
  }
 };
 return (
  <div
   className={`tab-pane fade show active'}`}
   id="v-pills-profile"
   role="tabpanel"
   aria-labelledby="v-pills-profile-tab"
   tabIndex={0}
  >
   <div>
    <form className="row">
     <div className="col-12">
      <div className="d-flex justify-content-between align-items-center">
       <h6 className="kychead">Update KYC</h6>
       <HelpModal/>
      </div>
      <div className="kyc-set-wrap">
       <div className="form-check">
        <input
         className="form-check-input styled"
         type="radio"
         id="flexRadioDefault11"
         name="drone"
         value="0"
         checked={docType === "0"}
         onChange={() => setDocType("0")}
        />
        <label className="form-check-label styled" htmlFor="flexRadioDefault11">
         Driving Licence (DL)
        </label>
       </div>
       <div className="form-check styled">
        <input
         className="form-check-input styled"
         type="radio"
         id="flexRadioDefault22"
         name="drone"
         value="1"
         checked={docType === "1"}
         onChange={() => setDocType("1")}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault22">
         Aadhaar
        </label>
       </div>

       <div className="form-check styled">
        <input
         className="form-check-input styled"
         type="radio"
         id="flexRadioDefault33"
         name="drone"
         value="2"
         checked={docType === "2"}
         onChange={() => setDocType("2")}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault33">
         Selfie
        </label>
       </div>

       <div className="form-check styled">
        <input
         className="form-check-input styled"
         type="radio"
         id="flexRadioDefault44"
         name="drone"
         value="3"
         checked={docType === "3"}
         onChange={() => setDocType("3")}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault44">
         Others
        </label>
       </div>
      </div>
     </div>
    </form>
    {handleComponent()}
   </div>
  </div>
 );
};
export default Index;
