import { coupanIcon } from "../assets";
import { useState } from "react";
import {
  decryptData,
  getLocalStorageData,
  hindiTextValidation,
} from "../utills/helper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCoupanData } from "../redux/actions/masterActions";
import { RootState } from "../redux/reducers";
import { toastr } from "react-redux-toastr";
import { langErrorMsg } from "../screen/city-freedo/constants/constants";

const SelectCoupan = ({ handleCouponApplied, model_id, cityId }) => {
  const [coupanCode, setCoupanCode] = useState("");
  const [readMore, setReadMore] = useState<boolean>(false);
  const [langError, setLangError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const coupanDetails = useSelector(
    (state: RootState) => state.homeReducer?.coupanDetails
  );

  useEffect(() => {
    dispatch(getCoupanData({ model_id, city_id: cityId }));
  }, []);
  const applyCoupanCode = () => {
    if (coupanCode) {
      const index = coupanArray(coupanDetails)?.findIndex(
        (data) =>
          data.code.toLocaleLowerCase() == coupanCode.toLocaleLowerCase()
      );
      let selectedCoupon =
        index > -1 ? coupanArray(coupanDetails)[index] : null;
      handleCouponApplied(selectedCoupon);
    } else toastr.error("Oops", "Please enter coupon code.");
  };
  const setCouponApply = (copn: any) => {
    setCoupanCode(copn.code);
    handleCouponApplied(copn);
  };
  const coupanArray = (coupanDetails: any) => {
    try {
      let d1 = decryptData(coupanDetails?.parta);
      let d2 = decryptData(coupanDetails?.partb);
      console.log("0000", d1, d2);
      return d1.concat(d2);
    } catch (err) {}
  };
  const handleInputChange = (e) => {
    if(langError)setLangError(false);
    const hindiValidation = hindiTextValidation(e.target.value);
    hindiValidation ? setCoupanCode(e.target.value) : setLangError(true);
  };

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex={-1}
      id="coupon-selected"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="fas fa-times"></i>
        </button>
        <h5 className="offcanvas-title" id="offcanvasRightLabel">
          {" "}
          Apply Coupon Code{" "}
        </h5>
      </div>
      <div className="offcanvas-body">
        <div className="searchbox-wrap">
          <input
            name="Coupon"
            type="text"
            value={coupanCode}
            placeholder="Enter Coupon Code"
            onChange={handleInputChange}
          />
          <button
            onClick={applyCoupanCode}
            data-bs-toggle="offcanvas"
            data-bs-target="#coupon-selected"
            aria-controls="coupon-selected"
          >
            <span>APPLY</span>{" "}
          </button>
        </div>
        {langError && (
          <span className="error-field">{langErrorMsg}</span>
        )}

        {/*
          Coupon status---->
            "Active"   : 1,
            "Inactive" : 2,
            "Deleted"  : 3,
            "Hidden"   : 4 */}

        {Object.keys(coupanDetails).length
          ? coupanArray(coupanDetails)
              .filter((x) => x.status == 1)
              .map((_details: any, index: number) => {
                return (
                  <div key={index}>
                    <div
                      className="coupon-box-wrap"
                      /* onClick={() => setCouponApply(_details)} */
                      key={_details.code}
                    >
                      <div className="left">
                        <div className="coupon-icon-sec">
                          <img src={coupanIcon} alt="" />
                        </div>
                        <div className="coupon-content">
                          <div className="coupn-name">{_details.code}</div>
                          <div className="coupn-title">{_details.name}</div>
                          {_details.description ? (
                            <p>
                              {_details.terms_and_conditions} <br />
                              <a
                                href="#collapseExample"
                                data-bs-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                onClick={() => setReadMore(!readMore)}
                                className="mt-2"
                              >
                                {readMore ? "Read Less" : "Read More"}
                              </a>
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="apply-sec">
                        <button
                          className="btn freedo-btn-link"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#coupon-selected"
                          aria-controls="coupon-selected"
                          onClick={() => setCouponApply(_details)}
                        >
                          Apply
                        </button>
                        <div className="save-code">
                          {_details.unit == 1
                            ? "Save ₹" + Math.round(_details.discount)
                            : "Save " + _details.discount + "%"}
                        </div>
                      </div>
                    </div>
                    <div className="read-more-cpn">
                      <div className="collapse" id="collapseExample">
                        <div className="card card-body">
                          {_details?.description}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
          : null}
        {/* <div className="read-more-cpn">
          <div className="collapse" id="collapseExample">
            <div className="card card-body">
              Some placeholder content for the collapse component.
            </div>
          </div>
        </div> */}
        {/* <button
          onClick={applyCoupanCode}
          className="freedo-primary-button rounded-pill applycoupon-btn"
          data-bs-toggle="offcanvas"
          data-bs-target="#coupon-selected"
          aria-controls="coupon-selected"
        >
          Apply Coupon
        </button> */}
      </div>
    </div>
  );
};
export default SelectCoupan;
