import React, { useState, useEffect } from "react";
import kycServices from "../../../redux/services/kycServices";
import { getLocalStorageData, handleMessage } from "../../../utills/helper";
import { toastr } from "react-redux-toastr";
import { cameraIcon } from "../../../assets";
import { Tooltip } from "react-tooltip";
import { actions } from "../../../redux";
import { RootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { FirebaseEvents } from "../../../utills/firebaseEvents";
import { defaultHeaders } from "../../../config/axiosConfig";

const UploadDL = ({ userKycStatusCheck }) => {
  const [dlFrontImageUrl, setDlFrontImageUrl] = useState<any>(null);
  const [dlBackImageUrl, setDlBackImageUrl] = useState<any>(null);
  const [frontImageData, setFrontImageData] = useState<any>(null);
  const [backImageData, setBackImageData] = useState<any>(null);
  const [frontImage, setFrontImage] = useState<any>(null);
  const [backImage, setBackImage] = useState<any>(null);
  const [saveImageResponse, setSaveImageResponse] = useState<any>(null);
  const [isManualClicked, setIsManualClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { userProfileDetail } = useSelector(
    (state: RootState) => state.profileReducer
  );
  /* useEffect(() => {
  kycServices
   .getDLDocsignedurl({ docType: "DlFront", extension: "jpg" })
   .then((response: any) => {
    if (response && response.status === 200) {
     const dataFront = response.data?.result?.data;
     setDlFrontImageUrl(dataFront);
    }
   });
  kycServices
   .getDLDocsignedurl({ docType: "DlBack", extension: "jpg" })
   .then((response: any) => {
    if (response && response.status === 200) {
     const dataBack = response.data?.result?.data;
     setDlBackImageUrl(dataBack);
    }
   });
 }, []); */

  const frontSigned = () => {
    kycServices
      .getDLDocsignedurl({ docType: "DlFront", extension: "jpg" })
      .then((response: any) => {
        if (response && response.status === 200) {
          const dataFront = response.data?.result?.data;
          setDlFrontImageUrl(dataFront);
        }
      });
  };

  const backSigned = () => {
    kycServices
      .getDLDocsignedurl({ docType: "DlBack", extension: "jpg" })
      .then((response: any) => {
        if (response && response.status === 200) {
          const dataBack = response.data?.result?.data;
          setDlBackImageUrl(dataBack);
        }
      });
  };

  /* useEffect(() => {
    if (userProfileDetail.kyc_detail) {
      if (userProfileDetail?.kyc_detail?.Result) {
        setFrontImage(userProfileDetail?.kyc_detail?.Result.dlFrontUrl);
        setBackImage(userProfileDetail?.kyc_detail?.Result.dlBackUrl);
      }
    }
  }, [userProfileDetail]); */
  const handleFrontImage = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFrontImage(URL.createObjectURL(file));
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        //console.log(reader.result);
        //setFrontImageData(reader.result);
        setFrontImageData(file);
      });
    }
  };
  const handleBackImage = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setBackImage(URL.createObjectURL(file));
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        //console.log(reader.result);
        //setBackImageData(reader.result);
        setBackImageData(file);
      });
    }
  };
  const onSubmit = async () => {
    let tag = "front";
    await uploadImageSthree(tag);
    tag = "back";
    await uploadImageSthree(tag);
  };
  const uploadImageSthree = async (tag) => {
    let tempUrl, apiUrl;
    if (frontImageData && backImageData) {
      if (tag === "front") {
        tempUrl = frontImageData;
        apiUrl = dlFrontImageUrl;
        fetchData(tempUrl, apiUrl, tag);
      } else {
        console.log("KycDLInitiation",{})
        FirebaseEvents("KycDLInitiation",{})
        tempUrl = backImageData;
        apiUrl = dlBackImageUrl;
        fetchData(tempUrl, apiUrl, tag);
      }
    } else {
    }
  };

  const fetchData = async (tempUrl, apiUrl, tag) => {
    try {
      fetch(apiUrl.signedUrl, {
        method: "put",
        headers: new Headers({
          ...defaultHeaders,
          "Content-Type": "image/jpeg",
        }),
        body: tempUrl,
      })
        .then((res) => {
          if (res.status === 200) {
            //console.log("Successfully uploaded ", tag, " image");
            // let data;
            if (tag === "front") {
            } else {
              saveImageData();
            }
          }
          else if (res.status === 403) {
            toastr.error("", "Server timed out, Please Upload Your Driver's License again.");
          }

          else{
            toastr.error("",handleMessage(res))
          }
        })
        .catch(function (error) {
          toastr.error("", handleMessage(error));
        });
    } catch (error) {}
  };
  const saveImageData = async () => {
    setIsLoading(true);
    const Token = getLocalStorageData("token");
    let payload = {
      docType: "Dl",
      image1: dlFrontImageUrl?.key,
      image2: dlBackImageUrl?.key,
      token: Token.token,
    };
    //console.log("saveimageRequest",payload)
    kycServices
      .saveDLimageDataKyc(payload)
      .then((response: any) => {
        setIsLoading(false);
        if (response && response.status === 200) {
         
          const result = response.data?.result;
          if (
            result &&
            (result.kycStatus === "INVALID_DL" ||
              result?.kycStatus === "DL_ALREADY_EXISTS")
          ) {
            setSaveImageResponse(result);
            setIsManualClicked(false);
            toggleManualeModel();
            FirebaseEvents("KycDLUploadFailure",{})
          }
          else {
            toastr.success("", result.message);
            FirebaseEvents("KycDLUploadSuccess",{})
            dispatch(actions.getUserKycStatus());
          }
        }
        else if(response?.response?.status === 400){
          toastr.error("", "Please Upload Valid Driving License.");
          FirebaseEvents("KycDLUploadFailure",{})
        }
        else{
          toastr.error("",handleMessage(response));
        }
      })
      .catch(function (error) {
        FirebaseEvents("KycDLUploadFailure",{})
        toastr.error("", handleMessage(error));
        setIsLoading(false);
      });
  };
  const btnManualClicked = () => {
    setIsManualClicked(true);
    toggleManualeModel();
  };
  const toggleManualeModel = () => {
    setTimeout(() => {
      const ele = document.getElementById("ManualKYCButton");
      ele && ele.click();
    }, 200);
  };
  const OpenImageinNewWindow = (img: any) => {
    if (img) {
      window.open(img);
    }
  };
  const ManualKycModal = () => {
    return (
      <div
        className="modal fade"
        id="manualKYCModal"
        tab-index="-1"
        aria-labelledby="manualKYCModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="manualKYCModalLabel">
                Manual KYC
              </h4>
              <button
                type="button"
                className="close-model"
                data-bs-dismiss="modal"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>

            <div className="modal-body">
              {isManualClicked ? (
                <>
                  <h6 className="text-center p-3">
                    Please Call Freedo Executive
                  </h6>
                  <h4 className="text-center p-3">+91 95998 19940</h4>
                </>
              ) : (
                <>
                  <p>{saveImageResponse?.message}</p>
                  <p>Sorry for the inconvenience.</p>
                  <p className="float-end">--Team Freedo</p>
                </>
              )}
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="freedo-primary-button rounded-pill"
                >
                  {isManualClicked ? "OK" : "Try Again"}
                </button>
                {!isManualClicked && (
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="freedo-outline-btn rounded-pill ms-2"
                    onClick={() => btnManualClicked()}
                  >
                    Manual
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const isVerified = userKycStatusCheck.dlVerified;
  return (
    <>
      <div className="area" id="Driving-Licence" style={{ display: "block" }}>
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-8">
            <div className="downloadfile-wrap align-middle">
              {isLoading ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <>
                  {isVerified ? (
                    <>
                      <div className="success-text">
                        <p className="success-text-dl">DL Successfully Verified!</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <h6 className="mb-2">Upload Your Driver's License</h6>
                      <div className="camera-wrap d-flex justify-content-center flex-wrap">
                        <div className="front-cam">
                          <div className="dlImageContainer">
                            <img
                              src={frontImage ? frontImage : cameraIcon}
                              alt=""
                              onClick={() => OpenImageinNewWindow(frontImage)}
                            />
                          </div>
                          <div className="mx-auto pt-2 fileIcon">
                            <label htmlFor="frontDrLicUpload">
                              <i
                                className="fa-solid fa-arrow-up-from-bracket fa-fade fa-lg"
                                role="button"
                                onClick={frontSigned}
                              ></i>
                            </label>
                            <input
                              type="file"
                              name="frontDrLicUpload"
                              id="frontDrLicUpload"
                              onChange={handleFrontImage}
                              accept="image/*"
                            />
                          </div>
                        </div>
                        <div className="back-cam">
                          <div className="dlImageContainer">
                            <img
                              src={backImage ? backImage : cameraIcon}
                              alt=""
                              onClick={() => OpenImageinNewWindow(backImage)}
                            />
                          </div>
                          <div className="mx-auto pt-2 fileIcon">
                            <label htmlFor="backDrLicUpload">
                              <i
                                className="fa-solid fa-arrow-up-from-bracket fa-fade fa-lg"
                                role="button"
                                onClick={backSigned}
                              ></i>
                            </label>
                            <input
                              type="file"
                              name="backDrLicUpload"
                              id="backDrLicUpload"
                              onChange={handleBackImage}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>

                      <>
                        <p>Upload Front and Back images of Driving Licence</p>
                        <p className="support-file">
                          Supported Formats: Jpeg, Jpg and Png
                        </p>
                      </>
                    </>
                  )}
                  {/* <div className="camera-wrap d-flex justify-content-center flex-wrap">
          <div className="front-cam">
           <div className="dlImageContainer">
            <img
             src={frontImage ? frontImage : cameraIcon}
             alt=""
             onClick={() => OpenImageinNewWindow(frontImage)}
            />
           </div>
           {!isVerified && (
            <div className="mx-auto pt-2 fileIcon">
             <label htmlFor="frontDrLicUpload">
              <i
               className="fa-solid fa-arrow-up-from-bracket fa-fade fa-lg"
               role="button"
              ></i>
             </label>
             <input
              type="file"
              name="frontDrLicUpload"
              id="frontDrLicUpload"
              onChange={handleFrontImage}
              accept="image/*"
             />
            </div>
           )}
          </div>
          <div className="back-cam">
           <div className="dlImageContainer">
            <img
             src={backImage ? backImage : cameraIcon}
             alt=""
             onClick={() => OpenImageinNewWindow(backImage)}
            />
           </div>
           {!isVerified && (
            <div className="mx-auto pt-2 fileIcon">
             <label htmlFor="backDrLicUpload">
              <i
               className="fa-solid fa-arrow-up-from-bracket fa-fade fa-lg"
               role="button"
              ></i>
             </label>
             <input
              type="file"
              name="backDrLicUpload"
              id="backDrLicUpload"
              onChange={handleBackImage}
              accept="image/*"
             />
            </div>
           )}
          </div>
         </div>
         {!isVerified && (
          <>
           <p>Upload Front and Back images of Driving Licence</p>
           <p className="support-file">Supported Formats: Jpeg, Jpg and Png</p>
          </>
         )} */}
                </>
              )}
            </div>

            {/* <div className="down-processwrap">
              <div className="uoloaded">
                <h6 className="mb-2">Uploading: 1 of 1 Files</h6>
                <div className="uploaded-fileprocess rounded">Back-dl.jpg <a href="#">
                  <i className="fas fa-times" data-tooltip-id="cancel-upload" data-tooltip-content="Cancel Upload"></i>
                  <Tooltip id="cancel-upload" />
                </a>
                </div>
                <div className="progress" role="progressbar" aria-label="Example with label" aria-valueNow="25" aria-valueMin="0" aria-valueMax="100">
                  <div className="progress-bar"></div>
                </div>
              </div>
            </div> */}

            {/* <div className="after-upload-wrap">
              <h6 className="mb-2"> Uploaded Files </h6>
              <div className="afterload-file">Front-dl.jpg
                <a href="#">
                  <i className="fas fa-trash-alt" data-tooltip-id="delete-file" data-tooltip-content="Delete File"></i>
                  <Tooltip id="delete-file" />
                </a>
              </div>
            </div> */}
            {!isVerified && (
              <div className="update-profile-wrap text-center mt-4">
                <button
                  type="submit"
                  className="freedo-primary-button-small float-center rounded-pill"
                  onClick={() => onSubmit()}
                  disabled={(!frontImage && !backImage) || isLoading}
                >
                  Submit
                </button>
                <button
                  id="ManualKYCButton"
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#manualKYCModal"
                  style={{ display: "none" }}
                ></button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ManualKycModal />
    </>
  );
};
export default UploadDL;
