import React from "react";
import InputField from "./InputField";
interface FormProps {
  type: string;
  required?: boolean;
  placeholder: string;
  img?: string;
  label: string;
  id: string;
  value: any;
  onChange: Function;
  name: string;
  maxLength: number;
  errorMsg: string;
  restrictFuntion: any;
  inputMode?: string;
  pattern?: string;
  style?: object;
}
const Form = ({
  type,
  required,
  placeholder,
  img,
  label,
  id,
  value,
  onChange,
  name,
  maxLength,
  errorMsg,
  restrictFuntion,
  ...rest
}: FormProps) => {
  return (
    <div className="">
      <label className="form-label pt-1">
        {label}
        {required && <span className="required-mark">*</span>}
      </label>
      <div className="input-group" style={{ marginTop: -8 }}>
        {img ? (
          <span className="input-group-text" id={id}>
            <img src={img} alt={img} />
          </span>
        ) : null}
        <InputField
          className="form-control"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          maxLength={maxLength}
          restrictFuntion={restrictFuntion}
          {...rest}
        />
      </div>
      {!!errorMsg && <span className="error-field">{errorMsg}</span>}
    </div>
  );
};
export default Form;
