import React from 'react';
import GetInTouch from './GetInTouch';
import OfficeAdreess from './OfficeAdreess';
import TopBanner from './TopBanner';

const index = () => {
    return (
        <>
            <TopBanner />
            <section className="contact-content">
                <div className="container-fluid">
                    <GetInTouch />
                    <OfficeAdreess />
                </div>
            </section>
        </>
    );
};

export default index;
