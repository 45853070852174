import { paymentSuccess } from "../assets";
import { thankyouPage } from "../constant/enum";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const details = [
  {
    purpose: thankyouPage?.MITC_Verified,
    mainMessage: "Your MITC details is successfully verified !",
    subMessage: "Thank you for choosing to ride with Freedo.",
    redirectTo: "/",
  },
];

const ThankyouPage = () => {
  const [data, setData] = useState<any>({});
  const navigate = useNavigate();

  const location = useLocation();
  const purpose = location?.pathname?.split("/")[2];
  console.log("99", purpose);
  useEffect(() => {
    if (purpose) {
      let obj: any = details?.find((item) => {
        return item?.purpose === purpose;
      });
      console.log("99", obj);
      setData(obj);
      setTimeout(() => {
        navigate(obj?.redirectTo);
      }, 6000);
    }
  }, [purpose]);

  return (
    <>
      <div className="container payment-wrap">
        <div className="d-flex justify-content-center mt-2">
          <div className="card w-100 mb-2 border-0">
            <div className="card-body">
              <video width="100%" autoPlay muted loop>
                <source src={paymentSuccess} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
              </video>
              <div className="text-center payment-text">
                <h5>{data?.mainMessage}</h5>
                <p className="mt-1 mb-1">
                  <small>{data?.subMessage}</small>
                </p>
              </div>
            </div>

            {/* <div className="d-flex justify-content-center m-3">
              <button
                onClick={handleRedirection}
                className="freedo-primary-button btn-sm rounded-pill"
              >
                View Booking Details
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankyouPage;
