import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import "../setting.css";
import UserProfileSelfie from "./UserProfileSelfie";
import { toastr } from "react-redux-toastr";
import Resizer from "react-image-file-resizer";
import {
  eyeFeatherProfile,
  cameraFeatherProfile,
  uploadFeatherProfile,
} from "../../../assets";
import { compressSizer, imgFileReader } from "../../../utills/helper";
import en from "../../../locales/en.json";
const EditProfilePicture = (props: any) => {
  const webcamRef: any = React.useRef(null);
  const [selfieModal, setselfieModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(props.show);

  useEffect(() => {
    setShowDrawer(props.show)
  }, [props.show]);

  const handleOptionSelected = (type: string) => {
    if (type === "upload") {
      const ele = document.getElementById("image_upload_profile");
      ele && ele.click();
    } else if (type === "view") {
      props.handleSelectedCallback(null, type);
    } else {
      setselfieModal(true);
    }
  };
  const handleSelfieModal = () => {
    setselfieModal(!selfieModal);
  };
  const handleUploadFile = (event) => {
    let file = event?.target?.files[0];
    if(file){
    let fileType = file["type"];
    const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      toastr.error("", en.errorMessages.InvalidFile);
      return;
    }
    const fileSize = (file.size / 1024 / 1024).toFixed(2)
    
    if(Number(fileSize) > 10){
      toastr.error("", en.errorMessages.InvalidFileSize10MB);
      return;
    }
    props.handleSelectedCallback(file, "");
  }
  };
  const captureSelfie = React.useCallback(async () => {
    const imageSrc: any =
      webcamRef?.current && webcamRef?.current?.getScreenshot();

    await fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file: any = new File([blob], "My Profile Selfie");
        Resizer.imageFileResizer(
          file,
          1920,
          1080,
          "JPEG",
          compressSizer(file.size),
          0,
          (uri: any) => {
            props.handleSelectedCallback(uri, "");
          },
          "file",
          200,
          200
        );
      });

    setselfieModal(false);
  }, [webcamRef]);

  const toggleDrawer = () => {
    // debugger
    // setShowDrawer((pre) => !pre);
    props.handleClose()
  };

  return (
    <>
     

      <Dropdown
        show={showDrawer}
        onToggle={()=>props.handleClose()}
        id="userSettingsDropdown"
        className="upload-list-options"
      >
        <Dropdown.Menu>
          {!props.hideViewOption && <li
            className="p-1 ps-3 mb-1"
            onClick={() => handleOptionSelected("view")}
          >
            <img src={eyeFeatherProfile} alt="" /> View photo
          </li>}
          <li
            className="p-1 ps-3 mt-2 mb-1"
            id="uploadImageFile"
            onClick={() => handleOptionSelected("upload")}
          >
            <img src={uploadFeatherProfile} alt="" />
            Upload New
          </li>
          <li
            className="p-1 ps-3 mt-2 mb-1"
            onClick={() => handleOptionSelected("selfie")}
            id="openProfileSelfieModal"
            data-bs-toggle="modal"
            data-bs-target="#selfieProfileModal"
          >
            <img src={cameraFeatherProfile} alt="" />
            Take Selfie
          </li>
        </Dropdown.Menu>
      </Dropdown>

      <input
        accept="image/*"
        type="file"
        onChange={handleUploadFile}
        onClick={(e:any)=> e.target.value = null}
        style={{ display: "none" }}
        id="image_upload_profile"
      />
      {
        <UserProfileSelfie
          heading={"Please take selfie"}
          webcamRef={webcamRef}
          selfieModal={selfieModal}
          captureSelfie={captureSelfie}
          handleModal={handleSelfieModal}
        />
      }
    </>
  );
};

export default EditProfilePicture;
