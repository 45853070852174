
import Skeleton from 'react-loading-skeleton';
import CardSkelaton from './CardSkelaton';
export default () => {
return  <div className="col-md-6 skelaton-ride">
       <span className='imgsection'> <Skeleton count={1} height={220} width={350}/></span>
         <div className='mt-5'>
       <CardSkelaton />
       </div> 
      </div>
}