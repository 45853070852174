import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Routes } from "../../../constant/routeContants";
import {
  cancleBooking,
  getBookingDetailsById,
  getCancellationCharge,
  setCancleSuccess,
} from "../../../redux/actions/rentActions/bookingAction";
import { handleModalClose } from "../../../utills/helper";

const CancleBookingModal = ({ setModalFlag }) => {
  const [confirmCancellation, setConfirmCancellation] = useState(false);
  const { bookingDetails, cancellationCharges, cancelLoader, cancleSuccess } =
    useSelector((state: any) => state?.bookingReducer);
  const {
    brand_name,
    bike_name,
    start_date,
    end_date,
    amount,
    pickup_location,
    mode_of_pick_up_id,
    vehicleImagePath,
    bike_image,
    id,
    booking_id
  } = bookingDetails;
  const dispatch = useDispatch();

  let Duration = moment
    .duration(moment(end_date).diff(moment(start_date)))
    .asDays();
  Duration = Duration ? Math.floor(Duration) : 0;

  const closeModal = () => {
    setModalFlag((pre) => ({
      ...pre,
      cancleRideModal: false,
    }));
  };

  useEffect(() => {
    dispatch(getCancellationCharge(id));
  }, []);

  const handleCancelRide = () => {
    dispatch(cancleBooking(id));
  };

  useEffect(() => {
    if (cancleSuccess) {
      dispatch(setCancleSuccess(false));
      dispatch(getBookingDetailsById({ booking_id: id }));
      closeModal();
    }
  }, [cancleSuccess]);

  return (
    <Modal
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      className="modal fade vehicle-model"
      id="cancel-modal"
      tabIndex={-1}
      aria-hidden="true"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center">
          {!confirmCancellation && (
            <h6 className="m-auto modal-title" id="bookvehicleLabel">
              Cancel Ride
            </h6>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {confirmCancellation ? (
          <div className="text-center">
            {cancellationCharges?.charge && (
              <h3 className="mb-3">
                A cancellation charge of ₹{Math.round(cancellationCharges?.charge* 100) / 100} will be
                applied
              </h3>
            )}
            <p>Are you sure you want to cancel the ride?</p>
            <button
              className="freedo-primary-button rounded-pill  mt-4"
              onClick={handleCancelRide}
            >
              {cancelLoader ? (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Yes"
              )}
            </button>
            <button
              className="freedo-primary-button rounded-pill mt-4 m-2"
              onClick={() => setConfirmCancellation(false)}
            >
              No
            </button>
          </div>
        ) : (
          <div className="row align-items-center vehicle-specs">
            <div className="col-md-6">
              <img
                alt="..."
                className="w-100 img-responsive"
                src={vehicleImagePath + bike_image}
              />
            </div>
            <div className="col-md-6">
              <div className="bike-sp-wrap">
                <h6 className="bike-brand">{brand_name}</h6>
                <h4>{bike_name}</h4>
                {/* <div className="booking-id-wrap ">
                  <h6 className="">
                    Booking ID :<span>{' '}{booking_id}</span>
                  </h6>
                </div> */}
                <ul className="specific-list">
                <li className="d-flex" style={{fontWeight:"600", }}>
                    <span >
                    Booking ID :
                    </span>
                    <span style={{color:'#2BC7C9'}}>{booking_id}</span>
                  </li>
                  <li className="d-flex">
                    <span>
                      {mode_of_pick_up_id == 2
                        ? "Pick Up Location"
                        : "Home Delivery Location"}
                    </span>
                    <span>{pickup_location}</span>
                  </li>
                  <li className="d-flex">
                    <span>Pick Up Date</span>
                    <span>
                      {moment(start_date).format("DD-MMM-YYYY") || "-NA-"}
                    </span>
                  </li>

                  <li className="d-flex">
                    <span>Planned Drop-off Date</span>
                    <span>
                      {moment(end_date).format("DD-MMM-YYYY") || "-NA-"}
                    </span>
                  </li>

                  <li className="d-flex">
                    <span>Duration</span>
                    <span>
                      {Duration
                        ? `${Duration} ${Duration == 1 ? "Day" : "Days"}`
                        : "-NA-"}
                    </span>
                  </li>

                  <li className="d-flex">
                    <span>Total Amount Paid</span>
                    <span>{`₹${Math.round(amount)}` || "-NA-"}</span>
                  </li>

                  <li className="d-flex">
                    <span>Cancellation Charges</span>
                    <span>{`₹${Math.round(cancellationCharges?.charge* 100) / 100}` || "-NA-"}</span>
                  </li>

                  <li className="d-flex">
                    <span>Refunded Amount</span>
                    <span>
                      {`₹${Math.round(cancellationCharges?.refundAmount* 100) / 100}` || "-NA-"}
                    </span>
                  </li>
                </ul>
              </div>
              <button
                className="freedo-primary-button rounded-pill w-100 mt-4"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setConfirmCancellation(true)}
              >
                Cancel Booking
              </button>
              <div className="text-center booking-status-policy">
                <Link to={Routes.TERMS_CONDITIONS} target='_blank'>
                  Freedo Refund & Cancellation Policy
                </Link>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CancleBookingModal;
