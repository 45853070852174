import React from "react";
import { fb, insta, linkdin, office } from "../../assets";
import DATA from "../../locales/en.json";
import { NavLink } from "react-router-dom";
const OfficeAdreess = () => {
  const { FBLink, InstaLink, LinkdinLink } = DATA.contact;
  return (
    <div className="office-content">
      <div className="row">
        <div className="col-md-7">
          <div className="office-address-wrap">
            <h2 className="mb-4">Office Address</h2>
            <h5>Corporate Address</h5>
            <p>
            <a
              style={{ color: "black"}}
              href="https://www.google.com/maps/place/Freedo+-+Corporate+Office/@28.4925337,77.0099291,13z/data=!4m10!1m2!2m1!1sfreedo+gurgaon!3m6!1s0x390d193e53816ad7:0x523d00dcbc02157e!8m2!3d28.4925337!4d77.0820269!15sCg5mcmVlZG8gZ3VyZ2FvbpIBGG1vdG9yY3ljbGVfcmVudGFsX2FnZW5jeeABAA!16s%2Fg%2F11rcvb506p?entry=ttu"
              target="_blank"
            >
              Freedo · 47, Phase IV, Udyog Vihar, Sector 18, Gurugram, Haryana{" "}
              <br />
              122015, India
            </a>

            </p>

            <h5>Regional Office Address</h5>
            <a
              href="https://www.google.com/maps/place/Freedo-+Bike+%26+Scooty+Rentals+In+Noida/@28.6278031,77.3777979,17z/data=!3m1!4b1!4m6!3m5!1s0x390cef92012090c9:0x9e38bbf8f44aa440!8m2!3d28.6277984!4d77.3803728!16s%2Fg%2F11rn76pmn3?entry=ttu"
              target="_blank"
              style={{ color: "black" }}
            >
              <p>
                <b>Noida</b> : Basement - Lohia Building, H-193, Sector 63,
                Noida, Uttar Pradesh 201301, India
              </p>
            </a>
            <a
              href="https://www.google.com/maps/place/Freedo-+Bike+%26+Scooty+Rentals+In+Bangalore/@12.8836947,77.6428379,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae15e8ef459389:0xdff99ea52154562c!8m2!3d12.8836895!4d77.6454128!16s%2Fg%2F11trc4s5nz?entry=ttu"
              target="_blank"
              style={{ color: "black", marginTop: "0.5%" }}
            >
              <p>
                <b>Bangalore</b> : No-168/13 Singasandra Lake, Building no 3 &
                4, Hosur Road, Behind Dakshin Honda Showroom, Bengaluru, Urban
                Karnataka, 560066
              </p>
            </a>
            <a
              href="https://www.google.com/maps/place/Freedo+-+Bike+and+Scooty+Rentals+in+Gurgaon/@28.4569341,77.0010972,17z/data=!4m10!1m2!2m1!1sfreedo+gurgaon!3m6!1s0x390d17c47562fbe9:0x4e08999dbd5a5176!8m2!3d28.4568451!4d77.0056046!15sCg5mcmVlZG8gZ3VyZ2FvbpIBGG1vdG9yY3ljbGVfcmVudGFsX2FnZW5jeeABAA!16s%2Fg%2F11k9s3znsg?entry=ttu"
              target="_blank"
              style={{ color: "black", marginTop: "0.5%" }}
            >
              <p>
                <b>Gurugram</b> : Near Ara Machine, Khata no 591, Kila no 36/10,
                Krishna Nagar, Krishna Nagar, Gurugram, Haryana 122001
              </p>
            </a>
            <a
              href="https://www.google.com/search?q=freedo+rentals+jaipur&rlz=1C1GCEJ_enIN1028IN1028&tbm=lcl&sxsrf=APwXEdfpNNNtQP1coV1ZlK00nzg4U_e3AA%3A1685082661466&ei=JVJwZI-BHLbn4-EPu9mByAI&ved=0ahUKEwjPp9CLrpL_AhW28zgGHbtsACkQ4dUDCAk&uact=5&oq=freedo+rentals+jaipur&gs_lcp=Cg1nd3Mtd2l6LWxvY2FsEAMyBQgAEIAEOgQIIxAnOgcIABCABBAKOggIABCKBRCGAzoKCAAQgAQQFBCHAjoGCAAQFhAeOggIABAWEB4QClCsAlj4DWD6EGgAcAB4AIAB9gGIAegNkgEFMC42LjOYAQCgAQHAAQE&sclient=gws-wiz-local#rlfi=hd:;si:4750909932559561697,l,ChVmcmVlZG8gcmVudGFscyBqYWlwdXJaHSIVZnJlZWRvIHJlbnRhbHMgamFpcHVyKgQIAxABkgEYbW90b3JjeWNsZV9yZW50YWxfYWdlbmN5qgFSEAEqEiIOZnJlZWRvIHJlbnRhbHMoADIfEAEiG9hC61_xEoRFz8zRvdgJ235CN4S-x8IxZ4HgaTIZEAIiFWZyZWVkbyByZW50YWxzIGphaXB1cg;mv:[[26.9032337730693,75.83851345732077],[26.90188950665844,75.83637841894492]]"
              target="_blank"
              style={{ color: "black", marginTop: "0.5%" }}
            >
              <p>
                <b>Jaipur</b> : Pink Square, Plot No. 1,2, Janta Colony, Raja
                Park, Janta Colony, Govind Marg, Raja Park, Jaipur, Rajasthan,
                302004
              </p>
            </a>
          </div>
          <div className="support-wrap">
            <div className="row">
              <div className="col-md-4">
                <h6>Chat to support</h6>
                <NavLink to="mailto:contact@freedo.rentals">
                  contact@freedo.rentals
                </NavLink>
              </div>

              <div className="col-md-4">
                <h6>Call us</h6>
                <a href="tel:91 95998 19940">+91 95998 19940</a>
              </div>

              <div className="col-md-4">
                <h6> Follow us</h6>
                <div className="social-links">
                  <NavLink to={FBLink} target="_blank">
                    <img src={fb} alt="facebook" />
                  </NavLink>
                  <NavLink to={InstaLink} target="_blank">
                    <img src={insta} alt="instagram" />
                  </NavLink>
                  <NavLink to={LinkdinLink} target="_blank">
                    <img src={linkdin} alt="linkedin" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <img src={office} alt="" />
        </div>
      </div>
    </div>
  );
};

export default OfficeAdreess;
