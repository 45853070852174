import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Footer from "../component/Footer";
import HeaderBar from "../component/HeaderBar";
import TopHeaderBar from "../component/TopHeaderBar";
import { setAuthToken } from "../config/axiosConfig";
import { getData } from "../config/filterStorage";
import { LocalStorage } from "../constant/enum";
import "../css/fonts.css";
import "../css/global.css";
import "../css/profile.css";
import { actions } from "../redux";
import { RootState } from "../redux/store";
import WrongUrl from "../screen/redirectionPages/inavlidUrl";
import { customRoutes, unauthRoutes } from "../utills/routes";
import BikerentalsSEO from "../component/BikeRentalsSEO";
import UnderMaintenance from "../component/UnderMaintenance";
import OutsideAlerter from "../component/outSideAlert";
import { showUnderMaintenance } from "../redux/actions";

const RoutWeb = () => {
  const dispatch = useDispatch();
  const TERMS_CONDITIONS_APP =
    window.location.pathname === "/terms-conditions-app";

  const [showRoutes, setShowRoutes] = useState(false);
  const { isAuthenticated, underMaintenance } = useSelector(
    (state: RootState) => state.authReducer
  );

  useEffect(() => {
    getData(LocalStorage.token).then((data: any) => {
      if (data) {
        let parsedData = JSON.parse(data);
        console.log("RoutWeb>", parsedData);
        setAuthToken(parsedData?.token);
        dispatch(
          actions.setUserToken({
            ...parsedData,
            // ExpireTime: "2022-12-14 12:33:02.000",
          })
        );
        setTimeout(() => {
          dispatch(actions.getUserProfile());
        }, 1);
      }
    });

    setTimeout(() => {
      setShowRoutes(true);
    }, 200);
  }, []);

  if (!showRoutes) {
    return <></>;
  }

  return (
    <>
      {!TERMS_CONDITIONS_APP && (
        <div className="sticky-top">
          <TopHeaderBar />
          <HeaderBar />
        </div>
      )}
      <div style={{ overflowY: "hidden", zIndex: -99 }}>
        {underMaintenance ? (
          <OutsideAlerter
            handleOutsideClick={(e) => dispatch(showUnderMaintenance(false))}
          >
            <UnderMaintenance />
          </OutsideAlerter>
        ) : (
          <Routes>
            {[...unauthRoutes, ...customRoutes].map((route: any, ind) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ))}
            <Route path="*" element={<WrongUrl />} />
          </Routes>
        )}
        <Footer />
        <BikerentalsSEO />
      </div>
    </>
  );
};

export default RoutWeb;
