import { combineReducers } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
import localeReducer from "./localeReducers";
import authReducer from "./authReducer";
import homeReducer from "./homeReducer";
import rentReducres from "./rentReducres";
import checkoutReducer from "./checkoutReducer";
import profileReducer from './profileReducers';
import bookingReducer from './bookingReducer';

const appReducer = combineReducers({
  localeReducer,
  toastr: toastrReducer,
  authReducer,
  homeReducer,
  checkoutReducer,
  profileReducer,
  rentReducres,
  bookingReducer
});

export const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
