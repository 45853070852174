import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const CancellationTab = ({ cancellationData, setModalFlag }) => {
  const {
    amount,
    cancellation_charge,
    cancellation_date,
    cancellation_refunded_amount,
  } = cancellationData;

  return (
    <div
      id="collapseEight"
      className="accordion-collapse collapse"
      aria-labelledby="headingEight"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <div className="Vehicle-Price-Details">
          <div className="table-responsive">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td>Booking Cancellation Date </td>
                  <td>
                    {cancellation_date
                      ? moment(cancellation_date).format("DD-MMM-YYYY")
                      : "-NA-"}
                  </td>
                </tr>
                <tr>
                  <td>Paid Amount </td>
                  <td>₹{Math.round(amount) || 0}</td>
                </tr>
                <tr>
                  <td>Cancellation Charge </td>
                  <td>₹{Math.round(cancellation_charge* 100) / 100 || 0}</td>
                </tr>
                <tr>
                  <td>Refund Amount </td>
                  <td>₹{Math.round(cancellation_refunded_amount* 100) / 100 || 0}</td>
                </tr>
                <tr>
                  <td>Status </td>
                  <td className="booking-status-policy">
                    <Link
                      to={""}
                      onClick={() => {
                        setModalFlag((pre) => ({
                          ...pre,
                          bookingStatus: true,
                          // refundScreen: true,
                        }));
                      }}
                    >
                      Track Status
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancellationTab;
