import React from 'react';
import { vision } from '../../assets';
import { Routes } from '../../constant/routeContants';

const OurVision = () => {
    return (
        <div className="vision-wrap">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-5 col-sm-6 col-12">
                    <img src={vision} alt="Freedo's Vision" />
                </div>
                <div className="col-lg-7 col-md-7 col-sm-6 col-12">
                    <div className="content-wrap" id="our-vision">
                        <h2>Our Vision</h2>
                        <p>
                        To democratize micro mobility as a services by removing all roadblocks between people and mobility.<br/>
                        <span className='font-italic'>"We thrive to make mobility so ingrained in your lifestyle that it becomes a fundamental right"</span>
                        </p>
                        <h6>Choose Freedom to Move, Choose Freedo !</h6>
                        {/* <a href={Routes.RENT} className="freedo-primary-button rounded-pill">
                            {' '}
                            Explore Our Fleet
                            <i className="fa-solid fa-right-long"></i>
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurVision;
