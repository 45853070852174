import { getAnalytics, logEvent } from "firebase/analytics";
import ReactGA from "react-ga";
import { NODE_ENV } from "../config/envConfig";
console.log("process.env.NODE_ENV", NODE_ENV)

export const FirebaseEvents = (tag: any, data: any) => {
  // @ts-ignore  
  if (NODE_ENV == "production") {
    try {
      let analytics = getAnalytics();
      logEvent(analytics, tag, data);
      ReactGA.event(tag, data);
    } catch (error) {
      console.log("this is error", error);
    }
  } else {
    console.log(
      "Firebase Event is not called here as it is",
      NODE_ENV
    );
  }
};
