import React from "react";
import { bgBanner, productSlider } from "../../../assets";
import { Routes } from "../../../constant/routeContants";
import EnquiryForm from "./EnquiryForm";
import { Link } from "react-router-dom";

const TopBanner = ({ cityId, cityName, data}) => {
  
  return (
    <>
      <div
        className="freedo-city-wrapper"
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-7 col-sm-6">
              <h5>{data?.subHeading}</h5>
              <h1>
                {data?.heading}
              </h1>
              <p>
                {data?.text}
              </p>
            <Link to={Routes.RENT} state={{cityId, cityName}}
              className="freedo-primary-button rounded-pill text-dark"
            >
              Book Now
            </Link>
              {/* <a
                href={Routes.RENT}
                className="freedo-primary-button rounded-pill text-dark"
              >
                Book Now
              </a> */}
            </div>
            <div className="col-md-5 col-sm-6">
              <div className="banner-right">
                <img src={data?.backgroungImg} />

                <div className="left-content">Wide Range of Vehicles</div>

                <div className="right-content">Enjoy Hassle-Free Travel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* < div>
        <div className="row align-items-center">
          <div className="img-responsive wow zoomIn main-banner-wrap"
            style={{ animationDuration: "1s", backgroundImage: `url(${data?.backgroungImg})` }}
          >

            <div className="col-md-8">
              <div className="bg-bann">
                <div className="">
                  <h1>{data?.heading}</h1>
                  <p>
                    Get the best two-wheeler rental solution for your travel
                    needs
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div id="contct" className="ftr-form web-form-view">
                <h3>Book Your Vehicle </h3>
                <EnquiryForm />
              </div>
            </div>
          </div>


        </div>
      </div>
      <div id="contct" className="ftr-form mobile-view-form">
        <h3>Book Your Vehicle </h3>
        <EnquiryForm />
      </div> */}
    </>
  );
};

export default TopBanner;
