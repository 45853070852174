import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputField from "./InputField";
import { checkPhone } from "../utills/regex";
import OtpInput from "./OTPField";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAccStatus,
  getDelAccOptions,
  otpForDelAcc,
} from "../redux/actions";
import { RootState } from "../redux/reducers";
import { toastr } from "react-redux-toastr";
import en from "../locales/en.json";
import { toastMessages } from "../constant/enum";
import { Routes } from "../constant/routeContants";
import Skeleton from "react-loading-skeleton";
import { hindiTextValidation } from "../utills/helper";
import { langErrorMsg } from "../screen/city-freedo/constants/constants";

interface InputDefaultValue {
  otp: string;
  mobile_number: string;
  id: number | string;
  option_name: string;
  status: string;
  customRemarks: string;
}

const inputDefaultValue = {
  otp: "",
  mobile_number: "",
  id: "",
  option_name: "",
  status: "",
  customRemarks: "",
};

const DeleteAccount = () => {
  const [inputValue, setInputValues] =
    useState<InputDefaultValue>(inputDefaultValue);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [errMsg, setErrMsg] = useState<boolean>(false);
  const { deleteAccountOptions = [], deleteAccountOptionsLoader } = useSelector(
    (state: RootState) => state.authReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deleteAccount } = en;
  const isAllValuesFilled =
    inputValue.mobile_number?.length === 10 &&
    inputValue.id &&
    inputValue.otp?.length === 6;

  useEffect(() => {
    dispatch(getDelAccOptions({ type: "delAccOptions" }));
  }, []);

  const handleCheckboxChange = (option) => {
    setInputValues({ ...inputValue, ...option });
  };

  const handleChange = (name: string, value: string) => {
    setInputValues({ ...inputValue, [name]: value });
  };

  const handleVerifyNow = () => {
    if (inputValue.mobile_number.length >= 10) {
      dispatch(otpForDelAcc({ mobile_number: inputValue.mobile_number }));
      setShowOtpInput(true);
    }
  };

  const handleChangeNumber = () => {
    setInputValues({ ...inputValue, mobile_number: "" });
    setShowOtpInput(false);
  };

  const navigateToHome = () => {
    navigate(Routes.HOME);
  };

  const handleDeleteAccount = () => {
    if (isAllValuesFilled) {
      const {
        mobile_number,
        otp,
        option_name = "",
        id = "",
        customRemarks = "",
      } = inputValue;

      const payloadData = {
        data: {
          mobile_number,
          otp,
          status: 3, // status 3 will be sent in every case
          remarks: option_name,
          option_id: id,
        },
        navigateToHome,
      };

      // in case of custom remark
      if (id === 7) {
        if (!customRemarks) {
          toastr.error("", toastMessages.mandatoryFields);
          return;
        } else {
          payloadData.data.remarks = customRemarks;
        }
      }
      dispatch(changeAccStatus(payloadData));
    } else {
      toastr.error("", toastMessages.mandatoryFields);
    }
  };

  const handleCustomRemark = (value) => {
    if(errMsg) setErrMsg(false);
    if (!value.trim()) {
      setInputValues({
        ...inputValue,
        customRemarks: "",
      });
    } else {
      setInputValues({ ...inputValue, customRemarks: value });
    }
  };
  return (
    <>
      <div className="container payment-wrap mt-3 mb-3">
        <div className="d-flex justify-content-center mt-2">
          <div className="card w-100 mb-2 border-0">
            <div className="card-body">
              <div className="payment-text">
                <form className="row mt-1">
                  <div className="col-md-6">
                    <h5>{deleteAccount.deleteAccount}</h5>
                    <p className="mt-1">
                      <small>{deleteAccount.doYouWishToDelete}</small>
                    </p>
                    <h5>{deleteAccount.selectAnOption}</h5>
                    {deleteAccountOptionsLoader ? (
                      <Skeleton count={5} height={10} width={200} />
                    ) : (
                      deleteAccountOptions?.map((option) => (
                        <div key={option.id}>
                          <label className="d-flex align-items-center">
                            <InputField
                              type="checkbox"
                              name="options"
                              className="form-check-input me-2"
                              value={option.id}
                              isChecked={inputValue.id === option.id}
                              onChange={(name, value, checked) =>
                                handleCheckboxChange(option)
                              }
                            />
                            {option.option_name}
                          </label>
                        </div>
                      ))
                    )}
                    {inputValue.option_name.includes("Others") ? (
                      <textarea
                        value={inputValue.customRemarks}
                        name={"custom_remarks"}
                        className="form-control  w-100"
                        onChange={(e: any) => {
                          const hindiValidation = hindiTextValidation(
                            e.target.value
                          );
                          {
                            hindiValidation
                              ? handleCustomRemark(e.target.value)
                              : setErrMsg(true);
                          }
                        }}
                        maxLength={150}
                        rows={4}
                        placeholder="Enter your reason"
                      />
                    ) : null}
                  </div>
                  <div>
                    {errMsg && (
                      <span className="error-textArea">
                        {langErrorMsg}
                      </span>
                    )}
                  </div>

                  <div className="col-md-6">
                    <h5>{deleteAccount.contactNumber}</h5>

                    <InputField
                      value={inputValue.mobile_number}
                      name={"mobile_number"}
                      type={"text"}
                      className="form-control  w-75 mt-2"
                      onChange={handleChange}
                      maxLength={10}
                      placeholder="Enter your contact number"
                      restrictFuntion={checkPhone}
                      isDisabled={
                        !inputValue.option_name ||
                        showOtpInput ||
                        !!(inputValue.id === 7 && !inputValue.customRemarks)
                      }
                    />
                    {!showOtpInput ? (
                      <div className="small-text">
                        <Link
                          to="#"
                          className={`p-0 text-decoration-underline ${
                            inputValue.mobile_number?.length == 10
                              ? ""
                              : "d-none"
                          } `}
                          onClick={handleVerifyNow}
                        >
                          {deleteAccount.verifyNow}
                        </Link>
                      </div>
                    ) : (
                      <>
                        <div className="small-text">
                          <Link
                            to="#"
                            className={`p-0 text-decoration-underline`}
                            onClick={handleChangeNumber}
                          >
                            {deleteAccount.changeNumber}
                          </Link>
                        </div>
                        <div className="verify-em">
                          <p>
                            {deleteAccount.enterOTPsent}
                            {/* <b>{`+91${userProfileDetail?.emergency_number}`}</b> */}
                          </p>
                          <div className="delete-account-otp-container mt-2">
                            <OtpInput
                              name={"otp"}
                              handleChange={(otp: string) =>
                                handleChange("otp", otp)
                              }
                            />
                            <p className="resend text-muted mb-0">
                              Didn't receive code?{" "}
                              <Link to="" onClick={handleVerifyNow}>
                                {deleteAccount.requestAgain}
                              </Link>
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className="d-flex justify-content-center m-3 align-items-center flex-wrap delete-account-button-container">
              <Link to={Routes.PRIVACY_POLICIE} className="me-3">
                {deleteAccount.ChangedMind}
              </Link>
              <button
                onClick={handleDeleteAccount}
                className="freedo-outline-btn-small rounded-pill"
              >
                {deleteAccount.deleteAccount}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
