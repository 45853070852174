import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import AddressSection from "./AddressSection";

const CreateAddressModel = ({ resetState, addressData, isEditAddress }) => {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const addressModel = locale["addressModel"];
  return (
    <div className="change-address-wrapper">
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvaschangeaddress"
        aria-labelledby="offcanvaschangeaddressLabel"
      >
        <div></div>
        <div className="offcanvas-header top-header-canvas">
          <h5 className="offcanvas-title" id="offcanvaschangeaddressLabel">
            Add {addressModel["deliveryAddress"]}
          </h5>
          <button
            onClick={() => resetState()}
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            id="settingCloseModel"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div className="offcanvas-body text-start">
          <AddressSection
            resetState={resetState}
            addressData={addressData}
            isEditAddress={isEditAddress}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateAddressModel;
