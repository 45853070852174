import { addDays } from "date-fns";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import SelectTimeSlot from "../../../component/SelectTimeSlot";
import { Routes } from "../../../constant/routeContants";
import {
  clearModifyBookingState,
  getBookingChangeCharges,
  getModelAvailableDates,
  getModificationCharges,
  modifyBooking,
  setModifyBookingState,
} from "../../../redux/actions/rentActions/bookingAction";
import { convertRentalDayIntoNumber } from "../../../utills/helper";

interface DatePickerDateInterface {
  startDate: null | Date;
  endDate: null | Date;
}
const ModifyBookingModal = ({ setModalFlag, callOrderApi }) => {
  const dispatch = useDispatch();
  const {
    bookingDetails,
    modifyBookingState,
    modelAvailableDates,
    modificationCharges,
    modificationChargesData,
  } = useSelector((state: any) => state?.bookingReducer);
  const { showSuccessModal, showErrorModal } = useSelector(
    (state: any) => state.localeReducer
  );
  const datePickerRef = useRef<any>();
  const tableRef = useRef<any>(null);

  const { modifyStep, newStartDate, newEndDate, timeSlot } = modifyBookingState;
  const [datePickerDate, setDatePickerDate] = useState<DatePickerDateInterface>(
    {
      startDate: null,
      endDate: null,
    }
  );
  const [availabeDates, setAvailableDates] = useState<[] | Date[]>([]);
  const [showModifiedScreen, setShowModifiedScreen] = useState(false);
  const [showTimeSlotIcon, setShowTimeSlotIcon] = useState(false);
  const [showCharges, setShowChrages] = useState(false);
  const [modifyLoader, setModifyLoader] = useState(false);

  const {
    amount,
    branch_id,
    mode_of_pick_up_id,
    id,
    city_id,
    model_id,
    brand_id,
    start_date,
    end_date,
    rental_days,
    buffer_end_date,
    start_time,
    end_time,
  } = bookingDetails;

  useEffect(() => {
    let payload = {
      cityId: city_id,
      branchId: branch_id,
      modelId: model_id,
      sourceId: brand_id || 1,
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      bookingStartDate: start_date,
      endDate: end_date,
      bufferEndDate: buffer_end_date,
    };
    dispatch(getBookingChangeCharges());
    dispatch(getModelAvailableDates(payload));
    return () => {
      dispatch(clearModifyBookingState());
    };
  }, []);

  useEffect(() => {
    if (modelAvailableDates?.availableDates?.length) {
      let dates: any = [...modelAvailableDates?.availableDates].map(
        (date) => new Date(date)
      );
      let showCurrentdate = dates?.includes(
        moment(start_date).format("YYYY-MM-DD")
      );
      setAvailableDates(dates);
      setShowTimeSlotIcon(showCurrentdate);
    }
  }, [modelAvailableDates]);

  useEffect(() => {
    if (showSuccessModal || showErrorModal) {
      closeModal();
    }
  }, [showSuccessModal, showErrorModal]);

  useEffect(() => {
    if (showCharges) {
      tableRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [showCharges]);

  const toggleDatePicker = (value) => {
    datePickerRef.current.setOpen(value);
  };

  const handleCalenderClose = () => {
    setDatePickerDate({
      startDate: null,
      endDate: null,
    });
  };

  const setState = (key, value) => {
    let newState = { ...modifyBookingState, [key]: value };
    dispatch(setModifyBookingState(newState));
  };

  const handleTimeSlot = (slot) => {
    setState("timeSlot", slot);
  };

  const handleConfirm = (e) => {
    e.stopPropagation();
    let newState = {
      ...modifyBookingState,
      newStartDate: datePickerDate.startDate,
      newEndDate: datePickerDate.endDate,
    };
    dispatch(setModifyBookingState(newState));
    setModifyLoader(true);
    dispatch(
      getModificationCharges(
        {
          id,
          date: moment(datePickerDate.startDate).format("YYYY-MM-DD"),
        },
        modificationChargeCallback
      )
    );
  };

  const modificationChargeCallback = (res) => {
    setModifyLoader(false);
    if (res) {
      toggleDatePicker(false);
      setShowModifiedScreen(true);
    }
  };

  const handleModifyRide = () => {
    setModifyLoader(true);
    const payload = {
      bookingId: id,
      newStartDate: moment(newStartDate || start_date).format("YYYY-MM-DD"),
      newEndDate: moment(newEndDate || end_date).format("YYYY-MM-DD"),
      futureMonths: 2,
      charge: modificationCharges,
      time_slot_id: timeSlot.id,
    };
    dispatch(modifyBooking(payload, callback));
  };

  const callback = (response) => {
    setModifyLoader(false);
    closeModal();
    callOrderApi(
      response?.amount,
      response?.paid_for,
      response?.helpData,
      () => {}
    );
  };

  const closeModal = () => {
    setModalFlag((pre) => ({
      ...pre,
      modifyRideModal: false,
    }));
  };

  const modalContent = () => {
    switch (modifyStep) {
      case "1":
        return existingHtml();
      case "2":
        return confirmationHtml();
      case "3":
        return confirmationHtml();
    }
  };

  const handleBack = () => {
    switch (modifyStep) {
      case "2":
        setState("modifyStep", "1");
        break;
    }
  };

  const sortModificationChargesData = (): [] => {
    return modificationChargesData.sort((a, b) => a.id - b.id);
  };

  const existingHtml = () => {
    return (
      <>
        <div className="date-container mb-1">
          <h4 className="m-auto mb-2 modal-sub-title">Current Details</h4>
          <div className="modify-label-items ">
            <p>
              <span className="label">Current Start Date: </span>
              <span className="">
                {moment(start_date).format("DD-MMM-YYYY") || "-NA-"}
              </span>
              <span
                className="cp"
                onClick={() => {
                  if (!showModifiedScreen) setShowModifiedScreen(true);
                  toggleDatePicker(true);
                }}
              >
                <i
                  data-tooltip-id="modify-booking-tooltip"
                  className="fas fa-edit"
                ></i>
                <Tooltip
                  id="modify-booking-tooltip"
                  place="bottom"
                  content="Click here to edit start date"
                />
                <DatePicker
                  ref={datePickerRef}
                  showIcon={true}
                  className="form-control form-control-sm"
                  selected={datePickerDate.startDate}
                  onChange={(date) => {
                    let newState = {
                      startDate: date,
                      endDate: addDays(
                        date,
                        convertRentalDayIntoNumber(rental_days)
                      ),
                    };
                    setDatePickerDate(newState);
                  }}
                  startDate={datePickerDate.startDate || newStartDate}
                  endDate={datePickerDate.endDate || newEndDate}
                  shouldCloseOnSelect={false}
                  includeDates={availabeDates}
                  onCalendarClose={handleCalenderClose}
                >
                  <div className="m-auto">
                    <button
                      className="cancel-booking-btn rounded-pill m-4"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDatePicker(false);
                      }}
                    >
                      Cancel
                    </button>{" "}
                    <button
                      className="freedo-outline-btn rounded-pill m-4"
                      onClick={(e) => handleConfirm(e)}
                      disabled={!datePickerDate.startDate}
                    >
                      {modifyLoader ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Confirm"
                      )}
                    </button>{" "}
                  </div>
                </DatePicker>
              </span>
              <span
                className="cp"
                onClick={() => toggleDatePicker(true)}
              ></span>
            </p>
          </div>
          <div className="modify-label-items ">
            <p>
              <span>Current End Date: </span>
              <span className="">
                {moment(end_date).format("DD-MMM-YYYY") || "-NA-"}
              </span>
            </p>
          </div>
          <div className="modify-label-items ">
            <p>
              <span>Current Time Slot: </span>
              <span className="">
                {start_time ? start_time + "-" + end_time : "-NA-"}
              </span>
              {!showModifiedScreen && !showTimeSlotIcon && (
                <span
                  className="cp"
                  onClick={() => setShowModifiedScreen(true)}
                >
                  <i
                    data-tooltip-id="modify-booking-tooltip"
                    className="fas fa-edit"
                  ></i>
                  <Tooltip
                    id="modify-booking-tooltip"
                    place="bottom"
                    content="Click here to edit start date"
                  />
                </span>
              )}
            </p>
          </div>
          <div className="modify-label-items ">
            <p>
              <span>Duration:</span>
              <span>{rental_days || "N/A"}</span>
            </p>
          </div>
          <div className="modify-label-items ">
            <p>
              <span>Total Amount Paid:</span>
              <span>{`₹${Math.round(amount) || 0}`}</span>
            </p>
          </div>
        </div>
        {showModifiedScreen && modifiedHtml()}
      </>
    );
  };

  const modifiedHtml = () => {
    return (
      <>
        <div>
          <hr className="mt-3" />
        </div>
        <div className="date-container ">
          <h4 className="m-auto mb-2 modal-sub-title">Modified Details</h4>
          <div className="modify-label-items ">
            <p>
              <span>New Start Date: </span>
              <span className="">
                {moment(newStartDate || start_date).format("DD-MMM-YYYY") ||
                  "-NA-"}
              </span>
            </p>
          </div>
          <div className="modify-label-items ">
            <p>
              <span>New End Date: </span>
              <span className="">
                {moment(newEndDate || end_date).format("DD-MMM-YYYY") || "-NA-"}
              </span>
            </p>
          </div>
          <div className="modify-label-items ">
            <p>
              <span>Modified Charges:</span>
              <span className="theme-color">{`₹${
                Math.round(modificationCharges) || 0
              }`}</span>
            </p>
          </div>
          <div>
            <SelectTimeSlot
              handleChange={handleTimeSlot}
              filterData={{
                date: moment(newStartDate || start_date).format("YYYY-MM-DD"),
                branch_id,
                option_id: mode_of_pick_up_id?.mode == 1 ? 2 : 3,
              }}
              isModifyRide={true}
            />
          </div>
          <div className="text-center">
            <button
              disabled={!timeSlot}
              className="btn-sm freedo-primary-button rounded-pill mt-4"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setState("modifyStep", "2")}
            >
              Confirm
            </button>
          </div>
          <div className="color-primary text-center cp mt-1 fw-500">
            <i className="fa-solid fa-circle-info mx-1" />
            <span
              onClick={() => {
                setShowChrages(!showCharges);
              }}
            >
              Freedo Modification Charges Policy
            </span>
          </div>
          {showCharges && <>{modificationChargesHtml()}</>}
        </div>
      </>
    );
  };
  // modificationChargesData  = [{ time_period:'3333', charges:'3444', limit_and_remarks:'4566' }]
  const modificationChargesHtml = () => {
    return (
      <div className="table-responsive modification-table mt-3 mb-2">
        <div className="d-flex align-items-center justify-content-between">
          <span></span>
          <h6 className="text-center m-2">Modification Charges</h6>
          <i
            onClick={() => setShowChrages(false)}
            className="fa fa-times cp m-2"
            aria-hidden="true"
          ></i>
        </div>
        <table className="table table-hover table-lg">
          <thead>
            <tr>
              <th className="fw-500">Time Period</th>
              <th className="fw-500">Charges</th>
              <th className="fw-500">Limits and Remarks</th>
            </tr>
          </thead>
          <tbody>
            {modificationChargesData.length
              ? sortModificationChargesData()?.map(
                  ({ time_period, charges, limit_and_remarks }) => (
                    <tr>
                      <td>{time_period}</td>
                      <td>{charges}</td>
                      <td>{limit_and_remarks}</td>
                    </tr>
                  )
                )
              : null}
          </tbody>
        </table>
        <div ref={tableRef} className="text-center py-2">
          <span className="text-center fw-500">
            For Detail Terms and Conditions
            <Link className="m-1" to={Routes.TERMS_CONDITIONS} target="_blank">
              Click Here
            </Link>
          </span>
        </div>
      </div>
    );
  };

  const finalHtml = () => {
    return (
      <div className="date-container">
        <div className="modify-label-items ">
          <p>
            <span>New Start Date: </span>
            <span className="">
              {moment(newStartDate || start_date).format("DD-MMM-YYYY") ||
                "-NA-"}
            </span>
          </p>
        </div>
        <div className="modify-label-items ">
          <p>
            <span>New End Date: </span>
            <span className="">
              {moment(newEndDate || end_date).format("DD-MMM-YYYY") || "-NA-"}
            </span>
          </p>
        </div>
        <div className="modify-label-items ">
          <p>
            <span>New Pick Up Slot:</span>
            <span className="">
              {timeSlot?.from_time} - {timeSlot?.to_time}
            </span>
          </p>
        </div>
        <div className="modify-label-items ">
          <p>
            <span>Duration:</span>
            <span>{rental_days || "N/A"}</span>
          </p>
        </div>
        <div className="modify-label-items ">
          <p>
            <span>Total Amount Paid:</span>
            <span>{`₹${Math.round(amount) || 0}`}</span>
          </p>
        </div>
        <div className="modify-label-items ">
          <p>
            <span>Modified Charges:</span>
            <span>{`₹${Math.round(modificationCharges) || 0}`}</span>
          </p>
        </div>
        <div className="text-center mb-2">
          <button
            className="freedo-primary-button rounded-pill mt-4"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleModifyRide}
          >
            {modifyLoader ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              `${
                modificationCharges
                  ? `Pay Now ₹${Math.round(modificationCharges)}`
                  : "Modify Booking"
              }`
            )}
          </button>
        </div>
      </div>
    );
  };

  const confirmationHtml = () => {
    return (
      <div className="text-center">
        <h3 className="mb-2">Are you sure you want to modify the ride?</h3>
        {modificationCharges > 0 && (
          <p>
            A modification charge of ₹{Math.round(modificationCharges)} will be
            applied.
          </p>
        )}
        <button
          style={{ padding: "9px 28px" }}
          className="freedo-primary-button rounded-pill mt-4"
          onClick={handleModifyRide}
        >
          {modifyLoader ? (
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            "Yes"
          )}
        </button>
        <button
          style={{ padding: "9px 28px" }}
          className="freedo-primary-button rounded-pill mt-4 m-2"
          onClick={() => {
            if (modifyLoader) setModifyLoader(false);
            setState("modifyStep", "1");
          }}
        >
          No
        </button>
      </div>
    );
  };
  return (
    <Modal
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      className="modal fade vehicle-model modify-booking-details"
      id="modify-modal"
      tabIndex={-1}
      aria-hidden="true"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title className={` ${modifyStep == "1" ? "" : "d-none"} `}>
          <h5
            className="m-auto modal-title theme-border-bottom"
            id="modifyRideLable"
          >
            {"Modify Ride"}
          </h5>
        </Modal.Title>
        {modifyStep !== "1" && <span></span>}
        <span>
          <i
            onClick={closeModal}
            className="fa fa-times cp icons"
            aria-hidden="true"
          ></i>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className=" align-items-center ">{modalContent()}</div>
      </Modal.Body>
    </Modal>
  );
};

export default ModifyBookingModal;
