import React from "react";
const Missions = ({ data }) => {
  return (
    <>
      <section className="hassel-wrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 col-sm-8">
              <div className="content-wrap">
                <h5>{data?.subHeading}</h5>
                <h2 className="lefthead">{data?.heading}</h2>
                <p>{data?.text}</p>

                <h2 className="lefthead mt-4">{data?.title}</h2>
                <p>{data?.description}</p>

                <div className="content-icon d-flex">
                  <div className="icons-wrap">
                    <img src={data?.imgOne} alt="" />
                    <h3>
                      {data?.imgOneText}
                    </h3>
                  </div>

                  <div className="icons-wrap">
                    <img src={data?.imgTwo} alt="" />
                    <h3>
                      {data?.imgTwoText}
                    </h3>
                  </div>

                  <div className="icons-wrap">
                    <img src={data?.imgThree} alt="" />
                    <h3>
                      {" "}
                      {data?.imgThreeText}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Missions;
