import React, { useState, useEffect } from "react";
import { digiLocker } from "../../../assets";
import kycServices from "../../../redux/services/kycServices";
import { FirebaseEvents } from "../../../utills/firebaseEvents";
const AdharVerification = ({ userKycStatusCheck }) => {
  const [digilockerurlData, setDigilockerurlData] = useState<any>(null);
  useEffect(() => {
    kycServices.getDigilocakerUrl().then((response: any) => {
      if (response && response.status === 200) {
        const dataa = response.data?.result?.data;
        setDigilockerurlData(dataa);
        // FirebaseEvents("KycAadharUploadSuccess",{})
      }
      else{
        // FirebaseEvents("KycAadharUploadFailure",{})
      }
    });
  }, []);

  const openDigilocakerVerification = () => {
    if (digilockerurlData) {
      FirebaseEvents("KycAadharInitiation",{})
        window.open(`${digilockerurlData?.url}`)
    }
  };
  const SuccessVerified =()=>{
    return <div> 
    <div className="successfully-card ">
      <img src="images/Biometric-illustration.png" alt="" />
      <p> Aadhaar Successfully Verified!</p>
      <p className="support-file">
        
        <br />
        Your Aadhaar is already verified with Digilocker
      </p>
    </div>
  </div>
  }
  return (
    <>
    { !userKycStatusCheck.aadhaarVerified ? 
    <div className="area" id="Aadhaar">
      <div className="adhaar-wrap">
        <img src={digiLocker} alt="" />
        <p>
          Aadhaar Card Verification is done via Digilocker. Follow simple steps
          to complete verification with Freedo.
        </p>
        <ul className="adhaar-process">
          <li> 1. Initiate KYC </li>
          <li> 2. Enter Aadhaar Number </li>
          <li> 3. Verify Aadhaar Via OTP </li>
          <li> 4. Verification with Freedo is Done </li>
        </ul>
      </div>

      <div className="adhaar-button-wrap text-center">
        <button
          type="submit"
          className="freedo-primary-button-small rounded-pill w-50"
          onClick={() => openDigilocakerVerification()}
        >
          Initiate KYC{" "}
        </button>
      </div>
    </div>
    :
    <SuccessVerified />}
    </>
  );
};
export default AdharVerification;
