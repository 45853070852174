import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./component.css";
import { checkNumeric } from "../utills/regex";

const initialState = {
  otp1: "",
  otp2: "",
  otp3: "",
  otp4: "",
  otp5: "",
  otp6: "",
};

const OtpInput = forwardRef((props: any, ref) => {
  const [fields, setFields] = useState(initialState);
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const inputRef4 = useRef<HTMLInputElement>(null);
  const inputRef5 = useRef<HTMLInputElement>(null);
  const inputRef6 = useRef<HTMLInputElement>(null);

  const inputRefs = [
    inputRef1,
    inputRef2,
    inputRef3,
    inputRef4,
    inputRef5,
    inputRef6,
  ];
  const handleChange = (field: string) => (value: string) => {
    setFields((prevState) => ({ ...prevState, [field]: value }));
  };

  const clearState = () => {
    setFields({ ...initialState });
  };

  // used in MITC screen to focus on OTP input
  const focusOTPInput = () => {
    setTimeout(() => {
      inputRef1?.current?.focus();
    }, 500);
  };

  useImperativeHandle(ref, () => {
    return { clearOtp: clearState, focusOTPInput };
  });

  useEffect(() => {
    props?.handleChange(
      fields?.otp1 +
        fields?.otp2 +
        fields?.otp3 +
        fields?.otp4 +
        fields?.otp5 +
        fields?.otp6
    );
  }, [
    fields?.otp1 +
      fields?.otp2 +
      fields?.otp3 +
      fields?.otp4 +
      fields?.otp5 +
      fields?.otp6,
  ]);

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");

    // Distribute the pasted value across the input fields
    let updatedOtp = { ...initialState };
    if (!checkNumeric(pastedData)) return;
    let i = 1;
    for (i = 1; i <= Math.min(pastedData.length, 6); i++) {
      updatedOtp[`otp${i}`] = pastedData[i - 1];
    }
    inputRefs[i === 7 ? 5 : i - 1]?.current?.focus();
    setFields(updatedOtp);
  };

  return (
    <div className="otpContainer">
      <input
        name="otp1"
        type="text"
        autoComplete="off"
        className="otpInput"
        value={fields.otp1}
        ref={inputRef1}
        onChange={(e) => {
          if (checkNumeric(e.target.value)) {
            handleChange("otp1")(e.target.value);
            e.target.value && inputRef2.current?.focus();
          }
        }}
        maxLength={1}
        autoFocus
        inputMode="numeric"
        pattern="[0-9]*"
        onPaste={handlePaste}
      />
      <input
        name="otp2"
        type="text"
        autoComplete="off"
        className="otpInput"
        value={fields.otp2}
        ref={inputRef2}
        onChange={(e) => {
          if (checkNumeric(e.target.value)) {
            handleChange("otp2")(e.target.value);
            if (e.target.value) inputRef3.current?.focus();
          }
        }}
        onKeyDown={(e: any) => {
          if (!e.target.value && e.key === "Backspace")
            inputRef1.current?.focus();
        }}
        maxLength={1}
        inputMode="numeric"
        pattern="[0-9]*"
      />
      <input
        name="otp3"
        type="text"
        autoComplete="off"
        className="otpInput"
        value={fields.otp3}
        ref={inputRef3}
        onChange={(e) => {
          console.log(3, e.target.value, "handleChange");
          if (checkNumeric(e.target.value)) {
            handleChange("otp3")(e.target.value);
            if (e.target.value) inputRef4.current?.focus();
          }
        }}
        onKeyDown={(e: any) => {
          if (!e.target.value && e.key === "Backspace")
            inputRef2.current?.focus();
        }}
        maxLength={1}
        inputMode="numeric"
        pattern="[0-9]*"
      />
      <input
        name="otp4"
        type="text"
        autoComplete="off"
        className="otpInput"
        value={fields.otp4}
        ref={inputRef4}
        onChange={(e) => {
          if (checkNumeric(e.target.value)) {
            handleChange("otp4")(e.target.value);
            if (e.target.value) inputRef5.current?.focus();
          }
        }}
        onKeyDown={(e: any) => {
          console.log(4, e.target.value, "onKeyDown");
          if (!e.target.value && e.key === "Backspace")
            inputRef3.current?.focus();
        }}
        maxLength={1}
        inputMode="numeric"
        pattern="[0-9]*"
      />
      <input
        name="otp4"
        type="text"
        autoComplete="off"
        className="otpInput"
        value={fields.otp5}
        ref={inputRef5}
        onChange={(e) => {
          console.log(5, e.target.value, "handleChange");
          if (checkNumeric(e.target.value)) {
            handleChange("otp5")(e.target.value);
            if (e.target.value) inputRef6.current?.focus();
          }
        }}
        onKeyDown={(e: any) => {
          console.log(5, e.target.value, "onKeyDown");
          if (!e.target.value && e.key === "Backspace")
            inputRef4.current?.focus();
        }}
        maxLength={1}
        inputMode="numeric"
        pattern="[0-9]*"
      />
      <input
        name="otp4"
        type="text"
        autoComplete="off"
        className="otpInput"
        value={fields.otp6}
        ref={inputRef6}
        onChange={(e) => {
          if (checkNumeric(e.target.value)) {
            handleChange("otp6")(e.target.value);
          }
        }}
        onKeyDown={(e: any) => {
          if (!e.target.value && e.key === "Backspace")
            inputRef5.current?.focus();
        }}
        maxLength={1}
        inputMode="numeric"
        pattern="[0-9]*"
      />
    </div>
  );
});

export default React.memo(OtpInput);
