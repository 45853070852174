import Skeleton from "react-loading-skeleton";
export default () => {
  return (
    <section className="Booking-details-wrapper booking-detail-skelaton">
      <div className="container-fluid">
        <div className="renting-wrap">
          <Skeleton width={"15%"} height={"20px"} />
          <div className="breadcrumb-wrap">
            <nav aria-label="breadcrumb">
              <Skeleton width={"30%"} height={"20px"} />
            </nav>
          </div>
          <div className="row mt-3">
            <div className="col-lg-7">
              <div className="booking-status-wrapper">
                <div className="booking-status-top">
                  <div className="booking-bike-wrap">
                    <div className="booking-bike-model">
                      <Skeleton width={"150px"} height={"80px"} />
                    </div>
                    <div className="bike-model-details mr-5">
                      <Skeleton width={"200px"} height={"20px"} />
                      <div>
                        <Skeleton width={"200px"} height={"20px"} />
                      </div>
                    </div>
                  </div>
                  <Skeleton
                    width={"130px"}
                    height={"40px"}
                    borderRadius={"20px"}
                  />
                </div>
              </div>

              <div className="booking-status-bottom">
                <div className="booking-id-first">
                  <div className="booking-id-wrap mb-3">
                    <Skeleton width={"100px"} height={"20px"} />
                    <Skeleton width={"200px"} height={"20px"} />
                  </div>
                  <Skeleton
                    width={"130px"}
                    height={"35px"}
                    borderRadius={"20px"}
                  />
                </div>
                <div className="booking-id-mid">
                  <div className="row">
                    <div className="col-md-4 col-sm-6 mb-5">
                      <Skeleton width={"100px"} height={"20px"} />
                      <Skeleton width={"200px"} height={"20px"} />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <Skeleton width={"100px"} height={"20px"} />
                      <Skeleton width={"200px"} height={"20px"} />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <Skeleton width={"100px"} height={"20px"} />
                      <Skeleton width={"200px"} height={"20px"} />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <Skeleton width={"100px"} height={"20px"} />
                      <Skeleton width={"200px"} height={"20px"} />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <Skeleton width={"100px"} height={"20px"} />
                      <Skeleton width={"200px"} height={"20px"} />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <Skeleton width={"100px"} height={"20px"} />
                      <Skeleton width={"200px"} height={"20px"} />
                    </div>
                    <div className="col-md-4 col-sm-6 mt-5">
                      <Skeleton width={"80px"} height={"20px"} />
                      <Skeleton width={"150px"} height={"20px"} />
                    </div>
                  <div className="col-md-3 col-sm-6 mt-5"></div>
                  <div className="col-md-3 col-sm-6 mt-5 end-ride">
                  <Skeleton
                    width={"130px"}
                    height={"40px"}
                    borderRadius={"20px"}
                  />
                  <Skeleton
                    width={"130px"}
                    height={"40px"}
                    borderRadius={"20px"}
                  />
                  </div>
                  
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="booking-detailsfaq">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item row">
                       <div className="col-md-4"> <Skeleton width={"300px"} height={"30px"} /></div>
                       <div className="col-md-7"></div>
                       <div className="col-md-1"><Skeleton width={"30px"} height={"30px"} borderRadius={"20px"} /></div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                  <div className="accordion-item row">
                       <div className="col-md-4"> <Skeleton width={"300px"} height={"30px"} /></div>
                       <div className="col-md-7"></div>
                       <div className="col-md-1"><Skeleton width={"30px"} height={"30px"} borderRadius={"20px"} /></div>
                  </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-4 ml-2"><Skeleton width={"230px"} height={"150px"} /></div>
                            <div className="col-md-1"></div>
                            <div className="col-md-4">
                                <Skeleton width={"150px"} height={"20px"} />
                                <Skeleton width={"250px"} height={"20px"} count={3} />
                                </div>
                        </div>
                        
                  </div>
                  <hr />
                  <div className="accordion-item row">
                       <div className="col-md-4"> <Skeleton width={"300px"} height={"30px"} /></div>
                       <div className="col-md-7"></div>
                       <div className="col-md-1"><Skeleton width={"30px"} height={"30px"} borderRadius={"20px"} /></div>
                  </div>
                  <hr />
                  <div className="accordion-item row">
                       <div className="col-md-4"> <Skeleton width={"300px"} height={"30px"} /></div>
                       <div className="col-md-7"></div>
                       <div className="col-md-1"><Skeleton width={"30px"} height={"30px"} borderRadius={"20px"} /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
