import React, { useState, useEffect, useRef } from "react";
import SelectCityModal from "../homeScreen/SelectCityModal";
import BlogCard from "./BlogCard";
import { blogsData } from "../../constant/BlogData";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toggleCityModal } from "../../redux/actions";
import { useDispatch } from "react-redux";

const Blog = () => {
  const dispatch = useDispatch();
  const [searchPopup, setSearchPopup] = useState<boolean>(false);
  const [visibleItems, setVisibleItems] = useState(10); // Initial number of items to display

  const location = useLocation();
  const tag = location?.state?.topic;

  const handleCityChange = (newCity: any) => {
    console.log("this new city", newCity);
  };

  const { cardData, searchOptions, mostPopular } = blogsData;
  const [card, setCard] = useState<any>();

  const [topic, setTopic] = useState<string>("All");

  const handleCards = (topic: string): any => {
    setTopic(topic);
    setSearchPopup(false);
  };

  useEffect(() => {
    if (tag) {
      setTopic(tag);
    }
  }, [tag]);

  useEffect(() => {
    if (topic === "All") {
      setCard(cardData);
    } else {
      let cardTopic = cardData.filter(function (item) {
        return item?.topic === topic;
      });
      setCard(cardTopic);
    }
  }, [topic]);

  const loadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
  };

  return (
    <>
      <Helmet>
        <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="title"
          content="Freedo: Bike Rentals | Two Wheelers Rentals App"
        />
        <meta
          name="description"
          content="Freedo rentals offers bikes and scooters on rent in Noida and NCR online at cheap prices. Wide Range of Two wheelers at affordable hourly rates"
        />
        <meta name="url" content="https://freedo.rentals/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freedo.rentals/" />
        <meta
          property="og:title"
          content="Bike Rentals in Noida, Delhi NCR | Freedo Rentals"
        />
        <meta
          property="og:description"
          content="Freedo rentals offers bikes and scooters on rent in Noida and NCR online at cheap prices. Wide Range of Two wheelers at affordable hourly rates"
        />
        {/* <meta
          property="og:image"
          content="https://herowebuatfreedo.azurewebsites.net/logo.jpg"
        /> */}
        <meta property="twitter:url" content="https://freedo.rentals/" />
        <meta
          property="twitter:title"
          content="Bike Rentals in Noida, Delhi NCR | Freedo Rentals"
        />
        <meta
          property="twitter:description"
          content="Freedo rentals offers bikes and scooters on rent in Noida and NCR online at cheap prices. Wide Range of Two wheelers at affordable hourly rates"
        />

        <meta property="og:site_name" content="Freedo Rentals" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="in" />
        {/* <meta property="og:image:width" content="300" /> */}
        {/* <meta property="og:image:height" content="300" /> */}
      </Helmet>
      <section className="blog-main mt-4">
        <div className="container-fluid">
          <div className="search-blog mx-auto col-md-8 col-sm-10 mb-5">
            <div className="input-group mb-3">
              <a
                href="#"
                className="input-group-text"
                id="basic-addon1"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => dispatch(toggleCityModal(true))}
              >
                <i className="fas fa-map-marker-alt"></i>
              </a>
              <input
                type="text"
                onBlur={() => setSearchPopup(false)}
                onClick={() => setSearchPopup(true)}
                className="form-control"
                placeholder="Search topic here"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={topic}
                readOnly
              />
              <a
                href="#"
                className="input-group-text bg-transparent"
                id="basic-addon2"
              >
                <i className="fas fa-search"></i>
              </a>
            </div>
            <div className="locate-me-model-wrap">
              <div className="location-wrap">
                <SelectCityModal handleChange={handleCityChange} />
              </div>
            </div>
            {searchPopup ? (
              <div className="search-options">
                <ul>
                  {searchOptions?.map((option, ind) => (
                    <li key={ind}>
                      <Link
                        to={""}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          handleCards(option);
                        }}
                      >
                        {option}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        {card?.length > 0 && (
          <>
            <div className="d-flex align-items-center flex-wrap">
              <Link to={`/blog/${card[0]?.link}`} className="blog-main-img">
                {" "}
                <img src={card[0]?.imgSrc} alt="Blog Main Image" />{" "}
              </Link>
              <div className="blog-content-main">
                <span className="badge bg-freedo-theme fw-normal mb-3">
                  {" "}
                  {card[0]?.topic}{" "}
                </span>
                <h5 className="mb-2">
                  {" "}
                  <Link
                    to={`/blog/${card[0]?.link}`}
                    className="text-dark d-block"
                  >
                    {" "}
                    {card[0]?.title}{" "}
                  </Link>{" "}
                </h5>
                <div className="blog-time text-dark mb-2">
                  <i className="far fa-calendar"></i> {card[0]?.date} /{" "}
                  {card[0]?.location}
                </div>
                <p className="text-secondary fw-light">{card[0]?.content}</p>

                <Link
                  to={`/blog/${card[0]?.link}`}
                  className="text-dark pt-2 d-block text-decoration-underline"
                >
                  Read More
                </Link>
              </div>
            </div>
          </>
        )}

        <div className="container-fluid my-4">
          <div className="row">
            {card?.length > 0 &&
              card
                .slice(1)
                .reverse()
                ?.slice(1, visibleItems)
                ?.map((item, ind) => <BlogCard data={item} key={item.id} />)}
            {card?.length > visibleItems ? (
              <div className="text-center">
                <button
                  className="freedo-primary-button-small rounded-pill fw-medium px-5 mt-3"
                  onClick={loadMore}
                >
                  Load More Stories
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
