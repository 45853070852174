import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button1 } from "../component/Button";
import { LocalStorage } from "../constant/enum";
import en from "../locales/en.json";
import { setZohoLeads } from "../redux/actions";
import { RootState } from "../redux/reducers";
import {
  emailValidation,
  getLocalStorageData,
  mobileValidation,
  setLocalStorageData,
} from "../utills/helper";
import Form from "./Form";

interface Ifields {
  userName: string;
  emailOrPhone: string;
}

const initialState = {
  userName: "",
  emailOrPhone: "",
};
interface Ivalue {
  value: string | number;
}

interface Ipayload {
  name: string;
  phone?: string;
  email?: string;
  locationId: string;
}

const ComingSoonCityModal = () => {
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const clickRef = useRef<any>();
  const dispatch = useDispatch();
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const errorMessages = locale["errorMessages"];

  const [fields, setFields] = useState<Ifields>({
    userName: "",
    emailOrPhone: "",
  });

  const [errFields, setErrFields] = useState<Ifields>({
    userName: "",
    emailOrPhone: "",
  });

  const onChange = (name: string, value: string) => {
    setFields({ ...fields, [name]: value });
    setErrFields({ ...errFields, [name]: "" });
  };

  const clearState = () => {
    setFields({ ...initialState });
    setErrFields({ ...initialState });
  };

  const validateFields = (e: any) => {
    let validatePhone = mobileValidation(fields?.emailOrPhone);
    let validateEmail = emailValidation(fields?.emailOrPhone);
    let isEmail: boolean = true;

    const trimmedUserName = fields?.userName ? fields.userName.trim() : "";

    if (!trimmedUserName || !fields?.emailOrPhone) {
      const error: any = {
        ...errFields,
      };
      if (!trimmedUserName) {
        error.userName = errorMessages["requiredField"];
      }
      if (!fields?.emailOrPhone) {
        error.emailOrPhone = errorMessages["requiredField"];
      }
      setErrFields(error);
    } else {
      validatePhone ? (isEmail = false) : (isEmail = true);
      if (!validatePhone && !validateEmail) {
        setErrFields({
          ...errFields,
          emailOrPhone: errorMessages["InvalidPhoneEmail"],
        });
      } else {
        return { success: true, isEmail };
      }
    }
  };

  const setLocalData = () => {
    let data: Array<object> | null = getLocalStorageData(
      LocalStorage.zohoLeads
    );
    if (data) {
      data?.push({ cityId: cityData?.id, emailOrPhone: fields?.emailOrPhone });
      setLocalStorageData(LocalStorage.zohoLeads, data);
    } else {
      data = [{ cityId: cityData?.id, emailOrPhone: fields?.emailOrPhone }];
      setLocalStorageData(LocalStorage.zohoLeads, data);
    }
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    let validate = validateFields(e);
    const ele = document.getElementById("submitButton");

    let payload: Ipayload = {
      name: fields.userName,
      locationId: cityData?.id,
    };
    validate?.isEmail
      ? (payload.email = fields.emailOrPhone)
      : (payload.phone = fields.emailOrPhone);

    if (validate?.success) {
      setLocalData();
      dispatch(setZohoLeads(payload));
      clearState();
      clickRef.current?.click();
    }
  };
  
  return (
    <>
      <div
        className="modal-body background-overlay"
        style={{ backgroundImage: `url(${cityData?.city_coming_soon_image_path})` }}
      >
        <button
          type="button"
          className="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => clearState()}
        ></button>
        <div className="card">
          <div className="card-body">
            <div className="text-center">
              <h4>{en?.home?.homePageCarousal?.NotifyMe}</h4>
              <p>Please notify me when {cityData?.city_name} is live</p>
            </div>
            <div className="d-flex justify-content-center">
              <div className="mt-1">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <Form
                        type="text"
                        placeholder="Enter your name"
                        label={""}
                        id="basic-addon1"
                        value={fields.userName}
                        onChange={onChange}
                        name="userName"
                        maxLength={100}
                        errorMsg={errFields.userName}
                        //  showError={errorState.email}
                        restrictFuntion={null}
                      />
                    </div>
                    <div className="col-md-12 col-12">
                      <Form
                        type="text"
                        placeholder="Enter your Email or Mobile no."
                        label={""}
                        id="basic-addon1"
                        value={fields.emailOrPhone}
                        onChange={onChange}
                        name="emailOrPhone"
                        maxLength={100}
                        errorMsg={errFields.emailOrPhone}
                        // showError={errorState.email}
                        restrictFuntion={null}
                      />
                    </div>
                    <div className="mt-4 d-flex justify-content-center">
                      {" "}
                      <Button1
                        type="submit"
                        className="freedo-primary-button rounded-pill btn-block "
                        title="Notify Me"
                        style={{ position: "unset" }}
                        onClick={(e: any) => submitForm(e)}
                      />
                      <a
                        id="submitButton"
                        ref={clickRef}
                        data-bs-dismiss="modal"
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoonCityModal;
