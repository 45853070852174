import React, { useEffect } from "react";
import en from "../../locales/en.json";
import { getFAQList } from "../../redux/actions/masterActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const FAQs = () => {
  const { FAQs } = en.home;
  const dispatch = useDispatch();
  const faqList = useSelector((state: any) => state?.homeReducer.faqList);
  useEffect(() => {
    dispatch(getFAQList({}));
  }, []);
  return (
    <section className="faq-inner-wrapper faq-wrapper">
      <h2 className="text-center">{FAQs.title}</h2>
      <div className="container-fluid">
        <div className="faq-wrap">
          <div className="accordion" id="accordionExample">
            {FAQs.FaqsData?.slice(0, 3).map((item, ind) => (
              <div key={item.id} className="accordion-item">
                <h2 className="accordion-header" id={`heading${item.id}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${item.id}`}
                    aria-expanded={false}
                    aria-controls={`collapse${item.id}`}
                  >
                    {item.question}
                  </button>
                </h2>
                <div
                  id={`collapse${item.id}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading${item.id}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">{item.answer}</div>
                </div>
              </div>
            ))}
          </div>

          {faqList.length > 2 ? (
            <div className="d-flex justify-content-center faq-showmore">
              <Link
                to="/faq"
                className="freedo-outline-btn-small rounded-pill mb-3"
              >
                Show More
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default FAQs;
