import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { takeSelfie, uploadSelfie } from "../../../assets";
import { FirebaseEvents } from "../../../utills/firebaseEvents";
import {
  compressSizer,
  getLocalStorageData,
  handleMessage,
  imgFileReader,
} from "../../../utills/helper";
import { toastr } from "react-redux-toastr";
import kycServices from "../../../redux/services/kycServices";
import TakeSelfieModel from "../../../component/TakeSelfieModel";
import SuccessScreen from "./SuccessScreen";
import { getUserKycStatus } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { defaultHeaders } from "../../../config/axiosConfig";

const Selfie = ({ userKycStatusCheck }) => {
  const dispatch = useDispatch();
  const Token = getLocalStorageData("token");
  const [fileUri, setFileUri] = useState<any>("");
  const [srcUri, setSrcUri] = useState("");
  const [sThreeApiUrl, setSThreeApiUrl] = useState<any>({});
  const [isLoadingDocs, setIsLoadingDocs] = useState(false);
  const [selfieModal, setselfieModal] = useState(false);
  const selfieVerified = userKycStatusCheck?.selfieVerified;
  const webcamRef: any = React.useRef(null);

  useEffect(() => {
    const data = {
      docType: "Selfie",
      extension: "jpg",
    };
    requestSignedUrl(data);
  }, []);

  const requestSignedUrl = async (data) => {
    kycServices
      .getDLDocsignedurl({ docType: data.docType, extension: data.extension })
      .then((response: any) => {
        if (response && response.status === 200) {
          const s3url = response.data?.result?.data;
          setSThreeApiUrl(s3url);
        }
      });
  };
  const handleImageInput = () => {
    const ele = document.getElementById("image_upload");
    ele && ele.click();
  };

  const handleUploadClick = (event) => {
    var file = event?.target?.files[0];
    var fileType = file["type"];
    const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      toastr.error("", "Invalid file type");
      return;
    }
    imgFileReader(file, setSrcUri);
    setFileUri(file);
  };
  const captureSelfie = React.useCallback(async () => {
    setselfieModal(!selfieModal);

    const imageSrc: any =
      webcamRef?.current && webcamRef?.current?.getScreenshot();

    await fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file: any = new File([blob], "My Selfie");
        Resizer.imageFileResizer(
          file,
          1920,
          1080,
          "JPEG",
          compressSizer(file.size),
          0,
          (uri: any) => {
            imgFileReader(uri, setSrcUri);
            setFileUri(uri);
          },
          "file",
          200,
          200
        );
      });
    setselfieModal(false);
  }, [webcamRef]);

  const uploadImageS3 = async () => {
    if (fileUri) {
      setIsLoadingDocs(true);
      console.log("KycSelfieInitiation", {});
      FirebaseEvents("KycSelfieInitiation", {});
      try {
        fetch(sThreeApiUrl?.signedUrl, {
          method: "put",
          headers: new Headers({
            ...defaultHeaders,
            "Content-Type": "image/jpeg",
          }),
          body: fileUri,
        })
          .then((res) => {
            if (res.status == 200) {
              let data = {
                docType: "Selfie",
                image1: sThreeApiUrl?.key,
                token: Token,
              };
              saveImageData(data);
            }
          })
          .catch(function (error) {
            setIsLoadingDocs(false);
            toastr.error("Oops", handleMessage(error));
          });
      } catch (error) {
        setIsLoadingDocs(false);
        toastr.error(
          "",
          "Something went wrong, please upload image lesser than 5mb !"
        );
      }
    } else {
      toastr.warning("", "Please pick the image!");
    }
  };
  const saveImageData = async (data) => {
    kycServices
      .saveDLimageDataKyc(data)
      .then((response: any) => {
        if (response && response.status === 200) {
          const result = response.data?.result;
          toastr.success("", result.message);
          FirebaseEvents("KycSelfieUploadSuccess", {});
          console.log("KycSelfieUploadSuccess", {});
          dispatch(getUserKycStatus());
        }
        setIsLoadingDocs(false);
      })
      .catch(function (error) {
        toastr.error("Oops", handleMessage(error));
        console.log("KycSelfieUploadFailure", {});
        FirebaseEvents("KycSelfieUploadFailure", {});
        console.log("KycSelfieUploadFailure", {});
        setIsLoadingDocs(false);
      });
  };

  const handleSelfieModal = () => {
    setselfieModal(!selfieModal);
  };
  return (
    <div className="area" id="Selfie">
      {/* <!-- Successfully -upload-sec --> */}
      {selfieVerified ? (
        <SuccessScreen doc="Selfie" />
      ) : (
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-8">
            <div className="Selfie-wrap">
              {fileUri ? (
                <img
                  src={srcUri}
                  className="img-thumbnail selfie"
                  alt="Uploaded Selfie"
                />
              ) : (
                <>
                  <div className="take-selfie-wrap">
                    <div className="uselfie_card" onClick={handleImageInput}>
                      <input
                        accept="image/*"
                        type="file"
                        onChange={handleUploadClick}
                        style={{ display: "none" }}
                        id="image_upload"
                      />
                      <img src={uploadSelfie} alt="" />
                      <span>Upload a selfie</span>
                    </div>
                    <div>OR</div>
                    <div
                      className="tselfie_card camera"
                      data-bs-toggle="modal"
                      data-bs-target="#selfieModal"
                      onClick={handleSelfieModal}
                    >
                      <i
                        className="fa-solid fa-camera fa-fade"
                        role="button"
                      ></i>
                      <span>Take a selfie</span>
                    </div>
                  </div>
                  <p className="support-file">
                    Supported Formats: Jpg, Jpeg and Png
                  </p>
                </>
              )}
            </div>

            {fileUri && (
              <div className="after-upload-wrap">
                <h6 className="mb-2"> Uploaded Files </h6>
                <div className="afterload-file rounded">
                  {fileUri?.name}{" "}
                  <a onClick={() => setFileUri(null)}>
                    <i className="fa-solid fa-rotate-right" role="button"></i>
                  </a>
                </div>
              </div>
            )}
            <div className="KYC-button-wrap">
              <button
                type="submit"
                className="freedo-primary-button-small rounded-pill"
                onClick={uploadImageS3}
                disabled={!fileUri}
              >
                {isLoadingDocs ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
          <TakeSelfieModel
            heading={"Please take selfie"}
            webcamRef={webcamRef}
            selfieModal={selfieModal}
            captureSelfie={captureSelfie}
            handleModal={handleSelfieModal}
          />
        </div>
      )}
    </div>
  );
};

export default Selfie;
