import { RENT_ACTIONS } from "../actionTypes";
const {
  GET_VEHICLE_TYPE,
  SET_VEHICLE_TYPE,
} = RENT_ACTIONS;

export const getVehicleType = (payload: any) => ({
  type: GET_VEHICLE_TYPE,
  payload,
});

export const setVehicleType = (payload: any) => ({
  type: SET_VEHICLE_TYPE,
  payload,
});


export const setVehicleModel = (payload: any) => ({
  type: SET_VEHICLE_TYPE,
  payload,
});
