import { localeInterface } from "../../interfaces/actionInterfaces";
import { PROFILE_ACTIONS } from "../actions/actionTypes";

const initialState = {
  bookingList: [],
  bookingDetails: {},
  userProfileDetail: {},
  userKycStatus: {},
  otherDocsStatus: {},
  totalBooking: null,
  extendDetails: {},
  currentBooking: {},
  priceDetails: {},
  paginationData :{},
  supportQueriesList: []
};

const {
  SET_BOOKING_LIST,
  SET_BOOKING_DETAILS_BY_ID,
  SET_USER_PROFILE,
  SET_USER_KYC_STATUS,
  SET_ADDITIONAL_DOCS_STATUS,
  SET_EXTEND_DETAILS,
  SET_CURRENT_BOOKING,
  SET_PRICE_DETAILS_BY_ID,
  SET_PAGINATION_DATA,
  CLEAR_PAGINATION_DATA,
  SET_QUERIES_LIST
} = PROFILE_ACTIONS;
const profileReducer = (state: any = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    case SET_BOOKING_LIST:
      return {
        ...state,
        totalBooking: action.payload?.total,
        bookingList: action.payload?.bookingDetails,
      };
    case SET_BOOKING_DETAILS_BY_ID:
      return {
        ...state,
        bookingDetails: action.payload,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfileDetail: action.payload,
      };
    case SET_USER_KYC_STATUS:
      return {
        ...state,
        userKycStatus: action.payload,
      };
    case SET_ADDITIONAL_DOCS_STATUS:
      return {
        ...state,
        otherDocsStatus: action.payload,
      };
    case SET_EXTEND_DETAILS:
      return {
        ...state,
        extendDetails: action.payload,
      };
    case SET_CURRENT_BOOKING:
      return {
        ...state,
        currentBooking: action.payload,
      };
    case SET_PRICE_DETAILS_BY_ID:
      return {
        ...state,
        priceDetails: action.payload,
      };
    case SET_PAGINATION_DATA:
      return {
        ...state, 
        paginationData : action?.payload
      };

    case CLEAR_PAGINATION_DATA :
      return {
        ...state, 
        paginationData : {}
      }
      case SET_QUERIES_LIST :
      return {
        ...state, 
        supportQueriesList : action?.payload
      }
      
    default:
      return state;
  }
};
export default profileReducer;
