import Skeleton from "react-loading-skeleton"

export default () => {
    return <div className="complete-ride">
    <div className="content-wrap">
      <h6>
        <Skeleton count={1} height={20} width={150}/>
      </h6>
      <div className="date">
        <Skeleton count={1} height={20} width={150} />
      </div>
    </div>

    <div className="">
      <div className="">
        <Skeleton count={1} height={22} width={500} />
      </div>
      <div className="mt-2">
        <Skeleton count={1} height={22} width={500} />
      </div>
    </div>

    <div className="all-ride-details d-flex">
      <ul>
        <li><Skeleton count={1} height={15} width={50} /> </li>
        <li><Skeleton count={1} height={15} width={50} /> </li>
        <li><Skeleton count={1} height={15} width={50} /></li>
      </ul>

      <div className="text-end">
      <Skeleton count={1} height={30} borderRadius={"15px"}  width={100} />
      </div>
    </div>
  </div>
}