import en from "../locales/en.json";
import { useLocation } from "react-router-dom";
import { showSEOFooterRoutes } from "../constant/routeContants";
import { AppLink } from "../constant/enum";

const BikerentalsSEO = () => {
  const { footer } = en;
  const { pathname = "" } = useLocation();
  const showSEOFooter = showSEOFooterRoutes[pathname];

  return (
    <footer>
      {showSEOFooter ? (
        <div className="container-fluid">
          <hr />
          <h4>Explore Best Two-Wheelers On Rent</h4>
          <div className="freedo-city-SEO">
            <p>
              {" "}
              Getting around in busy cities such as Delhi NCR, Gurgaon,
              Bangalore, Noida, Vijayawada and Jaipur can be quite a task. Given
              the large crowds and busy streets, it can be pretty challenging.
              Rest assured, even for a short day excursion, renting a bike can
              be a mutually beneficial arrangement. Choosing scooter and bike
              rentals provides a convenient solution to the fast-paced city
              life. Whether you're zipping through the streets of Bangalore or
              discovering the lively lanes of Jaipur, renting a bike offers you
              the freedom to navigate the urban landscape effortlessly. Why not
              enhance your city adventure by opting for a rental bike?
            </p>
            <h4>
              <b>Get Premium Bike and Scooter Rentals at Freedo!! </b>
            </h4>
            <p>
              Freedo offer best bike rentals that are available for daily,
              weekly, and monthly basis for commutation. All of our two-wheelers
              are delivered by our diligent Freedo Champions. Our bikes undergo
              routine maintenance to ensure they provide the best value for
              money. Depending on your travel needs, you can rent a bike or
              scooter for different periods. It could be as follows:
            </p>{" "}
            <ul>
              <li> 24-Hour Rental</li> <li>48-Hour Rental</li>{" "}
              <li>72-Hour Rental</li>
              <li>Weekly</li>
              <li>Monthly</li> <li>Quarterly </li>
            </ul>
            Your two-wheeler rental cost depends on the duration and the model.
            Additionally, we offer customized bookings based on your
            requirements.
            <h4>
              <b>
                What sets Freedo Rental apart from other two-wheeler rental
                platforms?
              </b>
            </h4>
            The most important thing when renting a bike or scooter is having an
            affordable package, the newest model, and the ability to travel
            conveniently. All of these features are available with Freedo. You
            feel as though you are driving your own vehicle! Our bike and scooty
            rentals will allow you to explore new parts of the city and enjoy a
            weekend getaway more freely.
            <br />
            <h4>
              <b>The Freedo Edge</b>
            </h4>
            <div className="freedo-city-SEO-1">
              <p>
                <b>
                  1. Not letting the hassle get in the way of owning a vehicle!
                </b>
                <br />
                Many travelers find owning a personal vehicle to be a hassle.
                From long documentation process to queing up in RTOs all these
                processes are time consuming. Maintenance and repair services as
                well are needed to be provided on a timely basis. With our
                two-wheeler rental services, you won't have to worry about these
                hassles. <br /> Renting a bike or scooter allows you to ride
                them anywhere without worrying about maintenance or ownership
                issues. You can even renew the contract if you intend to use the
                vehicle for an extended period.
              </p>
              <p>
                <b>2. A wide range of two-wheeler models to choose from</b>{" "}
                <br />
                You must be interested in riding a bike of your choice. We
                understand these needs and offer a wide selection of bike and
                scooty rentals to our customers at Freedo. Our company provides
                a variety of scooter and bike rentals to suit your needs!
                <br />
                You can choose from various models like the Hero Splendor, Hero
                Pleasure Plus, Royal Enfield Classic 350, the Xtreme 160 R
                Stealth, and many more! Also, these rented new bikes cost less
                than the new ones. At Freedo, you can choose the model that
                suits you best!
              </p>{" "}
              <p>
                <b>
                  3. Faster, easier booking of bike rentals on the Freedo App
                </b>{" "}
                <br />
                Our vision at Freedo is to remove roadblocks between mobility
                and user. With Freedo: Scooty and Bike rentals App, you can
                easily <a href="/rent">book a bike </a> or scooter hassle-free
                and at the convenience of your home. Select the vehicle and
                package that suits your travel needs. You're done! You can rely
                on us for an enjoyable ride.
              </p>
              <p>
                <b>4. Affordable packages</b>
                <br /> You can spend a lot of money on travel in metropolitan
                cities even if you have a pre planned budget. You'll need to pay
                more for better service, whether food or taxi fares. High taxi
                fares will cost you a lot of money. Spend your money wisely if
                you want to enjoy the best travel destinations. You can rent a
                two-wheeler within your budget.
                <br />
                With Freedo Rentals, you can rent a two-wheeler at affordable
                rates. Our mission is to make daily commutes economical for all
                our riders. Renting a bike or scooter doesn't require you to
                spend much money upfront. Save money, have fun, and save time by
                renting two-wheelers using our two-wheeler rental app.
              </p>
              <p>
                <b>5. Expert assistance in choosing bike rentals</b> <br />{" "}
                Choosing a suitable bike model for a travel journey can be
                challenging. Freedo Rentals offers an expert team of
                professionals who help you select the suitable bike model for
                your travel journeys. No matter what concern you have, we will
                address it diligently!
                <br />
                You can reduce your dependence on personal vehicles by riding
                self-driving two-wheelers. Choose your two-wheeler from our bike
                and scooter rentals services and ease your travel hassle
                greatly.
              </p>
            </div>
            <h4>
              <b>
                What makes Freedo Rentals a reliable option for bike and scooter
                rentals?
              </b>
            </h4>
            <p>
              Freedo understands the diverse mobility demands of today’s
              travelers. By offering smart, affordable, and accessible mobility
              options to riders with our reliable{" "}
              <a href="/rent">two wheeler on rent</a>, we make travel within the
              city easy, affordable, convenient, and smooth.
              <br />
              We offer an excellent opportunity for all travel enthusiasts
              looking forward to traveling comfortably, conveniently, and
              affordably across the city. With our rented bikes, you can explore
              the town without worrying about vehicle maintenance hassles and
              enjoy a joyful ride experience.
              <br />
              We deliver a perfect mobility solution to our riders by offering
              safe and hassle-free travel opportunities. Daily commutes within a
              city or outdoor travel do not require owning a vehicle and
              incurring unnecessary maintenance expenses. If you're looking to
              rent a scooty, you've come to an ideal place.
              <br />
              Our bike and <a href="/rent">scooter rental services</a> are
              perfect for adventure enthusiasts who enjoy biking and exploring
              new places. Travelers can choose from a variety of two-wheelers to
              suit their mobility needs. Besides offering scooter and bike
              rentals for travel, we also offer them for personal and work
              commutes in other cities as well.
            </p>
            <h4>
              <b>
                Freedo Rentals brings you scooter rentals at an affordable
                price!
              </b>
            </h4>
            <p>
              There is no need to own a car in the city to reach the places you
              want to visit. If you want to visit family-friendly attractions in
              the city, you must go ahead with bike and scooter rentals. It will
              make your travel journeys smooth, seamless, and convenient
              experience.
              <br />
              If you are looking for a premium bike and scooty rental in Noida,
              Freedo Rentals can offset your travel demands with their
              <a href="/rent"> two-wheeler rentals</a>. With an impressive range
              of bikes and scooters like Classic 350, Destini 125, Maestro Edge
              110, Glamour, etc., we satisfy our riders' expectations.
              <br />
              Next time you book a bike or scooter rental, head to Freedo
              Rentals!
            </p>
            <h4>
              <b>
                Book Bike and Scooty rentals In Delhi, Gurgaon, and Bangalore
                and many more cities at Freedo
              </b>
            </h4>
            <ul>
              <b>VARIOUS CITIES WE SERVE WITH OUR FLEET</b>
              <li>
                <b>Jaipur</b>: Rent a bike or scooty in Jaipur and explore the
                pink city in stay.
              </li>
              <li>
                <b>Gurgaon</b>: Whether it's for business or leisure, we offer
                bike rentals In Gurgaon for your travel needs.
              </li>
              <li>
                <b>Bangalore</b>: From scooty to bike rentals, get everything
                you need for a smooth ride in the garden city.
              </li>

              <li>
                <b>Delhi</b>: Unleash your inner adventurer on rides with our
                scooter rentals in Delhi.
              </li>

              <li>
                <b>Noida</b>: Buckle up for fun with our bike and scooter
                rentals in Noida for your next trip.
              </li>
              <li>
                <b> Vadodara</b>: Embrace the ease of user bike rentals in
                Vadodara. Two Wheels, One Solution.
              </li>
              <li>
                <b>Ghaziabad</b>: Discover Ghaziabad hidden gems with the best
                bike rentals in town.{" "}
              </li>
              <li>
                <b>Vijayawada</b>: Explore the Vijayawada city in style with our
                bike rentals.
              </li>
            </ul>
            <h4>
              <b>Easy to Book Bike and Scooter Rentals at Freedo</b>
            </h4>
            <p>
              Explore the metro cities and experience the ultimate convenience
              with Freedo's easy-to-book bike and scooter rentals. With just a
              few taps on our user-friendly bike rental app, you can unlock
              endless possibilities for exploring the metropolis cities.
              <br />
              No more hassle or waiting in long lines—simply choose your ride,
              book it instantly, and hit the road in your style. Whether you're
              commuting to work, meeting friends, or enjoying a leisurely ride,
              Freedo makes it easy. With a fleet of well-maintained bikes and
              scooters, and reasonable pricing, your journey with Freedo is
              always smooth and stress-free.
              <br />
              Say goodbye to long queues and paperwork – our streamlined process
              puts you in the driver's seat in no time. Discover the freedom of
              mobility with Freedo and make every journey an adventure. Download
              the Freedo app today and let the exploration begin!
            </p>
            <h4>
              <b>How to Book Bike and Scooter rentals At Freedo</b>
            </h4>
            <p>
              Travel the city without any concerns with Freedo Rentals. Our
              affordable two-wheelers on rent make getting around easy. No more
              worries about public transport – just download the Freedo app and
              you're all set. Ditch the crowded buses and trains, as you can
              travel on your own terms. We offer the latest models, so picking
              your ride is simple. Enjoy easy, convenient travel with Freedo
              Rentals.
              <br />
              Our<a href="/rent"> two-wheeler rental services</a> simplify the
              commute for travelers and help them save time and money. To make
              online booking of two-wheeler rentals quick, easy, and smooth, we
              have developed a bike rental app on both{" "}
              <a href={AppLink.playstoreLink}>Google Play</a> and the{" "}
              <a href={AppLink.appStoreLink}>App Store</a>.
              <br />
              With our scooter and bike rental app, you can manage your account,
              find nearby bike stations, reserve bikes, and pay securely. You
              can book your preferred <a href="/rent">scooty rentals</a> at
              Freedo by following these steps:
            </p>
            <ul>
              <li>Sign up.</li>
              <li>Select pickup location and date.</li>
              <li>Choose a bike model.</li>
              <li>Add accessories if needed.</li>
              <li>Review cart.</li>
              <li>Complete booking and payment.</li>
              <li>Opt for home delivery or pickup.</li>
              <li>Return bike after rental.</li>
            </ul>
            <p>
              At Freedo Rentals, you will not only find your favorite bike, but
              you'll also have a family to share your travel experiences with.
              What are you waiting for? Book your next bike and{" "}
              <a href="/rent">scooter rentals</a> with us and make your travel
              journey a ride to remember. <br />
            </p>
            <div className="footer-loaction-wrap"></div>
          </div>
        </div>
      ) : null}

      <div className="copyright-wrap">{footer["2023FreedoRentals"]}</div>
    </footer>
  );
};

export default BikerentalsSEO;
