import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import EndRideModal from "./EndRideModal";
import ExtendRideModal from "./ExtendRideModal";
import { modifyRide, noCurrentBooking, upcoming, upcomingBooking } from "../../../assets";
import moment from "moment";
import OnGoingSkelaton from "../../../component/SkelatonLoader/OnGoingSkelaton";
import {
  getBookingDetails,
  getCurrentBooking,
} from "../../../redux/actions/rentActions/bookingAction";
import { commmaSeparator, getEncryptedText } from "../../../utills/helper";
import { STATUS, WAREHOUSE_STATUS } from "../../../constant/enum";
import { Routes } from "../../../constant/routeContants";
import { toastr } from "react-redux-toastr";
import { processOrder, verifyOrderPayment } from "../../../redux/actions";
import { RAZORPAY_KEY } from "../../../config/apiUrls";
import  BookingFeedbackModal  from "../../../component/BookingFeedbackModal";
import en from "../../../locales/en.json";
const { Ongoing, Upcoming, Cancelled, Pending } = STATUS;
const { Rejected, Accepted, Inprocess } = WAREHOUSE_STATUS;
var CryptoJS = require("crypto-js");

const OnGoingRide = ({ currentTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentBooking, bookingList } = useSelector(
    (state: any) => state?.bookingReducer
  );
  const { userProfileDetail } = useSelector(
    (state: any) => state.profileReducer
  );
  const [sklFlag, setSkl] = useState(true);
  const [modelFlag, setModalFlag] = useState({
    endRide: false,
    extendRide: false,
  });
  const handleClick = (e, keyname: string) => {
    e.preventDefault();
    setModalFlag((prev) => ({ ...prev, [keyname]: true }));
  };
  useEffect(() => {
    dispatch(getCurrentBooking({}));
  }, [currentTab]);
  useEffect(() => {
    setTimeout(() => {
      setSkl(false);
    }, 500);
  }, [currentBooking]);
  const { endRide, extendRide } = modelFlag;
  const {
    amount,
    bike_name,
    branch_id,
    branch_name,
    city_id,
    city_name,
    drop_off_location,
    end_date_time,
    id,
    location,
    pickup_location,
    rental_days,
    start_date_time,
    status,
    warehouse_status,
    additional_charges,
    mode_of_pick_up_id,
    is_modified
  } = currentBooking;
  const handleUpcoming = (e) => {
    e.preventDefault();
    let object = {
      bookingId: currentBooking["id"],
      from: "currentBooking",
    };
    let encrypt = getEncryptedText(JSON.stringify(object));
    navigate(`/booking-details/${encrypt}`);
  };
  const encryptRazorPay = (plainText: any) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(plainText),
      "@dmin"
    ).toString();

    return ciphertext;
  };
  
  const callOrderApi = async (amount, paidFor, helpData, stopLoader) => {
    let tempAmount = parseFloat(amount).toFixed(2);

    let responseData = {
      data: encryptRazorPay({
        amount: Number(tempAmount),
        bookingId: id,
        userId: userProfileDetail?.id,
        paidFor: paidFor,
        helpData: helpData ? helpData : {},
        branchId: branch_id,
      }),
    };
    dispatch(
      processOrder({
        payload: responseData,
        callBack: handleProcessOrderCallback,
        handleLoader: stopLoader,
      })
    );
  };
  const handleProcessOrderCallback = (data: any, stopLoader) => {
    displayRazorpay(data.Result.id, data.Result.amount, stopLoader);
  };

  const loadScript = (src: any) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (orderid: any, amount: any, stopLoader) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      stopLoader();
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    } else stopLoader();
    const options = {
      key: RAZORPAY_KEY,
      amount: parseInt(amount),
      currency: "INR",
      timeout: 300,
      name: "Freedo Rentals",
      order_id: orderid,
      description: "Credits towards Freedo Rentals",
      prefill: {
        name: userProfileDetail?.first_name,
        email: userProfileDetail?.email_id,
        contact: userProfileDetail?.mobile_number,
      },
      theme: {
        color: "#2BC7C9",
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: "All payment methods",
              instruments: [
                {
                  method: "upi",
                },
                {
                  method: "card",
                },
                {
                  method: "wallet",
                },
                {
                  method: "netbanking",
                },
                {
                  method: "emi",
                },
                {
                  method: "cardless_emi",
                },
              ],
            },
          },
          sequence: ["block.banks"],
          preferences: {
            show_default_blocks: false,
          },
        },
      },

      handler: async function (response: any) {
        console.log("RESPONSE>>>", response);
        const reqPayload = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          //razorpay_signature: response.razorpay_signature,
        };
        dispatch(
          verifyOrderPayment({
            payload: reqPayload,
            callBack: paymentVerificationCallback,
          })
        );
      },
    };
    console.log("options", options);
    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  };
  const paymentVerificationCallback = (response: any) => {
    if (response?.data) {
      if (response.data?.Result?.paymentStatus == "pending") {
        window.open(
          `${process.env.PUBLIC_URL}/payment-pending-web/${response.data.Result.orderId}`
        );
      } else {
        toastr.success("Success", "Payment successful");
        const initPagination = {
          limit: 2,
          page: 1,
          status: "",
        };
        dispatch(getCurrentBooking({}));
        dispatch(getBookingDetails(initPagination));
      }
    } else {
      toastr.error("Oops", response?.data?.result?.Message);
    }
  };
  const handleFeedbackSubmit = ()=>{
    dispatch(getCurrentBooking({}));
  }
  const handleNavigate = (e)=>{
    e.preventDefault()
    navigate(Routes.RENT)
  }
  return (
    <>
      {/* {endRide && (
        <EndRideModal
          onHide={setModalFlag}
          bookingDetailsById={currentBooking}
          callOrderApi={callOrderApi}
        />
      )} */}
      {extendRide && (
        <ExtendRideModal
          onHide={setModalFlag}
          bookingDetailsById={currentBooking}
          from="currentBooking"
        />
      )}

      <div
        className={`col-md-${
          currentBooking?.message === "No booking Found" &&
          bookingList?.message === "No booking Found"
            ? "12"
            : "6 -pe-1"
        }`}
      >
        {sklFlag ? (
          <OnGoingSkelaton />
        ) : (
          <>
            {" "}
            {currentBooking?.message === "No booking Found" ? (
              <>
                <div>
                  <div className="mx-auto text-center">
                    <img
                      src={noCurrentBooking}
                      className="no-booking-img"
                      alt="No Booking Found"
                    />
                    <p className="mt-3">You have not booked any rides</p>
                    <span className="text-muted">
                      Would you like to plan your next ride with us ?
                    </span>
                    <h4 className="mt-2 no-booking-found">
                      Explore Renting Fleet
                    </h4>
                    <div className="mt-5">
                      <a
                        onClick={handleNavigate}
                        className="freedo-primary-button rounded-pill mt-5"
                      >
                        Explore Fleet
                      </a>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
              {currentBooking?.feedbackStatus === en.feedback.feedback_status_pending && <BookingFeedbackModal bookingId={currentBooking["id"]} handleSubmitCallback={handleFeedbackSubmit}/>}

              {/* { <BookingFeedbackModal bookingId={currentBooking["id"]} handleSubmitCallback={handleFeedbackSubmit}/>} */}
                <a
                  href="#"
                  className="ongoing-ride-box d-block"
                  onClick={handleUpcoming}
                >
                  
                  <img src={upcomingBooking} alt="upcoming freedo ride" />
                  <span
                    className={
                      status === Upcoming && warehouse_status === Inprocess
                        ? "approval"
                        : status?.toLowerCase()
                    }
                  >
                    {status === Upcoming && warehouse_status === Inprocess
                      ? "Approval In Progress"
                      : status}{" "}
                    Ride
                  </span>
                </a>
                <div className="upcoming-details">
                <div className={`content-wrap ${is_modified && "content-wrap-modified"}`}>
                    <h6>{bike_name}</h6>
                    <div className="date">
                      <span>
                        {moment(start_date_time).format("DD MMM YYYY")}
                      </span>
                      <span className="hr-border"></span>
                      <span className="active">
                        {moment(end_date_time).format("DD MMM YYYY")}
                      </span>
                    </div>
                  </div>
                  {is_modified && (
                    <div className="text-end mb-1">
                      <img className="p-0" src={modifyRide} />
                    </div>
                  )}
                  <div className="ride-address">
                    <div className="pickup-ride">
                      <span>
                        {mode_of_pick_up_id == 2
                          ? "Pick-Up Address:"
                          : "Home Delivery Address:"}
                      </span>

                      {pickup_location}
                    </div>
                    <div className="drop-ride active">
                      <span>Drop Location:</span>
                      {!drop_off_location ? "-NA-" : drop_off_location}
                    </div>
                  </div>

                  <div className="all-ride-details d-flex">
                    <ul>
                      <li>{rental_days} </li>
                      <li>{city_name} </li>
                      <li> ₹ {commmaSeparator(Math.round(amount))}/-</li>
                    </ul>

                    {status?.toLowerCase() == Ongoing.toLowerCase() && (
                      <div className="text-end">
                        {/* {!additional_charges && (
                          <Link
                            to="#"
                            className="border rounded-pill danger-btn text-danger border-danger"
                            onClick={(e) => {
                              handleClick(e, "endRide");
                            }}
                          >
                            End Ride
                          </Link>
                        )} */}

                        <Link
                          to="#"
                          className="freedo-primary-button rounded-pill text-dark"
                          onClick={(e) => {
                            handleClick(e, "extendRide");
                          }}
                        >
                          Extend Ride
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default OnGoingRide;
