import React from 'react';
import { mission } from '../../assets';

const OurMission = () => {
    return (
        <div className="mission-wrap">
            <div className="row align-items-center">
                <div className="col-lg-7 col-md-7 col-sm-6 col-12">
                    <div className="content-wrap" id="our-mission">
                        <h2>Our Mission</h2>
                        <p className='text-justify'>
                            To constantly evolve and provide micro mobility solutions by solving the problem of daily transportation. 
                            <br /><span className='font-italic'>"Helping commuters and travelers get to exactly where they need to go through innovative solutions"</span>
                        </p>
                        <h6>Choose Freedom to Move, Choose Freedo !</h6>
                        {/* <a href="#" className="freedo-primary-button rounded-pill">
                            Blogs
                            <i className="fa-solid fa-right-long"></i>
                        </a> */}
                    </div>
                </div>

                <div className="col-lg-5 col-md-5 col-sm-6 col-12">
                    <img src={mission} alt="Freedo's Mission" />
                </div>
            </div>
        </div>
    );
};

export default OurMission;
