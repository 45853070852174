import config from "./config.json";
import { NODE_ENV } from "./envConfig";
const configEnv: any = config;
const env: any = NODE_ENV;
const BASE_URL = configEnv[env]["BASE_URL"];
const WEB_URL = configEnv[env]["WEB_URL"];
const BASE_URL_CUSTOMER = configEnv[env]["BASE_URL_CUSTOMER"];
const oldVersion = 'v1/'
const versioning = "v2/";
const LatestVersion = "v5/";
const kyc = "kyc/";
const RAZORPAY_KEY = configEnv[env]["RAZOR_PAY_KEY"];
const FeedbackAPIExtenstion = "/dragon-buzz/";
const API_URLS = {
    sendOTP: "sendOTP",
    verifyOTP: "verifyOTP",
    getMaster: "getMaster",
    packageMaster: "package_master",
    getVehicleModelListForCustomer: "vehicle/model-list",
    getCityList: "city/0",
    getCityById: "getCityById",
    getBookingList: "booking/",
    getBookingDetailsById: "booking/",
    vehicleType: "vehicle_type",
    sendOtpForSignUp: "sendOtpForSignUp",
    signupWithOtp: "signupWithOtp",
    userProfile: "user/get",
    updateUserProfile: "user/update",
    relationList: "relations",
    userLogout: "logout",
    getVehicleModelListDetails: "vehicle/model-detail",
    getFaqList: "public/faq",
    postContact: "public/createContactDetail",
    addAddress: "addAddress",
    deleteAddress: "deleteAddress",
    updateAddress: "updateAddress",
    extendDetails: "booking/extentionPaymentDetails/",
    getCurrentBooking: "booking/current-booking/",
    endRide: "endRide/",
    advertisement: "advertisement",
    getExtendPackage: "plans/extentions/",
    getBookingPriceById: "booking/booking-price/",
    sendEmergencyNumberOTP: "user/send-otp",
    verifyEmergencyNumberOTP: "user/verify-otp",
    getDocumentDetails: "document/",
    getBranch: "branch/",
    extendRide: "extendride",
    findNearestCity: 'public/location/findNearestCity',
    notifyMe: 'public/notifyMe',
    uploadOdometerReadings: 'uploadOdometerReadings',
    payExtraCharges: 'payExtraCharges',
    getVehicleDetails: 'public/vehicle/getVehicleDetails',
    getAllMitcIds: 'mitc/getAllMitcIds',
    getMitcHtmlContent: 'public/mitc/getMitcHtmlContent',
    sendOtpForMitc: 'public/mitc/sendOtpForMitc',
    verifyMitcForm: 'public/mitc/verifyMitcForm',
    getDelAccOptions: 'del_account_options',
    sendOtpForDelAcc: 'public/v2/sendOtpForDeleteAcoount',
    changeAccStatus: 'public/v2/changeAccountStatus',
    cancellationCharge: 'cancellation-charge/',
    cancelBooking: 'cancel-booking/',
    checkRefundStatus: 'checkRefundStatusV2/',
    getBookingStatus: 'getBookingStatus/',
    modificationCharges: 'modificationCharges/',
    modifyBooking: 'modifyBooking',
    getModelAvailableDates: 'getModelAvailableDates',
    bookingChangeCharges: 'bookingChangeCharges',
    getIssueCategoryList: 'getMaster/issue_category/0',
    saveHelpDesk: 'helpDesk',
    getUserProfileSignedUrl: 'public/getSignedUrl',
    SubmitFeedbackForm: 'feedback/v1',
    getFeedbackFormByBookingId: 'feedback/v1/form/'
};
const CHECKOUT_API_URLS = {
    getCouponList: "getcouponsV2",
    getCustomer: "getCustomer",
    getBranchSlotsForBooking: "getBranchSlotsForBooking",
    getHomeDeliveryCharges: "getBranchDeliveryCharges",
    getHomeAddress: "getAddress/all",
    validateUserEmergency: "validateUserEmergency",
    storeBookingAppV4: "storeBookingAppV4",
    storeBookingV1: "v1/storeBooking",
    processOrder: "payment/order",
    verifyBookingPayment: "payment/verificationRes",
};
const KYC_API_URLS = {
    getDlSignedurl: "getSignedUrl",
    generateDigiLockerUrlWeb: "aadhaar/generateDigiLockerUrlWeb",
    userCheckKYC: "userKycChecks",
    saveDlImageData: "saveImageData",
    checkUserDocumentStatus: "manualKyc/customer/checkUserDocumentStatus",
    createAdditionalDocs: "manualKyc/customer/createAdditionalDocs",
};
export {
    BASE_URL,
    BASE_URL_CUSTOMER,
    versioning,
    LatestVersion,
    oldVersion,
    API_URLS,
    CHECKOUT_API_URLS,
    KYC_API_URLS,
    kyc,
    RAZORPAY_KEY,
    WEB_URL,
    FeedbackAPIExtenstion
};
