import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detectLoactionVideo, targetLogo, vadodara } from "../../assets";
import CityBadge from "../../component/CityBadge";
import ComingSoonCityModal from "../../component/ComingSoonCityModal";
import { LocalStorage } from "../../constant/enum";
import en from "../../locales/en.json";
import {
  getCityListMasters,
  getNearestCity,
  saveSelectedCity,
  toggleCityModal,
} from "../../redux/actions";
import { RootState } from "../../redux/reducers";
import { FirebaseEvents } from "../../utills/firebaseEvents";
import { getLocalStorageData, setLocalStorageData } from "../../utills/helper";

interface ICity {
  city_name: string;
}
interface ISelectCityModal {
  handleChange: (selectedCity: ICity) => void;
}

const SelectCityModal = ({ handleChange }: ISelectCityModal): JSX.Element => {
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const { cityList, detectlocationloader, isCitySelected } = useSelector(
    (state: RootState) => state.homeReducer
  );
  const { userData } = useSelector((state: RootState) => state.authReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cityList.length) {
      dispatch(getCityListMasters({ type: "GetCityList", payload: {} }));
    }
  }, []);

  const { homePageCarousal } = en.home;
  const getSelectedCity = (indx: number) => {
    let firebaseObject: any = {
      city: cityList[indx]?.city_name,
    };
    if (userData?.mobile_number) firebaseObject.mobile = userData.mobile_number;
    console.log("SelectCity", firebaseObject);
    FirebaseEvents("SelectCity", firebaseObject);
    setLocalStorageData(LocalStorage.userCity, cityList[indx]);
    dispatch(saveSelectedCity(cityList[indx]));
    dispatch(toggleCityModal(false));
    handleChange(cityList[indx]);
  };

  const filterCommingSoonCity = (cityData) => {
    let data = getLocalStorageData(LocalStorage.zohoLeads);
    let filteredData = data?.filter(
      (city: any) => city?.cityId == cityData.id
    )[0];
    if (filteredData) return "";
    return "#exampleModalToggle2";
  };

  useEffect(() => {
    if (detectlocationloader) {
    } else {
      handleModalClose();
    }
  }, [detectlocationloader]);

  const handleModalClose = () => {
    let ele = document.getElementById("modal-close-button");
    if (ele) ele.click();
  };

  const handleDetectLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          if (position.coords) {
            let payload = {
              latitude: position.coords?.latitude,
              longitude: position.coords?.longitude,
            };
            dispatch(getNearestCity(payload, cityList, handleSuccess));
          }
        },
        function (error) {
          alert(error.message);
        }
      );
    } else {
      alert("Location not available");
    }
  };

  const handleSuccess = (cityData) => {
    if (handleChange) handleChange(cityData);
    setLocalStorageData(LocalStorage.userCity, cityData);
  };

  const [isUpcomingModal, setIsUpcomingModal] = useState<boolean>(false);
  const DetectLocationButton = ({ onClick }) => (
    <button
      type="button"
      className="light-freedo-btn rounded-pill detect-btn"
      onClick={onClick}
    >
      <img src={targetLogo} alt="" className="detect-btn-img" />
      {homePageCarousal.DetectMyLocation}
    </button>
  );

  const DetectMyLocationScreen = () => (
    <>
      <div className="modal-header d-flex justify-content-center">
        <h4 className="modal-title" id="exampleModalLabel">
          {`Detecting your Location`}
        </h4>
      </div>
      <button
        type="button"
        className="btn-close"
        id="modal-close-button"
        data-bs-dismiss="modal"
        aria-label="Close"
        style={{ display: "none" }}
      ></button>
      <div className="modal-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-center mb-1">
            <img width="160px" height={"160px"} src={detectLoactionVideo} />
          </div>
        </div>
      </div>
    </>
  );

  const ShowCityList = ({ homePageCarousal }) => (
    <div className="modal-header">
      <h4 className="modal-title" id="exampleModalLabel">
        {homePageCarousal.SelectCity}
      </h4>

      {cityData && (
        <button
          id="close-btn"
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => dispatch(toggleCityModal(false))}
        ></button>
      )}
    </div>
  );
  return (
    <>
      <div
        className="modal fade"
        data-bs-backdrop={cityData ? "none" : "static"}
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex={-1}
      >
        <div
          className={`modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl mx-auto ${
            isUpcomingModal ? "coming-soon-city-modal" : ""
          }`}
        >
          <div className="modal-content">
            <>
              {!(detectlocationloader || isUpcomingModal) && (
                <>
                  <div className="modal-header">
                    <h4 className="modal-title" id="exampleModalLabel">
                      {homePageCarousal.SelectCity}
                    </h4>

                    {cityData && (
                      <button
                        id="close-btn"
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => dispatch(toggleCityModal(false))}
                      ></button>
                    )}
                  </div>

                  <div className="modal-body">
                    <div className="container">
                      {cityList.length ? (
                        <DetectLocationButton onClick={handleDetectLocation} />
                      ) : null}

                      {/* {!cityData && (
                      <div className="text-danger">
                        *Please select the city first.
                      </div>
                    )} */}
                      <div className="row citylist-container">
                        {cityList.map((item: any, indx: number) =>
                          item.status === 1 ||
                          item.status === 5 ||
                          item.status === 6 ? (
                            <>
                              <CityBadge
                                ind={indx}
                                getSelectedCity={getSelectedCity}
                                item={item}
                                cityData={cityData}
                                setIsUpcomingModal={setIsUpcomingModal}
                                filterCommingSoonCity={filterCommingSoonCity}
                              />
                            </>
                          ) : (
                            null
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* detect my location pop up vedio */}
              {detectlocationloader && <DetectMyLocationScreen />}
            </>
          </div>
        </div>
        {/* </Modal> */}
      </div>
      <div
        className="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content coming-new-city-content">
            <div className="coming-soon-city">
              <ComingSoonCityModal />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectCityModal;
