import axios from "axios";
import {
  API_URLS,
  BASE_URL,
  BASE_URL_CUSTOMER,
  KYC_API_URLS,
  LatestVersion,
  oldVersion
} from "../../config/apiUrls";
import { Axios, customAxios } from "../../config/axiosConfig";


const getUserProfile = () => {
  return customAxios.get(BASE_URL_CUSTOMER + LatestVersion +API_URLS.userProfile);
};
const getRelationList = () => {
  return customAxios.get(BASE_URL_CUSTOMER + API_URLS.relationList);
};
const getUserKycStatus = () => {
  return customAxios.get(BASE_URL + `/${KYC_API_URLS.userCheckKYC}`);
};
const getDocumentsStatus = () => {
  return customAxios.get(BASE_URL + `/${KYC_API_URLS.checkUserDocumentStatus}`);
};
const createAdditionalDocs = (params: any) => {
  return customAxios.post(
    BASE_URL + `/${KYC_API_URLS.createAdditionalDocs}`,
    params
  );
};
const uploadFilesOnS3 = (url, params: any) => {
  return Axios.put(url, params, {
    headers: {
      "Content-Type": "image/jpeg",
    },
  });
};
const postProfileUpdate = (params: {}) => {
  return customAxios.put(
    BASE_URL_CUSTOMER + LatestVersion + API_URLS.updateUserProfile,
    params
  );
};
const sendOtpEmegencyNumber = () => {
  return customAxios.put( BASE_URL_CUSTOMER + LatestVersion + `${API_URLS.sendEmergencyNumberOTP}`);
};
const verifyOtpEmegencyNumber = (params: {}) => {
  return customAxios.put(
    BASE_URL_CUSTOMER + LatestVersion + API_URLS.verifyEmergencyNumberOTP,
    params
  );
};
const getSupportIssuesList = () => {
  return customAxios.get(BASE_URL_CUSTOMER + API_URLS.getIssueCategoryList );
};
const saveHelpDeskQuery = (params: {}) => {
  return customAxios.post(
    BASE_URL_CUSTOMER + oldVersion + API_URLS.saveHelpDesk,
    params
  );
};
const getUserProfileSignedUrl = async (params:{})=>{
  const baseUrl = BASE_URL_CUSTOMER + API_URLS.getUserProfileSignedUrl
  try {
    return await customAxios.post(baseUrl, params);
} catch (ex) {
    return ex;
}
}
export default {
  getUserProfile,
  getRelationList,
  getUserKycStatus,
  getDocumentsStatus,
  createAdditionalDocs,
  uploadFilesOnS3,
  postProfileUpdate,
  sendOtpEmegencyNumber,
  verifyOtpEmegencyNumber,
  getSupportIssuesList,
  saveHelpDeskQuery,
  getUserProfileSignedUrl
};
