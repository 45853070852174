import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { validate , hindiTextValidation} from "../../../../utills/helper";
import "./feedback.css";
import { Modal } from "react-bootstrap";
import {
  Rating,
  ThinStar,
  Heart,
  RoundedStar,
  Star,
  StickerStar,
} from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import { Button1 } from "../../../../component/Button";
import bookingServices from "../../../../redux/services/bookingServices";
import { toastr } from "react-redux-toastr";
import en from "../../../../locales/en.json";
import { RootState } from "../../../../redux/reducers";
import { feedbackSide, sadFeedback, loveFeedback, stisFeedackSmall, loveFeedbackSmall, sadFeedbackSmall } from "../../../../assets";
import { Routes } from "../../../../constant/routeContants";
import { handleModalClose } from "../../../../utills/helper";
import { arrStarBasedData, CustomStar } from "../../../city-freedo/constants/constants";

const myStyles = {
  itemShapes: CustomStar,
  activeFillColor: "#26e8c8",
  inactiveFillColor: "#E9E9E9",
};

const Index: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTagsList, setSelectedTagsList] = useState<any[]>([]);
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAllBooking, setShowAllBooking] = useState(false);
  const [RatingData, setRatingData] = useState<any>(null);
  const [ratingQuestion, setRatingQuestion] = useState<any>(null);
  const [reviewQuestion, setReviewQuestion] = useState<any>(null);
  const { isAuthenticated } = useSelector(
    (state: RootState) => state.authReducer
  );
  const params = useParams();
  const navigate = useNavigate();
  // console.log(params, "params");
  window.onpopstate = function () {
    handleModalClose("modal-backdrop");
  };
  useEffect(() => {
    window.scrollTo(0,0)
    if (isAuthenticated) {
      if (params.id && Number(params.id)) {
        bookingServices
          .getFeedbackFormByBookingId({ id: params.id })
          .then((resp: any) => {
            if (resp?.data?.IsSuccess) {
              setRatingData(resp?.data?.Result)
              setShowAllBooking(false)
              if(resp?.data?.Result?.questions?.length){
                const ratingQustion = resp?.data?.Result?.questions?.filter((e:any)=> e?.type === "rating")
                setRatingQuestion(ratingQustion.length ? ratingQustion[0]: null)
                const reviewQustion = resp?.data?.Result?.questions?.filter((e:any)=> e?.type === "input")
                setReviewQuestion(reviewQustion.length ? reviewQustion[0]: null)
              }
              window.scrollTo(0,0)
            } else {
              toastr.error("", resp?.data?.Message);
              setShowAllBooking(true)
            }
          })
          .catch((err: any) => {
            toastr.error("",err?.response?.data.Message ? err?.response?.data.Message :  en.errorMessages.SomethingWentWrong);
            console.log(err);
            setShowAllBooking(true)
          });
      } else {
        toastr.error("", en.errorMessages.SomethingWentWrong);

      }
    } else {
      toastr.error("", en.feedback.loginRequired);
    }
  }, [isAuthenticated]);

  const handleRatingChange = (rate: any) => {
    // debugger;
    // setRating(rate);
    // setShowModal(true)
    if(rating === 0){
    submitRating(false, rate, selectedTagsList)
    }
  };
  
  const handleClose = () => {
    setShowModal(false);
    navigate(Routes.HOME);
    // window.location.replace("/");
    
  };
  const submitRating = (isSubmit:boolean, ratings:any, tagLists:any) => {
    
    if(isSubmit){ setIsLoading(true)}
    let rateAnswer: any[] = []
    const rateLengthMulti = Number(RatingData?.ratingLength ?? 5)/5
    const newRate = Number(ratings) * rateLengthMulti

    // let PayloadData: any ={feedbackId: RatingData?._id,answers: [], tagAnswer:tagLists}
    let PayloadData: any ={feedbackId: RatingData?.feedbackId ?? 0,answers: [], tagAnswer:tagLists}
    if(ratings > 0){
      rateAnswer.push({ questionId : ratingQuestion?.questionId, answer: newRate})
    }
    if(description !== ""){
      rateAnswer.push({ questionId : reviewQuestion?.questionId, answer: description})
    }
    PayloadData.answers = rateAnswer
    const payload = {
      data:PayloadData
  };
    bookingServices
      .submitFeedbackForm(payload)
      .then((resp: any) => {
        setIsLoading(false);
        if (resp?.data?.IsSuccess) {
          if(isSubmit){
            setShowModal(true)
          }
          setRating(ratings)
          setSelectedTagsList(tagLists)
        } else {
          // toastr.error("", resp?.data?.Message);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        // toastr.error("", en.errorMessages.SomethingWentWrong);
        console.log(err);
      });
  };
  const handleTagClick = (item: any, indx: any) => {
    // debugger;
    const itmNew = { ...item, selected: !item?.selected };
const ind = selectedTagsList.indexOf(item?.tag)
const newTagList = [...selectedTagsList];
    if(ind > -1){
      newTagList.splice(ind, 1);
      
    }
    else{
      newTagList.push(item?.tag)
    }
    
    setSelectedTagsList(newTagList);
    submitRating(false, rating, newTagList)
  };
  const handleOnChange = (e: any) => {
    // const name = e.target.name;
    const value = e.target.value;
    const hindiValidation = hindiTextValidation(value);
    if(hindiValidation){
      setDescription(value);
    }
  };
  const submitForm = () => {
    // console.log(description);
    if (rating > 0) {
      // setShowModal(true);
      submitRating(true, rating, selectedTagsList)
    }
  };
  const goToAllBookings = () => {
    navigate(Routes.PROFILE, {
      state: { values: "my-bookings" },
    });
  };
  const getTagsArrayByRatingSelected =()=>{

    return RatingData?.tags[rating]

  }

  const getRatingTitle =()=>{
    if(rating === 0){
      return ratingQuestion?.questionDesc ? ratingQuestion?.questionDesc : en.feedback.ratingTitle
    }
    else{
      return arrStarBasedData[rating].name
      
    }
  }
  return (
    <section className="Setting-wrapper">
      
      <div className="container-fluid">
        {isAuthenticated ? (
          <div className="d-flex justify-content-center">
            
            {RatingData && <div className="row align-items-center feedback-page-container">
              {/* <div className="col-md-5 col-sm-7 col-11 h-100">
                <div className="d-flex justify-content-center p-7">
                  <img className="feedback-side-img" src={feedbackSide}></img>
                </div>
              </div>
              <div className="col-md-1 col-sm-7 col-1 d-flex justify-content-center">
                <div className="feedback-center-line"></div>
              </div> */}
              <div className="feedback-card-header">
                  <h4 className="text-center ps-3">{en.feedback.title}</h4>
                  </div>
              <div className="col-md-12 col-sm-7 col-12 h-100">
                <div className="feedback-container">
                  
                <h6 className="pt-4">{getRatingTitle()}</h6>
                  <div className="pt-3">
                    <Rating
                      style={{ maxWidth: 200}}
                      value={rating}
                      onChange={handleRatingChange}
                      itemStyles={myStyles}
                      readOnly={rating > 0}
                    />
                  </div>
                  {rating === 0 && <div className="gap"> <p></p> </div>}
                  {rating > 0 && <>
                  <h6 className="pt-4 pb-2">{getTagsArrayByRatingSelected()?.question}</h6>
                  <div className="tag-container">
                    {getTagsArrayByRatingSelected()?.tagsArray?.map((itm: any, indx: Number) => {
                      return (
                        <div
                          className={`tag${selectedTagsList.indexOf(itm?.tag) > -1 ? "-selected" : ""}`}
                          onClick={() => handleTagClick(itm, indx)}
                        >
                          {itm?.value}
                        </div>
                      );
                    })}
                  </div>
                  
                  <h6 className="pt-4 pb-3">
                  {reviewQuestion?.questionDesc ? reviewQuestion?.questionDesc : en.feedback.inputTitle}
                  </h6>
                  <textarea
                    value={description}
                    name={"description"}
                    className="form-control"
                    onChange={handleOnChange}
                    maxLength={200}
                    rows={4}
                    placeholder={"Enter here..."}
                    disabled={rating === 0}
                  />
                  <p className="text-end w-100">{`${description.length}/200`}</p>
                  </>}
                  
                  <Button1
                    type="button"
                    className="freedo-primary-button rounded-pill w-100 mt-5"
                    loading={isLoading}
                    title={en.feedback.submitFeedback}
                    onClick={submitForm}
                    disabled={rating === 0}
                  />
                  {rating === 0 && <div className="d-flex justify-content-center pt-3 pb-3">
                    <a
                      href=""
                      onClick={(e) => {
                        // setShowModal(true);
                        handleClose();
                        e.preventDefault();
                      }}
                      // className="mx-1"
                    >
                      {en.feedback.skipNow}
                    </a>
                  </div>}
                </div>
              </div>
            </div>}
            {showAllBooking && <div className="h-100">
              <div>
              <h6 className="pt-5">{en.feedback.noFeedbackTitle}</h6>
            <Button1
                    type="button"
                    className="freedo-primary-button rounded-pill w-100 mt-5 mb-5"
                    title={en.feedback.allBookings}
                    onClick={goToAllBookings}
                  />
                  </div>
            </div>
            }
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <h4 className="p-4">{en.feedback.loginRequired}</h4>
          </div>
        )}
      </div>
      <Modal
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        className="modal fade vehicle-model"
        id="help-modal"
        tabIndex={-1}
        aria-hidden="true"
        onHide={handleClose}
      >
        <Modal.Header closeButton className="">
          <Modal.Title className="text-center "></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="justify-content-center text-center pb-3">
            <img src={arrStarBasedData[rating]?.emoji}></img>
            <div className="p-3">
            <p>{arrStarBasedData[rating]?.msg1}</p>
              <h6 className="pt-2">{arrStarBasedData[rating]?.msg2}</h6>
              {/* <p>{en.feedback.successTitle1}</p>
              <h6>{en.feedback.successTitle2}</h6> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default Index;
