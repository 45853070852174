import Skeleton from "react-loading-skeleton";

export const MitcFormsSkelaton = () => {
  return (
    <div className="billing-wrap">
      <div className="table-responsive">
        <table className={`table table-borderless table-sm`}>
          <tbody>
            {[1].map((_keys) => {
              return (
                <tr key={_keys}>
                  <td>
                    <Skeleton width={"200px"} height={"20px"} />
                  </td>
                  <td>
                    <Skeleton width={"40px"} height={"20px"} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
