import { useEffect, useState } from "react";
import "../screen/setting/myBookings/feedback";
import { Modal } from "react-bootstrap";
import {
  Rating
} from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import { Button1 } from "./Button";
import bookingServices from "../redux/services/bookingServices";
import { validate , hindiTextValidation} from "../utills/helper";
import { toastr } from "react-redux-toastr";
import en from "../locales/en.json";
import { starFeedbackBtn, feedbackSide, sadFeedback, loveFeedback, stisFeedackSmall, loveFeedbackSmall, sadFeedbackSmall} from "../assets";
import { handleModalClose } from "../utills/helper";
import { arrStarBasedData, CustomStar } from "../screen/city-freedo/constants/constants";

  const myStyles = {
    itemShapes: CustomStar,
    activeFillColor: "#26e8c8",
    inactiveFillColor: "#E9E9E9",
  };

const BookingFeedbackModal = (props:any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTagsList, setSelectedTagsList] = useState<any[]>([]);
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [RatingData, setRatingData] = useState<any>(null);
  const [ratingQuestion, setRatingQuestion] = useState<any>(null);
  const [reviewQuestion, setReviewQuestion] = useState<any>(null);

  window.onpopstate = function () {
    handleModalClose("modal-backdrop");
  };
  useEffect(() => {
      if (props.bookingId && showModal) {
        bookingServices
          .getFeedbackFormByBookingId({ id: props.bookingId })
          .then((resp: any) => {
            if (resp?.data?.IsSuccess) {
              setRatingData(resp?.data?.Result)
              
              if(resp?.data?.Result?.questions?.length){
                const ratingQustion = resp?.data?.Result?.questions?.filter((e:any)=> e?.type === "rating")
                setRatingQuestion(ratingQustion.length ? ratingQustion[0]: null)
                const reviewQustion = resp?.data?.Result?.questions?.filter((e:any)=> e?.type === "input")
                setReviewQuestion(reviewQustion.length ? reviewQustion[0]: null)
              }
              
            } else {
              toastr.error("", resp?.data?.Message);
              handleClose()
            }
          })
          .catch((err: any) => {
            toastr.error("",err?.response?.data.Message ? err?.response?.data.Message :  en.errorMessages.SomethingWentWrong);
            console.log(err);
            handleClose()
          });
        }
  }, [showModal]);

  const handleRatingChange = (rate: any) => {
    // debugger;
    // setRating(rate);
    // setIsSubmitted(true)
    if(rating === 0){
    submitRating(false, rate, selectedTagsList)
    }
  };
  
  const handleClose = () => {
    // navigate(Routes.HOME);
    // window.location.replace("/");
    setShowModal(false);
    setIsSubmitted(false)
    setRating(0)
    setDescription("")
    setSelectedTagsList([])
    props.handleSubmitCallback()
  };
  const submitRating = (isSubmit:boolean, ratings:any, tagLists:any) => {
    if(isSubmit){ setIsLoading(true)}

    const rateLengthMulti = Number(RatingData?.ratingLength ?? 5)/5
    const newRate = Number(ratings) * rateLengthMulti
    let rateAnswer: any[] = []
    let PayloadData: any ={feedbackId: RatingData?.feedbackId ?? 0 ,answers: [], tagAnswer:tagLists}
    if(ratings > 0){
      rateAnswer.push({ questionId : ratingQuestion?.questionId, answer: newRate})
    }
    if(description !== ""){
      rateAnswer.push({ questionId : reviewQuestion?.questionId, answer: description})
    }
    PayloadData.answers = rateAnswer
    const payload = {
      data:PayloadData
  };
    bookingServices
      .submitFeedbackForm(payload)
      .then((resp: any) => {
        setIsLoading(false);
        if (resp?.data?.IsSuccess) {
          if(isSubmit){
            setIsSubmitted(true)
            // toastr.success("", resp?.data?.Message);
          }
          // setIsSubmitted(true);
          setRating(ratings)
          setSelectedTagsList(tagLists)
          
        } else {
        //   toastr.error("", resp?.data?.Message);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        // toastr.error("", en.errorMessages.SomethingWentWrong);
        console.log(err);
      });
  };
  const handleTagClick = (item: any, indx: any) => {
    // debugger;
    const itmNew = { ...item, selected: !item?.selected };
const ind = selectedTagsList.indexOf(item?.tag)
const newTagList = [...selectedTagsList];
    if(ind > -1){
      newTagList.splice(ind, 1);
      
    }
    else{
      newTagList.push(item?.tag)
    }
    
    setSelectedTagsList(newTagList);
    submitRating(false, rating, newTagList)
  };
  const handleOnChange = (e: any) => {
    // const name = e.target.name;
    const value = e.target.value;
    const hindiValidation = hindiTextValidation(value);
    if(hindiValidation){
      setDescription(value);
    }
  };
  const submitForm = () => {
    
    // console.log(description);
    if (rating > 0) {
      // setShowModal(true);
      submitRating(true, rating, selectedTagsList)
    } 
  };
  const getTagsArrayByRatingSelected =()=>{

    return RatingData?.tags[rating]

  }

  const getRatingTitle =()=>{
    if(rating === 0){
      return ratingQuestion?.questionDesc ? ratingQuestion?.questionDesc : en.feedback.ratingTitle
    }
    else{
      return arrStarBasedData[rating].name
      
    }
  }
  return (
    <>
   <div
        // onClick={() => setShowModal(true)}
        className="feedback-button text-start"
      >
        <img className="pb-1" src={starFeedbackBtn} alt=""></img>
        <a
          href=""
          onClick={(e) => {
            setShowModal(true);
            e.preventDefault();
          }}
          // className="mx-1"
        >
          
          
          Rate This Ride
        </a>
       
      </div>
      <Modal
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal && RatingData}
        className="modal fade vehicle-model feedback-modal"
        id="FeedbackModal"
        tabIndex={-1}
        aria-hidden="true"
        onHide={handleClose}
      >
        <Modal.Header closeButton className="">
          <Modal.Title className="d-flex justify-content-center w-100 feedback-modal-title">{en.feedback.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <>
            {!isSubmitted ? 
        <div className="feedback-container">
            <div className="text-center">
                  <h6 className="pt-4">{getRatingTitle()}</h6>
                  <div className="pt-3 d-flex justify-content-center">
                    <Rating
                      style={{ maxWidth: 200 }}
                      value={rating}
                      onChange={handleRatingChange}
                      itemStyles={myStyles}
                      readOnly={rating > 0}
                    />
                  </div>
                  {rating === 0 && <div className="gap"> <p></p> </div>}
                  {rating > 0 && <>
                  <h6 className="pt-4 pb-2">{getTagsArrayByRatingSelected()?.question}</h6>
                  <div className="tag-container d-flex justify-content-center">
                    {getTagsArrayByRatingSelected()?.tagsArray?.map((itm: any, indx: Number) => {
                      return (
                        <div
                          className={`tag${selectedTagsList.indexOf(itm?.tag) > -1 ? "-selected" : ""}`}
                          onClick={() => handleTagClick(itm, indx)}
                        >
                          {itm?.value}
                        </div>
                      );
                    })}
                  </div>
                  
                  <h6 className="pt-4 pb-3">
                  {reviewQuestion?.questionDesc ? reviewQuestion?.questionDesc : en.feedback.inputTitle}
                  </h6>
                  <textarea
                    value={description}
                    name={"description"}
                    className="form-control"
                    onChange={handleOnChange}
                    maxLength={200}
                    rows={4}
                    placeholder={"Enter here..."}
                    disabled={rating === 0}
                  />
                  <p className="text-end w-100">{`${description.length}/200`}</p>
                  </>}
                  <Button1
                    type="button"
                    className="freedo-primary-button rounded-pill w-100 mt-3"
                    loading={isLoading}
                    title={"Submit Feedback"}
                    onClick={submitForm}
                    disabled={rating === 0}
                  />
                  {rating === 0 && <div className="d-flex justify-content-center pt-3">
                    <a
                      href=""
                      onClick={(e) => {
                        // setShowModal(true);
                        handleClose();
                        e.preventDefault();
                      }}
                      // className="mx-1"
                    >
                      {en.feedback.skipNow}
                    </a>
                  </div>}
                </div>
                </div>
                :
                <div className="justify-content-center text-center pb-3">
            <img src={arrStarBasedData[rating]?.emoji}></img>
            <div className="p-3">
              {/* <p>{en.feedback.successTitle1}</p>
              <h6>{en.feedback.successTitle2}</h6> */}
               <p>{arrStarBasedData[rating]?.msg1}</p>
              <h6 className="pt-2">{arrStarBasedData[rating]?.msg2}</h6>
            </div>
          </div>
                }
                </>
        </Modal.Body>
      </Modal>
    
    </>);
};
export default BookingFeedbackModal;
