import axios from "axios";
import { API_URLS, BASE_URL_CUSTOMER, versioning } from "../../config/apiUrls";
import { Axios, customAxios } from "../../config/axiosConfig";
import { getMasterData } from "../../config/getApiUrls";

const userLogin = (payload: any) =>
  Axios
    .post(BASE_URL_CUSTOMER + API_URLS.sendOTP, payload)
    .catch((ex: any) => ex);

const verifyOTP = (payload: any) =>
  Axios
    .post(BASE_URL_CUSTOMER + API_URLS.verifyOTP, payload)
    .catch((ex: any) => ex);

const userSignUp = (payload: any) =>
  Axios
    .post(BASE_URL_CUSTOMER + API_URLS.signupWithOtp, payload)
    .catch((ex: any) => ex);

const sendOtpForSignUp = (payload: any) =>
  Axios
    .post(BASE_URL_CUSTOMER + API_URLS.sendOtpForSignUp, payload)
    .catch((ex: any) => ex);

const userLogout = () =>
  customAxios.get(API_URLS.userLogout, {}).catch((ex) => ex);

const getOptions = (params: any) =>
  Axios
    .get(BASE_URL_CUSTOMER + getMasterData({ type: params.type }))
    .catch((ex: any) => ex);

const getOtpForDelAcc = (payload: any) =>
  Axios
    .post(BASE_URL_CUSTOMER + API_URLS.sendOtpForDelAcc, payload)
    .catch((ex: any) => ex);

const changeStatus = (payload: any) =>
  Axios
    .put(BASE_URL_CUSTOMER + API_URLS.changeAccStatus, payload)
    .catch((ex: any) => ex);

export default {
  userLogin,
  verifyOTP,
  userSignUp,
  sendOtpForSignUp,
  userLogout,
  getOptions,
  getOtpForDelAcc,
  changeStatus,
};
