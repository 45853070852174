import React, { useState, useEffect } from "react";
import { restrictName, validate } from "../../../utills/helper";
import { checkNumberInput } from "../../../utills/regex";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../redux/reducers";
import InputField from "../../../component/InputField";
import EmergencyContact from "./EmergencyContact";
import { actions } from "../../../redux";
import { Routes } from "../../../constant/routeContants";
const intitalProfile = {
  email_id: "",
  first_name: "",
  id: 0,
  last_name: "",
  mobile_number: "",
  gender: "",
  relation: "",
  relation_name: "",
  emergency_number: "",
};
const Index = (props: any) => {
  const { userProfileDetail } = useSelector(
    (state: RootState) => state.profileReducer
  );
  const [userProfile, setUserProfile] = useState(intitalProfile);
  const [errorFields, setErrorFields] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_id: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    setUserProfile(userProfileDetail);
  }, [userProfileDetail]);

  const handleChange = (name: string, value: any) => {
    setUserProfile({
      ...userProfile,
      [name]: value,
    });
    setErrorFields({
      ...errorFields,
      [name]: "",
    });
  };
  const handleEmergencyDataChange = (name: string, value: string) => {
    // console.log(name, value);
    setUserProfile({
      ...userProfile,
      [name]: value,
    });
  };
  const validateForm = (e: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    const validation = validate(userProfile, errorFields);
    if (validation.error) {
      console.log(validation.errorFields);
      return setErrorFields({ ...errorFields, ...validation.errorFields });
    }

    const payload = {
      firstName: userProfile.first_name,
      lastName: userProfile.last_name,
      emailId: userProfile.email_id,
      gender: userProfile.gender,
    };

    dispatch(actions.postUpdateUserProfile(payload));
  };

  return (
    <div
      className="tab-pane fade show active"
      id="v-pills-home"
      role="tabpanel"
      aria-labelledby="v-pills-home-tab"
      tabIndex={0}
    >
      <div className="row">
        <div className="col-md-8">
          <h5>My Profile</h5>
          <form className="row mt-3" onSubmit={validateForm}>
            <div className="col-md-6">
              <label htmlFor="inputname4" className="form-label">
                First Name
              </label>
              <InputField
                value={userProfile.first_name}
                name={"first_name"}
                type={"text"}
                className="form-control"
                onChange={handleChange}
                placeholder="Enter your first name"
                restrictFuntion={restrictName}
              />
              {errorFields["first_name"] !== "" && (
                <div className="error-field-container">
                  <span className="error-field">
                    {errorFields["first_name"]}
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="inputlastname4" className="form-label">
                Last Name
              </label>
              <InputField
                value={userProfile.last_name}
                name={"last_name"}
                type={"text"}
                className="form-control"
                onChange={handleChange}
                placeholder="Enter your last name"
                restrictFuntion={restrictName}
              />
              {errorFields["last_name"] !== "" && (
                <div className="error-field-container">
                  <span className="error-field">
                    {errorFields["last_name"]}
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="inputmobile4" className="form-label">
                Mobile Number
              </label>
              <InputField
                value={userProfile.mobile_number}
                name={"mobile_number"}
                type={"text"}
                maxLength={10}
                isDisabled={true}
                className="form-control"
                placeholder="Enter your mobile number"
                onChange={handleChange}
                restrictFuntion={checkNumberInput}
              />
              {errorFields["mobile_number"] !== "" && (
                <div className="error-field-container">
                  <span className="error-field">
                    {errorFields["mobile_number"]}
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="inputEmail4" className="form-label">
                Email ID
              </label>
              <InputField
                value={userProfile.email_id}
                name={"email_id"}
                type={"text"}
                className="form-control"
                onChange={handleChange}
                placeholder="Enter your email"
              />
              {errorFields["email_id"] !== "" && (
                <div className="error-field-container">
                  <span className="error-field">{errorFields["email_id"]}</span>
                </div>
              )}
            </div>

            <div className="col-12">
              <h6 className="gnhead">Gender</h6>
              <div className="gender-set-wrap">
                <div className="form-check">
                  <InputField
                    value={"Male"}
                    name={"gender"}
                    type={"radio"}
                    className="form-check-input"
                    isChecked={userProfile.gender === "Male"}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Male
                  </label>
                </div>
                <div className="form-check">
                  <InputField
                    value={'Female'}
                    name={"gender"}
                    type={"radio"}
                    className="form-check-input"
                    isChecked={userProfile.gender === 'Female'}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Female
                  </label>
                </div>

                <div className="form-check">
                  <InputField
                    value={'Others'}
                    name={"gender"}
                    type={"radio"}
                    className="form-check-input"
                    isChecked={userProfile.gender === 'Others'}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault3"
                  >
                    Others
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="update-profile-wrap">
                <button
                  type="submit"
                  className="freedo-primary-button-small rounded-pill"
                >
                  {" "}
                  Update Profile{" "}
                </button>
                <p className="small-text">
                  Have you updated your KYC?{" "}
                  <Link
                    to={Routes.PROFILE}
                    state={{ values: "kyc" }}
                    title="User KYC"
                  >
                    Update Here
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
        <EmergencyContact
          profileData={userProfile}
          handleValuechange={handleEmergencyDataChange}
          handleSaveEmergency={validateForm}
        />
      </div>
    </div>
  );
};

export default Index;
