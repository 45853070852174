import React from "react";
// @ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DatePickerInput({
  startDate,
  handleDatePickerOnchange,
  datePickerRef,
  minDate = new Date(),
  isClearableEnabled = true,
}) {
  const showClearIcon = startDate ? isClearableEnabled : false;
  const showCalenderIcon = isClearableEnabled
    ? startDate
      ? false
      : true
    : true;
  /* 
      Calender Icon - 
      1. if isClearableEnabled is true , only show when startDate is null
      2. if isClearableEnabled is false, show everytime i.e set showIcon to true
    */
  return (
    <>
      <DatePicker
        isClearable={showClearIcon}
        showIcon={showCalenderIcon}
        className="form-control form-control-sm"
        icon="fa-regular fa-calendar"
        minDate={minDate}
        dateFormat={"dd-MM-yyyy"}
        placeholderText={"Date"}
        ref={datePickerRef}
        scrollableYearDropdown
        toggleCalendarOnIconClick
        todayButton="Today"
        selected={startDate ? new Date(startDate) : ""}
        onChange={handleDatePickerOnchange}
        onKeyDown={(e) => {
          e.preventDefault();
       }}
       onFocus={(e) => (e.target.readOnly = true)}
       /* To Open Calender at top */
      //  popperPlacement="top-start"
      />
    </>
  );
}

export default DatePickerInput;
