import { localeInterface } from "../../interfaces/actionInterfaces";
import en from "../../locales/en.json";
import { ACTIONS, GLOBAL_ACTION, HOME_ACTIONS } from "../actions/actionTypes";

const {
  SHOW_API_SUCCEED_MODAL,
  SHOW_API_FAILED_MODAL,
  CLEAR_API_STATUS,
  SET_MODAL_MESSAGE,
} = GLOBAL_ACTION;
const initialState = {
  locale: en,
  basicFilter: {
    city: {},
    date: "",
    endDate: "",
    package: {},
  },
  selectedCity: {},
  advertisements: [],
  locationLoader: false,
  locationVehicleList: {},
  showSuccessModal: false,
  showErrorModal: false,
  modalMessage: "",
};
const localeReducer = (state = initialState, action: localeInterface) => {
  const prevState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.LOCALE: {
      if (payload === "en") {
        return {
          ...prevState,
          locale: en,
        };
      } else return { ...prevState };
    }
    case HOME_ACTIONS.SET_BASIC_FILTER:
      return {
        ...prevState,
        basicFilter: payload,
      };
    case HOME_ACTIONS.SET_SELECTED_CITY:
      return {
        ...prevState,
        selectedCity: payload,
      };
    case GLOBAL_ACTION.SET_ADVERTISMENT:
      return {
        ...prevState,
        advertisements: payload,
      };
    case GLOBAL_ACTION.SET_LOCATION_LOADER:
      return {
        ...prevState,
        locationLoader: payload,
      };
    case GLOBAL_ACTION.SET_LOCATION_VEHICLE_LIST:
      return {
        ...prevState,
        locationVehicleList: payload,
      };
    case SHOW_API_SUCCEED_MODAL:
      return {
        ...prevState,
        showSuccessModal: payload,
      };
    case SHOW_API_FAILED_MODAL:
      return {
        ...prevState,
        showErrorModal: payload,
      };
    case SET_MODAL_MESSAGE:
      return {
        ...prevState,
        modalMessage: payload,
      };
    case CLEAR_API_STATUS:
      return {
        ...prevState,
        showSuccessModal: false,
        showErrorModal: false,
        modalMessage: "",
      };
    default:
      return { ...prevState };
  }
};

export default localeReducer;
