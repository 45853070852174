import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

const CustomPagination = ({
  result,
  itemsPerPage,
  totalrecord,
  setPagination,
}) => {
  const { paginationData } = useSelector((state: any) => state.profileReducer);
  const [pageCount, setPageCount] = useState(0);
  
  useEffect(() => {
    setPageCount(Math.ceil(totalrecord / itemsPerPage));
  }, [itemsPerPage, totalrecord]);
  const handlePageCount = (event) => {
    setPagination((prev) => ({ ...prev, page: event.selected + 1 }));
    // const newOffset = event.selected * itemsPerPage % result.length;
    // setItemOffset(newOffset)
  };
  console.log({ pageCount });
  return (
    <ReactPaginate
      nextLabel="next"
      onPageChange={handlePageCount}
      initialPage={paginationData?.page - 1 || 0}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      previousLabel="prev"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
      renderOnZeroPageCount={null}
    />
  );
};

export default CustomPagination;
