import { deleteIcon, editIcon } from "../assets";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import CreateAddressModel from "./CreateAddressModel";
import { actions } from "../redux";
import AddressSkelaton from "./SkelatonLoader/AddressSkelaton";
const initiaState = {
  id: null,
  name: "",
  address: "",
  landmark: "",
  zipcode: "",
  city: "",
  latitude: 0,
  longitude: 0,
  saveAs: "",
  isDefaultAddress: false,
  formattedAddress: "",
  others: null,
};
const getAddIcon = (param) => {
  let iconstring = "";
  switch (param) {
    case "Home":
      iconstring = "fa-home";
      break;
    case "Friends":
      iconstring = "fa-users";
      break;
    case "Others":
      iconstring = "fa-users";
      break;
    case "Work":
      iconstring = "fa-briefcase";
      break;
    default:
      iconstring = "fa-map-marker";
  }

  return iconstring;
};
const SelectAddress = (props: any) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initiaState);
  const [sklFlag, setSklFlag] = useState(true);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const { homeAddressList } = useSelector(
    (state: RootState) => state.checkoutReducer
  );
  const [AddressList, setAddress] = useState([...homeAddressList]);
  useEffect(() => {
    dispatch(actions.getHomeAddressList({ payload: {} }));
  }, []);
  useEffect(() => {
    if (homeAddressList?.length) {
      let defaultAddress = homeAddressList?.filter(
        (item) => item?.user_addresses?.is_default
      );
      defaultAddress.length &&
        props.handleChange("location_id", defaultAddress[0].id, defaultAddress[0]?.city?.id);
      setAddress(homeAddressList);
    }
    setTimeout(() => {
      setSklFlag(false);
    }, 1000);
  }, [homeAddressList]);
  const handleDeleteAddress = (id) => {
    dispatch(actions.deleteAddress({ id }));
  };

  const handleEditAddress = (data) => {
    setIsEditAddress(true);
    setState({
      id: data.id,
      name: data?.name,
      address: data?.address1,
      landmark: data?.landmark,
      zipcode: (data?.zip && String(data?.zip)) || "",
      city: data?.city?.city_name,
      latitude: data?.latitude,
      longitude: data?.longitude,
      saveAs: data?.user_addresses?.save_as,
      isDefaultAddress: data?.user_addresses?.is_default,
      formattedAddress: data?.formatted_address,
      others: data?.user_addresses?.others,
    });
    openModel();
  };

  const openModel = () => {
    const ele = document.getElementById("settingAddaddress");
    ele && ele.click();
  };

  const resetState = () => {
    setIsEditAddress(false);
    setState(initiaState);
    closeModel();
  };
  const closeModel = () => {
    const ele = document.getElementById("settingCloseModel");
    ele && ele.click();
  };
  return (
    <div className="saved-address mt-3">
      <div className="address-row w-100">
        {AddressList.length === 0 ? (
          <>
            <h2>
              <span className="select_address">No Address Found</span>
            </h2>
            <div className="d-flex justify-content-end mb-1">
              <div className="mb-1">
              <a
                href="#"
                className="freedo-outline-btn-small rounded-pill btn-block"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvaschangeaddress"
                aria-controls="offcanvaschangeaddress"
                id="settingAddaddress"
              >
                Add Address
              </a>
              </div>
            </div>
          </>
        ) : (
          <>
            <h2>
              <span className="select_address">My Addresses</span>
            </h2>
            <div className="d-flex justify-content-end">
              <div className="mb-1">
              <a
                href="#"
                className="freedo-outline-btn-small rounded-pill btn-block"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvaschangeaddress"
                aria-controls="offcanvaschangeaddress"
                id="settingAddaddress"
              >
                Add Address
              </a>
              </div>
            </div>
            <div className="address-box-wrap row">
              {sklFlag ? (
                <AddressSkelaton />
              ) : (
                AddressList?.map((_details, index) => {
                  return (
                    <>
                      <a
                        // role='button'
                        onClick={() =>
                          props.handleChange("location_id", _details.id, _details?.city?.id)
                        }
                        className={`${props.timeSlotDetail?.location_id == _details.id &&
                          "selected"
                          }  address-box cp`}
                      >
                        <i
                          className={`fas ${getAddIcon(
                            _details.user_addresses.save_as
                          )}  address-icon`}
                          aria-hidden="true"
                        ></i>

                        <div className="address-top">
                          <h6> {_details.name} </h6>
                          <div className="action-icon-wrap">
                            <img
                              className="cp"
                              src={editIcon}
                              alt="Edit Saved Address"
                              title="Edit Address"
                              onClick={() => handleEditAddress(_details)}
                            />
                            <img
                              className="cp"
                              src={deleteIcon}
                              alt="Delete Saved Address"
                              title="Delete Address"
                              onClick={() => handleDeleteAddress(_details.id)}
                            />
                          </div>
                        </div>
                        <p className="space-top">
                          {_details.formatted_address
                            ? _details.formatted_address
                            : _details.address1 +
                            ", " +
                            _details.landmark +
                            ", " +
                            _details.city.city_name +
                            ", " +
                            _details.zip}{" "}
                        </p>
                      </a>
                    </>
                  );
                })
              )}
            </div>
          </>
        )}
      </div>
      <CreateAddressModel
        resetState={resetState}
        addressData={state}
        isEditAddress={isEditAddress}
      />
    </div>
  );
};
export default SelectAddress;
