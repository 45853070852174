import React from "react";

const SuccessScreen = (props : any) => {
  return (
    <div>
      <div className="successfully-card ">
        <img src="images/Biometric-illustration.png" alt="" />
        <p> {props.doc} Successfully Verified!</p>
        {/* <p className="support-file">
          We'll notify you
          <br />
          once your document is verified by Team Freedo
        </p> */}
      </div>
    </div>
  );
};

export default SuccessScreen;
