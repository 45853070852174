import React from 'react';
import { assistance, breakdown, complimentaryHelmet, government, homeDelivery, insuredRide, payments, sanitized } from '../../assets';
import en from '../../locales/en.json'

interface featureInterface {
    src?: string;
    content?: any;
}
const {freedoFeature} =en.home
const FreedoContainer = ({ src, content }: featureInterface) => {
    return (
        <div className="card">
            <span className="card-left">
                <img src={src} alt="" />
            </span>
            <>{content}</>
        </div>
    );
};

const FreedoFeature = () => {
    return (  
        <section className="features-wrap">
            <div className="container-fluid">
                <div className="features-content">
                <FreedoContainer
                        content={
                            <h6 className="card-right">
                                {freedoFeature.Government}
                                <br />
                                {freedoFeature.Compliant}
                            </h6>
                        }
                        src={government}
                    />
                    <FreedoContainer
                        content={
                            <h6 className="card-right">
                                {freedoFeature.Doorstep} <br />
                                {freedoFeature.Delivery}
                            </h6>
                        }
                        src={homeDelivery}
                    />
                    <FreedoContainer 
                    content={<h6 className="card-right">
                        {freedoFeature.Instant} <br />
                        {freedoFeature.Payments1}
                        </h6>
                    } src={payments} />
                    <FreedoContainer
                        content={
                            <h6 className="card-right">
                                {freedoFeature.Services} <br />
                                {freedoFeature.Maintainance}
                            </h6>
                        }
                        src={breakdown}
                    />
                    <FreedoContainer
                        content={
                            <h6 className="card-right">
                                {freedoFeature.Sanitized} <br />
                                {freedoFeature.Vehicles}
                            </h6>
                        }
                        src={sanitized}
                    />  
                    
                </div>
            </div>
        </section>
    );
};

export default FreedoFeature;
