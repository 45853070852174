import React from "react";
import { Fade } from "react-reveal";
import { locationBlog1, locationBlog2, locationBlog3 } from "../../../assets";

const Blogs = ({ data }) => {
  return (
    <section className="city-blog-wrap">
      <div className="container-fluid">
        <div className="row flex-nowrap overflow-x-auto">
          {data?.baneers?.length > 0 &&
            data?.baneers?.map((data) => (
              <div className="col-md-4 col-sm-7 col-7 blog-card-box">
                <div className="card border-0">
                  <img src={data?.img} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title"> {data?.heading}</h5>
                    <p className="card-text">{data?.text}</p>
                    <a
                      href={data?.link}
                      className="freedo-primary-button rounded-pill text-dark"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            ))}
          {/* <div className="card border-0">
              <img
                src={locationBlog1}
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">
                  {" "}
                  Hill Stations near Bangalore to visit with Freedo
                </h5>
                <p className="card-text">
                  Bike trips are a dream of every traveler. What can be a better
                  ride than exploring mother nature and experiencing beautiful
                  scenarios of hill stations?!
                </p>
                <a
                  href="#"
                  className="freedo-primary-button rounded-pill text-dark"
                >
                  Read More
                </a>
              </div>
            </div> */}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
