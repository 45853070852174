import React from "react";
import { speak1, speak2, speak3, fb, insta } from "../../assets";
import en from "../../locales/en.json";
import ShowMore from "../../component/ShowMore";
interface CardPictureInterface {
  src: string;
  title: string;
  content: string;
  logoSrc: string;
  ind: number;
}
const { freedoPictureSpeak } = en.home;

const arrData = [
  {
    id: 1,
    src: speak1,
    title: "Prarabdh Mishra",
    content: `Riding Harley's legendary Fat Bob 114 was on my bucket list for a long, it was amazing to have the ease of renting it, getting it delivered to my home by the professional team, easy pickup, and the best memories made out of it. Thanks, Team Freedo!`,
    logoSrc: fb,
  },
  {
    id: 2,
    src: speak2,
    title: "Arya",
    content: `It was the best decision to rent the bike from Freedo. Their services were great, they provided full support in every aspect. We drove 1000kms across Karnataka and the bike was superb, we didnt face any issue regarding the bike. Totally worth the money!`,
    logoSrc: insta,
  },
  {
    id: 3,
    src: speak3,
    title: "Amit Dubey",
    content: `"Three years with Freedo Rentals across India—seamless process, no deposits, excellent maintenance. Affordable rates, beats competition. Thanks, Freedo team, for reliable and budget-friendly service. Highly recommended! Will look forward to freedo rentals `,
    logoSrc: insta,
  },
];

const CardPicture = ({
  src,
  title,
  content,
  logoSrc,
  ind,
}: CardPictureInterface) => {
  
  return (
    <div key={ind} className="col-lg-4 col-md-4">
      <div className="card testimonial">
        <div className="testimonial-img-container">
          <img
            className="card-img-top img-responsive"
            src={src}
            alt="Freedo Customer Speaks"
          />
        </div>
        <div className="card-body">
          <h5 className="card-title">
            <img src={logoSrc} alt="" className="testimonial-logo" />
            {title}
          </h5>
          <p className="card-text"> 
            <ShowMore text={content} characterToShow={100} />
          </p>
        </div>
      </div>
    </div>
  );
};

const FreedoPictureSpeak = () => {
  return (
    <section className="blog-wrapper">
      <div className="container-fluid">
        <div className="blog-wrap">
          <h2>{freedoPictureSpeak.WhenPicturesSpeaks}</h2>
          <div className="row">
            {arrData.map(({ src, title, content, logoSrc, id }, ind) => (
              <CardPicture
                key={id}
                ind={ind}
                src={src}
                title={title}
                content={content}
                logoSrc={logoSrc}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreedoPictureSpeak;
