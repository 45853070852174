import { localeInterface } from "../../interfaces/actionInterfaces";
import { PROFILE_ACTIONS } from "../actions/actionTypes";

const initialExtendState = {
  packageId: "",
  odometerReading: 0,
  additionalHelmet: false,
  additionalHelmetData: null,
  selectedPackage: {},
};

const initialState = {
  bookingList: [],
  bookingDetails: {},
  extendDetails: {},
  currentBooking: {},
  priceDetails: {},
  documentDetails: {},
  packageForExtend: [],
  extendRideState: {
    ...initialExtendState,
  },
  cancellationCharges: {},
  cancelLoader: false,
  cancleSuccess: false,
  refundData: {},
  bookingStatus: {},
  bookingListLoader: false,
  modificationChargesData:[],
  modificationCharges: 0,
  modelAvailableDates: {},
  modifyBookingState: {
    modifyStep: "1",
    newStartDate: null,
    newEndDate: null,
    timeSlot: null,
  },
};

const {
  SET_BOOKING_LIST,
  SET_BOOKING_DETAILS_BY_ID,
  SET_EXTEND_DETAILS,
  SET_CURRENT_BOOKING,
  SET_PRICE_DETAILS_BY_ID,
  SET_DOCUMENT_DETAILS_BY_ID,
  SET_EXTEND_PACKAGE,
  SET_EXTEND_RIDE_DATA,
  RESET_EXTEND_RIDE_DATA,
  SET_CANCELLATION_CHARGES,
  SET_CANCLE_LOADER,
  CANCEL_SUCCESS,
  SET_REFUND_STATUS,
  SET_BOOKING_STATUS,
  SET_BOOKING_LIST_LOADER,
  SET_MODIFICATION_CHARGES,
  SET_MODEL_AVAILABLE_DATES,
  SET_MODIFY_BOOKING_STATE,
  CLEAR_MODIFY_BOOKING_STATE,
  SET_BOOKING_CHANGE_CHARGES,
} = PROFILE_ACTIONS;
const bookingReducer = (state: any = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    case SET_BOOKING_LIST:
      return {
        ...state,
        totalBooking: action.payload?.total,
        bookingList: action.payload?.bookingDetails,
      };
    case SET_BOOKING_LIST_LOADER:
      return {
        ...state,
        bookingListLoader: action.payload,
      };
    case SET_BOOKING_DETAILS_BY_ID:
      return {
        ...state,
        bookingDetails: action.payload,
      };
    case SET_EXTEND_DETAILS:
      return {
        ...state,
        extendDetails: action.payload,
      };
    case SET_CURRENT_BOOKING:
      return {
        ...state,
        currentBooking: action.payload,
      };
    case SET_PRICE_DETAILS_BY_ID:
      return {
        ...state,
        priceDetails: action.payload,
      };
    case SET_DOCUMENT_DETAILS_BY_ID:
      return {
        ...state,
        documentDetails: action.payload,
      };
    case SET_EXTEND_PACKAGE:
      return {
        ...state,
        packageForExtend: action.payload,
      };
    case SET_EXTEND_RIDE_DATA:
      return {
        ...state,
        extendRideState: action.payload,
      };
    case RESET_EXTEND_RIDE_DATA:
      return {
        ...state,
        extendRideState: { ...initialExtendState },
      };
    case SET_CANCELLATION_CHARGES:
      return {
        ...state,
        cancellationCharges: { ...action.payload },
      };
    case SET_CANCLE_LOADER:
      return {
        ...state,
        cancelLoader: action.payload,
      };
    case CANCEL_SUCCESS:
      return {
        ...state,
        cancleSuccess: action.payload,
      };
    case SET_REFUND_STATUS:
      return {
        ...state,
        refundData: action.payload,
      };
    case SET_BOOKING_STATUS:
      return {
        ...state,
        bookingStatus: action.payload,
      };
    case SET_MODIFICATION_CHARGES:
      return {
        ...state,
        modificationCharges: action.payload,
      };
    case SET_BOOKING_CHANGE_CHARGES:
      return {
        ...state,
        modificationChargesData: action.payload,
      };
    case SET_MODEL_AVAILABLE_DATES:
      return {
        ...state,
        modelAvailableDates: action.payload,
      };
    case SET_MODIFY_BOOKING_STATE:
      return {
        ...state,
        modifyBookingState: action.payload,
      };
    case CLEAR_MODIFY_BOOKING_STATE:
      return {
        ...state,
        modifyBookingState: {
          modifyStep: "1",
          newStartDate: null,
          newEndDate: null,
          timeSlot: null,
        },
        modificationCharges: 0,
        modelAvailableDates: {},
        modificationChargesData:[],
      };
    default:
      return state;
  }
};
export default bookingReducer;
