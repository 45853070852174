import { Link, useNavigate } from "react-router-dom";
import { completeRide, cancelRide, modifyRide } from "../../../assets";
import moment from "moment";
import { actions } from "../../../redux";
import CardSkelaton from "../../../component/SkelatonLoader/CardSkelaton";
import {
  commmaSeparator,
  getEncryptedText,
} from "../../../utills/helper";
import { STATUS, WAREHOUSE_STATUS } from "../../../constant/enum";
import { useDispatch } from "react-redux";
const { Ongoing, Upcoming, Cancelled, Pending } = STATUS;
const { Rejected, Accepted, Inprocess } = WAREHOUSE_STATUS;

const BookingCard = (props: any) => {
  const {
    status,
    start_date_time,
    rental_days,
    pickup_location,
    location,
    id,
    end_date_time,
    drop_off_location,
    bike_name,
    amount,
    warehouse_status,
    mode_of_pick_up_id,
    city_name,
    is_modified,
  } = props?.data;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRedirection = async (e: any, id: number) => {
    e.preventDefault();
    let payload = props?.pagination;
    dispatch(actions?.setPaginationData(payload));
    let object = {
      bookingId: id,
    };
    const encryptedObject = getEncryptedText(JSON.stringify(object));
    navigate(`/booking-details/${encryptedObject}`);
  };

  return (
    // <CardSkelaton />
    <div className="complete-ride">
      <div className={`content-wrap ${is_modified && "content-wrap-modified"}`}>
        {!props.bookingStatus && (
          <span
            className={`status-badge ${
              status === Upcoming && warehouse_status === Inprocess
                ? "approval"
                : status?.toLowerCase()
            }`}
          >
            {status === Upcoming && warehouse_status === Inprocess
              ? "Approval In Progress"
              : status}{" "}
            Ride
          </span>
        )}
        {/* need to update image icon based on their status */}
        <h6>
          {/* <img
            src={
              status.toLowerCase() === "cancelled" ? cancelRide : completeRide
            }
            alt=""
          /> */}
          {bike_name}
        </h6>
        <div className="date">
          <span>
            {moment(start_date_time, "YYYY-MM-DD").format("DD-MMM-YYYY")}
          </span>
          <span className="hr-border"></span>
          <span>
            {moment(end_date_time, "YYYY-MM-DD").format("DD-MMM-YYYY")}
          </span>
        </div>
      </div>
      {is_modified && (
        <div className="text-end mb-1">
          <img className="p-0" src={modifyRide} />
        </div>
      )}
      <div className="ride-address endride-address">
        <div className="pickup-ride">
          <span>
            {mode_of_pick_up_id == 2
              ? "Pick-Up Address:"
              : "Home Delivery Address:"}
          </span>{" "}
          {pickup_location}
        </div>
        <div className="drop-ride">
          <span>Drop Location:</span>
          {!drop_off_location ? "-NA-" : drop_off_location}
        </div>
      </div>

      <div className="all-ride-details d-flex">
        <ul>
          <li>{rental_days} </li>
          <li>{city_name} </li>
          <li> ₹ {commmaSeparator(Math.round(amount))}/-</li>
        </ul>

        <div className="text-end">
          <Link
            to="#"
            onClick={(e) => {
              handleRedirection(e, id);
            }}
            className="freedo-outline-btn-small rounded-pill text-dark"
          >
            View Details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BookingCard;
