import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../../component/CustomPagination";
import { RootState } from "../../../redux/store";
import BookingCard from "./BookingsCard";
import { useState } from "react";
import { useEffect } from "react";
import CardSkelaton from "../../../component/SkelatonLoader/CardSkelaton";
import { getBookingDetails } from "../../../redux/actions/rentActions/bookingAction";
import { setPaginationData, clearPaginationData } from "../../../redux/actions";
import { actions } from "../../../redux";

const initPagination = {
  limit: 4,
  page: 1,
  status: "",
};
const BookingTabs = ({
  bookingStatus,
  itemsperpage = 2,
  classNames = "col-md-6",
}) => {
  initPagination.status = bookingStatus;
  initPagination.limit = itemsperpage;
  const [sklFlag, setSkl] = useState(true);
  const dispatch = useDispatch();
  const { bookingList, totalBooking } = useSelector(
    (state: RootState) => state.bookingReducer
  );
  const { paginationData } = useSelector((state: any) => state.profileReducer);
  const [pagination, setPagination] = useState(initPagination);
  const [currentBookings, setCurrentBookings] = useState<Array<any>>([]);
  useEffect(() => {
    dispatch(getBookingDetails(pagination));
  }, [pagination, itemsperpage]);
  useEffect(() => {
    setCurrentBookings(bookingList);
    setTimeout(() => {
      setSkl(false);
    }, 500);
  }, [bookingList]);
  useEffect(() => {
    setPagination(initPagination);
  }, [bookingStatus]);
  return sklFlag ? (
    <div className="row">
      {Array(itemsperpage)
        .fill(null)
        .map((_index, ind) => (
          <div className={classNames} key={ind}>
            <CardSkelaton />
          </div>
        ))}
    </div>
  ) : (
    <>
      {bookingList?.message ? (
        ""
      ) : (
        <div className="row justify-content-center">
          {bookingList?.map((_details,ind) => {
            return (
              <div className={classNames} key={_details.booking_id+ind}>
                <BookingCard
                  bookingStatus={bookingStatus}
                  data={_details}
                  pagination={pagination}
                />
              </div>
            );
          })}
          {totalBooking > itemsperpage ? (
            <div className="pagination-wrap mt-3">
              <CustomPagination
                setPagination={setPagination}
                itemsPerPage={itemsperpage}
                result={bookingList}
                totalrecord={totalBooking}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
export default BookingTabs;
