import * as actionTypes from "../actionTypes";
const {
  GET_COUPON_MASTERS,
  GET_HOME_ADDRESS_LIST,
  GET_HOME_DELIVERY_CHARGES,
  GET_BRANCH_SLOTS,
  SET_VEHICLE_DATA,
  GET_VEHICLE_DATA,
  POST_CREATE_BOOKING,
  POST_PROCESS_ORDER,
  POST_PAYMENT_VERIFY,
  SET_BOOKING_DATA,
  VALIDATE_USER_EMERGENCY_DETAILS_REQUESTED,
  TOGGLE_HOME_DELIVERY_AVAILABLE,
} = actionTypes.CHECKOUT_ACTIONS;
export const getCouponMasters = (payload: any) => {
  return { type: GET_COUPON_MASTERS, payload };
};
export const getHomeAddressList = (payload: any) => {
  return { type: GET_HOME_ADDRESS_LIST, payload };
};
export const getHomeDeliverCharge = (
  payload: any,
  setDeliveryChargeLoader: any,
  updateHomeDeliveryCharge: any = null
) => {
  return {
    type: GET_HOME_DELIVERY_CHARGES,
    payload,
    setDeliveryChargeLoader,
    updateHomeDeliveryCharge,
  };
};
export const getBranchSlots = (payload: any) => {
  return { type: GET_BRANCH_SLOTS, payload };
};
export const getVehicleData = (payload: any) => {
  return { type: GET_VEHICLE_DATA, payload };
};
export const setVehicleData = (payload: any) => {
  return { type: SET_VEHICLE_DATA, payload };
};
export const createBooking = (payload: any) => {
  return { type: POST_CREATE_BOOKING, payload };
};
export const processOrder = (payload: any) => {
  return { type: POST_PROCESS_ORDER, payload };
};
export const verifyOrderPayment = (payload: any) => {
  return { type: POST_PAYMENT_VERIFY, payload };
};

export const setBookingData = (payload: any) => {
  return { type: SET_BOOKING_DATA, payload };
};

export const validateUserEmergency = () => {
  return { type: VALIDATE_USER_EMERGENCY_DETAILS_REQUESTED };
};

export const toggleHomeDeliveryAvailable = () => ({
  type: TOGGLE_HOME_DELIVERY_AVAILABLE,
});
