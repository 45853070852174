import InputField from "../../component/InputField";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { commmaSeparator, debounce } from "../../utills/helper";
import { useCallback } from "react";

const Filters = ({
  initialState,
  vehicleType,
  handleChange,
  handleCheckboxChange,
  rentDetails,
  secondaryFilter,
  setSecondaryFilter,
  maxPrice,
  minPrice,
  handleResetstate,
  secondaryFilterChanged,
}) => {
  const { filters } = rentDetails;
  const debouncedChangeHandler = useCallback(debounce(secondaryFilterChanged, 1000), []);

  return (
    <div className="col-lg-3 col-md-4 col-sm-4 col-4 renting-filter-wrapper">
      {/* Desktop View HTML starts here */}
      <div className="filter-list-wrap responsive-xl">
        <div className="filter-head-wrap">
          <h4>{filters.Filters}</h4>
          <a className="cp" onClick={handleResetstate}>
            {" "}
            <i className="fa-solid fa-rotate-right"></i>{" "}
          </a>
        </div>
        <div className="accordion" id="accordionPanelsStayOpenExample">
          <div className="accordion-item">
            <div className="accordion-header" id="panelsStayOpen-headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                {filters.VehicleType}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div className="accordion-body">
                <div className="vihicle-type-box">
                  <div className="form-check all-vehicle-type-label">
                    <input
                      value={""}
                      type={"radio"}
                      className="form-check-input  mt-0"
                      onChange={(e) =>
                        handleChange("vehicleType", e.target.value)
                      }
                      checked={secondaryFilter.vehicleType == ""}
                    />
                    <div>
                      <label className="form-check-label">All Vehicle Type</label>
                    </div>
                  </div>
                  {vehicleType.length > 0
                    ? vehicleType.map((type: any, ind: number) => (
                      <div className="form-check " key={type.id}>
                        <input
                          value={type.type_name}
                          type={"radio"}
                          className="form-check-input mt-0"
                          onChange={(e) =>
                            handleChange("vehicleType", e.target.value)
                          }
                          checked={
                            secondaryFilter.vehicleType == type.type_name
                          }
                        />
                        <label className="form-check-label">
                          {type.type_name}
                        </label>
                      </div>

                    ))
                    : null}
                </div>

                <InputField
                  value={"electric"}
                  name={"isElectric"}
                  type={"checkbox"}
                  className="form-check-input"
                  onChange={() => {
                    setSecondaryFilter((pre) => {
                      return {
                        ...pre,
                        isElectric: !secondaryFilter.isElectric,
                      };
                    });
                    secondaryFilterChanged(
                      "isElectric",
                      !secondaryFilter.isElectric
                    );
                  }}
                  isChecked={secondaryFilter.isElectric}
                  label="Electric"
                />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div className="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                {filters.PriceRange}
              </button>
            </div>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo"
            >
              <div className="accordion-body">
                <div className="d-flex justify-content-between">
                  <span>
                    {commmaSeparator(secondaryFilter?.priceRange &&
                      secondaryFilter?.priceRange[0])}{" "}
                    {"/-"}
                  </span>
                  <span>
                    {commmaSeparator(secondaryFilter?.priceRange &&
                      secondaryFilter?.priceRange[1])}
                    {"/-"}
                  </span>
                </div>
                <RangeSlider
                  min={minPrice}
                  max={maxPrice}
                  rangeSlideDisabled={true}
                  value={secondaryFilter.priceRange}
                  onInput={(value: Array<number>) => {
                    setSecondaryFilter((pre) => {
                      return {
                        ...pre,
                        priceRange: value,
                      };
                    });
                    debouncedChangeHandler("priceRange", value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseThree"
              >
                {filters.VehicleModel}
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree"
            >
              <div className="accordion-body">
                <div className="form-check">
                  <InputField
                    value={"0"}
                    name={"vehicleModel"}
                    type={"checkbox"}
                    className="form-check-input"
                    onChange={(name, value, checked) =>
                      handleCheckboxChange(name, value, checked)
                    }
                    isChecked={
                      secondaryFilter.vehicleModel?.filter(
                        (item) => item.checked
                      ).length == 0
                    }
                    label="All"
                  />
                </div>
                {secondaryFilter.vehicleModel?.length > 0
                  ? secondaryFilter.vehicleModel?.map(
                    (model: any, ind: number) => (
                      <div className="form-check" key={ind}>
                        <InputField
                          value={model?.id}
                          name={"vehicleModel"}
                          type={"checkbox"}
                          className="form-check-input"
                          onChange={(name, value, checked) =>
                            handleCheckboxChange(name, value, checked)
                          }
                          isChecked={model.checked}
                          label={model.model_name}
                        />
                      </div>
                    )
                  )
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Desktop View HTML ends here */}

      {/* Mobile View HTML starts here */}

      <a
        className="freedo-primary-button rounded-pill text-dark filter-offcanvasbtn d-md-none d-lg-none d-xl-none"
        data-bs-toggle="offcanvas"
        href="#offcanvasExample"
        role="button"
        aria-controls="offcanvasExample"
      >
        {filters.Filters}
      </a>

      <div
        className="offcanvas offcanvas-start mobileFiltersMain"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body">
          <button
            type="button"
            className="btn-close filter-btnclose"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
          <div className="filter-list-wrap responsive-sm">
            <div className="filter-head-wrap">
              <h4>{filters.Filters}</h4>
              <a
                onClick={() => {
                  let newState = [...secondaryFilter.vehicleModel].map(
                    (item) => {
                      item.checked = false;
                      return item;
                    }
                  );
                  setSecondaryFilter({
                    ...initialState,
                    priceRange: [minPrice, maxPrice],
                    vehicleModel: newState,
                  });
                }}
              >
                {" "}
                <i className="fa-solid fa-rotate-right"></i>{" "}
              </a>
            </div>
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <div
                  className="accordion-header"
                  id="panelsStayOpen-headingOne"
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    {filters.VehicleType}
                  </button>
                </div>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    <div className="vihicle-type-box">
                      <div className="all-vehicle-type-label ">
                        <input
                          value={""}
                          type={"radio"}
                          className="form-check-input mt-0"
                          onChange={(e) =>
                            handleChange("vehicleType", e.target.value, true)
                          }
                          checked={secondaryFilter.vehicleType == ""}
                        />
                        <div> <label className="form-check-label">
                          All Vehicle Type
                        </label>
                        </div>
                      </div>
                      {vehicleType.length > 0
                        ? vehicleType.map((type: any, ind: number) => (
                          <div className="form-check ml-3" key={ind}>

                            <input
                              value={type.type_name}
                              type={"radio"}
                              className="form-check-input -mt-1"
                              onChange={(e) =>
                                handleChange(
                                  "vehicleType",
                                  e.target.value,
                                  true
                                )
                              }
                              checked={
                                secondaryFilter.vehicleType == type.type_name
                              }
                            />
                            <div>
                              <label className="form-check-label ">
                                {type.type_name}
                              </label>
                            </div>
                          </div>
                        ))
                        : null}
                    </div>

                    <InputField
                      value={"electric"}
                      name={"isElectric"}
                      type={"checkbox"}
                      className="form-check-input"
                      onChange={() =>
                        setSecondaryFilter((pre) => {
                          return {
                            ...pre,
                            isElectric: !secondaryFilter.isElectric,
                          };
                        })
                      }
                      isChecked={secondaryFilter.isElectric}
                      label="Electric"
                    />
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div
                  className="accordion-header"
                  id="panelsStayOpen-headingTwo"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    {filters.PriceRange}
                  </button>
                </div>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body">
                    <div className="d-flex justify-content-between">
                      <span>
                        {secondaryFilter?.priceRange &&
                          secondaryFilter?.priceRange[0]}
                      </span>
                      <span>
                        {secondaryFilter?.priceRange &&
                          secondaryFilter?.priceRange[1]}
                      </span>
                    </div>
                    <RangeSlider
                      min={minPrice}
                      max={maxPrice}
                      rangeSlideDisabled={true}
                      value={secondaryFilter.priceRange}
                      onInput={(value: Array<number>) => {
                        setSecondaryFilter((pre) => {
                          return {
                            ...pre,
                            priceRange: value,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingThree"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    {filters.VehicleModel}
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div className="accordion-body">
                    <div className="form-check">
                      <InputField
                        value={"0"}
                        name={"vehicleModel"}
                        type={"checkbox"}
                        className="form-check-input"
                        onChange={(name, value, checked) =>
                          handleCheckboxChange(name, value, checked, true)
                        }
                        isChecked={
                          secondaryFilter.vehicleModel?.filter(
                            (item) => item.checked
                          ).length == 0
                        }
                        label="All"
                      />
                    </div>
                    {secondaryFilter.vehicleModel?.length > 0
                      ? secondaryFilter.vehicleModel?.map(
                        (model: any, ind: number) => (
                          <div className="form-check" key={ind}>
                            <InputField
                              value={model?.id}
                              name={"vehicleModel"}
                              type={"checkbox"}
                              className="form-check-input"
                              onChange={(name, value, checked) =>
                                handleCheckboxChange(
                                  name,
                                  value,
                                  checked,
                                  true
                                )
                              }
                              isChecked={model.checked}
                              label={model.model_name}
                            />
                          </div>
                        )
                      )
                      : null}
                  </div>
                </div>
              </div>
              <div className="float-end">
                <button
                  className="freedo-primary-button rounded-pill text-dark mt-3 mb-3"
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    secondaryFilterChanged("", "");
                  }}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile View HTML ends here */}
    </div>
  );
};

export default Filters;
