import { useRef, useState } from "react";

const TopFooter = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  const handleScroll = useRef(null);
  const handleMore = () => {
    setShowMore(!showMore);

    if (showMore)
      handleScroll.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  return (
    <section class="about-rental-wrap">
      <div class="container-fluid">
        <div class="row" ref={handleScroll}>
          <div class="col-md-12">
            {showMore ? <>{data?.jsxDetails}</> : <>{data?.jsxSnippet}</>}
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              {" "}
              <button
                className="rounded-pill freedo-primary-button text-dark"
                onClick={() => handleMore()}
              >
                {showMore ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopFooter;
