import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  userPic,
  settingTopBannerBike,
  userVerified,
  cameraProfile,
} from "../../assets";
import "./setting.css";
import Profile from "./myProfile";
import UpdateKYC from "./updateKYC";
import MyBookings from "./myBookings";
import { useLocation } from "react-router";
import SelectAddress from "../../component/SelectAddress";
import { windowSize } from "../../constant/enum";
import { FirebaseEvents } from "../../utills/firebaseEvents";
import EditProfilePicture from "./myProfile/editProfilePicture";
import UploadUserImageModal from "./uploadUserImageModal";
import { HelpModal } from "../../component/HelpModal";
const Setting = () => {
  const location = useLocation();
  const aadharVerified =
    location.search === "?confirmauthorization=true"
      ? true
      : location.search === "?confirmauthorization=false"
      ? false
      : null;

  const [activeLiObject, setActive] = useState(
    aadharVerified !== null ? "kyc" : location?.state?.values || "my-profile"
  );
  const { userData = {} } = useSelector(
    (state: RootState) => state.authReducer
  );
  const { userProfileDetail } = useSelector(
    (state: RootState) => state.profileReducer
  );
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [showEditPicMenu, setShowEditPicMenu] = useState<boolean>(false);
  const [showUploadModel, setShowUploadModel] = useState<boolean>(false);
  const [uploadSrcUri, setUploadSrcUri] = useState<any>(null);
  const [profileOptionSelected, setProfileOptionSelected] =
    useState<string>("");
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    if (aadharVerified === true) {
      console.log("KycAadharUploadSuccess", {});
      FirebaseEvents("KycAadharUploadSuccess", {});
    } else if (aadharVerified === false) {
      FirebaseEvents("KycAadharUploadFailure", {});
      console.log("KycAadharUploadFailure", {});
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (location?.state?.values) {
      setActive(location?.state?.values);
    } else {
      setActive((prev) => (aadharVerified !== null ? "kyc" : prev));
    }
  }, [location?.state?.values]);
  const handleChange = () => {
    window.scrollTo(0, 0);
  };
  const handleEditProfilePic = () => {
    setShowEditPicMenu(!showEditPicMenu);
  };
  const handleCapturedImage = (img: any, type: string) => {
    
    if (type === "view") {
      setProfileOptionSelected(type);
      setUploadSrcUri(null);
      setShowEditPicMenu(false);
      setShowUploadModel(true);
      // window.open(userProfileDetail.profile_image_url);
    } else {
      setProfileOptionSelected(type);
      setUploadSrcUri(img);
      setShowEditPicMenu(false);
      setShowUploadModel(true);
    }
  };

  const handlePreviewClose = () => {
    setShowUploadModel(false);
    setUploadSrcUri(null);
  };
  const onErrorProfileImage = (e: any) => {
    e.target.src = userPic;
  };
  return (
    <section className="Setting-wrapper">
      <div className="container-fluid">
        {(uploadSrcUri || profileOptionSelected === "view") &&
          showUploadModel && (
            <UploadUserImageModal
              img={uploadSrcUri}
              option={profileOptionSelected}
              handleClose={handlePreviewClose}
              changePictureHandle={()=> {setShowEditPicMenu(true);
                const ele = document.getElementById("uploadImageFile");
              ele && ele.click();}}
            ></UploadUserImageModal>
          )}
        <div className="setting-banner">
          <div className="set-top">
            <div className="left">
              <div className="user-pic">
                {
                  <img
                    className="user-image"
                    src={userProfileDetail.profile_image_url}
                    alt=""
                    onError={onErrorProfileImage}
                  />
                }
                <div className="overlay" onClick={handleEditProfilePic}>
                  <img src={cameraProfile} alt="" className="camera-image" />
                  <div className="text">Change profile photo</div>
                </div>
                <img className="verified-use" src={userVerified} alt="" />
                {/* <img
                  className="edit-use"
                  src={editBookingIcon}
                  alt=""
                  onClick={handleEditProfilePic}
                /> */}
                {(
                  <EditProfilePicture
                    show={showEditPicMenu}
                    handleClose={()=> {setShowEditPicMenu(false)}}
                    handleSelectedCallback={handleCapturedImage}
                  />
                )}
              </div>
              <div className="user-details">
                <h5>
                  {userData.first_name + " " + (userData.last_name || "")}
                </h5>
                <p>Rider</p>
              </div>
            </div>

            <div className="right">
              <img src={settingTopBannerBike} alt="" />
            </div>
          </div>
        </div>
      </div>
     
      <div className="setting-tab-wrap">
        <div className="container-fluid">
          {width >= windowSize.large ? (
            <div className="d-none d-sm-none d-md-none d-lg-block">
              <div className="d-flex align-items-start">
                <div
                  className="nav flex-column nav-pills me-3 setting-v-pill"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className={`nav-link ${
                      activeLiObject === "my-profile" ? "active" : ""
                    }`}
                    id="v-pills-home-tab"
                    type="button"
                    onClick={() => {
                      setActive("my-profile");
                      handleChange();
                      if (location.state) {
                        location.state.values = "my-profile";
                      }
                    }}
                  >
                    My Profile
                  </button>
                  <button
                    className={`nav-link ${
                      activeLiObject === "kyc" ? "active" : ""
                    }`}
                    id="v-pills-profile-tab"
                    type="button"
                    onClick={() => setActive("kyc")}
                  >
                    KYC
                  </button>
                  <button
                    className={`nav-link ${
                      activeLiObject === "my-bookings" ? "active" : ""
                    }`}
                    id="v-pills-messages-tab"
                    type="button"
                    onClick={() => setActive("my-bookings")}
                  >
                    My Bookings
                  </button>
                  <button
                    className={`nav-link ${
                      activeLiObject === "my-address" ? "active" : ""
                    }`}
                    id="v-pills-settings-tab"
                    type="button"
                    onClick={() => setActive("my-address")}
                  >
                    My Addresses
                  </button>
                </div>

                <div className="tab-content">
                  {activeLiObject === "my-profile" ? <Profile /> : null}
                  {activeLiObject === "kyc" ? <UpdateKYC /> : null}
                  {activeLiObject === "my-bookings" ? <MyBookings /> : null}
                  {activeLiObject === "my-address" ? (
                    <SelectAddress
                      timeSlotDetail={{}}
                      handleChange={() => {}}
                    />
                  ) : null}
                </div>
                
              </div>
              
            </div>
          ) : (
            <div className="d-md-block d-lg-none">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      onClick={() => {
                        setActive("my-profile");
                        handleChange();
                        if (location.state) {
                          location.state.values = "my-profile";
                        }
                      }}
                      className={`accordion-button ${
                        activeLiObject === "my-profile" ? "" : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      My Profile
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className={`accordion-collapse collapse ${
                      activeLiObject === "my-profile" ? "show" : ""
                    }`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Profile />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${
                        activeLiObject === "kyc" ? "" : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      onClick={() => {
                        setActive("kyc");
                        handleChange();
                      }}
                    >
                      KYC
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className={`accordion-collapse collapse ${
                      activeLiObject === "kyc" ? "show" : ""
                    }`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <UpdateKYC />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${
                        activeLiObject === "my-bookings" ? "" : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      onClick={() => {
                        setActive("my-bookings");
                        handleChange();
                      }}
                    >
                      My Bookings
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className={`accordion-collapse collapse ${
                      activeLiObject === "my-bookings" ? "show" : ""
                    }`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <MyBookings />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${
                        activeLiObject === "my-address" ? "" : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                      onClick={() => {
                        setActive("my-address");
                        handleChange();
                      }}
                    >
                      My Addresses
                    </button>
                  </h2>
                  <div
                    id="collapsefour"
                    className={`accordion-collapse collapse ${
                      activeLiObject === "my-address" ? "show" : ""
                    }`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <SelectAddress
                        timeSlotDetail={{}}
                        handleChange={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Setting;
