import React, { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toggleSignInDrawer } from "../../utills/helper";

const BlogCard = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(
    (state: RootState) => state.authReducer
  );

  const {
    imgSrc = "",
    topic = "",
    title = "",
    date = "",
    location = "",
    content = "",
    link = "",
    type = "",
  } = data || {};

  const onImageClick = () => {
    if (type === "freedoAd") {
      toggleSignInDrawer()
    } else {
      navigate(`/blog/${link}`);
    }
  };

  if (type === "freedoAd" && isAuthenticated) return null;

  return (
    <div className="col-lg-4 col-sm-6 my-2">
      <div onClick={onImageClick}>
        {" "}
        <img className="blog-card-img" src={imgSrc} alt={topic} />
      </div>

      {topic && (
        <div className="blog-content">
          <span className="badge bg-freedo-theme fw-normal mb-3"> {topic}</span>
          <h5 className="mb-2">
            <Link to={`/blog/${link}`} className="text-dark d-block">
              {" "}
              {title}
            </Link>{" "}
          </h5>
          <div className="blog-time text-dark mb-2">
            <i className="far fa-calendar"></i> {date} / {location}
          </div>
          <p className="blog-content-snippet text-secondary fw-light">
            {content}
          </p>

          <Link
            to={`/blog/${link}`}
            className="text-dark pt-2 d-block text-decoration-underline"
          >
            Read More
          </Link>
        </div>
      )}
    </div>
  );
};

export default memo(BlogCard);
