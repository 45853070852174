import React from "react";
import { banner } from "../../assets";
import { Link, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";

const index = () => {
  return (
    <>
    <Helmet>
    <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
    <meta
     name="description"
     content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
    />
    <meta name="author" content="Freedo Rentals" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta
     property="og:title"
     content="Bike Rentals in Gurgaon | Bike For Rent in Gurgaon"
    />
    <meta
     property="og:description"
     content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
    />
    <meta property="og:url" content="https://freedo.rentals/privacy-policy" />
    <meta property="og:image" content="https://freedo.rentals/page image url" />
    <meta property="og:site_name" content="Freedo Rentals" />
    <link rel="canonical" href="https://freedo.rentals/privacy-policy/" />
    <meta name="robots" content="index, follow" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
   </Helmet>
    <div>
      <div className="privacy-banner" style={{ backgroundImage: `url(${banner})` }}>
        <h5 className="banner-title">FREEDO PRIVACY POLICY</h5>
      </div>
      <div className="freedo-policy container">
        <div className="card mb-5">
          <div className="card-body px-4">
            <div className="mt-2">
              <p>
                We at <b>FREEDO RENTALS,</b> are committed to provide you details about how
                we handle your information. This <b>Freedo Privacy Policy</b> (referred here
                as the "Privacy Policy") applies to the information, that we collect
                and process about users of our Services, and those who communicate
                with us about our Services, interact with us on various platforms but
                not limited to ones in social media, our events, our surveys, contests
                and promotions, or are subscribed to our marketing and informational
                communications (the "Interactions").
              </p>
              <p>
                In this Privacy Policy, "Services" App and Vehicle respectively means:
              </p>
              <p>
                The rental service provided FREEDO as explained in our Annexure.
                Freedo mobile applications provided by Freedo to avail the service,
                and Freedo vehicles booked through the application.
              </p>
              <p>
                We have established this Privacy Policy to let you know the kinds of
                information we may gather during your use of the Services and related
                other Interactions, how we use your information, when we might
                disclose your information, and your rights and choices regarding your
                information that we collect and process.
              </p>
              <p>
                Freedo is a wholly owned and controlled enterprise of Hero MotoCorp
                Limited. Freedo name and its logo are registered trademark of Hero
                MotoCorp Limited.
              </p>
              <h6 className="mb-1">
                GOVERNING LAW
              </h6>
              <p>
                For any of the concerns and actions for or against us regarding this
                Privacy Policy it will be governed under Indian law. In case of any
                dispute between the parties, the courts of New Delhi shall have
                exclusive jurisdiction over any dispute arising out of or in
                connection with this agreement.
              </p>
              <h6 className="mb-1">
                THE INFORMATION WE COLLECT
              </h6>
              <p>
                We collect information related to our Services and Interactions
                directly from users, automatically related to their use of the
                Services, its various features and our Interactions, as well as from
                third parties. We may combine the information we collect from the
                following mentioned sources.
              </p>
              <div className="mt-1">
                <p>1. Information You Provide to Us.</p>
                <div>
                  <p>
                    We collect information directly from users:
                  </p>
                  <ul className="list-unstyled px-3">
                    <li>
                      <p>
                        a) Account registration, management, profile creation and
                        modification
                      </p>
                    </li>
                    <li>
                      <p>
                        b) Account access and use, as well as uploading content to the
                        Services and other associated activities
                      </p>
                    </li>
                    <li>
                      <p>c) Vehicle use</p>
                    </li>
                    <li>
                      <p>
                        d) Access to and use of the Sites and Apps
                      </p>
                    </li>
                    <li>
                      <p>
                        e) Submission of payment information
                      </p>
                    </li>
                    <li>
                      <p>
                        f) Participation in surveys, contests and promotions
                      </p>
                    </li>
                    <li>
                      <p>
                        g) Signing up to receive alerts or other information via email, text
                        or instant messages
                      </p>
                    </li>
                    <li>
                      <p className="dataTextRegularNotJustify">
                        h) Customer service, technical support, and related communications
                      </p>
                    </li>
                    <li>
                      <p className="dataTextRegularNotJustify">
                        i) Interaction on social media, and participation in other forums
                        forums
                      </p>
                    </li>
                    <li><p className="dataTextRegularNotJustify">
                      j) Submission of a feedback
                    </p>
                    </li>
                  </ul>
                  <p>
                    The types of information we collect directly from you are: your name,
                    e-mail address, phone number, postal address, other contact
                    information, billing details, communications preferences, payment and
                    transaction history, your date of birth and driver’s license
                    information or other national government approved identification card,
                    and any other information you submit to the Services or otherwise
                    provide to us. We also collect certain demographic data if you provide
                    it to us including age, gender, preferred language, and location data
                    including but not limited to current location and destination
                    location.
                  </p>
                  <p className="dataTextBold">
                    2. Automatically Collected Information.<br></br>
                    We also collect information through automated and technical means
                    as you browse our Sites, use our Apps, or otherwise use the
                    Services:-
                  </p>
                  <ul className="list-unstyled px-3">
                    <li>
                      <p>
                        <b>  a) Device and Online Usage.</b><br></br>
                        We collect information about your computer, browser, mobile or other device that you use to access the Services. We may use cookies,
                        log files and other similar technologies to collect such information, dates and times you access our Services.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b> b) Location Info and Vehicle Usage.</b><br></br>
                        We automatically collect and store location information from your device and from any Vehicles you use.
                        We collect and store the location information (e.g., city, state or zip code where available) associated with the IP address of the device you use to access the Services,
                        as well as, with your permission, your mobile device’s location information using GPS or Bluetooth (you can change your location/Bluetooth settings for your mobile device;
                        however, certain features may not be available through the App if you do so). We store other details associated with your
                        order like the kind of vehicle chosen, the model, hiring period,
                        and the driving pattern including the time and the behaviour.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b> c) Analytics.</b> <br></br>
                        We compile and analyse information derived from the use of our Services,
                        such as aggregate usage patterns, user preferences, peak demand times, common routes and other information.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>d) Third Party Platforms and Social Media Sites.</b> <br></br>
                        When you interact with us or post content about us on third-party social media platforms,
                        we may collect certain information about that interaction; the information that we may collect is based on your
                        settings on and the policies of these social media platforms.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b> e) Other Third-Party Sources.</b> <br></br>
                        We also may collect information about you that we may receive from business partners which aid in the services
                        of our application, marketers, analysts and other sources to, enable us to verify and update information contained
                        in our records and better customize the Services for you.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>f) Referrals.</b> <br></br>
                        We may from time to time conduct a referral service so that you may introduce people you know to our services.
                        If you choose to use our referral service to tell someone about our Services, we will provide you with a template
                        message and referral code to send to your friend. We will not collect the referral’s information unless
                        he/she signs up to use the Services with the referral code.
                      </p>
                    </li>
                  </ul>
                  <h6 className="mb-1">
                    INTENT OF INFORMATION
                  </h6>
                  <p>The information we collect serve the following purposes:</p>
                  <ul className="list-unstyled px-3">
                    <li>
                      <p>
                        <b>1. Providing Support and Services.</b>
                      </p>
                      <ul className="list-unstyled px-3">
                        <li>
                          <p>
                            a) To provide and operate the Services and related features, fulfill your orders and requests and to process your payments.
                          </p>
                        </li>
                        <li>
                          <p>
                            b) To update the Services.
                          </p>
                        </li>
                        <li>
                          <p>
                            c) To ensure the safety of you and all others associated with this service.
                          </p>
                        </li>
                        <li>
                          <p>
                            d) To track Vehicles, including location, battery levels and rental status.
                          </p>
                        </li>
                        <li>
                          <p>
                            e) To permit you to update, edit, and manage your content.
                          </p>
                        </li>
                        <li>
                          <p>
                            f) To communicate with you about your use of the Services and respond to your inquiries and complaints.
                          </p>
                        </li>
                        <li>
                          <p>
                            g) For troubleshooting, technical and customer service and support purposes
                          </p>
                        </li>
                        <li>
                          <p>
                            h) For improving our services as per your feedbacks.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <b>2. Verification</b>
                      </p>
                      <ul className="list-unstyled px-3">
                        <li>
                          <p> a) To verify the identity of users, applicants and others with whom we interact.</p>
                        </li>
                        <li>
                          <p>b) To confirm authorization of users that access and use the Services. </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <b>3. Improve Service and Analytics</b>
                      </p>
                      <ul className="list-unstyled px-3">
                        <li>
                          <p>a) To aggregate information for analyzing and evaluating.</p>
                        </li>
                        <li>
                          <p>b) To confirm authorization of users that access and use the Services. </p>
                        </li>
                        <li>
                          <p>c) To perform statistical, demographic, and marketing analyses of our users.</p>
                        </li>
                        <li>
                          <p>d) To analyze and understand usage and activity trends, demographic trends and for other research, analytical, and statistical purposes.</p>
                        </li>
                        <li></li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <b>4. Communication</b>
                      </p>
                      <ul className="list-unstyled px-3">
                        <li>
                          <p>a) To communicate with you about your account or transactions with us (including Services-related announcements).</p>
                        </li>
                        <li>
                          <p>b) To communicate with you about changes to our policies, our terms and conditions and service updates.</p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <b>5. Personalize Services, Marketing and Promotions</b>
                      </p>
                      <ul className="list-unstyled px-3">
                        <li>
                          <p>a) To personalize content and experiences on our Services, including providing you reports, recommendations, and feedback based on your preferences, and to use your location information for personalization purposes.
                          </p>
                        </li>
                        <li>
                          <p>b) To better target ads so that users receive ads that are relevant to them.</p>
                        </li>
                        <li>
                          <p>c) To send you information, news, updates and offers about us or our Services (subject to your consent).</p>
                        </li>
                        <li>
                          <p> d) For other direct marketing and promotional purposes.</p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <b>6. Protect Legal Rights and Prevent Misuse</b>
                      </p>
                      <ul className="list-unstyled px-3">
                        <li>
                          <p>a) To protect the Services and our business operations.
                          </p>
                        </li>
                        <li>
                          <p>b) To detect, investigate, prevent or take action regarding illegal activities, misuse, suspected fraud or situations involving potential threats to the safety or legal rights of any person or entity, and as evidence in litigation.
                          </p>
                        </li>
                        <li>
                          <p>c) To investigate, enforce and prevent violations of our policies and terms (including this Privacy Policy, our Terms of Use and Rental Agreement), as otherwise necessary to establish, protect and defend our legal rights</p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <b>7. Complying with Legal Obligations</b>
                      </p>
                      <ul className="list-unstyled px-3">
                        <li>
                          <p>a) To comply with the law.
                          </p>
                        </li>
                        <li>
                          <p>b) To respond to legal process or enforcement or legal process requests.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <b>8. General Business Operations</b>
                      </p>
                      <ul className="list-unstyled px-3">
                        <li>
                          <p>a) For the administration of our general business comprising- accounting, recordkeeping and legal functions.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <h6 className="mb-1">
                    DISCLOSURE OF YOUR INFORMATION
                  </h6>
                  <p> We disclose the information we collect, in the following ways:</p>
                  <ul className="list-unstyled">
                    <li>
                      <p><b>1. Business Partners and Third Parties:</b>  We may share your information with business partners who support us in providing you the service in furtherance of the purposes set out in this Policy; their use of your information is subject to this Privacy Policy. where required by applicable law, we will obtain your prior consent. You may at any time withdraw your consent or tell us to stop sharing your personal information with business partners and third parties.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>2. Third-Party Service Providers:</b> : We use a variety of third party service providers that perform functions on our behalf, such as user detail verification, billing and payment processing, notifications, tracking, storage, content management tools, analytics, customer service, service etc.
                      </p>
                    </li>
                    <li>
                      <p><b>3. General Business Operations:</b> Where necessary to the administration of our general business, accounting, record keeping and legal functions, to our tax advisors, legal counsel and other professional services entities or agents.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>4. Legal Compliance and Protections of Rights:</b> We may also use or disclose information if required to do so by law or if such action is necessary to establish, protect and defend our rights or property, the Services or our users, including to prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of our Terms of Use, Rental Agreement, other agreements or policies.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>5. Aggregate Information:</b> We may share aggregated information about usage of the Services time to time with third parties for research, marketing, analytics and other purposes, provided such information does not identify a particular individual, such as by publishing a report on usage trends. The sharing of such data is unrestricted.
                      </p>
                    </li>
                  </ul>
                  <h6 className="mb-1">USE OF COOKIES</h6>
                  <p>Like most Sites and Apps and online Services, we use "cookies," to record your preferences,
                    gather information about the use of our Services, personalize content and ads and track information about the
                    performance of our advertisements. We may also use these technologies to monitor traffic and make the Services
                    easier and/or more relevant for your use. We may combine this information with other information we collect from you.</p>
                  <h6 className="mb-1">HOW WE PROTECT YOUR INFORMATION</h6>
                  <p>We store and process your information, including any sensitive
                    financial information collected (as defined under the Information
                    Technology Act, 2000), if any, on computers that may be protected by
                    physical as well as reasonable technological security measures and
                    procedures in accordance to Information Technology Act 2000 and under
                    reasonable security practices and procedures and sensitive personal
                    data of information under rules 2011 of IT Act 2000.<br></br>
                    We take necessary security measures to protect your information
                    against accidental or unlawful destruction or accidental loss and from
                    any kind of unauthorized access. However, in this evolving time of
                    technology, no method of transmission over the Internet, and no means
                    of electronic or physical storage, is absolutely secure. We encourage
                    you to take steps to protect your information and prevent unauthorized
                    access to your password or account by keeping your log-in and password
                    private. We are not responsible for any lost, stolen, or compromised
                    passwords, or for any activity on your account via unauthorized
                    password activity.
                  </p>
                  <h6 className="mb-1"> RETENTION OF YOUR INFORMATION</h6>
                  <p> We retain your information a for as long as required to satisfy the
                    purpose for which it is collected and used (for example, for the time
                    necessary for us to provide you with customer service, answer queries
                    or resolve technical problems), unless a longer period is necessary
                    for our legal obligations or to establish, protect, or defend legal
                    claims.
                  </p>
                  <h6 className="mb-1"> THIRD PARTY LINKS AND SERVICES</h6>
                  <p> The Services provided by us in any form may contain links to
                    third-party websites or other platforms such as social media sites,
                    and also contain third-party plug-ins and functionalities. If you
                    choose to use these sites or features, you may disclose your
                    information not just to those third parties, but also to their users
                    and the public more generally depending on how their services
                    function. We are not responsible for the content or practices of those
                    websites or services. The collection, use, and disclosure of your
                    information will be subject to the privacy policies of the third-party
                    websites or services, and not this Privacy Policy. We therefore urge
                    you to read the privacy and security policies of these third parties.
                  </p>
                  <h6 className="mb-1"> CHANGES TO THIS PRIVACY POLICY</h6>
                  <p> We reserve the right to amend this Privacy Policy at any time to
                    reflect changes in the law, our data collection and use practices, the
                    features of our Services, or advances in technology. We will make the
                    revised Privacy Policy accessible through the Services and updating
                    the "Effective Date" for the Privacy Policy. If we make a material
                    change to the Privacy Policy, you will be provided with appropriate
                    notice in accordance with legal requirements. By continuing to use the
                    Services, you are confirming that you have read and understood the
                    latest version of this Privacy Policy.
                  </p>
                  <h6 className="mb-1"><Link to="/privacy-policy/delete-account">DELETE ACCOUNT</Link></h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default index;
