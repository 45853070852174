import { defaultHeaders } from "../config/axiosConfig";
import { NODE_ENV } from "../config/envConfig";

console.log("process.env.NODE_ENV", NODE_ENV)

declare global {
  interface Window {
    fbq: any;
  }
}

export const EventApi = (props: any) => {
    // @ts-ignore  
  if (NODE_ENV == "production") {
    try {
      console.log("Event Api called here", props?.data, props?.data?.length);
      if (props?.data && props?.data?.length > 0)
        window.fbq("track", props?.data[0]["event_name"]);
      fetch(
        "https://graph.facebook.com/v15.0/1467541263764811/events?access_token=EAAStorfwESUBAD1jpzUl7l93caUw39ZAjUenAL5JiVGIZA9adOg6ZBubYosEg7EB9k9ZBWq8JA5R215SfkFmjcTdjdPe6tr9ZBxuxfGqudf1fi68GNZAxdoYXDu2CQT6TAQ3WuK7FKhRlPu5XmWACP7ZBZAPU6vdmYt4ZABB2QvRDtSPkL0y0b5426A7tt7KHTLQZD",
        {
          method: "post",
          headers: new Headers({
            ...defaultHeaders,
            "Content-Type": "application/json",
          }),
          mode: "cors",
          body: JSON.stringify(props?.data),
        }
      )
        .then((res) => {
          return res;
        })
        .catch(function (error) { });
    } catch (error) {
      console.log("this is error", error);
    }
  } else {
    console.log("Event is not called here as it is", NODE_ENV);
  }
};
