import { getParamsString } from "../utills/helper";
import { API_URLS, LatestVersion, versioning } from "./apiUrls";
const {
  getMaster,
  packageMaster,
  getVehicleModelListForCustomer,
  vehicleType,
  getVehicleModelListDetails,
  advertisement,
  getDelAccOptions
} = API_URLS;
const getMasterData = (params: any) => {
  switch (params.type) {
    case "GetPackages":
      return `${getMaster}/${packageMaster}/0${
        params.id ? "?city_id=" + params.id : ""
      }`;
    case "GetCityList":
      return `${API_URLS.getMaster}/${API_URLS.getCityList}`;
    case "GetCarouselDetails":
      return `/v2/${API_URLS.getCityById}`;
    case "VehicleType":
      return `${getMaster}/${vehicleType}/0`;
    case "Advertisement":
      return `${getMaster}/${advertisement}/0`;
    case "delAccOptions":
      return `${getMaster}/${getDelAccOptions}/0`;
    
    
  }
};
const getVehicleList = (params: any) => {
  let baseUrl = `${LatestVersion}public/${getVehicleModelListForCustomer}/?`;
  baseUrl +=
    params.packageId && params.packageId != 0
      ? `packageId=${params.packageId}`
      : "";
  baseUrl +=
    params.cityId && params.cityId != 0 ? `&cityId=${params.cityId}` : "";
  baseUrl +=
    params?.modelIds?.length > 0
      ? `&modelIds[]=[${params.modelIds.join(",")}]`
      : "";
  baseUrl +=
    params.startDate && params.startDate != 0
      ? `&startDate=${params.startDate}`
      : "";
  baseUrl +=
    params.endDate && params.endDate != 0 ? `&endDate=${params.endDate}` : "";
  baseUrl += params.vehicleType ? `&vehicleType=${params.vehicleType}` : "";
  baseUrl += params.priceRange ? `&priceRange=${params.priceRange}` : "";
  baseUrl +=
    params.sortBy && params.sortBy != null ? `&sortBy=${params.sortBy}` : "";
  baseUrl +=
    params.isElectric !== undefined ? `&isElectric=${params.isElectric}` : "";
  return baseUrl;
};
export const fetchVehicleModelListDetails = (params) => {
  let route = `${LatestVersion}${getVehicleModelListDetails}?`;
  route += params.city_id ? `&cityId=${params.city_id}` : "";
  route += params.model_id ? `&modelId=${params.model_id}` : "";
  route += params.start_date ? `&startDate=${params.start_date}` : "";
  route += params.end_date ? `&endDate=${params.end_date}` : "";
  // route += params.locationId ? `&locationId=${params.locationId}` : "";
  route += params.packageId ? `&packageId=${params.packageId}` : "";
  route += params.sourceId ? `&sourceId=${params.sourceId}` : "";
  return route;
};
const getNearestCityParams = (params:any)=>{
  let baseUrl = `${LatestVersion}${API_URLS.findNearestCity}`+getParamsString(params);
  return baseUrl;
}


const getUrlWithParams = (url,params: any) => {
  let baseUrl =url 
  let array = Object.keys(params)
  for (let index = 0; index < array.length; index++) {
    const key = array[index];
    baseUrl += params[key] ? `&${key}=${params[key]}` : "";
  }
  return baseUrl
};

export { getMasterData, getVehicleList,getNearestCityParams,getUrlWithParams };
