import Skeleton from "react-loading-skeleton";

export default () => {
  return (
    <tbody>
      {[1, 2, 3, 4].map((_keys) => {
        return (
          <tr key={_keys}>
            <td><Skeleton width={"200px"} height={"20px"} /></td>
            <td><Skeleton width={"20px"} height={"20px"} borderRadius={"5px"} /></td>
          </tr>
        );
      })}
    </tbody>
  );
};