import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { phone } from "../../assets";
import { Button1 } from "../../component/Button";
import NewInputField from "../../component/NewInputField";
import OtpInput from "../../component/OTPField";
import { SignInProps } from "../../interfaces/authInterface";
import en from "../../locales/en.json";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";
import { FirebaseEvents } from "../../utills/firebaseEvents";
import { validate } from "../../utills/helper";
import { checkPhone } from "../../utills/regex";

const SignIn = ({ setIsSignIn }: SignInProps): JSX.Element => {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const navigate = useNavigate();
  const { authLoader, showOtpScreen } = useSelector(
    (state: RootState) => state.authReducer
  );
  const [seconds, setSeconds] = useState<number>(30);

  const { AuthScreens } = locale;

  const clearOtpRef = useRef<any>({});
  const dispatch = useDispatch();
  const initialState = {
    mobile: "",
    otp: "",
  };
  const [fields, setFields] = useState({ ...initialState });
  const [errorFields, setErrorFields] = useState({
    mobile: "",
  });
  const onChange = (name: string, value: string) => {
    setFields((prevState) => ({ ...prevState, [name]: value }));
    setErrorFields((prevState) => ({ ...prevState, [name]: "" }));
  };

  const callOnchange = useCallback(
    (otp: string) => {
      onChange("otp", otp);
    },
    [fields.otp]
  );
  const validateForm = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      const validation = validate(fields, errorFields);
      if (validation.error) {
        return setErrorFields({ ...errorFields, ...validation.errorFields });
      }
      submitForm();
    },
    [authLoader, fields]
  );

  const onClickResendOtp = (e: SyntheticEvent) => {
    e.preventDefault();
    setSeconds(30);
    dispatch(
      actions.resendOtp({
        email_otp: true,
        mobile_number: fields.mobile,
      })
    );
    clearOtpRef.current?.clearOtp();
  };
  const submitForm = () => {
    if (showOtpScreen) {
      console.log("VerifyOtp", { mobile: fields.mobile });
      FirebaseEvents("VerifyOtp", { mobile: fields.mobile });
      dispatch(
        actions.otpVerification({
          param: {
            mobile_number: fields.mobile,
            otp: fields.otp,
          },
          navigate,
        })
      );
    } else {
      FirebaseEvents("SendOtp", { mobile: fields.mobile });
      console.log("SendOtp", { mobile: fields.mobile });
      dispatch(
        actions.userLoginRequested({
          email_otp: true,
          mobile_number: fields.mobile,
        })
      );
    }
  };

  useEffect(() => {
    if (showOtpScreen) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          clearInterval(interval);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, showOtpScreen]);

  const otpScreen = () => {
    return (
      <div className="offcanvas-body">
        <h3>{AuthScreens["OTPVerification"]}</h3>
        <p className="mt-3 text-muted text-center">
          {AuthScreens["OTPWarning"]}
        </p>
        <p className="text-center mt-1 fw-bold">+91 {fields.mobile}</p>
        <form onSubmit={validateForm}>
          <OtpInput ref={clearOtpRef} handleChange={callOnchange} />
          <Button1
            type="submit"
            disabled={fields.otp.length < 6}
            className="freedo-primary-button rounded-pill w-100 mt-3"
            title={AuthScreens["VerifyCode"]}
            loading={authLoader}
            onSubmit={validateForm}
          />
        </form>
        <div className="text-center mt-1 resend-otp">
          {seconds ? (
            <>
              <span>{`${AuthScreens["resendOtpIn"]}${
                seconds >= 10 ? seconds : "0" + seconds
              }`}</span>
            </>
          ) : (
            <Link
              to=""
              onClick={(e: SyntheticEvent) => {
                onClickResendOtp(e);
              }}
            >
              {AuthScreens["resendOtp"]}
            </Link>
          )}
        </div>
      </div>
    );
  };

  const phoneScreen = () => {
    return (
      <div className="offcanvas-body">
        <h3>{AuthScreens["SignIn"]}</h3>
        <span className="signInToggle mb-4">
          {AuthScreens["dontHaveAccount"]}
          <span className="link" onClick={() => setIsSignIn(true)}>
            {en.AuthScreens.SignUp}
          </span>
        </span>
        <form onSubmit={validateForm}>
          {/*  <Form
              type="text"
              required
              placeholder="Enter mobile number"
              img={phone}
              label={AuthScreens["PhoneNumber"]}
              id="basic-addon1"
              value={fields.mobile}
              onChange={onChange}
              name="mobile"
              maxLength={10}
              errorMsg={errorFields.mobile}
              restrictFuntion={checkPhone}
              inputMode="numeric"
              pattern="[0-9]*"
            /> */}

          <NewInputField
            type="text"
            required
            placeholder="Enter mobile number"
            img={phone}
            label={AuthScreens["PhoneNumber"]}
            id="basic-addon1"
            value={fields.mobile}
            onChange={onChange}
            name="mobile"
            maxLength={10}
            errorMsg={errorFields.mobile}
            restrictFuntion={checkPhone}
            inputMode="numeric"
            pattern="[0-9]*"
          />

          <Button1
            type="submit"
            disabled={!fields.mobile}
            className="freedo-primary-button rounded-pill w-100 mt-3"
            title={AuthScreens["SignIn"]}
            loading={authLoader}
            onSubmit={validateForm}
          />
          <div className="privacyPolicy signInToggle">
            {AuthScreens["tNcNoteSignIn"]}
            <a className="link" href="/terms-conditions" target="_blank">
              {AuthScreens["termsAndConditions"]}
            </a>
            {AuthScreens["and"]}
            <a className="link" href="/privacy-policy" target="_blank">
              {AuthScreens["privacyPolicy"]}
            </a>
          </div>
        </form>
      </div>
    );
  };

  // main return
  return <>{showOtpScreen ? otpScreen() : phoneScreen()}</>;
};

export default SignIn;
