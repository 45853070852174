import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { delivermode } from "../assets";
import { getHomeDeliverCharge } from "../redux/actions";
import { MitcFormsSkelaton } from "./SkelatonLoader/MitcFormsSkelaton";
const DeliveryMode = (props: any) => {
  const { cityData, branchID } = props;
  const [isHomeDelivery, setIsHomedelivery] = useState<boolean>(false);
  const [homeDeliveryChargeData, setHomeDeliveryChargeData] =
    useState<any>(null);
  const dispatch = useDispatch();
  const { homeDeliverCharges, homeAddressList, addressNotDeliverable } =
    useSelector((state: RootState) => state.checkoutReducer);
  const [deliveryChargeLoader, setDeliveryChargeLoader] =
    useState<boolean>(true);
  const hasAddress = !!homeAddressList.length;

  const getCityId = () => {
    const selectedAddress = { ...props.addressData };
    if (selectedAddress) {
      return selectedAddress?.city?.id ?? 0;
    } else {
      return cityData ? cityData.id : 0;
    }
  };

  // returns selected address id
  const getAddressId = () => {
    const selectedAddress = { ...props.addressData };
    if (selectedAddress) {
      return selectedAddress?.id ?? 0;
    } else {
      return cityData ? cityData.id : 0;
    }
  };

  useEffect(() => {
    if (isHomeDelivery && hasAddress) {
      let payload = {
        city_id: getCityId(),
        branch_id: props?.selectedBranch?.id,
        addressId: getAddressId(),
      };
      dispatch(
        getHomeDeliverCharge(
          payload,
          setDeliveryChargeLoader,
          updateHomeDeliveryCharge
        )
      );
    } else {
      setHomeDeliveryChargeData(null);
    }
  }, [isHomeDelivery, props.addressData, props?.selectedBranch?.id]);

  useEffect(() => {
    setHomeDeliveryChargeData(homeDeliverCharges);
    props.handleChange("changeData", homeDeliverCharges);
  }, [homeDeliverCharges]);
  const handleValueChange = (value: boolean) => {
    setIsHomedelivery(value);
    props.handleChange("value", value);
  };

  const updateHomeDeliveryCharge = (value) => {
    props.handleChange("changeData", value);
  };

  return (
    <div className="mode-delivery row">
      <div className="col-sm-4 col-md-5">
        <img src={delivermode} width="20" className="mode-of-delivery" />
        <span className="mode-of-delivery">Mode Of Delivery</span>
      </div>
      <div className="col-sm-8 col-md-7" style={{ display: "flex" }}>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            id="flexRadioDefault1"
            name="drone"
            value="huey"
            checked={isHomeDelivery}
            onChange={() => handleValueChange(true)}
          />
          <label className="form-check-label">Home Delivery</label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            id="flexRadioDefault2"
            name="drone"
            value="huey"
            checked={!isHomeDelivery}
            onChange={() => handleValueChange(false)}
          />
          <label className="form-check-label">Pick-up Vehicle</label>
        </div>
      </div>
      {isHomeDelivery && (
        <div className="applicable-info">
          {!hasAddress ? (
            <p className="pay-now-term-and-condition">
              <em
                className="add-address-home-delivery text-decoration-underline"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvaschangeaddress"
                aria-controls="offcanvaschangeaddress"
              >
                Add address
              </em>{" "}
              for home delivery
            </p>
          ) : (
            <em>
              {deliveryChargeLoader ? (
                <MitcFormsSkelaton />
              ) : addressNotDeliverable?.Message ? (
                <p className="address-not-deliverable-text">
                  <span>*</span>
                  {addressNotDeliverable?.Message} Please select different
                  address.
                </p>
              ) : (
                isHomeDelivery && (
                  <>
                    <span>*</span>
                    {`Applicable One-Way Home Delivery Charges: ₹${
                      homeDeliveryChargeData?.home_delivery_charge
                        ? homeDeliveryChargeData?.home_delivery_charge
                        : 0
                    }. Home pickup would be charged separately`}
                  </>
                )
              )}
            </em>
          )}
        </div>
      )}
    </div>
  );
};

export default DeliveryMode;
