import { call, put, takeLatest } from "redux-saga/effects";
import { AUTH_ACTIONS, PROFILE_ACTIONS } from "../actions/actionTypes";
import profileServices from "../services/profileServices";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../utills/helper";
const {
  GET_USER_PROFILE,
  SET_USER_PROFILE,
  POST_UPDATE_PROFILE,
  GET_USER_KYC_STATUS,
  SET_USER_KYC_STATUS,
  UPLOAD_IMAGES_TO_S3,
  GET_ADDITIONAL_DOCS_STATUS,
  SET_ADDITIONAL_DOCS_STATUS,
  CREATE_ADDITIONAL_DOC,
  SEND_OTP_FOR_EMERGENCY_NUMBER,
  VERIFY_OTP_FOR_EMERGENCY_NUMBER,
  GET_QUERIES_LIST,
  SET_QUERIES_LIST,

} = PROFILE_ACTIONS;

function* getUserProfile(): any {
  try {
    yield put({ 
      type: AUTH_ACTIONS.SET_USER_DATA_REQUESTED,
    })
    const data = yield call(profileServices.getUserProfile);
    if (data?.status === 200) {
      yield put({
        type: SET_USER_PROFILE,
        payload: data.data.user,
      });
      
      yield put({
        type: AUTH_ACTIONS.SET_USER_DATA,
        payload: data.data.user,
      });
      yield put({ 
        type: AUTH_ACTIONS.SET_USER_DATA_COMPLETED,
      })
    } else {
     // toastr.error("", handleMessage(data));
      
    }
  } catch (error) {
    //toastr.error("", handleMessage(error));
    yield put({ 
      type: AUTH_ACTIONS.SET_USER_DATA_COMPLETED,
    })
  }
}
function* postUpdateUserProfile(action: any) {
  try {
    const data = yield call(profileServices.postProfileUpdate, action.payload);
    console.log(data);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data.data.message));
      yield* getUserProfile();
    } else {
      toastr.error("", handleMessage(data.data.message));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}

function* getUserKycStatus(): any {
  try {
    const data = yield call(profileServices.getUserKycStatus);
    if (data?.status === 200) {
      yield put({
        type: SET_USER_KYC_STATUS,
        payload: data.data.result?.data,
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}

function* getAdditionalDocsData(): any {
  try {
    const data = yield call(profileServices.getDocumentsStatus);
    if (data?.status === 200) {
      yield put({
        type: SET_ADDITIONAL_DOCS_STATUS,
        payload: data?.data?.Result || {},
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}

function* setAdditionalDocs(action: any): any {
  const { payload, setIsLoadingDocs,callBack } = action.payload;
  try {
    const data = yield call(profileServices.createAdditionalDocs, payload);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      setIsLoadingDocs(false);
      callBack()
    } else {
      toastr.error("", handleMessage(data));
      setIsLoadingDocs(false);
    }
  } catch (error) {
    setIsLoadingDocs(false);
  }
}

function* uploadToS3(action: any): any {
  const { url, params, callBack, setIsLoadingImage } = action.payload;
  setIsLoadingImage(true);
  try {
    const data = yield call(profileServices.uploadFilesOnS3, url, params);
    if (data?.status === 200) {
      callBack();
      setIsLoadingImage(false);
    } else {
      setIsLoadingImage(false);
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}
function* sendOtpEmergency(action: any): any {
  const { setIsVerifyUI } = action.payload;
  try {
    const data = yield call(profileServices.sendOtpEmegencyNumber);
    console.log(data);
    if (data?.status === 200) {
      setIsVerifyUI(true)
    } else {
      toastr.error("", handleMessage(data.data.message));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}
function* verifyOtpEmergency(action: any) {
  const { setIsVerifyUI, payload , setIsContactVerified} = action.payload;
  try {
    const data = yield call(profileServices.verifyOtpEmegencyNumber, payload);
    console.log(data);
    if (data?.status === 200) {
      setIsVerifyUI(false)
      setIsContactVerified(true);
      toastr.success("", handleMessage(data.data.Message));
      yield* getUserProfile();
    } else {
      toastr.error("", handleMessage(data.data.message));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}

function* getIssuesList(): any {
  try {
    const data = yield call(profileServices.getSupportIssuesList);
    if (data?.status === 200) {
      yield put({
        type: SET_QUERIES_LIST,
        payload: data?.data?.Result || {},
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}


function* profileSaga() {
  yield takeLatest(GET_USER_PROFILE, getUserProfile);
  yield takeLatest(POST_UPDATE_PROFILE, postUpdateUserProfile);
  yield takeLatest(GET_USER_KYC_STATUS, getUserKycStatus);
  yield takeLatest(GET_ADDITIONAL_DOCS_STATUS, getAdditionalDocsData);
  yield takeLatest(CREATE_ADDITIONAL_DOC, setAdditionalDocs);
  yield takeLatest(UPLOAD_IMAGES_TO_S3, uploadToS3);
  yield takeLatest(SEND_OTP_FOR_EMERGENCY_NUMBER, sendOtpEmergency);
  yield takeLatest(VERIFY_OTP_FOR_EMERGENCY_NUMBER, verifyOtpEmergency);
  yield takeLatest(GET_QUERIES_LIST, getIssuesList);
}
export default profileSaga;
