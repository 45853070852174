import { memo } from "react";
interface Button1Props {
  type: "button" | "submit" | "reset";
  disabled?: boolean;
  className: string;
  title: string;
  loading?: boolean;
  onSubmit?: any;
  onClick?: any;
  style?: object;
}

const Button1 = memo((props: Button1Props) => {
  const { title = "", loading, ...rest } = props;
  return (
    <>
      <button {...rest}>
        {loading ? (
          <div
            className="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          title
        )}
      </button>
    </>
  );
});

export { Button1 };
