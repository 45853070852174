import React from 'react'
import { story } from '../../assets'
import { Routes } from '../../constant/routeContants'

const OurStory = () => {
  return (
<div className="story-wrap">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-5 col-sm-6 col-12">
            <img src={story} alt="Freedo's Story" />
          </div>
          <div className="col-lg-7 col-md-7 col-sm-6 col-12">
            <div className="content-wrap" id="our-story">
              <h2>Our Story</h2>
              <p className='text-justify'>
              Freedo was founded on a simple idea of FREEDOM in mobility.
              We felt that like everything in today’s world, mobility also needed an update. Thus began our quest to create a Smart, Affordable and accessible mobility option.
              Freedo provides safe, happy and reliable commute services to our customers, through non-exclusive self-driving two-wheelers. 
              Freedo for a sustainable tomorrow aims to reduce dependence on personal vehicle and Owner-ship by introducing User-ship through shared vehicles, and in the process leaving the planet a bit healthier.
                            
              </p>
              <h6>Choose Freedom to Move, Choose Freedo !</h6>
              {/* <a href={Routes.RENT} className="freedo-primary-button rounded-pill">Book Freedo
                <i className="fa-solid fa-right-long"></i>
              </a> */}
            </div>
          </div>
        </div>
      </div>  )
}

export default OurStory