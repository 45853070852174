import React, { useEffect } from "react";
import en from "../locales/en.json";
import Slider from "react-slick";
import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { getAdvertisement } from "../redux/actions";
import { customRoutes } from "../utills/routes";
import { useNavigate } from "react-router-dom";
import { AuthRoutes } from "../constant/routeContants";
const settings = {
  dots: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const TopHeaderBar = () => {
  const { topHeader } = en;
  const { advertisements } = useSelector(
    (state: RootState) => state.localeReducer
  );
  const { isAuthenticated } = useSelector(
    (state: RootState) => state.authReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkForUnauthRoute();
    dispatch(getAdvertisement({ type: "Advertisement" }));
  }, []);

  const checkForUnauthRoute = () => {
    if (
      !isAuthenticated &&
      AuthRoutes.filter((route) => window.location.pathname.includes(route))
        ?.length
    )
      navigate("/");
  };

  const sliders = () => {
    return (advertisements as [])?.map((data: any) => {
      return (
        <div className="container-fluid" key={data.id}>
          <div className="align-items-center">
            <div className="mx-auto">
              <div className="Info-wrapper small-text linear-wipe">
                <span className="message">{data?.adv_txt}</span>
                <Countdown date={data?.release_day} renderer={renderer} />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderer: any = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null;
    else
      return (
        <div className="timer-wrap" style={{marginTop:"-2px"}}>
          <span>
            {" "}
            <b> {days} </b> days
          </span>
          <span>
            {" "}
            <b> {hours} </b> hr{" "}
          </span>
          <span>
            {" "}
            <b> {minutes} </b> mins
          </span>
          <span>
            {" "}
            <b> {seconds} </b> sec
          </span>
        </div>
      );
  };

  return (
    <div className="top-band">
      <div className="contact-wrap small-text">
        <a href={`mailto:${topHeader.Email}`}>
          <i className="fas fa-envelope"></i> {topHeader.Email}{" "}
        </a>
        <a href={`tel:${topHeader.Mobile}`}>
          <i className="fa-solid fa-phone"></i> {topHeader.Mobile}
        </a>
      </div>
      <Slider {...settings}>{sliders()}</Slider>
    </div>
  );
};

export default TopHeaderBar;
