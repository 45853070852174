import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { pagenotfoundIcon } from "../../assets";
import { useEffect } from "react";
const WrongUrl = () => {
    // will redirect after 404 page occurred with respect to wrong url  OR 
    // we can add a custom button to redirect to home page
    // useEffect(()=>{
    //     setTimeout(()=> {
    //         window.location.replace("/")
    //     },2000)
    // },[])
    return (
        <div>
            <img style={{ height: 600, marginLeft: "25%", padding: "7%" }} src={pagenotfoundIcon} />
        </div>
    );
};

export default WrongUrl;
