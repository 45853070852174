const firebaseConfig = {
  apiKey: "AIzaSyAEWDF1dPm26ktq6wswf_SKaWd4gbDb96o",
  authDomain: "freedo-rentals.firebaseapp.com",
  projectId: "freedo-rentals",
  storageBucket: "freedo-rentals.appspot.com",
  messagingSenderId: "725995595512",
  appId: "1:725995595512:web:124aae369bbd0b9257bd0d",
  measurementId: "G-5TYSWNDEXW",
};

export default firebaseConfig;
