import React from 'react';

const TopBanner = () => {
    return (
        <section className="main-faq-wrapper ">
            <div className="container-fluid">
                <div className="faq-banner-wrap">
                    <h2 className="text-center text-white">Contact Us</h2>
                </div>
            </div>
        </section>
    );
};

export default TopBanner;
