import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  delv,
  greenLocationMark,
  orangeLocationMark,
  redLocationMark,
  wareHouse,
  listPng,
  mapSVG,
} from "../../assets";

import en from "../../locales/en.json";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import AddressListModel from "../../component/AddressListModel";
import Slider from "react-slick";
import { locale } from "moment";
import { commmaSeparator } from "../../utills/helper";
const intialData = {
  pickupAddress: null,
  homeAddress: null,
};
const containerStyle = {
  width: "100%",
  height: "48vh",
  marginTop: "10px",
};
const CheckoutAddress = (props: any) => {
  // console.log(props);
  const { branchDetails, checkData } = props;
  const { selectedBranch, homeDelivery, selectedHomeAddress } = checkData;
  const [addressData, setAddressData] = useState<any>({ ...intialData });
  const [showmap, setShowMap] = useState<boolean>(true);
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const addressModel = locale["addressModel"];
  const addresss = homeDelivery
    ? addressData.homeAddress
    : addressData.pickupAddress;
 
  useEffect(() => {
    if (!selectedBranch && branchDetails.length) {
      if (props.lowestBranchId) {
        const filtrdBranch = branchDetails.filter(
          (itm: any) => itm.id === props.lowestBranchId
        );
        props.handleBranchChange(filtrdBranch[0]);
      } else if (branchDetails.length) {
        props.handleBranchChange(branchDetails[0]);
      }
    }
  }, [props]);
  const { checkout } = en;
  // console.log(branchDetails);
  const selectdMarkerLocation = (item: any) => {
    // console.log(item);
    props.handleBranchChange(item);
  };
  const getFullAddress = (addr: any) => {
    return `${addr?.address1}, ${addr?.address2 ? addr?.address2 : ""}, ${addr?.landmark ? addr?.landmark : ""
      }, ${addr?.zip}`;
  };

  const SampleNextArrow = (props: any) => {
    const { className, onClick, style } = props;
    return (
      <i
        onClick={onClick}
        className="fa fa-angle-right nextarr slick-arrow slick-next"
        style={{ ...style }}
        aria-hidden="true"
      />
    );
  };

  const SamplePrevArrow = (props: any) => {
    const { className, onClick, style } = props;
    return (
      <i
        onClick={onClick}
        className="fa fa-angle-left prevarr slick-arrow slick-prev"
        style={{ ...style }}
        aria-hidden="true"
      />
    );
  };
  const sliderSettings = {
    slidesToShow: branchDetails.length > 3 ? 3 : branchDetails.length,
    slidesToScroll: 1,
    autoplay: false,
    Infinite: false,
    centerMode: false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const mapCenter = () => {
    if (branchDetails.length) {
      return {
        lat: branchDetails[0].latitude,
        lng: branchDetails[0].longitude,
      };
    } else {
      return { lat: 0.0, lng: 0.0 };
    }
  };
  const handleHomeAddressChange = (item: any) => {
    props.handleAddressSelected(item);
  };
  return (
    <>
      <div className="map-details row">
        {showmap ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter()}
            zoom={11}
            clickableIcons={false}
          >
            {branchDetails.map((item: any, index: number) => {
              return (
                <Marker
                  key={index}
                  position={{
                    lat: item.latitude,
                    lng: item.longitude,
                  }}
                  icon={{
                    url:
                      item?.priceRange?.tag === 0
                        ? greenLocationMark
                        : item?.priceRange?.tag === 1
                          ? orangeLocationMark
                          : redLocationMark,
                    scaledSize: {
                      width: 30,
                      height: 30,
                      equals: () => true,
                    },
                    origin: {
                      x: 0,
                      y: 0,
                      equals: (value) => true,
                    },
                    anchor: {
                      x: 50,
                      y: 15,
                      equals: () => true,
                    },
                  }}
                  onClick={() => selectdMarkerLocation(item)}
                >
                  <InfoWindow
                    position={{
                      lat: item.latitude,
                      lng: item.longitude,
                    }}
                  >
                    <div className="infowindow">₹ {commmaSeparator(Math.round(item.price))}</div>
                  </InfoWindow>
                </Marker>
              );
            })}
          </GoogleMap>
        ) : (

          <div className="p-0 overflow-hidden">
            <Slider {...sliderSettings}>
              {branchDetails.map((item: any, index: number) => {
                return (
                  <div className="d-flex justify-content-center" key={index}>
                    <div className="card-3 h-100">
                      <img src={wareHouse} alt="" className="warehouse-img" />
                      <div className="card-body p-3">
                        <h5 className="card-title">{item?.branchName}</h5>
                        <p className="card-text">
                          {getFullAddress(item?.address)}
                        </p>
                        <div className="warhouse-price-wrapper">
                          <div className="pricetag">{`₹ ${Math.round(item.price)}`}</div>
                          <div
                            className={
                              item?.priceRange?.tag == 0
                                ? "price-type-low"
                                : item?.priceRange?.tag == 1
                                  ? "price-type-medium"
                                  : "price-type-high"
                            }
                          >
                            {item?.priceRange?.tag == 0
                              ? "Low Price"
                              : item?.priceRange?.tag == 1
                                ? "Medium Price"
                                : "High Price"}
                          </div>
                        </div>

                        {branchDetails.length > 1 && (
                          <>
                            <button
                              className="pay-now freedo-primary-button rounded-pill mt-2"
                              onClick={() => selectdMarkerLocation(item)}
                            >
                              {selectedBranch.id == item.id ? "Selected" : "Select"}
                            </button>
                          </>
                        )}

                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        )}
        <button
          className="list-view-wrap"
          id="Button1"
          type="button"
          onClick={() => setShowMap(!showmap)}
        >
          <img src={showmap ? listPng : mapSVG} alt="" />
          {showmap ? "List View" : "Map View"}
        </button>
      </div>
      <div className="delivery-details row">
        <div className="col-md-7">
          <img src={delv} className="delv-image" />
          <span className="del-detail">{checkout.pickupBranch}</span>
          <span className="head">{selectedBranch?.branchName}</span>
          <span className="text">
            {getFullAddress(selectedBranch?.address)}
          </span>
        </div>
      </div>
      {homeDelivery && (
        <div className="delivery-details row">
          <div className="col-md-7">
            <img src={delv} className="delv-image" />
            <span className="del-detail">{checkout.deliverHere}</span>
            <span className="head">{selectedHomeAddress?.name}</span>
            <span className="text">
            {selectedHomeAddress?.address1
                ? selectedHomeAddress?.address1
                : en.errorMessages.addAddressHomeDelivery}
            </span>
            <span>
              {(addressModel["addressType"] as [])?.map(
                (type) =>
                  selectedHomeAddress?.user_addresses?.save_as === type["name"] && (
                    <button className="btn-home smallest-text cursor-default">
                      <i
                        className={type["className"]}
                        aria-hidden="true"
                      ></i>
                      &nbsp;&nbsp;{type["name"]}
                    </button>
                  )
              )}
            </span>
          </div>
          <div className="col-md-5 col-sm-5 text-end change-address-wrapper">
            <button
              className="freedo-outline-btn rounded-pill"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvaschangeaddress"
              aria-controls="offcanvaschangeaddress"
            >
              {selectedHomeAddress
                ? checkout.changeAddress
                : checkout.addAddress}
            </button>
            <AddressListModel addAddess={!selectedHomeAddress} handleChange={handleHomeAddressChange} />
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutAddress;
