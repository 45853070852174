import React, { useState, useEffect } from "react";
import { androidBtn, footerLogo, iosBtn } from "../assets";
import { Routes, showSEOFooterRoutes } from "../constant/routeContants";
import en from "../locales/en.json";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppLink, LocalStorage } from "../constant/enum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { setLocalStorageData } from "../utills/helper";
import { saveSelectedCity } from "../redux/actions";
import { toastr } from "react-redux-toastr";
import { FirebaseEvents } from "../utills/firebaseEvents";

interface ListInterface {
  title: string;
  list: Array<any>;
  isLink: Boolean;
  ulClass: string | undefined;
  ind: number;
}

const FooterList = ({ title, list, isLink, ulClass, ind }: ListInterface) => (
  <div key={ind} className="col-lg-3 col-md-6 col-sm-6 col-12">
    <h4>{title}</h4>
    <ul className={`footer-list ${ulClass ? ulClass : ""}`}>
      {list?.map((item, ind) => (
        <li key={ind}>
          {isLink ? <Link to={item.link}>{item.label}</Link> : item.label}
        </li>
      ))}
    </ul>
  </div>
);
const Footer = () => {
  const { footer } = en;
  const { userData } = useSelector((state: RootState) => state.authReducer);
  const { cityList } = useSelector((state: RootState) => state.homeReducer);
  const [readMore, SetReadMore] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname = "" } = useLocation();

  const handleSelect = (city) => {
    setLocalStorageData(LocalStorage.userCity, city);
    let firebaseObject: any = {
      city: city?.city_name,
    };
    if (userData?.mobile_number) firebaseObject.mobile = userData.mobile_number;
    FirebaseEvents("SelectCity", firebaseObject);
    console.log(firebaseObject, "SelectCity");
    dispatch(saveSelectedCity(city));
    window.scrollTo(0, 0);
  };

  const handleFreedoLogo = () => {
    if (pathname === "/") window.scrollTo(0, 0);
    navigate("/");
  };

  return (
    <footer>
      <div className="container-fluid">
        <div className="main-content-wrapper">
          <img
            src={footerLogo}
            alt="footerLogo"
            onClick={handleFreedoLogo}
            style={{ cursor: "pointer" }}
          />

          {/*  <p>
            {footer.Text}
            {readMore && footer.Text}
            <a className="cp" onClick={() => SetReadMore((pre) => !pre)}>
              {" "}
              {readMore ?footer.ReadLess:footer.ReadMore}{" "}
            </a>
          </p> */}
          <div className="footer-content">
            <div className="row">
              {footer?.listItems?.map(
                ({ title, list, isLink, ulClass, id }, ind) => (
                  <FooterList
                    key={id}
                    ind={ind}
                    title={title}
                    list={list}
                    isLink={isLink}
                    ulClass={ulClass}
                  />
                )
              )}
            </div>
          </div>

          <div className="download-app-section">
            <div className="row">
              <div className="col-lg-9 col-md-6">
                <h4>{footer.DownloadOurApp}</h4>
                <div className="download-app-btn-wrap">
                  <Link target="_blank" to={AppLink.appStoreLink}>
                    {" "}
                    <img src={iosBtn} alt="Download from app store" />{" "}
                  </Link>
                  <Link target="_blank" to={AppLink.playstoreLink}>
                    {" "}
                    <img src={androidBtn} alt="Download from play store" />{" "}
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to={Routes.CONTACT}>{footer.GetInTouch}</Link>
              </div>
            </div>
          </div>
          <hr />
          <div className="footer-loaction-wrap">
            <h4>{footer.FreedoCities}</h4>
            <ul className="loacton-list">
              {cityList.map((city, ind) =>
                city.status === 1 || city.status === 5 || city.status === 6 ? (
                  <li key={ind} onClick={() => handleSelect(city)}>
                    <Link to={""}>
                      <i className="fa-solid fa-location-dot"></i>
                      {footer.BikeRentIn} <strong>{city.city_name} </strong>
                    </Link>
                  </li>
                ) : null
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="copyright-wrap">{footer["2023FreedoRentals"]}</div> */}
    </footer>
  );
};

export default Footer;
