import React, { useLayoutEffect } from 'react';
import './themes/global.css';
import '../src/css/global.css';
import RoutWeb from './routes/index';
import { Provider } from 'react-redux';
import store from "./redux/store";
import ReduxToastr from 'react-redux-toastr'
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import 'react-tooltip/dist/react-tooltip.css'
import 'react-range-slider-input/dist/style.css';
import 'react-loading-skeleton/dist/skeleton.css'
import { useLocation } from 'react-router';
import { SkeletonTheme } from "react-loading-skeleton";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "./config/firebaseConfig";
import ReactGA from 'react-ga';
import { NODE_ENV } from './config/envConfig';

function App() {
    const app = initializeApp(firebaseConfig);
    const TRACKING_ID = "G-5TYSWNDEXW";
    ReactGA.initialize(TRACKING_ID);
    const analytics = getAnalytics(app);
    // @ts-ignore  
    if (NODE_ENV == "production") {
        console.log = () => { }
        console.error = () => { }
        console.debug = () => { }
    }

    const location = useLocation();
    const options = {
        okText: "Yes",
        cancelText: "No",
    };
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Provider store={store}>
            <ReduxToastr
                preventDuplicates
                confirmOptions={options}
                position="top-center"
                newestOnTop={true}
                progressBar
            />
            <SkeletonTheme baseColor="#dedada66" highlightColor="#dddddd8f" >
                <RoutWeb />
            </SkeletonTheme>
        </Provider>
    );
}

export default App;
