import { BASE_URL_CUSTOMER, KYC_API_URLS, kyc, BASE_URL } from "../../config/apiUrls";
import { customAxios } from "../../config/axiosConfig";

const getDLDocsignedurl = async (params: any) => {
  let baseUrl = BASE_URL + `/${kyc}${KYC_API_URLS.getDlSignedurl}`;
  try {
        return await customAxios.post(baseUrl, params);
    } catch (ex) {
        return ex;
    }
};
const getuserKYCstatus = async () => {
  let baseUrl = BASE_URL_CUSTOMER + `${kyc}${KYC_API_URLS.userCheckKYC}`;
  try {
        return await customAxios.get(baseUrl);
    } catch (ex) {
        return ex;
    }
};
const getDigilocakerUrl = async () => {
  let baseUrl =
  BASE_URL + `/${kyc}${KYC_API_URLS.generateDigiLockerUrlWeb}`;
  try {
        return await customAxios.get(baseUrl);
    } catch (ex) {
        return ex;
    }
};
const saveDLimageDataKyc = async (params: any) => {
    let baseUrl = BASE_URL + `/${kyc}${KYC_API_URLS.saveDlImageData}`;
    try {
        return await customAxios.post(baseUrl, params);
    } catch (ex) {
        return ex;
    }
  };
export default {
  getDLDocsignedurl,
  getuserKYCstatus,
  getDigilocakerUrl,
  saveDLimageDataKyc,
};
