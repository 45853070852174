import { useNavigate } from "react-router-dom";
import { paymentSuccess } from "../../assets";
import { Routes } from "../../constant/routeContants";
const PaymentPending = () => {
  const navvigate = useNavigate();
  const handleRedirection = () => {
    navvigate(Routes.PROFILE, {
      state: {
        values: "my-bookings",
      },
    });
  };
  return (
    <div className="container payment-wrap">
      <div className="d-flex justify-content-center mt-2">
        <div className="card w-100 mb-2 border-0">
          <div className="card-body">
            <video width="100%" autoPlay muted loop>
              <source src={paymentSuccess} type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
            </video>
            <div className="text-center payment-text">
              <h5>Payment in Processing!</h5>
              <p className="mt-1 mb-1">
                <small>Please wait some time.</small>
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center m-3">
            <button
              onClick={handleRedirection}
              className="freedo-primary-button btn-sm rounded-pill"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPending;
