import { counterRight } from "../../assets";
import en from "../../locales/en.json";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const { freedoCounter } = en.home;
const CardCounter = ({ counter, counterText, ind }: any) => {
  return (
    <div key={ind} className="col-lg-6 col-md-6 col-sm-6 col-6 mb-4">
      {/* <span className="counter-number">{counter}</span> */}
      <CountUp
        className="counter-number"
        suffix={counterText === "Total Two-Wheelers" ? "" : "+"}
        start={0}
        end={counter}
        duration={3}
      ></CountUp>
      <p>{counterText}</p>
    </div>
  );
};

const FreedoCounter = () => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });


  return (
    <div ref={ref} className="counter-wrapper">
      <div className="container-fluid">
        <div className="counter-wrap">
          <div className="row align-items-center counter-row">
            <div className="col-lg-6 col-md-6">
              {inView && (
                <div className="row ">
                  {freedoCounter?.cardData.map(
                    ({ counter, counterText }, ind) => (
                      <CardCounter
                        key={ind}
                        ind={ind}
                        counter={counter}
                        counterText={counterText}
                      />
                    )
                  )}
                </div>
              )}
            </div>
            <div className="col-lg-6 col-md-6">
              <img src={counterRight} alt="total-count-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreedoCounter;
