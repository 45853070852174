import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopFilter from "./TopFilter";
import Filters from "./Filters";
import VehicleWrapper from "./VehicleWrapper";
import { actions } from "../../redux";
import {
  findEndDate,
  getLocalStorageData,
  setLocalStorageData,
} from "../../utills/helper";
import { RootState } from "../../redux/reducers";
import moment from "moment";
import { LocalStorage } from "../../constant/enum";
import { useLocation } from "react-router";
import { getCityListMasters, saveSelectedCity, setVehicleList } from "../../redux/actions";
import { FirebaseEvents } from "../../utills/firebaseEvents";
import { Helmet } from "react-helmet";

interface stateType {
  [key: string]: any;
}
const Rent = () => {
  const isMobileDevice = window.screen.width < 768;
  const dispatch = useDispatch();
  const { basicFilter } = useSelector(
    (state: RootState) => state.localeReducer
  );
  const { cityList } = useSelector((state: RootState) => state.homeReducer);
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const location = useLocation();
  const cityId = location?.state?.cityId || "";
  const cityName = location?.state?.cityName || "";

  const rentDetails: any = locale["rent"];
  const { packages, vehicleList, isPrimaryFilter } = useSelector(
    (state: RootState) => state.homeReducer
  );
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const { vehicleType } = useSelector((state: RootState) => state.rentReducres);

  const initialPrimaryState = {
    selectedPackage:
      basicFilter["package"] && basicFilter["package"]["id"]
        ? basicFilter["package"]
        : packages[0],
    startDate: basicFilter["date"] || moment(),
    endDate: setInitialEndDate(),
  };
  const initialSecondaryState = {
    vehicleType: "",
    isElectric: false,
    priceRange: [0, 0],
    vehicleModel: null,
    records: 6,
    sortBy: "",
  };
  const [isMounted, setIsMounted] = useState(false);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [primaryFilter, setPrimaryFilter] =
    useState<stateType>(initialPrimaryState);
  const [secondaryFilter, setSecondaryFilter] = useState<stateType>(
    initialSecondaryState
  );
  function setInitialEndDate() {
    return basicFilter["endDate"]
      ? basicFilter["endDate"]
      : findEndDate(moment(), packages[0]?.package_days);
  }
  useEffect(() => {
    if (!cityList?.length)
      dispatch(getCityListMasters({ type: "GetCityList", payload: {} }));
  }, []);

  useEffect(() => {
    FirebaseEvents("BikeRentalVisit", {});
    dispatch(
      actions.getVehicleType({
        type: "VehicleType",
      })
    );
  }, []);

  useEffect(() => {
    if (cityId && cityList?.length) {
      let selectedCity: any = cityList?.find((item) => {
        return item?.id == cityId && item?.city_name == cityName;
      });
      setLocalStorageData(LocalStorage.userCity, selectedCity);
      dispatch(saveSelectedCity(selectedCity));
    }
  }, [cityId, cityList]);

  useEffect(() => {
    dispatch(
      actions.getPackageMasters({
        id: cityData?.id,
        type: "GetPackages",
      })
    );
  }, [cityData?.city_name]);

  useEffect(() => {
    if (packages?.length && !primaryFilter?.selectedPackage?.id) {
      resetPackages()
    }
  }, [packages]);

  useEffect(() => {
    if (primaryFilter?.selectedPackage?.id && cityData?.id) {
      handleResetstate();
    }
  }, [primaryFilter, cityData?.id]);

  useEffect(() => {
    if (vehicleList?.vehicleModelList?.length && isPrimaryFilter) {
      let newState = [...vehicleList?.vehicleModelList].map((item) => {
        return {
          id: item?.modelId,
          checked: false,
          model_name: item.modelName,
        };
      });
      setMaxPrice(vehicleList?.maxPrice);
      setMinPrice(vehicleList?.minPrice);
      setSecondaryFilter((pre) => {
        return {
          ...pre,
          priceRange: [vehicleList?.minPrice, vehicleList?.maxPrice],
          vehicleModel: newState,
        };
      });
    }
  }, [vehicleList]);

  const resetPackages=()=>{
    setPrimaryFilter((prev) => {
      return {
        ...prev,
        selectedPackage: packages[0],
        endDate:  findEndDate(moment(primaryFilter.startDate), packages[0]?.package_days),
      };
    });
  }

  const primaryFilterChanged = () => {
    let currentPackage = primaryFilter.selectedPackage?.id
      ? primaryFilter.selectedPackage
      : packages[0];
    let endDate = findEndDate(
      primaryFilter.startDate,
      currentPackage.package_days
    );
    let payload = {
      cityId: cityData?.id,
      packageId: currentPackage.id,
      startDate: primaryFilter.startDate
        ? moment(primaryFilter.startDate).format("YYYY-MM-DD")
        : null,
      endDate: endDate ? endDate : null,
    };
    callListApi(payload, true);
  };

  const secondaryFilterChanged = (name, value) => {
    let currentPackage = primaryFilter.selectedPackage?.id
      ? primaryFilter.selectedPackage
      : packages[0];
    let endDate = findEndDate(
      primaryFilter.startDate,
      currentPackage.package_days
    );
    let vehicleModels =
      name == "vehicleModel" ? [...value] : [...secondaryFilter.vehicleModel];
    let modelIds = vehicleModels
      .map((item) => item && item.checked && item.id)
      .filter((item) => item);
    let filters = {
      cityId: cityData?.id,
      packageId: currentPackage.id,
      startDate: primaryFilter.startDate
        ? moment(primaryFilter.startDate).format("YYYY-MM-DD")
        : null,
      endDate: endDate ? endDate : null,
      vehicleType: secondaryFilter.vehicleType
        ? secondaryFilter.vehicleType.toLowerCase()
        : "",
      modelIds: modelIds.length > 0 ? modelIds : [],
      priceRange: secondaryFilter.priceRange
        ? secondaryFilter.priceRange
        : null,
      isElectric: secondaryFilter.isElectric,
      sortBy: secondaryFilter.sortBy,
    };
    let payload =
      name == "" || name == "vehicleModel"
        ? filters
        : {
            ...filters,
            [name]: value,
          };
    callListApi(payload, false);
  };

  const setLocationPageFilters = () => {
    setSecondaryFilter((prev) => ({
      ...prev,
      vehicleModel: location?.state?.vehicleModel || null,
    }));
    // if(isMobileDevice)secondaryFilterChanged('vehicleModel',location?.state?.vehicleModel)
  };

  const callListApi = (payload, isPrimary) => {
    dispatch(
      actions.getVehicleList({
        payload,
        primaryFilter: isPrimary,
      })
    );
  };

  // Triggered when package is changes
  const handlePackageChange = (value) => {
    let currentPackage =
      packages.find((item: any) => item.package_name === value) || packages[0];
    let endDate = findEndDate(
      primaryFilter.startDate,
      currentPackage.package_days
    );
    setPrimaryFilter((prev) => {
      return {
        ...prev,
        selectedPackage: currentPackage,
        endDate,
      };
    });
  };
  // triggred when Input type 'text' or 'radio' button
  const handleChange = (
    name: string,
    value: string,
    isMobile: boolean | undefined
  ) => {
    setSecondaryFilter({
      ...secondaryFilter,
      [name]: value,
    });
    if (!isMobile) secondaryFilterChanged(name, value);
  };

  // triggred when Input type 'checkbox'
  const handleCheckboxChange = (
    name: any,
    id: number,
    checked: boolean,
    isMobile: boolean | undefined
  ) => {
    const copyModels = [...secondaryFilter[name]];
    const modifiedModels = copyModels.map((model) => {
      if (id == model.id) {
        model.checked = checked;
      } else if (id == 0) {
        model.checked = false;
      }
      return model;
    });
    setSecondaryFilter((prev) => {
      return {
        ...prev,
        [name]: modifiedModels,
      };
    });
    if (!isMobile) secondaryFilterChanged(name, modifiedModels);
  };

  const handleResetstate = () => {
    setSecondaryFilter({
      ...initialSecondaryState,
    });
    primaryFilterChanged();
  };

  return (
    <>
      <Helmet>
        <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="title"
          content="Freedo: Bike Rentals | Two Wheelers Rentals App"
        />
        <meta
          name="description"
          content="We provide great options for bike and scooter rental in Noida NCR. Get most reasonable rates with flexible km plans. Rent a bike now from our wide range fleet"
        />
        <meta name="url" content="https://freedo.rentals/rent" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freedo.rentals/rent" />
        <meta
          property="og:title"
          content="Book Bike On Rent in Noida | Greater Noida | Delhi NCR"
        />
        <meta
          property="og:description"
          content="We provide great options for bike and scooter rental in Noida NCR. Get most reasonable rates with flexible km plans. Rent a bike now from our wide range fleet"
        />
        <meta property="twitter:url" content="https://freedo.rentals/rent" />
        <meta
          property="twitter:title"
          content="Book Bike On Rent in Noida | Greater Noida | Delhi NCR"
        />
        <meta
          property="twitter:description"
          content="We provide great options for bike and scooter rental in Noida NCR. Get most reasonable rates with flexible km plans. Rent a bike now from our wide range fleet"
        />
        <meta name="googlebot" content="index" />
        <link rel="canonical" href="https://freedo.rentals/rent" />
      </Helmet>
      <section className="renting-wrapper ">
        <div className="container-fluid">
          <div className="renting-wrap">
            <div className="row">
              {/* Left side filter screen */}
              <Filters
                initialState={initialSecondaryState}
                vehicleType={vehicleType}
                handleChange={handleChange}
                handleCheckboxChange={handleCheckboxChange}
                rentDetails={rentDetails}
                maxPrice={maxPrice}
                minPrice={minPrice}
                handleResetstate={handleResetstate}
                setSecondaryFilter={setSecondaryFilter}
                secondaryFilter={secondaryFilter}
                secondaryFilterChanged={secondaryFilterChanged}
              />
              <div className="col-lg-9 col-md-8 col-sm-12 col-12">
                <section className="Renting-wrapper rent-filter-result">
                  <div className="rent-wrap">
                    {/* Top section with city, dates etc... */}
                    <TopFilter
                      cityData={cityData}
                      packages={packages}
                      handlePackageChange={handlePackageChange}
                      findEndDate={findEndDate}
                      setPrimaryFilter={setPrimaryFilter}
                      primaryFilter={primaryFilter}
                      resetPackages={resetPackages}
                    />
                    {/* Vehicles card section */}
                    <VehicleWrapper
                      state={{ ...primaryFilter, ...secondaryFilter }}
                      setSecondaryFilter={setSecondaryFilter}
                      secondaryFilterChanged={secondaryFilterChanged}
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rent;
