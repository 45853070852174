import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cameraProfile, email, name, phone, userPic } from "../../assets";
import { Button1 } from "../../component/Button";
import NewInputField from "../../component/NewInputField";
import OtpInput from "../../component/OTPField";
import { SignInProps, SignUpFields } from "../../interfaces/authInterface";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";
import { FirebaseEvents } from "../../utills/firebaseEvents";
import {
  hindiTextValidation,
  restrictNameWithSpaces,
  validate,
  handleMessage,
} from "../../utills/helper";
import { checkPhone } from "../../utills/regex";
import profileServices from "../../redux/services/profileServices";
import { toastr } from "react-redux-toastr";
import en from "../../locales/en.json";
const initialState: SignUpFields = {
  fullName: "",
  phone: "",
  email: "",
  otp: "",
  genderId: "",
};

const SignUp = ({ setIsSignIn }: SignInProps) => {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { showOtpSignupScreen, authLoader, isAuthenticated } = useSelector(
    (state: RootState) => state.authReducer
  );
  const [uploadSrcUri, setUploadSrcUri] = useState<any>(null);
  const [uploadImage, setUploadImage] = useState<any>(null);
  const [showEditPicMenu, setShowEditPicMenu] = useState<boolean>(false);
  const [isImageUploading, setImageIsUploading] = useState<boolean>(false);
  const { AuthScreens, errorMessages } = locale;
  const dispatch = useDispatch();
  const [sThreeApiUrl, setSThreeApiUrl] = useState<any>({});
  const [fields, setFields] = useState<SignUpFields>({
    fullName: "",
    phone: "",
    email: "",
    otp: "",
    genderId: "",
  });
  const [errorFields, setErrorFields] = useState<SignUpFields>({
    fullName: "",
    phone: "",
    email: "",
    genderId: "",
  });

  useEffect(() => {
    setFields({ ...initialState });
  }, [isAuthenticated]);

  const onChange = (name: string, value: string) => {
    const hindiValidation = hindiTextValidation(value);
    if (name === "email") value = value.trim();
    if (name === "fullName") value = value.trimStart();
    setErrorFields((prevState) => ({ ...prevState, [name]: "" }));
    setFields((prevState) => ({ ...prevState, [name]: value }));
  };
  const validateForm = (e: React.FormEvent) => {
    e.preventDefault();
    const validation = validate(fields, errorFields);
    if (validation.error) {
      return setErrorFields({ ...errorFields, ...validation.errorFields });
    }
    submitForm();
  };

  const handleGender = (e: any) => {
    setFields((prevState) => ({ ...prevState, genderId: e.target.value }));
    setErrorFields({ ...errorFields, genderId: "" });
  };

  const submitForm = () => {
    if (showOtpSignupScreen) {
      SubmitInputData();
    } else {
      if (uploadSrcUri) {
        requestSignedUrl();
      } else {
        SubmitInputData();
      }
    }
  };

  const handleFileSelectClick = () => {
    const ele = document.getElementById("image_upload_signup");
    ele && ele.click();
  };
  const SubmitInputData = () => {
    let firstName = fields.fullName?.split(" ")[0];
    let lastName = fields.fullName?.split(" ")[1];
    if (showOtpSignupScreen) {
      FirebaseEvents("VerifyOtp", { mobile: fields.phone });
      dispatch(
        actions.userSignUpRequested({
          email_id: fields.email,
          first_name: firstName,
          last_name: lastName,
          mobile_number: fields.phone,
          otp: fields.otp,
          gender_id: fields.genderId,
          profile_image: sThreeApiUrl?.key ?? "",
        })
      );
    } else {
      FirebaseEvents("SendOtp", { mobile: fields.phone });
      dispatch(
        actions.sendOtpForSignUp({
          email_id: fields.email,
          first_name: firstName,
          last_name: lastName,
          gender_id: fields.genderId,
          mobile_number: fields.phone,
        })
      );
    }
  };

  const requestSignedUrl = async () => {
    setImageIsUploading(true);
    profileServices
      .getUserProfileSignedUrl({
        mobile_number: fields.phone ?? "",
        extension: "jpg",
      })
      .then((response: any) => {
        if (response && response.status === 200) {
          const s3url = response.data?.Result;
          setSThreeApiUrl(s3url);

          uploadProfileImageS3(s3url);
        }
      })
      .catch((err: any) => {
        setImageIsUploading(false);
        toastr.error("", en.errorMessages.SomethingWentWrong);
      });
  };
  const uploadProfileImageS3 = (s3Data: any) => {
    const imgUri = uploadSrcUri;
    if (imgUri && s3Data) {
      try {
        fetch(s3Data?.signedUrl, {
          method: "put",
          headers: new Headers({
            "Content-Type": "image/jpeg",
          }),
          body: imgUri,
        })
          .then((res) => {
            setImageIsUploading(false);
            if (res.status == 200) {
              SubmitInputData();
            }
          })
          .catch(function (error) {
            setImageIsUploading(false);
            toastr.error("Oops", handleMessage(error));
          });
      } catch (error) {
        setImageIsUploading(false);
        toastr.error("", en.errorMessages.SomethingWentWrong);
      }
    } else {
      setImageIsUploading(false);
      toastr.warning("", en.errorMessages.SomethingWentWrong);
    }
  };
  const otpScreen = () => {
    return (
      <div className="offcanvas-body sign-up">
        <h3>{AuthScreens["OTPVerification"]}</h3>
        <p className="text-center mt-3">
          {AuthScreens["otpScreenNote"]}
          <span>
            {AuthScreens["countryCodeInd"]}
            {fields.phone}
          </span>
          {AuthScreens["andEmailId"]}
          <span>{fields.email}.</span> {AuthScreens["pleaseEnterBelow"]}
        </p>
        {/* <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ marginTop: 5, fontWeight: "bold" }}>
              +91 {fields.phone}
            </p>
            <p style={{ marginTop: 5, fontWeight: "bold", marginLeft: 10 }}>
              {fields.email}
            </p>
          </div> */}
        {/* <p>
            {AuthScreens["RegisteredSuccessful"]}
          </p> */}

        <form onSubmit={validateForm}>
          <OtpInput handleChange={(otp: string) => onChange("otp", otp)} />
          <Button1
            type="submit"
            // disabled={fields.otp.length < 6}
            className="freedo-primary-button rounded-pill w-100 "
            title={AuthScreens["VerifyCode"]}
            loading={authLoader}
          />
        </form>
      </div>
    );
  };
  const handleCapturedImage = (img: any, type: string) => {
    if (type !== "view") {
      setUploadSrcUri(img);
      setShowEditPicMenu(false);
      const reader: any = new FileReader();
      reader.readAsDataURL(img);
      reader.addEventListener("load", () => {
        setUploadImage(reader.result);
      });
      // setShowUploadModel(true);
    }
  };
  const handleUploadFile = (event) => {
    let file = event?.target?.files[0];
    let fileType = file["type"];
    const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      toastr.error("", en.errorMessages.InvalidFile);
      return;
    }
    setUploadSrcUri(file);
    // setShowEditPicMenu(false);
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => {
      setUploadImage(reader.result);
    });
  };
  const signUpDetails = () => {
    return (
      <div className="offcanvas-body">
        <h3>{AuthScreens["SignUp"]}</h3>
        <span className="signInToggle mb-4">
          {AuthScreens["alreadyHasAccount"]} &nbsp;
          <span className="link" onClick={() => setIsSignIn(false)}>
            {" "}
            {AuthScreens["SignIn"]}
          </span>
        </span>
        <form onSubmit={validateForm}>
          <div className="user-signup">
            <div className="user-pic-signup">
              <img className="user-image" src={uploadImage ?? userPic} alt="" />

              <div className="overlay" onClick={() => handleFileSelectClick()}>
                <img src={cameraProfile} alt="" className="camera-image" />
                <div className="text">
                  {uploadImage
                    ? "Change profile photo"
                    : "Choose profile photo"}
                </div>
              </div>
            </div>
            {/* { (
                  <EditProfilePicture
                    show={showEditPicMenu}
                    hideViewOption={true}
                    handleClose={()=> setShowEditPicMenu(false)}
                    handleSelectedCallback={handleCapturedImage}
                  />
                )} */}
          </div>
          <NewInputField
            type="text"
            required
            placeholder="Enter full name"
            img={name}
            label={AuthScreens["FullName"]}
            id="basic-addon1"
            value={fields.fullName}
            onChange={onChange}
            name="fullName"
            maxLength={100}
            errorMsg={errorFields.fullName}
            restrictFuntion={restrictNameWithSpaces}
          />
          <NewInputField
            type="text"
            required
            placeholder="Enter mobile number"
            img={phone}
            label={AuthScreens["PhoneNumber"]}
            id="basic-addon1"
            value={fields.phone}
            onChange={onChange}
            name="phone"
            maxLength={10}
            errorMsg={errorFields.phone}
            // showError={errorState.phone}
            restrictFuntion={checkPhone}
            inputMode={"numeric"}
            pattern="[0-9]*"
          />
          <NewInputField
            type="text"
            required
            placeholder="Enter email address"
            img={email}
            label={AuthScreens["Email"]}
            id="basic-addon1"
            value={fields.email}
            onChange={onChange}
            name="email"
            maxLength={100}
            errorMsg={errorFields.email}
            // showError={errorState.email}
            restrictFuntion={null}
          />

          <label className="form-label pt-1">
            {"Gender"}
            {<span className="required-mark">*</span>}
          </label>

          <div onChange={handleGender}>
            {" "}
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value={1}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                Male
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value={2}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                Female
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value={3}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                Others
              </label>
            </div>
          </div>
          <input
            accept="image/*"
            type="file"
            onChange={handleUploadFile}
            onClick={(e: any) => (e.target.value = null)}
            style={{ display: "none" }}
            id="image_upload_signup"
          />
          {!!errorFields.genderId && (
            <span className="error-field">
              {errorMessages["requiredField"]}
            </span>
          )}

          <Button1
            type="submit"
            className="freedo-primary-button rounded-pill w-100 mt-3"
            loading={isImageUploading}
            title={AuthScreens["CreateMyAccount"]}
          />
          <div className="privacyPolicy signInToggle">
            {AuthScreens["tNcNoteSignUp"]}
            <a className="link" href="/terms-conditions" target="_blank">
              {AuthScreens["termsAndConditions"]}
            </a>
            {AuthScreens["and"]}
            <a className="link" href="/privacy-policy" target="_blank">
              {AuthScreens["privacyPolicy"]}
            </a>
          </div>
          {/* <div className="hrRow">
              <hr />OR <hr />
            </div> */}
        </form>
      </div>
    );
  };

  // main return
  return <>{showOtpSignupScreen ? otpScreen() : signUpDetails()}</>;
};

export default SignUp;
