import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMitcData, sendMitcOtp, verifyMitc } from "../../redux/actions";
import VerifyOtp from "./VerifyOtp";
import { thankyouPage } from "../../constant/enum";

const Mitc = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const encrypted_booking_id = location?.search?.split("?")
    ? location?.search?.split("?")[1]
    : "";
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState("");

  const [mitcData, setMitcData] = useState<any>({});
  const [usageType, setusageType] = useState("");
  const [seconds, setSeconds] = useState<number>(0);
  const [disableCB, setDisableCB] = useState<boolean>(false);
  const otpFocusRef = useRef<any>(null);

  useEffect(() => {
    getMitcHtmlContent();
  }, []);

  const getMitcHtmlContent = () => {
    dispatch(
      getMitcData(
        {
          encrypted_booking_id,
        },
        handleSuccess
      )
    );
  };

  const handleSuccess = (tag, data) => {
    setLoading(false);
    switch (tag) {
      case "SendOtp":
        setOpenModal(true);
        setSeconds(30);
        break;
      case "VerifyOtp":
        setTimeout(() => {
          navigate(`/thankyou-screen/${thankyouPage?.MITC_Verified}`);
        }, 2000);
        break;
      case "GetMitcData":
        setMitcData(data);
        break;
    }
    otpFocusRef?.current && otpFocusRef?.current?.focusOTPInput();
  };

  const handleOnclick = () => {
    setDisableCB(true);
    dispatch(
      sendMitcOtp(
        {
          encrypted_booking_id,
        },
        handleSuccess
      )
    );
  };

  const submitOtp = (otp, e) => {
    e.preventDefault();
    dispatch(
      verifyMitc(
        {
          encrypted_booking_id,
          usage_type: usageType,
          otp: Number(otp),
        },
        handleSuccess
      )
    );
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <div>
      <h3 className="text-center">
        Most Important Terms and Conditions (MITC)
      </h3>
      <div className="p-2">
        <div dangerouslySetInnerHTML={{ __html: mitcData?.html_content }} />
        {mitcData?.html_content && mitcData?.status == false ? (
          <div className="purpose-hire mt-4">
            <div
              style={{ display: "flex", gap: "10px", fontWeight: "bold" }}
              className="purpose-hire-box"
            >
              <h6>Purpose for Hire :</h6>
              {mitcData?.usage_types &&
                mitcData?.usage_types.map((item, ind) => (
                  <div className="form-check form-check-inline mitc-check">
                    <input
                      className="form-check-input mitc-check"
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      value={item.usage_type}
                      disabled={disableCB}
                      onClick={(e: any) => {
                        setusageType(e.target.value);
                      }}
                      checked={usageType == item.usage_type}
                    />
                    <label className="form-check-label">
                      {item.usage_type}
                    </label>
                  </div>
                ))}
            </div>
            <div className="mt-2 text-center">
              <button
                className="freedo-primary-button rounded-pill mb-2"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#mitcOtp"
                aria-controls="mitcOtp"
                onClick={handleOnclick}
                disabled={!usageType || disableCB}
                style={{
                  borderRadius: 12,
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                Send OTP
              </button>
            </div>
          </div>
        ) : mitcData?.html_content && mitcData?.status == true ? (
          <div>
            <tbody>
              <tr>
                <th>Purpose for Hire</th>
                <td>{mitcData?.purpose_for_hire}</td>
              </tr>
              <tr>
                <th style={{ paddingRight: "10px" }}>
                  Digitally Signed Date and Time
                </th>
                <td>
                  {mitcData?.date_of_mitc_verified
                    ? moment(mitcData?.date_of_mitc_verified).format("lll")
                    : "NA"}
                </td>
              </tr>
            </tbody>
            <p />
            <p />
          </div>
        ) : null}
      </div>
      <VerifyOtp
        submitOtp={submitOtp}
        encrypted_booking_id={encrypted_booking_id}
        seconds={seconds}
        setSeconds={setSeconds}
        setDisableCB={setDisableCB}
        disableCB={disableCB}
        otpFocusRef={otpFocusRef}
      />
    </div>
  );
};

export default Mitc;
