import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { useEffect, useState } from "react";
import { saveBasicFilter, setVehicleList } from "../../../redux/actions";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../constant/routeContants";
import moment from "moment";

const RentingFleet = ({
  cityName,
  cityId,
  callListApi,
  vehicleList,
  packages,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);

  useEffect(() => {
    if (vehicleList?.vehicleModelList?.length) {
      let newState = [...vehicleList?.vehicleModelList].map((item) => {
        return {
          id: item?.modelId,
          checked: false,
          model_name: item.modelName,
        };
      });
      setVehicleModels(newState || []);
      setVehicles(vehicleList?.vehicleModelList);
    }
  }, [vehicleList]);

  const handleBookNow = (data) => {
    let payload = { ...vehicleList };
    payload.vehicleModelList = [{ ...data }];
    dispatch(setVehicleList(payload));
    navigate(Routes.RENT);
  };

  return (
    <section className="renting-fleet">
      <h2 className="rental-head">Our Renting Fleet In {cityName}</h2>
      {vehicles?.length == 0 ? (
        <div className="d-flex justify-content-center">
          {"No vehicle data found"}
        </div>
      ) : (
        <div className="img-container">
          <div className="img-bg">
            {/* <div className="brand-name">HERO</div> */}
          </div>
          <div className="container-fluid">
            <div
              id="carouselExampleDark"
              class="carousel carousel-dark slide"
              data-bs-ride="carousel"
            >
              <Carousel indicators={false}>
                {vehicles?.map((item, index) => (
                  <Carousel.Item interval={100000}>
                    <div className="carousel-inner">
                      <div className="rent-card active">
                        <img
                          className="d-block"
                          //  src={vehicle1}
                          src={`${vehicleList.vehicleModelImagePath?.vehicleImagePath}${item.imageName}`}
                          alt=""
                        />
                        <div className="brand-name">
                          {item?.brandName || ""}
                        </div>
                      </div>
                      <div className="rent-content mt-5">
                        <h5> {item?.modelName || ""} </h5>
                        <button
                          className="rounded-pill freedo-primary-button"
                          onClick={() => handleBookNow(item)}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                    {/* <Carousel.Caption> */}
                    {/* <h3>First slide label</h3>
                <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p> */}
                    {/* </Carousel.Caption> */}
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default RentingFleet;
