import React from "react";
import { hindiTextValidation } from "../utills/helper";
import { useState } from "react";
import { langErrorMsg } from "../screen/city-freedo/constants/constants";

interface InputForm {
  type: string;
  placeholder?: string;
  value: string | number | any;
  onChange: Function;
  name: string;
  maxLength?: number;
  restrictFuntion?: Function;
  isChecked?: boolean;
  label?: string;
  className: string;
  isDisabled?: boolean;
  Id?: string;
  inputMode?: any;
  pattern?: string;
}
const InputField = ({
  type,
  placeholder,
  value,
  onChange,
  name,
  maxLength,
  restrictFuntion,
  isChecked,
  label,
  className,
  isDisabled,
  Id,
  inputMode,
  pattern,
  ...rest
}: InputForm) => {
  const [langError, setLangError] = useState<boolean>(false);
  return (
    <>
      <input
        id={Id}
        value={value ?? ""}
        maxLength={maxLength}
        name={name}
        type={type}
        className={className}
        placeholder={placeholder}
        checked={isChecked}
        disabled={isDisabled}
        onChange={(e) => {
          const hindiValidation = hindiTextValidation(e.target.value);
          console.log(e.target.checked);
          if (type === "checkbox") {
            onChange(e.target.name, value, e.target.checked);
          } else {
            hindiValidation
              ? onChange(
                  e.target.name,
                  restrictFuntion
                    ? restrictFuntion(e.target.value)
                    : e.target.value,
                  setLangError(false)
                )
              : setLangError(true);
          }
        }}
        inputMode={inputMode}
        pattern={pattern}
        {...rest}
      />
      {label && <label className="form-check-label">{label}</label>}
      {langError && (
        <span className="error-field">{langErrorMsg}</span>
      )}
    </>
  );
};

export default InputField;
