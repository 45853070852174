import React, { useState } from "react";
import {
  bannerPartner,
  city,
  company,
  email,
  freedoCircle,
  freedoType,
  freeservice,
  govt,
  insuredRide,
  landmark,
  licence,
  location,
  phone,
  pin,
  user,
  wideRange,
} from "../../assets";
import InputField from "../../component/InputField";
import { restrictName, restrictNumber, mobileValidation } from "../../utills/helper";
import "./css/index.css";

const companyFormArray = [
  {
    name: "companyName",
    type: "text",
    placeholder: "Freedo",
    label: "Company Name*",
    maxLength: 8,
    errorMsg: "",
    restrictFuntion: null,
    imgSrc: company,
  },
  {
    name: "ownerName",
    type: "text",
    placeholder: "Rentals",
    label: "Name of the Owner*",
    maxLength: 8,
    errorMsg: "",
    restrictFuntion: restrictName,
    imgSrc: user,
  },
  {
    name: "mobile",
    type: "text",
    placeholder: "9876543210",
    label: "Mobile Number*",
    maxLength: 10,
    errorMsg: "",
    restrictFuntion: restrictNumber,
    imgSrc: phone,
  },
  {
    name: "secondaryMobile",
    type: "text",
    placeholder: "9876543210",
    label: "Secondary Mobile Number*",
    maxLength: 10,
    errorMsg: "",
    restrictFuntion: restrictNumber,
    imgSrc: phone,
  },
  {
    name: "email",
    type: "text",
    placeholder: "freedo@rentals.com",
    label: "Email*",
    maxLength: 8,
    errorMsg: "",
    restrictFuntion: null,
    imgSrc: email,
  },
  {
    name: "rentalLicence",
    type: "text",
    placeholder: "STR-QPL-00100",
    label: "Rental License",
    maxLength: 8,
    errorMsg: "",
    restrictFuntion: null,
    imgSrc: licence,
  },
  {
    name: "fleetSize",
    type: "text",
    placeholder: "5",
    label: "Fleet Size*",
    maxLength: 8,
    errorMsg: "",
    restrictFuntion: restrictNumber,
    imgSrc: insuredRide,
  },
  
];
const addressFormArray = [
  {
    name: "address1",
    type: "text",
    placeholder: "Address Line 1",
    label: "Address of location",
    maxLength: 8,
    errorMsg: "",
    restrictFuntion: null,
    imgSrc: location,
  },
  {
    name: "address2",
    type: "text",
    placeholder: "Address Line 2",
    label: "Address of location",
    maxLength: 8,
    errorMsg: "",
    restrictFuntion: null,
    imgSrc: location,
  },
  {
    name: "landmark",
    type: "text",
    placeholder: "Near Freedo",
    label: "Landmark",
    maxLength: 8,
    errorMsg: "",
    restrictFuntion: null,
    imgSrc: landmark,
  },
  {
    name: "city",
    type: "select",
    placeholder: "",
    label: "City*",
    maxLength: 8,
    errorMsg: "",
    restrictFuntion: null,
    imgSrc: city,
  },
  {
    name: "pincode",
    type: "text",
    placeholder: "110009",
    label: "Pin Code*",
    maxLength: 8,
    errorMsg: "",
    restrictFuntion: restrictNumber,
    imgSrc: pin,
  },
];
interface FormProps {
  type: string;
  placeholder: string;
  label: string;
  id: string;
  value: any;
  onChange: Function;
  name: string;
  maxLength: number;
  errorMsg: string;
  restrictFuntion: any;
  imgSrc: any;
}
const Form = ({
  type,
  placeholder,
  label,
  id,
  value,
  onChange,
  name,
  maxLength,
  errorMsg,
  restrictFuntion,
  imgSrc,
}: FormProps) => (
  <div className="col-md-6 col-sm-6 col-12 mb-3">
    <label className="form-label">{label}</label>
    <div className="input-group">
      <div className="input-group-text">
        <img src={imgSrc} alt="" />
      </div>
      <div className="vr"></div>
      <InputField
        Id={id}
        type={type}
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        maxLength={maxLength}
        restrictFuntion={restrictFuntion}
      />
      {errorMsg && <span className="error-message">{errorMsg}</span>}
    </div>
  </div>
);
const Index = () => {
  const initialState = {
    companyName: "",
    ownerName: "",
    mobile: "",
    secondaryMobile: "",
    email: "",
    rentalLicence: "",
    fleetSize: "",
    address1: "",
    address2: "",
    landmark: "",
    city: "",
    pincode: "",
  };
  const initialErrorState = {
    companyName: "",
    ownerName: "",
    mobile: "",
    secondaryMobile: "",
    email: "",
    rentalLicence: "",
    fleetSize: "",
    address1: "",
    address2: "",
    landmark: "",
    city: "",
    pincode: "",
  };
  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  
  
  const onChange = (name: string, value: string) => {
    setState((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="freedo-partner-wrapper">
        <div className="freedo-part-ban-content d-flex align-items-center">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-10 col-sm-12">
                <h5>Join Freedo</h5>
                <h1>Start your vehicle-rental business with Freedo</h1>
                <p>
                  Freedo introduces smart, affordable, and easily accessible
                  mobility options by offering its premium range of two-wheeler
                </p>

                <a
                  href="#"
                  className="freedo-primary-button rounded-pill text-dark"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  {" "}
                  Get Started{" "}
                </a>

                {/* <!-- Modal --> */}
                <div
                  className="modal fade startup-form"
                  id="staticBackdrop"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-5 col-sm-5 d-flex">
                            <img src={bannerPartner} className="modelimg" />
                          </div>
                          <div className="col-md-7 col-sm-12 d-flex">
                            <div className="form-wrapper-partner ">
                              <ul
                                className="nav nav-pills mb-3"
                                id="pills-tab"
                                role="tablist"
                              >
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link active"
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                  >
                                    Company Details
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link"
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                  >
                                    Address Details
                                  </button>
                                </li>
                              </ul>
                              <div
                                className="tab-content"
                                id="pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="pills-home"
                                  role="tabpanel"
                                  aria-labelledby="pills-home-tab"
                                >
                                  <form>
                                    <div className="row">
                                      {companyFormArray.map(
                                        ({
                                          name,
                                          type,
                                          placeholder,
                                          label,
                                          maxLength,
                                          errorMsg,
                                          restrictFuntion,
                                          imgSrc,
                                        }) => (
                                          <Form
                                            imgSrc={imgSrc}
                                            type={type}
                                            placeholder={placeholder}
                                            label={label}
                                            id="autoSizingInputGroup"
                                            value={state[name]}
                                            onChange={onChange}
                                            name={name}
                                            maxLength={maxLength}
                                            errorMsg={errorMsg}
                                            restrictFuntion={restrictFuntion}
                                          />
                                        )
                                      )}
                                      <div className="text-center mt-lg-4 mt-md-3 mt-2">
                                        <button className="freedo-primary-button rounded-pill text-dark"> 
                                          Save & Next
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>

                                <div
                                  className="tab-pane fade"
                                  id="pills-profile"
                                  role="tabpanel"
                                  aria-labelledby="pills-profile-tab"
                                >
                                  <form>
                                    <div className="row">
                                      {addressFormArray.map(
                                        ({
                                          name,
                                          type,
                                          placeholder,
                                          label,
                                          maxLength,
                                          errorMsg,
                                          restrictFuntion,
                                          imgSrc,
                                        }) => (
                                          <Form
                                            imgSrc={imgSrc}
                                            type={type}
                                            placeholder={placeholder}
                                            label={label}
                                            id="autoSizingInputGroup"
                                            value={state[name]}
                                            onChange={onChange}
                                            name={name}
                                            maxLength={maxLength}
                                            errorMsg={errorMsg}
                                            restrictFuntion={restrictFuntion}
                                          />
                                        )
                                      )}
                                      <div className="text-center mt-lg-4 mt-md-3 mt-2">
                                        <a className="freedo-primary-button rounded-pill text-dark">
                                          Submit
                                        </a>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="partner-banner-right">
            <img src={bannerPartner} />
          </div>
        </div>
      </div>

      <section className="partner-services-wrap">
        <div className="container-fluid text-center">
          <h2 className="rental-head">Why Become A Freedo Partner</h2>

          <div className="partner-services-icon-row">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="services-icons">
                  <img src={govt} alt="" />
                  Govt. Authorized Two Wheeler Rentals
                </div>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id
                  bibendum risus. Maecenas consectetur metus id dolor malesuada,
                  et sodales nibh egestas.
                </p>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="services-icons">
                  <img src={freeservice} alt="" />
                  Your Digital Platform
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id
                  bibendum risus. Maecenas consectetur metus id dolor malesuada,
                  et sodales nibh egestas.
                </p>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="services-icons">
                  <img src={wideRange} alt="" />
                  Use best-in-className fleet management tools
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id
                  bibendum risus. Maecenas consectetur metus id dolor malesuada,
                  et sodales nibh egestas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="freedo-business">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-3 col-md-3 col-sm-4 col-5">
              <img src={freedoType} alt="" />
            </div>
            <div className="col-lg-7 col-md-8 col-sm-12">
              <div className="business-content">
                <h1>
                  The Freedo You Know,
                  <br />
                  Reimagined For
                  <br />
                  Business
                </h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id
                  bibendum risus. Maecenas consectetur metus id dolor malesuada,
                  et sodales nibh egestas.
                </p>

                <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="joinfreedo-wrap">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-9">
              <h2>
                How To Join A Fleet To Drive With
                <br />
                Freedo
              </h2>
            </div>
            <div className="col-md-2 col-sm-3 col-4">
              <span className="d-block mx-auto">
                <img src={freedoCircle} alt="" />
              </span>
            </div>
          </div>
          <div className="join-freedo-content">
            <div className="row">
              <div className="col-md-4">
                <h5>Join Freedo</h5>
                <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying
                </p>
              </div>

              <div className="col-md-4">
                <h5>Connect as a partner</h5>
                <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying
                </p>
              </div>

              <div className="col-md-4">
                <h5>Start renting and earning</h5>
                <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="renting-vehicle-wrap">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <h4>Make Money By Renting Out Your Vehicle</h4>
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying
              </p>
              <a href="#" className="btn rounded-pill freedo-primary-button">
                Join Us Now
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-wrapper">
        <h2 className="rental-head text-center">FAQ's</h2>
        <div className="container-fluid">
          <div className="faq-wrap">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    How to book our bike rentals in Bangalore?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                    vero alias neque repellendus unde recusandae possimus harum
                    similique nulla? Temporibus laboriosam ea natus asperiores
                    fugiat eum amet nostrum nam unde.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Do Freedo Rentals give helmets with vehicles?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                    vero alias neque repellendus unde recusandae possimus harum
                    similique nulla? Temporibus laboriosam ea natus asperiores
                    fugiat eum amet nostrum nam unde.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    // aria-expanded="ture"
                    aria-controls="collapseThree"
                  >
                    What are the documents required for online two-wheeler
                    rental booking?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Booking bike and scooter rentals at Freedo is quite easy.
                    All you have to do is provide your original driving license
                    (a learner license is not applicable) and submit any
                    original government ID proof by the end of the booking.
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center faq-showmore">
              <a
                href="/faq"
                className="freedo-outline-btn-small rounded-pill"
              >
                Show More
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
