import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RentingFleetCard from "../../component/RentingFleetCard";
import { LocalStorage } from "../../constant/enum";
import { Routes } from "../../constant/routeContants";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";
import { getLocalStorageData } from "../../utills/helper";

const FreedoRentingFleet = () => {
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { rentingFleet } = locale["home"];
  const { packages, vehicleList } = useSelector(
    (state: RootState) => state.homeReducer
  );

 
  const [packageName, setPackageName] = useState("");
  const [selectedPackage, setSelectedPackage] = useState({});
  const [vehicles, setVehicles] = useState<any>([]);
  const [showVehicles, setShowVehicles] = useState({
    all: true,
    bikes: false,
    scooters: false,
  });

  useEffect(() => {
    dispatch(
      actions.getPackageMasters({
        id: cityData?.id,
        type: "GetPackages",
      })
    );
  }, [cityData?.id]);

  useEffect(() => {
    setVehicles(vehicleList?.vehicleModelList || []);
  }, [vehicleList]);

  useEffect(() => {
    if (packages?.length && cityData) {
      let currentPackage =
        packages.find((item: any) => item.package_name === packageName) ||
        packages[0];
      let vehicleType = showVehicles?.bikes
        ? "bike"
        : showVehicles?.scooters
        ? "scooter"
        : "";
      setSelectedPackage(currentPackage);
      let payload = {
        cityId: cityData?.id,
        packageId: currentPackage.id,
        vehicleType,
      };
      dispatch(
        actions.getVehicleList({
          payload,
          primaryFilter: true,
        })
      );
    }
  }, [packageName, packages?.length, showVehicles, cityData?.id]);

  const handleShowMore = () => {
    navigate(Routes.RENT);
  };
  return (
    <section className="Renting-wrapper">
      <div className="container-fluid">
        <div className="rent-wrap">
          <h2>{rentingFleet["RentingFleet"]}</h2>
          <div className="rent-tab-wrapper">
            <div className="rent-tab-wrap">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                {(rentingFleet["Show"] as []).map((item: any, ind) => (
                  <li className="nav-item" role="presentation" key={item.name}>
                    <button
                      className={`nav-link ${ind == 0 && "active"}`}
                      id={`pills-${item.value}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#pills-${item.value}`}
                      type="button"
                      role="tab"
                      aria-controls={`pills-${item.value}`}
                      aria-selected="true"
                      onClick={(e) =>
                        setShowVehicles((pre) => {
                          return {
                            all: false,
                            bikes: false,
                            scooters: false,
                            [item.name]: true,
                          };
                        })
                      }
                    >
                      {item.value}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="rent-days-wrap">
              <form>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setPackageName(e.target.value);
                  }}
                  value={packageName}
                >
                  {packages &&
                    packages?.map((item: any, ind: number) => (
                      <option id={item.id} key={ind}>
                        {item.package_name}
                      </option>
                    ))}
                </select>
              </form>
            </div>

            {/* <!-- Renting Fleet data  --> */}

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {vehicleList?.vehicleModelList?.length ? (
                  <div className="vehicle-wrapper">
                    {vehicleList?.vehicleModelList?.map((item: any, ind: number) => {
                      if (ind < 4)
                        return (
                          <RentingFleetCard
                            key={ind}
                            ind={ind}
                            data={item}
                            rentingFleet={rentingFleet}
                            filters={{ selectedPackage }}
                            imagePath={
                              vehicleList?.vehicleModelImagePath
                                ?.vehicleImagePath
                            }
                            isHomePage={true}
                          />
                        );
                    })}
                  </div>
                ) : (
                  <p className="text-center mt-2">No records found</p>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {vehicles?.length > 0 && (
                <button
                  className="freedo-outline-btn rounded-pill"
                  onClick={() => handleShowMore()}
                >
                  {rentingFleet["ShowMore"]}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreedoRentingFleet;
