import { call, put, takeLatest } from "redux-saga/effects";
import { GLOBAL_ACTION, PROFILE_ACTIONS } from "../actions/actionTypes";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../utills/helper";
import bookingServices from "../services/bookingServices";
import { modalMessages } from "../../constant/enum";

const {
  GET_BOOKING_LIST,
  SET_BOOKING_LIST,
  SET_BOOKING_DETAILS_BY_ID,
  GET_BOOKING_DETAILS_BY_ID,
  SET_EXTEND_DETAILS,
  GET_EXTEND_DETAILS,
  SET_CURRENT_BOOKING,
  GET_CURRENT_BOOKING,
  SET_EXTEND_PACKAGE,
  GET_EXTEND_PACKAGE,
  SET_PRICE_DETAILS_BY_ID,
  GET_PRICE_DETAILS_BY_ID,
  END_RIDE,
  SET_DOCUMENT_DETAILS_BY_ID,
  GET_DOCUMENT_DETAILS_BY_ID,
  GET_BRANCH,
  EXTEND_RIDE,
  ODOMETER_READING,
  PAY_EXTRA_CHARGES,
  GET_MITC_LIST,
  GET_CANCELLATION_CHARGES,
  CANCLE_BOOKING,
  CHECK_REFUND_STATUS,
  SET_CANCELLATION_CHARGES,
  SET_CANCLE_LOADER,
  CANCEL_SUCCESS,
  SET_REFUND_STATUS,
  GET_BOOKING_STATUS,
  SET_BOOKING_STATUS,
  SET_BOOKING_LIST_LOADER,
  GET_MODIFICATION_CHARGES,
  SET_MODIFICATION_CHARGES,
  GET_MODEL_AVAILABLE_DATES,
  SET_MODEL_AVAILABLE_DATES,
  MODIFY_BOOKING,
  GET_BOOKING_CHANGE_CHARGES,
  SET_BOOKING_CHANGE_CHARGES
} = PROFILE_ACTIONS;

const { SHOW_API_SUCCEED_MODAL, SHOW_API_FAILED_MODAL, SET_MODAL_MESSAGE } =
  GLOBAL_ACTION;

const { modifyBookingSuccess, modifyBookingFailed } = modalMessages;

function* getBookingList(action: any): any {
  try {
    yield put({
      type: SET_BOOKING_LIST_LOADER,
      payload: true,
    });
    const data = yield call(bookingServices.getBookingList, action.payload);
    if (data?.status === 200) {
      yield put({
        type: SET_BOOKING_LIST,
        payload: data.data,
      });
      yield put({
        type: SET_BOOKING_LIST_LOADER,
        payload: false,
      });
    } else {
      yield put({
        type: SET_BOOKING_LIST_LOADER,
        payload: false,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: SET_BOOKING_LIST_LOADER,
      payload: false,
    });
    toastr.error("", handleMessage(error));
  }
}
function* getBookingDetailsById(action: any): any {
  try {
    const data = yield call(
      bookingServices.getBookingDetailsById,
      action.payload
    );
    if (data?.status === 200) {
      yield put({
        type: SET_BOOKING_DETAILS_BY_ID,
        payload: data.data,
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}
function* getBookingExtendDetails(action: any) {
  try {
    const data = yield call(bookingServices.getExtendDetails, action.payload);
    if (data?.status === 200) {
      yield put({
        type: SET_EXTEND_DETAILS,
        payload: data.data,
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}
function* getCurrentBookingById(action: any) {
  try {
    const data = yield call(bookingServices.getCurrentBooking);

    if (data?.status === 200) {
      yield put({
        type: SET_CURRENT_BOOKING,
        payload: data.data,
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}
function* getExtendRidePackage(action: any) {
  try {
    const data = yield call(
      bookingServices.getPackageForExtendRide,
      action.payload
    );
    if (data?.status === 200) {
      yield put({
        type: SET_EXTEND_PACKAGE,
        payload: data.data?.planDetails,
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}
function* getPriceDetailById(action: any) {
  try {
    const data = yield call(bookingServices.getPriceDetailById, action.payload);
    action.handleSuccess();
    if (data?.status === 200) {
      yield put({
        type: SET_PRICE_DETAILS_BY_ID,
        payload: data.data,
      });
    } else {
      toastr.error("", data?.response?.data?.error);
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}

function* endRideRequested(action: any) {
  try {
    const data = yield call(bookingServices.endRide, action.id, action.payload);
    if (data?.status === 200) {
      action.handleSuccess(data?.data?.response);
    } else {
      action.setLoader && action.setLoader(false);
      toastr.error("", handleMessage(data));
    }
  } catch (error: any) {
    action.setLoader && action.setLoader(false);
    toastr.error("", handleMessage(error));
  }
}
function* getDocumentDetailsById(action: any) {
  try {
    const data = yield call(bookingServices.getDocumentsById, action.payload);
    if (data?.status === 200) {
      action.handleSuccess();
      yield put({
        type: SET_DOCUMENT_DETAILS_BY_ID,
        payload: data.data,
      });
    } else {
      action.handleSuccess();
      yield put({
        type: SET_DOCUMENT_DETAILS_BY_ID,
        payload: {},
      });
      // toastr.error("", handleMessage(data));
    }
  } catch (error) {}
}

function* getBranches(action: any) {
  try {
    const data = yield call(bookingServices.getBranches, action.payload);
    if (data?.status === 200) {
      console.log(data, 8889);
      // action.callback(data?.data?.Result||[]) // call callback function
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}
function* extendRide(action: any) {
  try {
    const data = yield call(bookingServices.extendRide, action.payload);
    if (data?.status === 200) {
      // toastr.success("", handleMessage(data));
      action.handleSuccess(data?.data); // call callback function
    } else {
      action?.setExtendRideLoader(false);
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    action?.setExtendRideLoader(false);
    toastr.error("", handleMessage(error));
  }
}
function* odometerReading(action: any) {
  try {
    const data = yield call(
      bookingServices.uploadOdometerReading,
      action.payload
    );
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      action.handleSuccess(data?.data);
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}

function* payExtraCharges(action: any) {
  try {
    const data = yield call(bookingServices.payExtraCharges, action.payload);
    if (data?.status === 200) {
      // toastr.success("", handleMessage(data));
      action.handleSuccess(data?.data);
    } else {
      action?.setExtraChargeLoader(false);
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    action?.setExtraChargeLoader(false);
    toastr.error("", handleMessage(error));
  }
}

function* getAllMitcList(action: any): any {
  try {
    const { handleSuccess, payload, handleError } = action;
    const data = yield call(bookingServices.getAllMitcList, payload);
    if (data?.status === 200) {
      handleSuccess(data?.data?.Result || []);
    } else {
      handleError();
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}

function* getCancellationCharge(action: any): any {
  try {
    yield put({
      type: SET_CANCLE_LOADER,
      payload: true,
    });
    const { payload } = action;
    const data = yield call(bookingServices.getCancellationCharge, payload);
    console.log(data, "getCancellationCharge");
    if (data?.status === 200) {
      yield put({
        type: SET_CANCELLATION_CHARGES,
        payload: data?.data?.Result || {},
      });
    } else {
      yield put({
        type: SET_CANCELLATION_CHARGES,
        payload: {},
      });
      toastr.error("", handleMessage(data));
    }
    yield put({
      type: SET_CANCLE_LOADER,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: SET_CANCLE_LOADER,
      payload: false,
    });
    yield put({
      type: SET_CANCELLATION_CHARGES,
      payload: {},
    });
    toastr.error("", handleMessage(error));
  }
}

function* cancleBooking(action: any): any {
  try {
    yield put({
      type: SET_CANCLE_LOADER,
      payload: true,
    });
    const { payload } = action;
    const data = yield call(bookingServices.cancleBooking, payload);
    if (data?.status === 200) {
      toastr.success(
        "Cancellation Successful",
        "You will receive refund in your account within 5-6 working days"
      );
      yield put({
        type: CANCEL_SUCCESS,
        payload: true,
      });
    } else {
      toastr.error("", handleMessage(data));
    }
    yield put({
      type: SET_CANCLE_LOADER,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: SET_CANCLE_LOADER,
      payload: false,
    });
    toastr.error("", handleMessage(error));
  }
}

function* checkRefundStatus(action: any): any {
  try {
    const { payload } = action;
    const data = yield call(bookingServices.checkRefundStatus, payload);
    if (data?.status === 200) {
      yield put({
        type: SET_REFUND_STATUS,
        payload: data?.data?.Result || {},
      });
    } else {
      yield put({
        type: SET_REFUND_STATUS,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: SET_REFUND_STATUS,
      payload: {},
    });
  }
}
function* getBookingStatus(action: any): any {
  try {
    const { payload } = action;
    const data = yield call(bookingServices.getBookingStatus, payload);
    if (data?.status === 200) {
      yield put({
        type: SET_BOOKING_STATUS,
        payload: data?.data?.Result || {},
      });
    } else {
      yield put({
        type: SET_BOOKING_STATUS,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: SET_BOOKING_STATUS,
      payload: {},
    });
  }
}

function* getModificationCharges(action: any): any {
  const { payload,callback } = action;
  try {
    const data = yield call(bookingServices.getModificationCharges, payload);
    if (data?.status === 200) {
      callback(true)
      yield put({
        type: SET_MODIFICATION_CHARGES,
        payload: data?.data?.Result?.charge || 0,
      });
    } else {
      callback(false)
      yield put({
        type: SET_MODIFICATION_CHARGES,
        payload: 0,
      });
    }
  } catch (error) {
    callback(false)
    yield put({
      type: SET_MODIFICATION_CHARGES,
      payload: 0,
    });
  }
}
function* getBookingChangeCharges(): any {
  try {
    const data = yield call(bookingServices.getBookingChangeCharges);
    if (data?.status === 200) {
      yield put({
        type: SET_BOOKING_CHANGE_CHARGES,
        payload: data?.data?.Result?.modificationChargesData || [],
      });
    } else {
      yield put({
        type: SET_BOOKING_CHANGE_CHARGES,
        payload: [],
      });
    }
  } catch (error) {
    yield put({
      type: SET_BOOKING_CHANGE_CHARGES,
      payload: [],
    });
  }
}


function* getModelAvailableDates(action: any): any {
  try {
    const { payload } = action;
    const data = yield call(bookingServices.getModelAvailableDates, payload);
    if (data?.status === 200) {
      yield put({
        type: SET_MODEL_AVAILABLE_DATES,
        payload: data?.data?.Result || {},
      });
    } else {
      yield put({
        type: SET_MODEL_AVAILABLE_DATES,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: SET_MODEL_AVAILABLE_DATES,
      payload: {},
    });
  }
}

function* modifyBooking(action): any {
  try {
    const { payload, callback } = action;
    const data = yield call(bookingServices.modifyBooking, payload);
    if (data?.status === 200) {
      console.log(data, "response");
      if (data?.data?.Result?.amount > 0) {
        callback(data?.data?.Result);
      } else {
        yield put({
          type: GET_BOOKING_DETAILS_BY_ID,
          payload: { booking_id: data?.data?.Result?.booking_id },
        });
        yield put({
          type: SHOW_API_SUCCEED_MODAL,
          payload: true,
        });
        yield put({
          type: SET_MODAL_MESSAGE,
          payload: modifyBookingSuccess,
        });
      }
    } else {
      yield put({
        type: SHOW_API_FAILED_MODAL,
        payload: true,
      });
      yield put({
        type: SET_MODAL_MESSAGE,
        payload: modifyBookingFailed,
      });
    }
  } catch (error) {
    yield put({
      type: SHOW_API_FAILED_MODAL,
      payload: true,
    });
    yield put({
      type: SET_MODAL_MESSAGE,
      payload: modifyBookingFailed,
    });
  }
}

function* bookingSaga() {
  yield takeLatest(GET_BOOKING_LIST, getBookingList);
  yield takeLatest(GET_BOOKING_DETAILS_BY_ID, getBookingDetailsById);
  yield takeLatest(GET_EXTEND_DETAILS, getBookingExtendDetails);
  yield takeLatest(GET_CURRENT_BOOKING, getCurrentBookingById);
  yield takeLatest(GET_EXTEND_PACKAGE, getExtendRidePackage);
  yield takeLatest(GET_PRICE_DETAILS_BY_ID, getPriceDetailById);
  yield takeLatest(END_RIDE, endRideRequested);
  yield takeLatest(GET_DOCUMENT_DETAILS_BY_ID, getDocumentDetailsById);
  yield takeLatest(GET_BRANCH, getBranches);
  yield takeLatest(EXTEND_RIDE, extendRide);
  yield takeLatest(ODOMETER_READING, odometerReading);
  yield takeLatest(PAY_EXTRA_CHARGES, payExtraCharges);
  yield takeLatest(GET_MITC_LIST, getAllMitcList);
  yield takeLatest(GET_CANCELLATION_CHARGES, getCancellationCharge);
  yield takeLatest(CANCLE_BOOKING, cancleBooking);
  yield takeLatest(CHECK_REFUND_STATUS, checkRefundStatus);
  yield takeLatest(GET_BOOKING_STATUS, getBookingStatus);
  yield takeLatest(GET_MODIFICATION_CHARGES, getModificationCharges);
  yield takeLatest(GET_MODEL_AVAILABLE_DATES, getModelAvailableDates);
  yield takeLatest(MODIFY_BOOKING, modifyBooking);
  yield takeLatest(GET_BOOKING_CHANGE_CHARGES, getBookingChangeCharges);
}
export default bookingSaga;
