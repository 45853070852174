import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actions } from "../redux";
import {
  capitalizeFirstLetter,
  commmaSeparator,
  findEndDate,
  getLocalStorageData,
  toggleSignInDrawer,
} from "../utills/helper";
import moment from "moment";
import { RootState } from "../redux/store";
import CustomModalVehicleDetail from "../component/CustomModalVehicleDetail";
import { setBookingData } from "../redux/actions";
import { LocalStorage } from "../constant/enum";
import { Routes } from "../constant/routeContants";
import { Button1 } from "./Button";
import { FirebaseEvents } from "../utills/firebaseEvents";
interface rentingFleetInterface {
  data: {
    brandName: string;
    TotalPrice: string;
    price: number;
    PerDay: string;
    UnlimitedKms: string;
    ZeroDepositFee: string;
    BookVehicle: string;
    modelName: string;
    isUnlimitedKM: boolean;
    isZeroDeposit: boolean;
    imageName: string;
    modelId: number | null;
    brandId: number | null;
    planPackageId: number | null;
    tag: string;
    packageDays: number | any;
  };
  ind: number;
  rentingFleet: any;
  filters?: any;
  imagePath: string;
  isHomePage:boolean
}
interface vecleInterface {
  type: string;
  brand: string;
  model: string;
  consumption: string;
  hp: string;
  cc: string;
  images: Array<string>;
}

const initialState = {
  type: "",
  brand: "",
  model: "",
  consumption: "",
  hp: "",
  cc: "",
  images: [],
};
const RentingFleetCard = ({
  data,
  ind,
  rentingFleet,
  filters,
  imagePath,
  isHomePage
}: rentingFleetInterface) => {
  const {
    brandName,
    price,
    modelName,
    isUnlimitedKM,
    isZeroDeposit,
    imageName,
    modelId,
    brandId,
    tag,
    packageDays,
  } = data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const { isAuthenticated, userData } = useSelector(
    (state: RootState) => state.authReducer
  );
  const { package_days } = filters.selectedPackage;

  const [rentingFleetLoader, setRentingFleetLoader] = useState<boolean>(false);

  const handleRentingFleetLoader = () => {
    setRentingFleetLoader(false)
    setOpenDetailModal(false)
  };
  const handleBookVehicle = () => {
    let startDate = filters?.startDate
      ? moment(filters?.startDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"); // if no start date is there, then todays date will be taken
    let endDate = findEndDate(startDate, package_days);
    let payload = {
      city_id: cityData?.id,
      model_id: modelId,
      start_date: startDate,

      end_date: endDate ? endDate : null,
      packageId: filters?.selectedPackage?.id,
      sourceId: brandId,
    };
    let journyData = {
      city: cityData || null,
      date: startDate,
      endDate: endDate || null,
      package: filters?.selectedPackage,
    };
    if (isHomePage){
      FirebaseEvents("HomePageBookNow", {package:package_days+'day'});
      console.log({package:package_days+'day'},'HomePageBookNow')
    }
    else{
      FirebaseEvents("Bike_Continue", {});
      console.log("Bike_Continue", {})
    }
    if (!isAuthenticated || Object.keys(userData)?.length == 0) {
      setRentingFleetLoader(false);
      setOpenDetailModal(false)
      dispatch(setBookingData({ payload, journyData }));
      toggleSignInDrawer()
    } else {
      setRentingFleetLoader(true);
      dispatch(
        actions.getVehicleData({
          payload,
          navigate,
          journyData,
          handleRentingFleetLoader,
        })
      );
    }
  };

  return (
    <div className="card" key={ind}>
      <div>
        <img
          onClick={() => {
            setOpenDetailModal(!openDetailModal);
          }}
          data-bs-toggle="modal"
          data-bs-target="#bookvehicle"
          style={{ cursor: "pointer" }}
          src={`${imagePath}${imageName}`}
          alt=""
        />
        <div className={`populer-tag ${!tag && "invisible"}`}>
          {capitalizeFirstLetter(tag)}
        </div>
        <h6 className="bike-brand">{brandName}</h6>
        <div className="name-price-wrap">
          <h6
            onClick={() => {
              setOpenDetailModal(!openDetailModal);
            }}
          >
            {modelName}
          </h6>
          <p className="price">
            {commmaSeparator(price)}
            {"/-"}
          </p>
        </div>
        <p className="perday-rent">
          {Math.ceil(price / packageDays)}
          {"/-"} <i>{rentingFleet.PerDay}</i>
        </p>
        <div className="deposite-wrap">
          {isUnlimitedKM && (
            <span>
              <i className="fa-solid fa-infinity"></i>{" "}
              {rentingFleet.UnlimitedKms}
            </span>
          )}
          {isZeroDeposit && (
            <span>
              <i className="fa-solid fa-ban"></i> {rentingFleet.ZeroDepositFee}
            </span>
          )}
        </div>
      </div>
      <div className="footer-btn">
        <button
          onClick={handleBookVehicle}
          disabled={rentingFleetLoader}
          className="freedo-primary-button rounded-pill"
          
        >
          {rentingFleetLoader && !openDetailModal ? (
            <div className="spinner-border spinner-border-sm text-light">
              <div className="visually-hidden" aria-hidden="true">Loading...</div>
            </div>
          ) : (
            <>{rentingFleet.BookVehicle}</>
          )}
        </button>
      </div>
      {openDetailModal && (
        <CustomModalVehicleDetail
          setOpenDetailModal={setOpenDetailModal}
          handleBookVehicle={handleBookVehicle}
          showBookVehicle={true}
          ind={ind}
          openDetailModal={openDetailModal}
          modelId={modelId}
          rentingFleetLoader={rentingFleetLoader}
        />
      )}
    </div>
  );
};

export default RentingFleetCard;
