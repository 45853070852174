import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { userAvatar, helpCrm, circleCheckSuccess } from "../assets";
import { getSupportQueryList } from "../redux/actions";
import profileServices from '../redux/services/profileServices'
import { validate } from "../utills/helper";
import { RootState } from "../redux/reducers";
import { toastr } from "react-redux-toastr";
import { Button1 } from "./Button";
import en from "../locales/en.json";
import { hindiTextValidation } from "../utills/helper";
import { langErrorMsg } from "../screen/city-freedo/constants/constants";
const HelpModal = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [issuesList, setIssuesList] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    ticketTypeId: 0,
    description: "",
  });
  const [errorFields, setErrorFields] = useState<any>({
    ticketTypeId: "",
    description: "",
  });
  const { supportQueriesList, userProfileDetail } = useSelector(
    (state: RootState) => state.profileReducer
  );

  useEffect(() => {
    dispatch(getSupportQueryList());
  }, []);
  useEffect(() => {
    supportQueriesList.length && setIssuesList(supportQueriesList);
  }, [supportQueriesList]);
  const handleClose = () => {
    setFormData({
      ticketTypeId: 0,
      description: "",
    })
    setIsLoading(false)
    setIsSubmitted(false);
    setShowModal(false);
  };
  const handleOnChange = (e: any) => {
    const hindiValidation = hindiTextValidation(e.target.value);
    if(hindiValidation){
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrorFields((prevState) => ({ ...prevState, [name]: "" }));
    }
    else{
      setErrorFields({ ...errorFields, description:langErrorMsg });
    }
  };
  const validateForm = (e: any) => {
    e.preventDefault();
    const validation = validate(formData, errorFields);
    if (validation.error) {
      return setErrorFields({ ...errorFields, ...validation.errorFields });
    }
    submitForm();
  };
  const submitForm = () => {
    console.log(formData)
    setIsLoading(true)
    const payload = {...formData, phone:userProfileDetail?.mobile_number ?? ""}
    profileServices.saveHelpDeskQuery(payload).then((resp:any)=>{
      setIsLoading(false)
      if(resp?.data?.IsSuccess){
        setIsSubmitted(true);
      }
      else{ 
        toastr.error("",resp?.data?.Message)
      }
      
    }).catch((err:any)=>{
      setIsLoading(false)
      toastr.error("",en.errorMessages.SomethingWentWrong)
      console.log(err)
    })
  };
  return (
    <>
      <div
        // onClick={() => setShowModal(true)}
        className={`text-end need-help-btn`}
      >
        <a
          href=""
          onClick={(e) => {
            setShowModal(true);
            e.preventDefault();
          }}
          // className="mx-1"
        >
          {en.contact.needHelpBtn}
          <img src={helpCrm} alt=""></img>
        </a>
       
      </div>
      <Modal
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        className="modal fade vehicle-model"
        id="help-modal"
        tabIndex={-1}
        aria-hidden="true"
        onHide={handleClose}
      >
        <Modal.Header closeButton className="">
          <Modal.Title className="text-center ">
            <p className="m-auto" id="bookvehicleLabel">
             {en.contact.needHelpBtn}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isSubmitted ? (
            <div className="row justify-content-center pb-3">
              <div>
                <label className="form-label">Issue Category *</label>
                <select
                  value={formData.ticketTypeId || "0"}
                  className="form-select"
                  onChange={handleOnChange}
                  name="ticketTypeId"
                >
                  <option value={"0"}>Select issue category</option>
                  {issuesList.map((item, ind) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.issue_name}
                    </option>
                  ))}
                </select>
                <div>
                  {errorFields.ticketTypeId !== "" && (
                    <span className="error-field">
                      {errorFields.ticketTypeId}
                    </span>
                  )}
                </div>
                <label className="form-label pt-3">
                {`${en.contact.WriteMessage}*`}
                </label>
                <textarea
                  value={formData.description}
                  name={"description"}
                  className="form-control  w-100"
                  onChange={handleOnChange}
                  maxLength={300}
                  rows={4}
                  placeholder={en.contact.writeMessagePlaceHolder}
                />
                <div>
                  {errorFields.description !== "" && (
                    <span className="error-field">
                      {errorFields.description}
                    </span>
                  )}
                </div>
                <Button1
            disabled={isLoading}
            type="button"
            className="freedo-primary-button rounded-pill w-100 mt-3"
            loading={isLoading}
            title={"Submit"}
            onClick={validateForm}
          />
              </div>
            </div>
          ) : (
            <div className="row pb-3 help-success">
              <p>{en.contact.freedoSupport}</p>
              {/* <img src={circleCheckSuccess} alt=""></img> */}
              <div className="checkmark-wrapper">
                <span className="checkmark"></span>
              </div>
              <p>{en.contact.querySubmitted}</p>
              <p>{en.contact.teamwillAnswer}</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export { HelpModal };
