import Skeleton from "react-loading-skeleton";

export default () => {
  return (
    <div className="billing-wrap">
      <div className="table-responsive">
        <table className={`table table-borderless table-sm`}>
          <tbody>
            {[1, 2, 3, 4, 5].map((_keys) => {
              return (
                <tr key={_keys}>
                  <td>
                    <Skeleton width={"200px"} height={"20px"} />
                  </td>
                  <td>
                    <Skeleton width={"40px"} height={"20px"} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <hr />
        <table className={`table table-borderless table-sm`}>
          <tbody>
            <tr>
              <td>
                <Skeleton width={"200px"} height={"20px"} />
              </td>
              <td>
                <Skeleton width={"40px"} height={"20px"} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
