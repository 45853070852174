export const REGEX = {
  alfa: /^[a-zA-Z ]*$/,
  alfaNumeric: /^[0-9a-zA-Z ]*$/,
  phone: new RegExp("^[6-9]([0-9]*)$"),
  numeric: /^[0-9]*$/,
  float: /^[+-]?[0-9]+([.][0-9]+)?([eE][+-]?[0-9]+)?$/,
  pin: /^[0-9]*$/,
};
export const DECRYPTION_KEY = "53A999C4D43A535AGE6E3EE5A54G517C";
export const checkAlfaNumeric = (value: string) => {
  let valid = true;
  if (!REGEX.alfaNumeric.test(value)) {
    valid = false;
  }
  return valid;
};

export const checkAlfa = (value: string) => {
  let valid = true;
  if (!REGEX.alfa.test(value)) {
    valid = false;
  }
  return valid;
};

export const checkNumeric = (value: string) => {
  let valid = true;
  if (!REGEX.numeric.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

export const checkFloat = (value: string) => {
  let valid = true;
  if (!REGEX.float.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

export const checkPhone = (value: string) => {
  let valid = value;
  if (!REGEX.phone.test(value) && value !== "") {
    valid = "";
  }
  return valid;
};

export const checkPin = (value: string) => {
  let valid = true;
  if (!REGEX.pin.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};
export const checkNumberInput = (value: string) => {
 
    if (!checkNumeric(value)) {
      return value.slice(0, -1)
    }
    else{
      return value

    }
  
};