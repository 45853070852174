

const Services = ({data}) => {
  return (
    <section class="services-wrap">
      <div class="container-fluid text-center">
        <h2 class="rental-head">{data?.heading}</h2>

        <div
          class="services-icon-row"
        >
          <div class="row justify-content-around">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="services-icons">
                <img src={data?.imgOne} alt="" />
                {data?.imgOneText}
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="services-icons">
                <img src={data?.imgTwo} alt="" />
                {data?.imgTwoText}
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="services-icons">
                <img src={data?.imgThree} alt="" />
                {data?.imgThreeText}
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="services-icons">
                <img src={data?.imgFour} alt="" />
                {data?.imgFourText}
              </div>
            </div>
          </div>
          <div class="row justify-content-evenly">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="services-icons">
                <img src={data?.imgFive} alt="" />
                {data?.imgFiveText}
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="services-icons">
                <img src={data?.imgSix} alt="" />
                {data.imgSixText}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
