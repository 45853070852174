import React from "react";
import Webcam from "react-webcam";
import { useState } from "react";
import { toastr } from "react-redux-toastr";
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "user",
};
const TakeSelfieModel = ({
  captureSelfie,
  webcamRef,
  heading,
  selfieModal,
  handleModal,
}) => {
  const [loader, setLoader] = useState<boolean>(true);
  const onWebcamError = (err: any) => {
    setTimeout(() => {
      toastr.error("Oops!", 'Camera is not available / accessable');
      const ele = document.getElementById("openSelfieModal");
      ele && ele.click();
    }, 1000);
    console.log("OnUserMediaError", err);
  };
  return (
    <div
      className="modal fade"
      id="selfieModal"
      // tabindex="-1"
      role="dialog"
      aria-labelledby="selfieModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div
        className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered"
        role="document"
      >
        {selfieModal ? (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mb-2 w-100 text-center">{heading}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleModal();
                  setLoader(true);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                {loader && (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                    style={{ position: "absolute", top: "150px" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
                <Webcam
                  audio={false}
                  height={300}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={320}
                  videoConstraints={videoConstraints}
                  onUserMedia={() => {
                    setLoader(false);
                  }}
                  onUserMediaError={(err: any) => {
                    onWebcamError(err);
                  }}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn freedo-primary-button-small"
                onClick={() => {
                  captureSelfie();
                  setLoader(true);
                }}
                data-bs-dismiss="modal"
              >
                Capture
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TakeSelfieModel;
