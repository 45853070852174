import Skeleton from "react-loading-skeleton";

export default () => {
  return (
    <>
      {[1, 2, 3].map(() => {
        return (
          <a href="#" className="address-box">
            <i className={`address-icon`} aria-hidden="true">
              <Skeleton count={1} height={20} width={20} borderRadius={20} />
            </i>
            <Skeleton count={1} height={20} />
            <Skeleton count={1} height={20} />
            <Skeleton count={1} height={20} />
            <Skeleton count={1} height={20} />
          </a>
        );
      })}
    </>
  );
};
