import { PROFILE_ACTIONS } from "../actionTypes";

const {
  GET_USER_PROFILE,
  GET_USER_KYC_STATUS,
  UPLOAD_IMAGES_TO_S3,
  GET_ADDITIONAL_DOCS_STATUS,
  CREATE_ADDITIONAL_DOC,
  POST_UPDATE_PROFILE,
  SEND_OTP_FOR_EMERGENCY_NUMBER,
  VERIFY_OTP_FOR_EMERGENCY_NUMBER,
  SET_PAGINATION_DATA,
  CLEAR_PAGINATION_DATA,
  GET_QUERIES_LIST
} = PROFILE_ACTIONS;
export const getUserProfile = () => {
  return { type: GET_USER_PROFILE };
};
export const getUserKycStatus = () => {
  return { type: GET_USER_KYC_STATUS };
};
export const uploadImagesToS3 = (payload: any) => {
  return { type: UPLOAD_IMAGES_TO_S3, payload };
};
export const getAdditionaDocsStatus = () => {
  return { type: GET_ADDITIONAL_DOCS_STATUS };
};
export const createAdditionaDocs = (payload: any) => {
  return { type: CREATE_ADDITIONAL_DOC, payload };
};
export const postUpdateUserProfile = (payload: {}) => {
  return { type: POST_UPDATE_PROFILE, payload };
};
export const sendOtpForEmergencyNumber = (payload: {}) => {
  return { type: SEND_OTP_FOR_EMERGENCY_NUMBER, payload };
};
export const verifyOtpForEmergencyNumber = (payload: {}) => {
  return { type: VERIFY_OTP_FOR_EMERGENCY_NUMBER, payload };
};

export const setPaginationData = (payload: any) => {
  return { type: SET_PAGINATION_DATA, payload };
};

export const clearPaginationData = () => {
  return {type: CLEAR_PAGINATION_DATA}
};
export const getSupportQueryList = () => {
  return { type: GET_QUERIES_LIST };
};