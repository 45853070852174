import { GLOBAL_ACTION, HOME_ACTIONS } from "../actionTypes";

const { GET_COUPAN_DATA, GET_FAQ_LIST } = HOME_ACTIONS;
const {
  GET_ADVERTISMENT,
  SET_ADVERTISMENT,
  SET_ENQUIRY,
  GET_VEHICLE_DETAILS,
  GET_MITC_CONTENT,
  SEND_OTP_MITC,
  VERIFY_MITC,
  SET_LOCATION_LOADER,
  CLEAR_API_STATUS,
  SHOW_API_SUCCEED_MODAL,
  SHOW_API_FAILED_MODAL,
  SET_MODAL_MESSAGE,
} = GLOBAL_ACTION;

export const getCoupanData = (payload: any) => {
  return { type: GET_COUPAN_DATA, payload };
};
export const getFAQList = (payload: any) => {
  return { type: GET_FAQ_LIST, payload };
};
export const postContactUs = (payload: any) => {
  return { type: GLOBAL_ACTION.POST_CONTACT_US, payload };
};

export const saveAddress = (payload: any) => {
  return { type: GLOBAL_ACTION.SET_ADDRESS, payload };
};

export const deleteAddress = (payload: any) => {
  return { type: GLOBAL_ACTION.DELETE_ADDRESS, payload };
};

export const getAdvertisement = (payload: any) => ({
  type: GET_ADVERTISMENT,
  payload,
});

export const setAdvertisement = (payload: any) => ({
  type: SET_ADVERTISMENT,
  payload,
});

export const setEnquiryForm = (payload: any, handleSuccess) => ({
  type: SET_ENQUIRY,
  payload,
  handleSuccess,
});

export const getVehicleDetailsAction = (payload: any, handleSuccess) => {
  return { type: GET_VEHICLE_DETAILS, payload, handleSuccess };
};

export const getMitcData = (payload: any, handleSuccess) => ({
  type: GET_MITC_CONTENT,
  payload,
  handleSuccess,
});

export const sendMitcOtp = (payload: any, handleSuccess) => ({
  type: SEND_OTP_MITC,
  payload,
  handleSuccess,
});

export const verifyMitc = (payload: any, handleSuccess) => ({
  type: VERIFY_MITC,
  payload,
  handleSuccess,
});

export const resendOtpMitc = (payload: any) => ({
  type: GLOBAL_ACTION?.RESEND_OTP_MITC,
  payload,
});

export const setLocationLoader = (payload: any) => ({
  type: SET_LOCATION_LOADER,
  payload,
});

export const resetResponseModalState = () => ({
  type: CLEAR_API_STATUS,
});

export const showApiSuccessModal = (payload: any) => ({
  type: SHOW_API_SUCCEED_MODAL,
  payload,
});
export const showApiFailModal = (payload: any) => ({
  type: SHOW_API_FAILED_MODAL,
  payload,
});
export const setModalMessage = (payload: any) => ({
  type: SET_MODAL_MESSAGE,
  payload,
});
