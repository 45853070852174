import { localeInterface } from "../../interfaces/actionInterfaces";
import { RENT_ACTIONS } from "../actions/actionTypes";
const { SET_VEHICLE_TYPE } = RENT_ACTIONS;
const initialState = {
  vehicleType: [],
};
const rentReducres = (state: any = initialState, action: localeInterface) => {
  const { type, payload } = action;
  switch (type) {
    case SET_VEHICLE_TYPE:
      return {
        ...state,
        vehicleType: payload,
      };
    default:
      return { ...state };
  }
};

export default rentReducres;
